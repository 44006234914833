import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_brgm.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=brgm_canalisations&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:3857&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.HAZMAT_TRANSPORT_PIPELINES)) {
        legends.natural_gaz = {
            layerKey: filtersKey.HAZMAT_TRANSPORT_PIPELINES,
            label: 'natural_gaz',
            needsTranslate: true,
            color: '#72A0FD',
            opacity: 0.6,
        };
        legends.hydrocarbons = {
            layerKey: filtersKey.HAZMAT_TRANSPORT_PIPELINES,
            label: 'hydrocarbons',
            needsTranslate: true,
            color: '#FD3232',
            opacity: 0.6,
        };
        legends.chemicals = {
            layerKey: filtersKey.HAZMAT_TRANSPORT_PIPELINES,
            label: 'chemicals',
            needsTranslate: true,
            color: '#D8AE32',
            opacity: 0.6,
        };
    }
    return legends;
};
const useHazmatTransportPipelinesLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.HAZMAT_TRANSPORT_PIPELINES],
        getLegends,
    })(filtersKey.HAZMAT_TRANSPORT_PIPELINES);
};
const HazmatTransportPipelinesLayer = () => {
    useHazmatTransportPipelinesLayer();
    return null;
};
export default HazmatTransportPipelinesLayer;
