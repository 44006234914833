import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Link, Spinner, Text } from '@chakra-ui/react';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import usePLUGPUDocuments from 'hooks/usePLUGPUDocuments';
import { formatDate } from 'utils/formatDate';
const PLUGPUSidebarContent = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const coords = new google.maps.Point(selectedTown?.lat, selectedTown?.lng);
    const { PLUGPUDocuments, isInitialLoadingDocuments } = usePLUGPUDocuments({
        coords,
    });
    return isInitialLoadingDocuments ? (<Center>
      <Spinner size="md"/>
    </Center>) : (PLUGPUDocuments?.name && (<Box marginBottom={1}>
        <Text as="div" textStyle="paragraph">
          <Text textStyle="paragraph" dangerouslySetInnerHTML={{
            __html: t('sidebar_content.plu_gpu.label', {
                type: PLUGPUDocuments.type,
                town: PLUGPUDocuments.town,
            }),
        }}/>{' '}
          <Text as="span" fontWeight="bold">
            {formatDate(PLUGPUDocuments?.date, 'short')}
          </Text>
        </Text>
        <Link href={PLUGPUDocuments?.url} variant="primary">
          {t('sidebar_content.plu_gpu.download')}
        </Link>
      </Box>));
};
export default PLUGPUSidebarContent;
