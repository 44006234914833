import * as React from 'react';
import { useMemo } from 'react';
import AgricultureRearingCompanySidebarContent from 'components/sidebarContent/AgricultureRearingCompanySidebarContent';
import BuildingConsumptionSidebarContent from 'components/sidebarContent/BuildingConsumptionSidebarContent';
import BuildingPermitNewMarketSidebarContent from 'components/sidebarContent/BuildingPermitNewMarketSidebarContent';
import CDACSidebarContent from 'components/sidebarContent/CDACSidebarContent';
import CellTowerSidebarContent from 'components/sidebarContent/CellTowerSidebarContent';
import CityHallSidebarContent from 'components/sidebarContent/CityHallSidebarContent';
import CompaniesSidebarContent from 'components/sidebarContent/CompaniesSidebarContent';
import CompanyOwnedPlotsSidebarContent from 'components/sidebarContent/CompanyOwnedPlotsSidebarContent';
import DataCenterProjectSidebarContent from 'components/sidebarContent/DataCenterProjectSidebarContent';
import DPESidebarContent from 'components/sidebarContent/DPESidebarContent';
import DREALSidebarContent from 'components/sidebarContent/DREALSidebarContent';
import ElectricNetworkCapareseauIsodistanceCarSidebarContent from 'components/sidebarContent/ElectricNetworkCapareseauIsodistanceCarSidebarContent';
import ElectricNetworkCapareseauIsodistanceSidebarContent from 'components/sidebarContent/ElectricNetworkCapareseauIsodistanceSidebarContent';
import FindMoreTextSidebarContent from 'components/sidebarContent/FindMoreTextSidebarContent';
import MRAESidebarContent from 'components/sidebarContent/MRAESidebarContent';
import NewMarketPricesSidebarContent from 'components/sidebarContent/NewMarketPricesSidebarContent';
import OldMarketDVFSidebarContent from 'components/sidebarContent/OldMarketDVFSidebarContent';
import PhotovoltaicNotProneAreaRPGSidebarContent from 'components/sidebarContent/PhotovoltaicNotProneAreaRPGSidebarContent';
import PinelSidebarContent from 'components/sidebarContent/PinelSidebarContent';
import PLUAdditionalSidebarContent from 'components/sidebarContent/PLUAdditionnalSidebarContent';
import PLUGPUSidebarContent from 'components/sidebarContent/PLUGPUSidebarContent';
import RealEstateTaxesSidebarContent from 'components/sidebarContent/RealEstateTaxesSidebarContent';
import ScotSidebarContent from 'components/sidebarContent/ScotSidebarContent';
import SimpleTextSidebarContent from 'components/sidebarContent/SimpleTextSidebarContent';
import SpaceSidebarContent from 'components/sidebarContent/SpaceSidebarContent';
import ThematicMapsNoneSidebarContent from 'components/sidebarContent/ThematicMapsNoneSidebarContent';
import ThematicMapsRealEstateSidebarContent from 'components/sidebarContent/ThematicMapsRealEstateSidebarContent';
import WindMRAESidebarContent from 'components/sidebarContent/WindMRAESidebarContent';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { filtersKey } from 'utils/filters';
const CONTENT_TYPES = {
    [filtersKey.PLU_ADDITIONAL]: PLUAdditionalSidebarContent,
    [filtersKey.PINEL]: PinelSidebarContent,
    [filtersKey.SCOT]: ScotSidebarContent,
    [filtersKey.REAL_ESTATE_TAXES]: RealEstateTaxesSidebarContent,
    [filtersKey.NEW_MARKET_PROGRAMS]: NewMarketPricesSidebarContent,
    [filtersKey.BUILDING_PERMIT_HOUSING]: BuildingPermitNewMarketSidebarContent,
    [filtersKey.BUILDING_PERMIT_OFFICES]: BuildingPermitNewMarketSidebarContent,
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMIT]: BuildingPermitNewMarketSidebarContent,
    [filtersKey.CELL_TOWER]: CellTowerSidebarContent,
    [filtersKey.BUILDING_CONSUMPTION]: BuildingConsumptionSidebarContent,
    [filtersKey.CDAC]: CDACSidebarContent,
    [filtersKey.DPE]: DPESidebarContent,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST]: PhotovoltaicNotProneAreaRPGSidebarContent,
    [filtersKey.OFFICE_BUILDINGS_MAIN]: SimpleTextSidebarContent,
    [filtersKey.THEMATIC_MAPS_NONE]: ThematicMapsNoneSidebarContent,
    [filtersKey.THEMATIC_MAPS_REAL_ESTATE]: ThematicMapsRealEstateSidebarContent,
    [filtersKey.NEIGHBORHOODS_INFOS]: CityHallSidebarContent,
    [filtersKey.COMPANY_OWNED_PLOTS]: CompanyOwnedPlotsSidebarContent,
    [filtersKey.LIGHT_VAT_NEIGHBORHOODS]: SimpleTextSidebarContent,
    [filtersKey.LIGHT_VAT_ESTATE]: SimpleTextSidebarContent,
    [filtersKey.NO_BUILDING_ALLOWED_ENVIRONMENTAL]: SimpleTextSidebarContent,
    [filtersKey.LIMITED_BUILDING_ALLOWED_ENVIRONMENTAL]: SimpleTextSidebarContent,
    [filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS]: SimpleTextSidebarContent,
    [filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_AERIAL]: SimpleTextSidebarContent,
    [filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL]: SimpleTextSidebarContent,
    [filtersKey.WIND_POTENTIAL_140]: SimpleTextSidebarContent,
    [filtersKey.RADAR]: SimpleTextSidebarContent,
    [filtersKey.WIND_UNESCO_NATURAL]: SimpleTextSidebarContent,
    [filtersKey.WIND_CONSTRAINT_HOUSING]: SimpleTextSidebarContent,
    [filtersKey.WIND_BIRD_AVIFAUNA]: SimpleTextSidebarContent,
    [filtersKey.MRAE]: MRAESidebarContent,
    [filtersKey.DREAL]: DREALSidebarContent,
    [filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]: ElectricNetworkCapareseauIsodistanceSidebarContent,
    [filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR]: ElectricNetworkCapareseauIsodistanceCarSidebarContent,
    [filtersKey.AGRICULTURE_REARING_COMPANY]: AgricultureRearingCompanySidebarContent,
    [filtersKey.ZAER_GEOTHERMAL]: SimpleTextSidebarContent,
    [filtersKey.OSM_GEOTHERMAL]: SimpleTextSidebarContent,
    [filtersKey.WIND_MRAE]: WindMRAESidebarContent,
    [filtersKey.COMPANIES]: CompaniesSidebarContent,
    [filtersKey.OLD_MARKET_DVF]: OldMarketDVFSidebarContent,
    [filtersKey.PLU_GPU]: PLUGPUSidebarContent,
    [filtersKey.DATACENTER_PROJECT]: DataCenterProjectSidebarContent,
    zaer_arrow: SimpleTextSidebarContent,
    parking_surface: SimpleTextSidebarContent,
    space: SpaceSidebarContent,
    zaer_installation: SimpleTextSidebarContent,
    ppr_icpe_seveso: SimpleTextSidebarContent,
    waste_isdd: SimpleTextSidebarContent,
    zaer_find_out_more: FindMoreTextSidebarContent,
    wind_potential_160_find_out_more: FindMoreTextSidebarContent,
    aeronautic_zone_zmt_find_out_more: FindMoreTextSidebarContent,
    wind_large_french_site_find_out_more: FindMoreTextSidebarContent,
    wind_zaer_exclusion_find_out_more: FindMoreTextSidebarContent,
    wasteland_find_out_more: FindMoreTextSidebarContent,
    electrical_networks_electricity_pylon_find_out_more: FindMoreTextSidebarContent,
    wind_bird_bat_find_out_more: FindMoreTextSidebarContent,
};
const FilterCategoryContent = ({ categoryId, item, filtersUpdateDate, }) => {
    const Content = CONTENT_TYPES[categoryId];
    if (!item || !Content) {
        return null;
    }
    const selectedTown = useSelectedTown();
    const getFilterUpdateDate = useMemo(() => {
        if (!filtersUpdateDate)
            return [];
        const result = filtersUpdateDate.find((filter) => filter.layersKey.includes(categoryId));
        return result ? result : [];
    }, [filtersUpdateDate, categoryId]);
    return (<Content item={item} selectedTown={selectedTown} categoryId={categoryId} filterUpdateDate={getFilterUpdateDate}/>);
};
export default FilterCategoryContent;
