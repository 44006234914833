import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Icon, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TELECOMMANDE } from 'analytics/useAnalytics';
import HelpIcon from 'images/icons/Help';
import { HELPDESK_MESSAGE_TYPES } from 'utils/helpdesk';
import ContactModal from './ContactModal';
const HelpModal = () => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { trackEvent } = useAnalytics();
    return (<>
      <Button variant="link" onClick={() => {
            trackEvent({
                category: ANALYTICS_TELECOMMANDE,
                action: 'Aide',
            });
            onOpen();
        }} rightIcon={<Icon as={HelpIcon} fill="colorMode.font800"/>} marginBottom={3}>
        <Text color="colorMode.font900" fontWeight={500} textStyle="small">
          {t('sidebar.menu.help')}
        </Text>
      </Button>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Aide</ModalHeader>
          <ModalCloseButton />
          <ModalBody textStyle="paragraph">
            {t('info_window.help.content')}{' '}
            <Link href={'tel:' + t('info_window.help.phone')} color="red.500">
              {t('info_window.help.phone')}
            </Link>
            {t('info_window.help.content_2')}{' '}
            <ContactModal type={HELPDESK_MESSAGE_TYPES.help} handleSuccess={onClose} triggerItem={(onOpen) => (<Link color="red.500" size="sm" onClick={onOpen}>
                  {t(`info_window.help.write_us`)}
                </Link>)}/>
          </ModalBody>

          <ModalFooter>
            <Button variant="outline" onClick={onClose}>
              {t(`info_window.help.close`)}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>);
};
export default HelpModal;
