import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Link } from '@chakra-ui/react';
import { useSize } from '@chakra-ui/react-use-size';
import useAnalytics from 'analytics/useAnalytics';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useTabsView, useToggleLayout } from 'hooks/contexts/useLayoutContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
import { BUILDING_PERMIT_PLOT_TAB, BUILDING_PLOT_TAB, BUSINESS_PLOT_TAB, DPE_PLOT_TAB, ENERGY_PLOT_TAB, FEASIBILITY_ADDITIONAL_PLOT_TAB, FEASIBILITY_PLOT_TAB, MY_CRM_PLOT_TAB, NEIGHBORHOOD_PLOT_TAB, OCCUPANT_PLOT_TAB, OWNER_PLOT_TAB, PLOT_PLOT_TAB, PLOT_RULES_PLOT_TAB, PLU_ADDITIONAL_REGULATION_PLOT_TAB, PLU_REGULATION_PLOT_TAB, SALES_PLOT_TAB, } from 'types/api';
import { isSwissSite } from 'utils/site';
import { hasBothFeasibility, hasOnlyFeasibilityAdditional } from 'utils/town';
import TabsSeeMore from './TabsSeeMore';
const PanelNavigation = ({ allowedSections, handleChangeSection, isLoading, trackEventCategory, panelTitle, section = null, }) => {
    const { t } = useTranslation();
    const tabsView = useTabsView();
    const toggleLayout = useToggleLayout();
    const selectedSection = section ?? useSectionHash();
    const selectedTown = useSelectedTown();
    const { trackEvent } = useAnalytics();
    const selectTab = (isSelected, section) => {
        if (isSelected) {
            toggleLayout();
        }
        trackEvent({
            category: trackEventCategory,
            action: `Onglet ${t(`tabs.${panelTitle}.section.${section}.title`)}`,
        });
        handleChangeSection(section);
    };
    const sizes = {
        [PLOT_PLOT_TAB]: 70,
        [ENERGY_PLOT_TAB]: 115,
        [FEASIBILITY_PLOT_TAB]: 82,
        [FEASIBILITY_ADDITIONAL_PLOT_TAB]: 178,
        [OWNER_PLOT_TAB]: 100,
        [PLU_REGULATION_PLOT_TAB]: 50, // PLU ou PLU arrêté
        [PLU_ADDITIONAL_REGULATION_PLOT_TAB]: 110,
        [PLOT_RULES_PLOT_TAB]: 60,
        [OCCUPANT_PLOT_TAB]: 90,
        [BUILDING_PLOT_TAB]: 85,
        [SALES_PLOT_TAB]: 100,
        [MY_CRM_PLOT_TAB]: 78,
        [NEIGHBORHOOD_PLOT_TAB]: 72,
        [DPE_PLOT_TAB]: 45,
        [BUSINESS_PLOT_TAB]: 150,
        [BUILDING_PERMIT_PLOT_TAB]: 42,
    };
    const elementRef = React.useRef();
    const dimensions = useSize(elementRef);
    let sliceSection = 0;
    const displayedSections = useMemo(() => {
        let displayedSections = [...allowedSections];
        const width = dimensions?.width;
        if (width === 0) {
            return displayedSections;
        }
        if (tabsView === TABS_VIEW_HALF_VERTICAL) {
            let total = 0;
            displayedSections.map((section, index) => {
                if (sizes[section]) {
                    total += sizes[section];
                }
                else {
                    total += 80;
                }
                if (width * 2 < total && sliceSection === 0) {
                    sliceSection = index;
                }
            });
            if (sliceSection !== 0) {
                displayedSections = displayedSections.slice(0, sliceSection - 1);
            }
        }
        return displayedSections;
    }, [dimensions?.width, tabsView, allowedSections]);
    const seeMoreSections = allowedSections.slice(displayedSections?.length);
    return (<Box ref={elementRef} width="calc(100% - 135px)">
      {displayedSections?.map((section) => {
            const isSelected = selectedSection === section;
            let title = t(`tabs.${panelTitle}.section.${section}.title`);
            if (section === PLU_REGULATION_PLOT_TAB) {
                if (isSwissSite()) {
                    title = t(`tabs.${panelTitle}.section.${section}_swiss.title`);
                }
                else if (hasBothFeasibility(selectedTown) ||
                    hasOnlyFeasibilityAdditional(selectedTown)) {
                    title = t(`tabs.${panelTitle}.section.${section}.title_order`);
                }
            }
            return (<Link key={section} as={Button} display="inline" flexShrink={0} _hover={{
                    backgroundColor: 'blue.500',
                    opacity: 0.8,
                    color: 'white',
                    _disabled: {
                        color: 'inherit',
                    },
                }} bgColor={isSelected ? 'colorMode.revertBase' : 'colorMode.base'} color={isSelected ? 'colorMode.base' : 'colorMode.font800'} paddingX={2} height="32px" alignItems="center" fontWeight={700} textStyle="paragraph" borderRadius="none" onClick={() => selectTab(isSelected, section)} disabled={isLoading} data-cy={`${panelTitle}_section_${section}${isSelected ? '_selected' : ''}`}>
            {title}
          </Link>);
        })}
      {!!seeMoreSections?.length && (<TabsSeeMore sections={seeMoreSections} handleClick={(isSelected, section) => selectTab(isSelected, section)} disabled={isLoading} data-cy={`${panelTitle}_tabs_see_more`} panelTitle={panelTitle}/>)}
    </Box>);
};
export default PanelNavigation;
