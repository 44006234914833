import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MORE_INFO } from 'analytics/useAnalytics';
import Filter from 'components/Filter';
import FilterTitle from 'components/FilterTitle';
import ContactModal from 'components/modals/ContactModal';
import SeeMoreAccordion from 'components/SeeMoreAccordion';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
import { LAYERS_KEY } from 'utils/formatLayersNames';
import { HELPDESK_MESSAGE_TYPES } from 'utils/helpdesk';
const PLUSidebarContent = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const { trackEvent } = useAnalytics();
    const { hasLayersTree } = useSiteConfiguration();
    const map = useMap();
    if (!selectedTown)
        return null;
    const type = !selectedTown?.infoType ? 'POS/PLU' : selectedTown?.infoType;
    return (<Box width="full" color="colorMode.font800" fontSize="13px">
      {selectedTown?.showPLU ? (<>
          {selectedTown?.infoDateFusion ? (<Box paddingBottom={1}>
              <Text as="span" fontWeight={500}>{`${t('sidebar_content.plu.fusion')} ${type} : `}</Text>
              <FilterTitle title={selectedTown?.infoDateFusion}/>
            </Box>) : (<Box paddingBottom={1}>
              <Text as="span" fontWeight={500}>{`${t('sidebar_content.plu.date')} ${type} : `}</Text>
              <Text as="span">{selectedTown?.infoDate || '-'}</Text>
            </Box>)}
          <Box paddingBottom={1}>
            <Text as="span" fontWeight={500}>{`${t('sidebar_content.plu.state')} ${type} : `}</Text>
            <Text as="span">{selectedTown?.lastDate || '-'}</Text>
          </Box>
          {(selectedTown?.pluiName || selectedTown?.EPCIName) && (<Box paddingBottom={1}>
              <Text as="span" fontWeight={500}>{`${t('sidebar_content.plu.pluiName')} : `}</Text>
              <Text as="span">
                {selectedTown?.pluiName
                    ? selectedTown?.pluiName
                    : selectedTown?.EPCIName}
              </Text>
            </Box>)}
          <Box onClick={() => {
                trackEvent({
                    category: ANALYTICS_MORE_INFO,
                    action: 'PLU',
                });
            }}>
            <SeeMoreAccordion title="sidebar.search_plots.more_info">
              <Box paddingLeft={3} borderLeft="1px solid" borderColor="colorMode.font700">
                <Box paddingBottom={2}>
                  <Text as="span" textStyle="paragraph" fontWeight={500}>{`${t('sidebar_content.plu.last_check')} : `}</Text>
                  <Text as="span" textStyle="paragraph">
                    {selectedTown?.lastCheckDate || '-'}
                  </Text>
                </Box>
                <ContactModal type={HELPDESK_MESSAGE_TYPES.plu} title={t('sidebar_content.plu.ask_check')} triggerItem={(onOpen) => (<Button variant="outline" size="sm" whiteSpace="initial" height={12} onClick={() => {
                    trackEvent({
                        category: ANALYTICS_MORE_INFO,
                        action: 'Demander une nouvelle vérification',
                    });
                    return onOpen();
                }}>
                      {t('sidebar_content.plu.ask_check')}
                    </Button>)}/>
                {hasLayersTree([
                'regulatory',
                'plu',
                'impacted_plots',
                LAYERS_KEY,
                filtersKey.PLU_EASTER_EGG,
            ]) && ['6088', '13055', '31555', '33063', '34172', '35238', '44109', '67482', '69123', '75056', '92040'].includes(selectedTown?.id) && (<Box marginTop="1" onClick={() => {
                    // We change zoom
                    if (map.getZoom() > 12) {
                        map.setZoom(12);
                    }
                }}>
                    <Filter filterKey={filtersKey.PLU_EASTER_EGG} filter={{
                    id: 'plu_dream',
                    needsTranslate: false,
                    title: 'Afficher le PLU rêvé (jeu)',
                }} parentCategory={filtersKey.IMPACTED_PLOTS} id="easter_egg"/>
                    <Text textStyle="paragraph" marginBottom="2">
                      {t('sidebar_content.plu_dream.zone')}
                    </Text>
                  </Box>)}
              </Box>
            </SeeMoreAccordion>
          </Box>
        </>) : (<ContactModal type={HELPDESK_MESSAGE_TYPES.ask_plu} title={t('sidebar_content.plu.ask_plu')} triggerItem={(onOpen) => (<Button variant="outline" size="sm" whiteSpace="initial" height={12} onClick={() => {
                    trackEvent({
                        category: ANALYTICS_MORE_INFO,
                        action: 'Demander le PLU',
                    });
                    return onOpen();
                }}>
              {t('sidebar_content.plu.ask_plu')}
            </Button>)}/>)}
    </Box>);
};
export default PLUSidebarContent;
