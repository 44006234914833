import * as React from 'react';
import RPGSection from 'components/tabs/plot-project/Sections/RPGSection';
import useProjectRPG from 'hooks/project/useProjectRPG';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PROJECT_RPG } from 'types/api';
const ProjectRPGSection = ({ project, }) => {
    const { hasProjectTab } = useSiteConfiguration();
    const { rpg, isLoading } = useProjectRPG(project?.id, hasProjectTab(PROJECT_RPG));
    return <RPGSection rpg={rpg} type="project" isLoading={isLoading}/>;
};
export default ProjectRPGSection;
