import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, HStack, Menu, MenuButton, MenuItem, MenuList, Switch, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_HEADER, ANALYTICS_PERSONALIZATION, } from 'analytics/useAnalytics';
import PersonalDataModal from 'components/modals/PersonalDataModal';
import ProfileModal from 'components/modals/ProfileModal';
import ColorModeSwitch from 'components/switches/ColorModeSwitch';
import { useLogout, useUserData } from 'hooks/contexts/useAuthContext';
import DropdownIcon from 'images/icons/Dropdown';
import Logout from 'images/icons/Logout';
import UserIcon from 'images/icons/User';
import { OPEN_PLOT_ONE_CLICK, OPEN_PLOT_TWO_CLICK } from 'types/api';
import useUserSettings from '../../hooks/personalization/useUserSettings';
const AccountDropdown = () => {
    const { t } = useTranslation();
    const logout = useLogout();
    const userData = useUserData();
    const { trackEvent } = useAnalytics();
    const { patchUserSettings } = useUserSettings();
    const { userSettings, isLoading, isUpdating } = useUserSettings();
    const [checkedOpenPlot, setCheckedOpenPlot] = useState(false);
    useEffect(() => {
        setCheckedOpenPlot(userSettings?.openPlot === OPEN_PLOT_ONE_CLICK);
    }, [userSettings]);
    const handleClick = (e) => {
        const checked = e.target.checked;
        setCheckedOpenPlot(checked);
        const action = checked
            ? 'Ouverture en 1 clic'
            : 'Désactiver ouverture en 1 clic';
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: action + ' - Paramètre compte',
        });
        patchUserSettings({
            openPlot: checked ? OPEN_PLOT_ONE_CLICK : OPEN_PLOT_TWO_CLICK,
        });
    };
    return (<Menu>
      {({ isOpen }) => (<>
          <MenuButton as={Button} leftIcon={<UserIcon fill="var(--colorModeFont-800)"/>} rightIcon={<Box transform={isOpen ? 'rotateX(180deg)' : 'rotateX(0deg)'} transition="transform 0.5s">
                <DropdownIcon fill="var(--colorModeFont-800)"/>
              </Box>} variant="ghost" padding={2} onClick={() => trackEvent({
                category: ANALYTICS_HEADER,
                action: 'Prénom Nom',
            })} data-cy="account_dropdown">
            <Text textStyle="paragraph" display={{
                base: 'none',
                lg: 'inline-block',
            }} data-cy="header_username" textOverflow="ellipsis" overflow="hidden" width="100%">
              {userData?.name}
            </Text>
          </MenuButton>
          <MenuList padding={0} backgroundColor="colorMode.background900" borderRadius="md" zIndex="21">
            <PersonalDataModal />
            <ProfileModal />
            {/*<SecurityModal />*/}

            <VStack paddingX={4} paddingY={3} spacing={2} alignItems="stretch">
              <HStack justifyContent="space-between">
                <Text color="colorMode.font900" textStyle="paragraph">
                  {t('header.account.mode')}
                </Text>
                <ColorModeSwitch />
              </HStack>

              {/*<HStack justifyContent="space-between">*/}
              {/*  <Text color="colorMode.font900" textStyle="paragraph">*/}
              {/*    {t('header.account.display')}*/}
              {/*  </Text>*/}
              {/*  <DisplaySwitch />*/}
              {/*</HStack>*/}
            </VStack>
            <VStack paddingX={4} paddingY={3} spacing={2} alignItems="stretch">
              <HStack justifyContent="space-between">
                <Text color="colorMode.font900" textStyle="paragraph">
                  {t('header.personalization.open_plot_one_click')}
                </Text>
                <Switch isChecked={checkedOpenPlot} onChange={handleClick} disabled={isLoading || isUpdating}/>
              </HStack>
            </VStack>

            <MenuItem paddingX={4} paddingY={3} borderBottomRadius="md" icon={<Logout fill="var(--chakra-colors-red-500)"/>} bgColor="colorMode.background800" onClick={() => {
                logout();
                trackEvent({
                    category: ANALYTICS_HEADER,
                    action: 'Se déconnecter',
                });
            }} data-cy="logout_btn">
              <Text textStyle="paragraph" color="red.500">
                {t('header.account.logout')}
              </Text>
            </MenuItem>
          </MenuList>
        </>)}
    </Menu>);
};
export default AccountDropdown;
