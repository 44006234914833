import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, Button, HStack, Link, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM, ANALYTICS_IMPORT_CONTACT, ANALYTICS_IMPORT_PUBLIPOSTAGE, ANALYTICS_TABLE, } from 'analytics/useAnalytics';
import { snakeCase } from 'lodash';
import ContactButton from 'components/buttons/ContactButton';
import DeleteButton from 'components/buttons/DeleteButton';
import { HighlightContactPlotsSwitch } from 'components/buttons/HighlightPlotsSwitch';
import PlotButton from 'components/buttons/PlotButton';
import ProjectButton from 'components/buttons/ProjectButton';
import SeeMapButton from 'components/buttons/SeeMapButton';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import ContactDrawer from 'components/modals/contactModal/ContactDrawer';
import ContactImportDrawer from 'components/modals/contactModal/ContactImportDrawer';
import PublipostageDrawer from 'components/modals/contactModal/PublipostageDrawer';
import Table from 'components/table/Table';
import SelectRows from 'components/tabs/utils/SelectRows';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import { CONTACT_TAB_TYPE } from 'context/TabsContext';
import { useToggleContactPlotsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import { useDeleteTab } from 'hooks/contexts/useTabsContext';
import useContact from 'hooks/crm/useContact';
import useContactList from 'hooks/crm/useContactList';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import MapIcon from 'images/icons/Map';
import { getRandomHexColor } from 'utils/color';
import { ACTION_CREATE, CONTACT_FILTERS_OPTIONS, getContactTypeFromCode, } from 'utils/constants';
import { contactsOptionsFiltration } from 'utils/contact';
import { formatDate } from 'utils/formatDate';
import { yesOrNo } from 'utils/formatText';
import { inDateRange } from 'utils/search';
import { TABS_VIEW_FULL_VERTICAL } from '../../../../context/LayoutContext';
import { useTabsView } from '../../../../hooks/contexts/useLayoutContext';
const getColumnHeader = (column) => (<Translation>
    {(t) => <Text align="center">{t(`tabs.contact.columns.${column}`)}</Text>}
  </Translation>);
const EXPORTED_FIELDS = [
    'titleStr',
    'lastname',
    'firstname',
    'company',
    'mobile',
    'landline',
    'createdAt',
    'primaryEmail',
    'address',
    'postalCode',
    'town',
    'type',
    'addressesPlots',
    'lastSentAt',
    'vendor',
    'npr',
    'npai',
    'plotIds',
];
const formatExportHeader = (t) => (header) => t(`tabs.contact.columns.${snakeCase(header)}`);
const formatExportValue = (t) => (value, field) => {
    if (field === 'createdAt') {
        return formatDate(value);
    }
    if (field === 'vendor') {
        return yesOrNo(t, value);
    }
    if (field === 'npr') {
        return yesOrNo(t, value);
    }
    if (field === 'npai') {
        return yesOrNo(t, value);
    }
    if (field === 'plotIds') {
        return value.replaceAll(';', ' ; ');
    }
    return value;
};
const initialSort = [
    {
        id: 'updatedAt',
        desc: true,
    },
];
const MyContactsPanel = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const tabsView = useTabsView();
    const { contacts, isInitialLoading: isLoading } = useContactList();
    const { deleteContact, isDeletingContact, deleteContacts } = useContact();
    const deleteTab = useDeleteTab();
    const { addContactPlotsHighlights, clearContactPlotsHighlights } = useToggleContactPlotsHighlights();
    const { hasFeature } = useSiteConfiguration();
    const [contactColors, setContactColors] = useState({});
    const [selectedContactsIds, setSelectedContactsIds] = useState();
    const [initialSelect, setInitialSelect] = useState(false);
    const [noContactSelected, setNoContactSelected] = useState(true);
    const [contactsFiltered, setContactsFiltered] = useState(contacts);
    const selectContactInputRef = useRef(null);
    const handleRowSelection = (rows, isAllSelected, isNoneSelected) => {
        setNoContactSelected(isNoneSelected);
        setInitialSelect(false);
        const contactsIds = rows.map((row) => {
            return row.id;
        });
        setSelectedContactsIds(contactsIds);
        if (isAllSelected) {
            setNoContactSelected(!isAllSelected);
            trackEvent({
                category: ANALYTICS_TABLE,
                action: 'Mes Contacts - Tout sélectionner/désélectionner ',
            });
        }
    };
    const getContactPlotsLegend = (contact) => {
        return t('legends.highlighted_plots_of', {
            owner: `${contact.firstname} ${contact.lastname}`,
        });
    };
    const contactsFiltration = (options) => {
        const optionsSelected = [];
        options.forEach((option) => {
            optionsSelected.push(option.value);
        });
        if (optionsSelected.length === 0) {
            return contacts;
        }
        return contacts.filter((contact) => {
            return contactsOptionsFiltration(optionsSelected, contact);
        });
    };
    const onChangeContactFilter = (options) => {
        setContactsFiltered(contactsFiltration(options));
        trackEvent({
            category: ANALYTICS_CRM,
            action: 'Mes parcelles filtre contact',
        });
    };
    useEffect(() => {
        if (!contacts)
            return;
        const options = selectContactInputRef.current.getValue();
        setContactsFiltered(contactsFiltration(options));
    }, [contacts]);
    useEffect(() => {
        if (!contacts)
            return;
        const highlightsData = [];
        const contactColors = {};
        contacts.forEach((contact) => {
            const contactPlots = Object.values(contact.plots);
            if (contactPlots.length) {
                const color = getRandomHexColor(contact.id);
                contactColors[contact.id] = color;
                highlightsData.push({
                    plotsInfo: contactPlots,
                    color,
                    legend: getContactPlotsLegend(contact),
                });
            }
        });
        if (highlightsData.length) {
            addContactPlotsHighlights(highlightsData);
            setContactColors(contactColors);
        }
        return () => {
            clearContactPlotsHighlights();
        };
    }, [contacts]);
    const columns = React.useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'lastname',
                        header: ({ table }) => {
                            return (<Stack direction="row">
                  <SelectRows {...{
                                checked: table
                                    .getFilteredRowModel()
                                    .rows.every((row) => row.getIsSelected()),
                                toggleRow: table.getToggleAllRowsSelectedHandler(),
                                setNoItemIsSelected: setNoContactSelected,
                            }}/>
                  {getColumnHeader('lastname')}
                </Stack>);
                        },
                        cell: (info) => {
                            return (<Stack direction="row">
                  <SelectRows id="delete_plot_select" {...{
                                checked: info.row.getIsSelected(),
                                disabled: !info.row.getCanSelect(),
                                toggleRow: info.row.getToggleSelectedHandler(),
                            }}/>
                  <ContactButton contactId={info.row.original.id} analyticOrigin={ANALYTICS_CRM} analyticDetail="Ouvrir fiche contact mes contacts" id={'contact_detail'}>
                    {info.getValue()}
                  </ContactButton>
                </Stack>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'firstname',
                        header: () => getColumnHeader('firstname'),
                        accessorFn: (row) => {
                            if (!row.firstname)
                                return t('table.empty_column');
                            return row.firstname;
                        },
                        cell: ({ row }) => row.original.firstname,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'titleStr',
                        header: () => getColumnHeader('title'),
                        accessorFn: (row) => {
                            if (!row.titleStr)
                                return t('table.empty_column');
                            return row.titleStr;
                        },
                        cell: ({ row }) => (<Text align="center">{row.original.titleStr}</Text>),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'company',
                        header: () => getColumnHeader('company'),
                        accessorFn: (row) => {
                            if (!row.company)
                                return t('table.empty_column');
                            return row.company;
                        },
                        cell: ({ row }) => row.original.company,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'mobile',
                        header: () => getColumnHeader('mobile'),
                        accessorFn: (row) => {
                            if (!row.mobile)
                                return t('table.empty_column');
                            return row.mobile;
                        },
                        cell: ({ row }) => row.original.mobile,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'landline',
                        header: () => getColumnHeader('landline'),
                        accessorFn: (row) => {
                            if (!row.landline)
                                return t('table.empty_column');
                            return row.landline;
                        },
                        cell: ({ row }) => row.original.landline,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'createdAt',
                        header: () => getColumnHeader('createdAt'),
                        cell: (info) => formatDate(info.getValue(), 'extendedToShort'),
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        accessorKey: 'updatedAt',
                        header: () => getColumnHeader('updated_at'),
                        cell: (info) => formatDate(info.getValue(), 'extendedToShort'),
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        accessorKey: 'action',
                        header: () => (<CustomTooltip label={t('global.actions.highlight_plots')}>
                <Box color="colorMode.font900">
                  <MapIcon width="30px" height="30px" fill="currentColor"/>
                </Box>
              </CustomTooltip>),
                        cell: ({ row }) => {
                            const contactPlots = Object.values(row.original.plots);
                            return (<Box textAlign="center">
                  <HighlightContactPlotsSwitch isDisabled={contactPlots.length === 0} plotsInfo={contactPlots} highlightColor={contactColors[row.original.id]} highlightLegend={getContactPlotsLegend(row.original)}/>
                </Box>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'primaryEmail',
                        header: () => getColumnHeader('primary_email'),
                        accessorFn: (row) => {
                            if (!row.primaryEmail)
                                return t('table.empty_column');
                            return row.primaryEmail;
                        },
                        cell: ({ row }) => (<Link variant="primary" href={`mailto:${row.original.primaryEmail}`} fontWeight={500}>
                {row.original.primaryEmail}
              </Link>),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'address',
                        header: () => getColumnHeader('address'),
                        accessorFn: (row) => {
                            if (!row.address)
                                return t('table.empty_column');
                            return row.address;
                        },
                        cell: ({ row }) => row.original.address,
                        filterFn: 'arrIncludesSome',
                        meta: {
                            minW: '150px',
                        },
                    },
                    {
                        accessorKey: 'postalCode',
                        header: () => getColumnHeader('postal_code'),
                        accessorFn: (row) => {
                            if (!row.postalCode)
                                return t('table.empty_column');
                            return row.postalCode;
                        },
                        cell: ({ row }) => row.original.postalCode,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'town',
                        header: () => getColumnHeader('town'),
                        accessorFn: (row) => {
                            if (!row.town)
                                return t('table.empty_column');
                            return row.town;
                        },
                        cell: ({ row }) => row.original.town,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'country',
                        header: () => getColumnHeader('country'),
                        accessorFn: (row) => {
                            if (!row.country)
                                return t('table.empty_column');
                            return t('contact.countries.' + row.country);
                        },
                        cell: ({ row }) => t('contact.countries.' + row.original.country),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'type',
                        accessorFn: (row) => {
                            return row.type
                                .map((type) => {
                                return getContactTypeFromCode(type);
                            })
                                .join(', ');
                        },
                        header: () => getColumnHeader('type'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'lastSentAt',
                        header: () => getColumnHeader('last_sent_at'),
                        accessorFn: (row) => {
                            if (!row.lastSentAt)
                                return t('table.empty_column');
                            return row.lastSentAt;
                        },
                        cell: ({ row }) => formatDate(row.original.lastSentAt),
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        accessorKey: 'vendor',
                        accessorFn: (row) => row.vendor === 1 ? t('global.yes') : t('global.no'),
                        header: () => getColumnHeader('vendor'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'npr',
                        accessorFn: (row) => row.npr === 1 ? t('global.yes') : t('global.no'),
                        header: () => getColumnHeader('npr'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'npai',
                        accessorFn: (row) => (row.npai ? t('global.yes') : t('global.no')),
                        header: () => getColumnHeader('npai'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'plots',
                        header: () => getColumnHeader('plots'),
                        cell: ({ row }) => {
                            return (<Box maxHeight="80px" overflowY="auto">
                  {row.original.plots.map((plot) => {
                                    return (<PlotButton key={plot.id} display="block" lineHeight="normal" lat={plot.lat} lng={plot.lng} townId={plot.townId} variant="link-primary" plotId={plot.id} analyticOrigin={ANALYTICS_CRM} analyticDetail="Ouvrir fiche parcelle mes contacts">
                        {plot.cadastreId}
                      </PlotButton>);
                                })}
                </Box>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                        meta: {
                            verticalAlign: 'top',
                        },
                    },
                    {
                        accessorKey: 'projects',
                        header: () => getColumnHeader('projects'),
                        cell: ({ row }) => {
                            return (<Box maxHeight="80px" overflowY="auto">
                  {row.original.projects.map((project) => {
                                    return (<ProjectButton key={project.id} display="block" lineHeight="normal" data-cy="project_button" projectId={project.id} projectLabel={project.label} projectTownIds={[project.townId]} lat={project.lat} lng={project.lng} analyticOrigin={ANALYTICS_CRM} analyticDetail={'Ouvrir fiche projet mes contacts'}>
                        {project.label}
                      </ProjectButton>);
                                })}
                </Box>);
                        },
                        meta: {
                            minW: '250px',
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                    {
                        accessorKey: 'id',
                        header: () => '',
                        cell: (info) => {
                            const { t } = useTranslation();
                            return (<DeleteButton mode="icon" confirmText={t('tabs.contact.delete_confirm')} handleDelete={() => deleteContact(info.row.original.id, {
                                    onSuccess: () => {
                                        deleteTab({
                                            type: CONTACT_TAB_TYPE,
                                            id: info.row.original.id,
                                        });
                                    },
                                })} disabled={isDeletingContact} size="sm" id="delete_contact"/>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, [contacts, contactColors]);
    const removeSelectedContacts = () => {
        if (selectedContactsIds?.length) {
            deleteContacts(selectedContactsIds, {
                onSuccess: () => {
                    setSelectedContactsIds([]);
                    setInitialSelect(true);
                },
            });
        }
    };
    return (<Stack paddingX={4} height="100%">
      <Table handleRowSelection={handleRowSelection} initialSelect={initialSelect} id="table_contacts" initialSort={initialSort} columns={columns} data={contactsFiltered || []} isLoading={isLoading} emptyMessage={t(`tabs.contact.no_result`)} exportTitle={t('tabs.contact.title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue(t)} withGlobalSearch={true} analytics={{
            getColumnName: (id) => t(`tabs.contact.columns.${id}`),
            tableName: t('tabs.contact.title'),
        }} initialPagination={{
            pageIndex: 0,
            pageSize: 20,
        }} headerTitle={<HStack flexWrap={tabsView === TABS_VIEW_FULL_VERTICAL ? 'nowrap' : 'wrap'}>
            <Box width="250px">
              <Select ref={selectContactInputRef} placeholder={t('tabs.contact.filters.placeholder')} closeMenuOnSelect={false} isMulti options={CONTACT_FILTERS_OPTIONS ?? []} styles={multiSelectStyles} isLoading={isLoading} onChange={onChangeContactFilter} loadingMessage={() => t('global.loading')} menuPlacement="top"/>
            </Box>
            <ContactDrawer action={ACTION_CREATE} triggerItem={(onOpen) => (<Button variant="primary" size="sm" onClick={() => {
                    onOpen();
                    trackEvent({
                        category: ANALYTICS_CRM,
                        action: 'Ajouter un contact mes contacts',
                    });
                }} data-cy="add_contact_button">
                  {t(`tabs.contact.add_contact`)}
                </Button>)}/>
            <SeeMapButton action="Mes contacts"/>
            {hasFeature('contact_import') && (<ContactImportDrawer triggerItem={(onOpen) => (<Button variant="outline" size="sm" onClick={() => {
                        onOpen();
                        trackEvent({
                            category: ANALYTICS_IMPORT_CONTACT,
                            action: 'Clic bouton importer des contacts',
                        });
                    }} data-cy="import_contact_button">
                    {t(`contact.import_contacts`)}
                  </Button>)}/>)}

            {hasFeature('publipostage') && (<PublipostageDrawer contactsIds={selectedContactsIds} triggerItem={(onOpen) => (<Button variant="outline" size="sm" disabled={noContactSelected} onClick={() => {
                        onOpen();
                        trackEvent({
                            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
                            action: 'Contact importer publipostage',
                        });
                    }} data-cy="import_publipostage_button">
                    {t(`contact.import_publipostage`)}
                  </Button>)}/>)}
            <DeleteButton id="my_plots_delete_button" size="sm" variant="outlineDelete" handleDelete={removeSelectedContacts} disabled={noContactSelected} showIcon confirmText={t('tabs.contact.remove_selection_confirm', {
                count: selectedContactsIds?.length,
            })} label={t('tabs.contact.remove_selection_confirm_title', {
                count: !noContactSelected ? selectedContactsIds?.length : 0,
            })}/>
          </HStack>}/>
    </Stack>);
};
export default MyContactsPanel;
