import { useContextSelector } from 'use-context-selector';
import TabsContext from 'context/TabsContext';
export const useTabs = () => {
    return useContextSelector(TabsContext, (state) => state.tabs);
};
export const useSelectedTab = () => {
    return useContextSelector(TabsContext, (state) => state.selectedTab);
};
export const useChangeSelectedTab = () => useContextSelector(TabsContext, (state) => state.changeSelectedTab);
export const useShowContact = () => useContextSelector(TabsContext, (state) => state.showContact);
export const useShowProject = () => useContextSelector(TabsContext, (state) => state.showProject);
export const useUpdateProjectName = () => useContextSelector(TabsContext, (state) => state.updateProjectName);
export const useShowPlot = () => useContextSelector(TabsContext, (state) => state.showPlot);
export const useSelectedBuilding = () => useContextSelector(TabsContext, (state) => state.selectedBuilding);
export const useSetSelectedBuilding = () => useContextSelector(TabsContext, (state) => state.setSelectedBuilding);
export const useSelectedSection = () => useContextSelector(TabsContext, (state) => state.selectedSection);
export const useSetSelectedSection = () => useContextSelector(TabsContext, (state) => state.setSelectedSection);
export const useResetTabs = () => useContextSelector(TabsContext, (state) => state.resetTabs);
export const useSetSectionHashToUrl = () => useContextSelector(TabsContext, (state) => state.setSectionHashToUrl);
export const useSectionHash = () => useContextSelector(TabsContext, (state) => state.sectionHash);
export const useProjects = () => useContextSelector(TabsContext, (state) => state.projects);
export const useContacts = () => useContextSelector(TabsContext, (state) => state.contacts);
export const usePlots = () => {
    return useContextSelector(TabsContext, (state) => state.plots);
};
export const useSelectedPlot = () => {
    return useContextSelector(TabsContext, (state) => state.selectedPlot);
};
export const useSelectedProject = () => {
    return useContextSelector(TabsContext, (state) => state.selectedProject);
};
export const useUpdatePlot = () => {
    return useContextSelector(TabsContext, (state) => state.updatePlot);
};
export const useIsTabOpened = () => useContextSelector(TabsContext, (state) => state.isTabOpened);
export const useDeleteTab = () => useContextSelector(TabsContext, (state) => state.deleteTab);
export const useToggleCRMTab = () => useContextSelector(TabsContext, (state) => state.toggleCRMTab);
export const useToggleLayerTab = () => useContextSelector(TabsContext, (state) => state.toggleLayerTab);
export const useUpdateContactInfo = () => useContextSelector(TabsContext, (state) => state.updateContactInfo);
