import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import ActivityList from 'components/activities/ActivityList';
import CourierList from 'components/couriers/CourierList';
import DocumentList from 'components/document/DocumentList';
import PhotoList from 'components/document/PhotoList';
import NoteList from 'components/notes/NoteList';
import { getPlotSkeleton } from 'components/tabs/plots/PlotPannelSkeletons';
import PlotStatus from 'components/tabs/plots/PlotSections/info/PlotStatus';
import PlotTag from 'components/tabs/plots/PlotSections/PlotTag';
import useCourier from 'hooks/courier/useCourier';
import usePlotFiles from 'hooks/crm/usePlotFiles';
import usePlotNotes from 'hooks/crm/usePlotNotes';
import useActivity from 'hooks/useActivity';
import usePlotImages from 'hooks/usePlotImages';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PERSONALIZATION_PLOT_TAG } from 'types/api';
import { FROM_PLOT, SPACING_PANEL } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import ContactList from './ContactList';
import GDPRCompliance from './GRPDcompliance';
import HistoryList from './HistoryList';
import ProjectList from './ProjectList';
const PlotMyCRM = ({ plot, isLoading: isLoadingPlot, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { hasDashboardItem, hasPlotInfo, hasPersonalization } = useSiteConfiguration();
    const { documents, addDocument, isLoadingAddDocument, photos, addPhoto, isLoadingAddPhoto, deleteFile, } = usePlotFiles(plot?.id);
    const { data: photosFiles, isLoading: isLoadingPhoto } = usePlotImages(plot?.id, photos && photos.map((photo) => photo.id.toString()));
    const { notes, isInitialLoading: isLoadingPlotNotes, deleteNote, addNote, updateNote, } = usePlotNotes(plot?.id);
    const showAgenda = hasDashboardItem('agenda');
    const showCourier = hasDashboardItem('courier');
    const { plotActivities, isLoadingPlotActivities } = useActivity({
        plotId: plot?.id,
    });
    const { plotCouriers, isLoadingPlotCouriers } = useCourier({
        enabledList: hasDashboardItem('courier'),
        plotId: plot?.id,
    });
    const skeleton = getPlotSkeleton();
    if (isLoadingPlot)
        return skeleton;
    return (<VStack padding={4} spacing={SPACING_PANEL} maxWidth="1200px" alignItems="flex-start">
      {hasPlotInfo('status') && <PlotStatus plotId={plot?.id}/>}
      {hasPersonalization(PERSONALIZATION_PLOT_TAG) && <PlotTag plot={plot}/>}
      {hasDashboardItem(filtersKey.USER_PROJECTS) && (<ProjectList plot={plot}/>)}
      {hasDashboardItem('myContact') && (<ContactList plot={plot} townId={plot.townId} showAgenda={showAgenda} showCourier={showCourier}/>)}
      <NoteList isLoading={isLoadingPlotNotes} notes={notes} title="tabs.plots.section.my_crm.note.title" modalId={plot?.id} modalLabel="tabs.plots.section.my_crm.note.add_note" modalSubmit={(data, onSuccess) => {
            addNote(data, {
                onSuccess: () => {
                    {
                        trackEvent({
                            category: ANALYTICS_CRM,
                            action: 'Créer une note',
                        });
                        onSuccess();
                    }
                },
            });
        }} onDeleteNote={(noteId, onSuccess) => {
            trackEvent({
                category: ANALYTICS_CRM,
                action: 'Supprimer une note',
            });
            deleteNote({ noteId }, {
                onSuccess: () => {
                    onSuccess();
                },
            });
        }} onUpdateNote={(noteId, note, onSuccess) => {
            updateNote({
                plotId: plot.id,
                noteId,
                note: note,
            }, {
                onSuccess,
            });
        }}/>
      <Heading as="h4" variant="h4">
        {t('tabs.document.title')}
      </Heading>
      <DocumentList plotId={plot.id} documents={documents} addDocument={addDocument} deleteDocument={deleteFile} isLoadingUpload={isLoadingAddDocument}/>
      <Heading as="h4" variant="h4">
        {t('tabs.photo.title')}
      </Heading>
      <PhotoList plotId={plot.id} photos={photos} deletePhoto={deleteFile} addPhoto={addPhoto} isLoadingPhoto={isLoadingPhoto} photosFiles={photosFiles} isLoadingUpload={isLoadingAddPhoto}/>

      {hasDashboardItem('agenda') && (<ActivityList title="tabs.plots.section.my_crm.activities.title" activities={plotActivities} isLoading={isLoadingPlotActivities} activityInfoDefault={{ plotId: plot.id }} analyticsCategory={ANALYTICS_CRM} analyticsDetail="Ouvrir fiche parcelle mes activités dans une parcelle"/>)}

      {hasDashboardItem('courier') && (<CourierList title="tabs.plots.section.my_crm.couriers.title" couriers={plotCouriers} isLoading={isLoadingPlotCouriers} courierInfoDefault={{ plotId: plot.id }} analyticsDetail="Envoyer un courrier via Mon CRM d'une parcelle" withExport={false} fromPanel={FROM_PLOT}/>)}
      <HistoryList plotId={plot.id}/>
      <GDPRCompliance />
    </VStack>);
};
export default PlotMyCRM;
