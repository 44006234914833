import { useMemo } from 'react';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useLayersTree } from 'hooks/contexts/useLayersTreeContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOLayerItem } from 'types/api';
import { getFilterNamesFromPathAndPrefix } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const LAYERS_PATH = 'regulatory.ppr.ppr_technological.ppr_technological_divers';
const LAYERS_PREFIX = 'ppr_technological_layer';
const IOPPRTechnologicalLayerItem = IOLayerItem;
const fetchPPRTechnological = (townId) => () => get(iots.array(IOPPRTechnologicalLayerItem), `legacy/${townId}/ppr-technological`);
const usePPRTechnologicalLayers = () => {
    const layersTree = useLayersTree();
    const filterNames = useMemo(() => {
        return getFilterNamesFromPathAndPrefix(layersTree, LAYERS_PATH, LAYERS_PREFIX);
    }, [JSON.stringify(layersTree)]);
    createLayersHook({
        fetch: fetchPPRTechnological,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filterNames);
};
const PPRTechnologicalLayers = () => {
    usePPRTechnologicalLayers();
    return null;
};
export default PPRTechnologicalLayers;
