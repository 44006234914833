import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, HStack, SimpleGrid, Switch, Text, VStack, } from '@chakra-ui/react';
import SirenLink from 'components/links/SirenLink';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import usePlotCoOwners from 'hooks/plot/usePlotCoOwners';
import { filtersKey } from 'utils/filters';
import { camelToSnakeCase } from 'utils/formatText';
import { getPlotSkeleton } from '../PlotPannelSkeletons';
const CoOwnerInfoDetail = ({ id, owner, }) => {
    const { t } = useTranslation();
    if (!owner[id])
        return null;
    return (<Box>
      <Text as="span">
        {t('tabs.plots.section.co_owner.' + camelToSnakeCase(id))}{' '}
      </Text>
      <Text as="span" fontWeight="bold">
        {owner[id]}
      </Text>
    </Box>);
};
const CoOwnerSiret = ({ owner }) => {
    const { t } = useTranslation();
    if (!owner?.siret)
        return null;
    return (<HStack spacing={1}>
      <Text as="span">{t('tabs.plots.section.co_owner.siret')}</Text>
      <SirenLink siren={owner.siret.substring(0, 9)} analyticDetail="Pappers Occupants" showExternalLink/>
    </HStack>);
};
const PlotCoOwner = ({ plotId }) => {
    const tabsView = useTabsView();
    const { t } = useTranslation();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const { coOwners, isInitialLoading: isLoadingCoOwners } = usePlotCoOwners(plotId);
    const handleChange = (filterKey) => {
        toggleFilter(filterKey);
    };
    const skeleton = getPlotSkeleton();
    if (isLoadingCoOwners)
        return skeleton;
    return (<VStack alignItems="start" justifyContent="start" width="full">
      <Heading as="h4" variant="h4" marginTop={2}>
        {t('tabs.plots.section.co_owner.title')}
        <Switch marginLeft={2} isChecked={isFilterActive(filtersKey.CO_OWNER)} data-cy={filtersKey.CO_OWNER} onChange={() => {
            handleChange(filtersKey.CO_OWNER);
        }} size="md"/>
      </Heading>
      {coOwners.length > 0 ? (<SimpleGrid width="full" spacing={2} columns={tabsView === TABS_VIEW_HALF_VERTICAL ? 1 : { sm: 1, md: 2, lg: 3 }} fontSize={14}>
          {coOwners.map((coOwner) => {
                const hasDetailedOnwerInfo = coOwner.legalRepresentative ||
                    coOwner.siret ||
                    coOwner.constructionPeriod;
                return (<Box backgroundColor="colorMode.background800" padding={2} key={coOwner.id}>
                <Box marginBottom={2}>
                  <Text fontWeight="bold" color="blue.500">
                    {t('tabs.plots.section.co_owner.address_reference')}
                  </Text>
                  <Text fontWeight="bold">{coOwner.address}</Text>
                </Box>
                {hasDetailedOnwerInfo && (<Box marginBottom={2}>
                    <Text fontWeight="bold" color="blue.500">
                      {t('tabs.plots.section.co_owner.legal_representative_title')}
                    </Text>

                    <CoOwnerInfoDetail id={'legalRepresentative'} owner={coOwner}/>
                    <CoOwnerInfoDetail id={'constructionPeriod'} owner={coOwner}/>

                    <CoOwnerSiret owner={coOwner}/>
                  </Box>)}
                <Box>
                  <Text fontWeight="bold" color="blue.500">
                    {t('tabs.plots.section.co_owner.co_owner_identification')}
                  </Text>

                  <CoOwnerInfoDetail id={'date'} owner={coOwner}/>
                  <CoOwnerInfoDetail id={'lots'} owner={coOwner}/>
                  <CoOwnerInfoDetail id={'housingCommercialOfficeLots'} owner={coOwner}/>
                  <CoOwnerInfoDetail id={'housingLots'} owner={coOwner}/>
                  <CoOwnerInfoDetail id={'parkingLots'} owner={coOwner}/>

                  <Box>
                    <Text as="span">
                      {t('tabs.plots.section.co_owner.count_ASL_AFUL_syndicate_union')}
                    </Text>{' '}
                    <Text as="span" fontWeight="bold">
                      {coOwner.ASLCount}/{coOwner.AFULCount}/
                      {coOwner.syndicateUnionCount}
                    </Text>
                  </Box>
                  <CoOwnerInfoDetail id={'serviceResidence'} owner={coOwner}/>
                </Box>
              </Box>);
            })}
        </SimpleGrid>) : (<Text textStyle="paragraph">
          {t('tabs.plots.section.co_owner.not_result')}
        </Text>)}
    </VStack>);
};
export default PlotCoOwner;
