import * as React from 'react';
import { useState } from 'react';
import { ColorPicker, ColorService, useColor, } from 'react-color-palette';
import { CloseIcon } from '@chakra-ui/icons';
import { Box, HStack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PERSONALIZATION, } from '../../analytics/useAnalytics';
import ArrowLeftIcon from '../../images/icons/ArrowLeft';
const ColorPalette = ({ id, analyticsAction, colorDefault, chosenColor, isDefault = false, isDefaultText, }) => {
    const { trackEvent } = useAnalytics();
    const [selectedColor, setSelectedColor] = useColor(colorDefault);
    const [active, setActive] = useState(false);
    const chooseColor = (color) => {
        setActive(true);
        const rgb = ColorService.hex2rgb(color);
        setSelectedColor({
            hex: color,
            rgb: ColorService.hex2rgb(color),
            hsv: ColorService.rgb2hsv(rgb),
        });
    };
    const setColor = (color) => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Changement de la couleur ' + analyticsAction,
        });
        setSelectedColor(color);
        chosenColor(id, color.hex);
    };
    return (<HStack cursor="pointer" position="relative">
      <Box backgroundColor={colorDefault} minWidth="28px" minHeight="28px" maxWidth="28px" maxHeight="28px" onClick={() => chooseColor(colorDefault)} border={active ? '2px solid black' : 'none'}/>
      {isDefault && <Text fontSize="12px">{isDefaultText}</Text>}
      <Box display={active ? 'block' : 'none'} width="350px" position="absolute" top="-2px" left="45px" boxShadow="md" borderRadius="md" backgroundColor="#FFFFFF" zIndex={1} padding={2}>
        <Box position="relative">
          <Box position="absolute" right="-20px" top="-18px" backgroundColor="#FFFFFF" padding="6px" boxShadow="3px 1px 5px rgba(0,0,0,0.1)" borderRadius="50%" lineHeight="0.55">
            <CloseIcon fontSize="12px" onClick={() => setActive(false)} _hover={{ color: 'colorMode.font900' }}/>
          </Box>
          <Box position="absolute" left="-29px" top="-6px">
            <ArrowLeftIcon />
          </Box>
          <ColorPicker height={150} hideInput={['hsv']} hideAlpha={true} color={selectedColor} onChange={setSelectedColor} onChangeComplete={setColor}/>
        </Box>
      </Box>
    </HStack>);
};
export default ColorPalette;
