import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import notSevesoImg from 'images/seveso_not.png';
import { filtersKey } from 'utils/filters';
import { fetchICPESevesoInfo, formatInfoWindowContent, getMarkerImg, IOICPEMarkerItem, } from './ICPESevesoLayer';
const fetchICPENoSeveso = (townId) => () => get(iots.array(IOICPEMarkerItem), `${townId}/icpe-no-seveso`);
const LEGENDS = {
    icpe_not_seveso: {
        layerKey: filtersKey.PPR_ICPE_NO_SEVESO,
        label: 'ppr_technological_icpe_not_seveso',
        needsTranslate: true,
        image: notSevesoImg,
    },
};
const useICPENoSevesoLayer = () => {
    const selectedTown = useSelectedTown();
    const { t } = useTranslation();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = useCallback(async (marker) => {
        const markerKey = `${marker.lat}/${marker.lng}`;
        if (markerInfo[markerKey]) {
            return markerInfo[markerKey];
        }
        const icpeInfo = await fetchICPESevesoInfo(selectedTown?.id, marker);
        const content = formatInfoWindowContent(icpeInfo, t);
        setMarkerInfo({
            ...markerInfo,
            [markerKey]: content,
        });
        return content;
    }, [selectedTown]);
    createLayersHook({
        fetch: fetchICPENoSeveso,
        markerImg: getMarkerImg,
        legends: LEGENDS,
        getInfoWindowContent,
    })(filtersKey.PPR_ICPE_NO_SEVESO);
};
const ICPENoSevesoLayer = () => {
    useICPENoSevesoLayer();
    return null;
};
export default ICPENoSevesoLayer;
