import * as React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, Image, Spinner, Stack, Text } from '@chakra-ui/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSite, { getSiteLogoWhite } from 'hooks/useSite';
import useSiteCarrousel from 'hooks/useSiteCarrousel';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
const CarouselHome = () => {
    const { t } = useTranslation();
    const { site, isLoading } = useSite();
    const [dataCarrousel, setDataCarrousel] = useState([
        {
            id: '1',
            title: 'defaultTitle1',
            subtitle: 'defaultSubtitle1',
            image: 'default1.png',
        },
        {
            id: '2',
            title: 'defaultTitle2',
            subtitle: 'defaultSubtitle2',
            image: 'default2.png',
        },
        {
            id: '3',
            title: 'defaultTitle3',
            subtitle: 'defaultSubtitle3',
            image: 'default3.png',
        },
        {
            id: '4',
            title: 'defaultTitle4',
            subtitle: 'defaultSubtitle4',
            image: 'default4.png',
        },
        {
            id: '5',
            title: 'defaultTitle5',
            subtitle: 'defaultSubtitle5',
            image: 'default5.png',
        },
    ]);
    const { siteCarrousel, isLoading: isLoadingSiteCarrousel } = useSiteCarrousel();
    useEffect(() => {
        if (siteCarrousel && siteCarrousel.length > 0) {
            setDataCarrousel(siteCarrousel);
        }
    }, [siteCarrousel]);
    return (<Stack width="100%" height="100vh" position="relative" overflow="hidden">
      <Box width="100%" position="absolute" top={0} height="20%" bgGradient={['linear(to-b, #1B1B1C, transparent)']} zIndex={10}/>

      <Box position="absolute" paddingTop="24px" paddingLeft="24px" zIndex={101}>
        {isLoading ? (<Spinner />) : (<Image src={getSiteLogoWhite(site)} objectFit="contain" height="24px"/>)}
      </Box>

      <Box width="100%">
        {isLoadingSiteCarrousel ? (<Box display="flex" alignItems="center" justifyContent="center" height="100%">
            <Spinner />
          </Box>) : (<Swiper slidesPerView={1} spaceBetween={0} loop={true} pagination={{
                clickable: true,
            }} modules={[Autoplay, Pagination]} centeredSlides={true} autoplay={{
                delay: 5000,
                disableOnInteraction: false,
            }} className="swiper-home">
            {dataCarrousel.map((item, index) => {
                return (<SwiperSlide key={index} className="slide">
                  <Image src={require(`../images/carrousel/${item.image}`)} objectFit="cover" height="100vh"/>

                  <Box zIndex={101} position="absolute" bottom="110px" width="100%" textAlign="center" maxWidth="450px" left="0" right="0" margin="auto">
                    <Heading marginBottom={2} color="white" variant="h2">
                      {t(`carrousel.legend.${item.title}`)}
                    </Heading>
                    <Text textStyle="paragraph" fontWeight={400} textAlign="center" color="white">
                      {t(`carrousel.legend.${item.subtitle}`)}
                    </Text>
                  </Box>
                  <Box width="100%" position="absolute" bottom={0} height="30%" bgGradient={['linear(to-b, transparent, #1B1B1C)']} zIndex={100}/>
                </SwiperSlide>);
            })}
          </Swiper>)}
      </Box>
    </Stack>);
};
export default CarouselHome;
