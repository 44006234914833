import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Icon, Link, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, } from 'analytics/useAnalytics';
import DownloadIcon from 'images/icons/Download';
const PlotPLUGPU = ({ PLUGPUInfo, from, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    if (!PLUGPUInfo) {
        return null;
    }
    return (<>
      <Text textStyle="paragraph">{PLUGPUInfo.name}</Text>
      {PLUGPUInfo.url && (<HStack alignItems="start" marginTop={1}>
          <Icon as={DownloadIcon} width={4} height={4} color="colorMode.revertBase" alignSelf="center"/>
          <Link isExternal={true} fontSize="12px" href={PLUGPUInfo.url} color="colorMode.revertBase" onClick={() => trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: 'Télécharger règlement carte GPU ' + from,
            })}>
            {t('legends.plu.url')}
          </Link>
        </HStack>)}
      {PLUGPUInfo.prescription && (<Text textStyle="paragraph">{PLUGPUInfo.prescription}</Text>)}
      {PLUGPUInfo.prescriptionUrl && (<HStack alignItems="start" marginTop={1}>
          <Icon as={DownloadIcon} width={4} height={4} color="colorMode.revertBase" alignSelf="center"/>
          <Link isExternal={true} fontSize="12px" href={PLUGPUInfo.url} color="colorMode.revertBase" onClick={() => trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: 'Télécharger prescription carte GPU ' + from,
            })}>
            {t('legends.plu.url_prescription')}
          </Link>
        </HStack>)}
    </>);
};
export default PlotPLUGPU;
