import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOBuildingPermitLayers } from 'types/api';
export const fetchBuildingPermit = (townId, siteId) => () => get(iots.array(IOBuildingPermitLayers), `legacy/${townId}/building-permit-new-market-layers`, { siteId });
export const QUERY_KEY = 'building-permit-layers';
export const getQueryKey = (townId, siteId) => [
    QUERY_KEY,
    townId,
    siteId,
];
const usePlotBuildingPermitLayers = (townId, siteId) => {
    const { data: buildingPermitLayers, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(townId, siteId),
        queryFn: fetchBuildingPermit(townId, siteId),
        enabled: !!townId,
    });
    return { buildingPermitLayers, isLoading };
};
export default usePlotBuildingPermitLayers;
