import { useMemo } from 'react';
import * as React from 'react';
import { Menu, MenuButton, MenuItem, MenuList, Tab } from '@chakra-ui/react';
import useClickTab from 'hooks/navigation/useClickTab';
import useTabTitle from 'hooks/navigation/useTabTitle';
const ShowMoreTabsButton = ({ tabs, }) => {
    return (<Menu>
      <MenuButton as={Tab} boxShadow="inset -4px 0px 4px -4px rgb(0 0 0 / 20%)" borderTopRadius="md" backgroundColor="colorMode.base" paddingY={1} border="transparent">
        +{tabs.length}
      </MenuButton>
      <MenuList zIndex={10}>
        {tabs.map((tab) => (<ShowMoreTabItem key={tab.id} tab={tab}/>))}
      </MenuList>
    </Menu>);
};
const ShowMoreTabItem = ({ tab }) => {
    const onClickTab = useClickTab();
    const { getTitle, getDetail } = useTabTitle();
    const title = useMemo(() => {
        const title = getTitle(tab);
        const detail = getDetail(tab);
        return title + (detail ? ' - ' + detail : '');
    }, [getTitle, getDetail, tab]);
    return (<MenuItem key={tab.id} onClick={onClickTab(tab)}>
      {title}
    </MenuItem>);
};
export default ShowMoreTabsButton;
