import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Button, Center, Heading, Spinner, Stack } from '@chakra-ui/react';
import usePersonalizationPlotTag from 'hooks/personalization/usePersonalizationPlotTag';
import { getRandomHexColor } from 'utils/color';
import 'react-color-palette/css';
import PersonalizationPlotTagForm from './PersonalizationPlotTagForm';
const PersonalizationPlotTag = () => {
    const { t } = useTranslation();
    const [addLabel, setAddLabel] = useState(false);
    const { personalizationPlotTags, isLoadingPlotTags } = usePersonalizationPlotTag(true);
    const defaultPlotTag = {
        id: '0',
        label: '',
        color: getRandomHexColor(),
    };
    return (<Stack width="full" spacing={1} padding={4} alignItems="start">
      <Heading as="h4" variant="h4">
        {t('tabs.personalization.section.plot_tag.info')} :
      </Heading>
      {isLoadingPlotTags ? (<Center height="100vh" width="100%">
          <Spinner />
        </Center>) : (<>
          {personalizationPlotTags
                ? personalizationPlotTags.map((plotTag) => {
                    return (<PersonalizationPlotTagForm key={plotTag.id} plotTag={plotTag}/>);
                })
                : t('tabs.personalization.section.plot_tag.no_labels')}
          {addLabel && (<PersonalizationPlotTagForm plotTag={defaultPlotTag} setAddLabel={setAddLabel}/>)}
          {!addLabel && (<Button type="button" leftIcon={<AddIcon fill="currentColor"/>} size="sm" variant="link-primary" onClick={() => setAddLabel(true)}>
              {t('tabs.personalization.section.plot_tag.add_label')}
            </Button>)}
        </>)}
    </Stack>);
};
export default PersonalizationPlotTag;
