import * as React from 'react';
import { Box, Progress, Text } from '@chakra-ui/react';
import PlotLayers from 'components/tabs/plots/PlotSections/rules/PlotLayers';
import useProjectLayers from 'hooks/crm/useProjectLayers';
const RulesSection = ({ projectId, projectTownId, landPointId, }) => {
    const { projectLayers, isLoading, isFetching, progress } = useProjectLayers(projectTownId, projectId, landPointId);
    return isFetching ? (<Box position="relative" display="flex" width="50%" margin="auto" height="100%" alignItems="center">
      <Progress size="lg" colorScheme="blue" bg="#C4C4C4" width="full" value={progress} rounded="md" sx={{
            '& > div': { transition: 'width 0.7s ease-in-out' },
        }}/>
      <Text position="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" fontSize="sm" color="white" fontWeight="bold">
        {progress} %
      </Text>
    </Box>) : (<PlotLayers layers={projectLayers} isLoading={isLoading} landPointId={landPointId}/>);
};
export default RulesSection;
