import * as React from 'react';
import { Box, Switch } from '@chakra-ui/react';
import { useTogglePlotDistributionPostsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import { getRandomHexColor } from 'utils/color';
export const PlotDistributionPostsHighlightsSwitch = ({ plotDistributionPostInfo, ...props }) => {
    const { arePlotDistributionPostsHighlighted, togglePlotDistributionPostsHighlights, } = useTogglePlotDistributionPostsHighlights();
    const color = getRandomHexColor();
    const onChange = () => {
        togglePlotDistributionPostsHighlights([plotDistributionPostInfo], color);
    };
    const highlighted = arePlotDistributionPostsHighlighted([
        plotDistributionPostInfo,
    ]);
    return (<DefaultPlotSwitch onChange={onChange} highlighted={highlighted} {...props}/>);
};
const DefaultPlotSwitch = ({ onChange, highlighted, ...props }) => {
    const isChecked = highlighted && !props.isDisabled;
    const switchProps = { ...props, onChange };
    return (<Box>
      <Switch size="sm" isChecked={isChecked} {...switchProps}/>
    </Box>);
};
