import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { latLngToTileValues } from 'utils/geo';
import { formatNatura2000Info, hasData } from 'utils/ign';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('LANDCOVER.FORESTINVENTORY.V2', 'LANDCOVER.FORESTINVENTORY.V2', zoom, coord);
};
const TILE_SIZE = 256;
const fetchInfoForCoords = (coords, map, t) => {
    const { tileRow, tileCol, i, j } = latLngToTileValues(coords, map, TILE_SIZE, TILE_SIZE);
    return get(iots.string, 'https://data.geopf.fr/wmts?' +
        '&SERVICE=WMTS&REQUEST=GetFeatureInfo&VERSION=1.0.0' +
        '&LAYER=LANDCOVER.FORESTINVENTORY.V2' +
        '&STYLE=LANDCOVER.FORESTINVENTORY.V2' +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&INFOFORMAT=' +
        encodeURIComponent('text/html') +
        '&TILEMATRIXSET=PM' +
        '&TILEMATRIX=' +
        map.getZoom() +
        '&TILEROW=' +
        tileRow +
        '&TILECOL=' +
        tileCol +
        '&I=' +
        i +
        '&J=' +
        j, {}, true, {}, false).then((response) => {
        return hasData(response) ? formatNatura2000Info(response, t) : '';
    });
};
const getDetails = (t) => () => async (coords, map) => {
    return await fetchInfoForCoords(coords, map, t);
};
const getLegends = () => {
    const legends = {};
    legends.forest_closed_without_tree_cover = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_without_tree_cover',
        needsTranslate: true,
        color: '#dec464',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_hardwood_islet = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_hardwood_islet',
        needsTranslate: true,
        color: '#438950',
        opacity: 0.6,
    };
    legends.forest_closed_with_pure_deciduous_oaks = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pure_deciduous_oaks',
        needsTranslate: true,
        color: '#224b2f',
        opacity: 0.6,
    };
    legends.forest_closed_with_pure_evergreen_oaks = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pure_evergreen_oaks',
        needsTranslate: true,
        color: '#6c7e43',
        opacity: 0.6,
    };
    legends.forest_closed_with_pure_beech = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pure_beech',
        needsTranslate: true,
        color: '#7ff986',
        opacity: 0.6,
    };
    legends.forest_closed_with_pure_chestnut = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pure_chestnut',
        needsTranslate: true,
        color: '#89f93b',
        opacity: 0.6,
    };
    legends.forest_closed_with_pure_black_locust = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pure_black_locust',
        needsTranslate: true,
        color: '#865935',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_other_hardwood = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_other_hardwood',
        needsTranslate: true,
        color: '#b4c860',
        opacity: 0.6,
    };
    legends.forest_closed_with_mixed_hardwood = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_mixed_hardwood',
        needsTranslate: true,
        color: '#6bd43f',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_conifers_islet = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_conifers_islet',
        needsTranslate: true,
        color: '#8283fc',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_maritime_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_maritime_pine',
        needsTranslate: true,
        color: '#ae42fb',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_scots_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_scots_pine',
        needsTranslate: true,
        color: '#8c3dfb',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_corsican_pine_black_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_corsican_pine_black_pine',
        needsTranslate: true,
        color: '#4e3ffb',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_alep_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_alep_pine',
        needsTranslate: true,
        color: '#e648fb',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_swiss_stone_pine_swiss_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_swiss_stone_pine_swiss_pine',
        needsTranslate: true,
        color: '#6f54e3',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_other_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_other_pine',
        needsTranslate: true,
        color: '#9d6efc',
        opacity: 0.6,
    };
    legends.forest_closed_with_mixing_pur_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_mixing_pur_pine',
        needsTranslate: true,
        color: '#ce9efc',
        opacity: 0.6,
    };
    legends.forest_closed_with_fir_or_spruce = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_fir_or_spruce',
        needsTranslate: true,
        color: '#77e2e6',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_larch = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_larch',
        needsTranslate: true,
        color: '#5f82fc',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_douglas = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_douglas',
        needsTranslate: true,
        color: '#5b99fc',
        opacity: 0.6,
    };
    legends.forest_closed_with_mixing_other_conifer = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_mixing_other_conifer',
        needsTranslate: true,
        color: '#488f9e',
        opacity: 0.6,
    };
    legends.forest_closed_with_pur_other_conifer_other_pur_pine = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_pur_other_conifer_other_pur_pine',
        needsTranslate: true,
        color: '#95fbff',
        opacity: 0.6,
    };
    legends.forest_closed_with_mixing_conifer = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_mixing_conifer',
        needsTranslate: true,
        color: '#4953fb',
        opacity: 0.6,
    };
    legends.forest_closed_with_mixing_hardwood_preponderant_and_conifers = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_mixing_hardwood_preponderant_and_conifers',
        needsTranslate: true,
        color: '#e87138',
        opacity: 0.6,
    };
    legends.forest_closed_with_mixing_conifers_preponderant_and_hardwood = {
        layerKey: filtersKey.FOREST,
        label: 'forest_closed_with_mixing_conifers_preponderant_and_hardwood',
        needsTranslate: true,
        color: '#e65436',
        opacity: 0.6,
    };
    legends.forest_open_without_tree_cover = {
        layerKey: filtersKey.FOREST,
        label: 'forest_open_without_tree_cover',
        needsTranslate: true,
        color: '#b3b3b3',
        opacity: 0.6,
    };
    legends.forest_open_with_pur_hardwood = {
        layerKey: filtersKey.FOREST,
        label: 'forest_open_with_pur_hardwood',
        needsTranslate: true,
        color: '#d8fcc2',
        opacity: 0.6,
    };
    legends.forest_open_with_pur_conifers = {
        layerKey: filtersKey.FOREST,
        label: 'forest_open_with_pur_conifers',
        needsTranslate: true,
        color: '#a0b2cb',
        opacity: 0.6,
    };
    legends.forest_open_with_mixing_hardwood_and_conifers = {
        layerKey: filtersKey.FOREST,
        label: 'forest_open_with_mixing_hardwood_and_conifers',
        needsTranslate: true,
        color: '#f5d247',
        opacity: 0.6,
    };
    legends.forest_poplarp_grove = {
        layerKey: filtersKey.FOREST,
        label: 'forest_poplarp_grove',
        needsTranslate: true,
        color: '#fefd35',
        opacity: 0.6,
    };
    legends.forest_moor = {
        layerKey: filtersKey.FOREST,
        label: 'forest_moor',
        needsTranslate: true,
        color: '#f9e7c1',
        opacity: 0.6,
    };
    legends.forest_herbaceous_formation = {
        layerKey: filtersKey.FOREST,
        label: 'forest_herbaceous_formation',
        needsTranslate: true,
        color: '#fdf8a9',
        opacity: 0.6,
    };
    return legends;
};
const useForestLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = useMemo(() => getDetails(t), [t]);
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.FOREST],
        getLegends,
        getInfoWindowContent,
        tileWidth: TILE_SIZE,
        tileHeight: TILE_SIZE,
        opacity: 0.6,
    })(filtersKey.FOREST);
};
const ForestLayer = () => {
    useForestLayer();
    return null;
};
export default ForestLayer;
