import * as React from 'react';
import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { TabItem } from 'components/tabs/TabsNavigation/TabsItem/TabItemWrapper';
import ShowMoreTabsButton from 'components/tabs/TabsNavigation/TabsScroll/ShowMoreTabsButton';
import { PLOT_TAB_TYPE } from 'context/TabsContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useResetPlotSelectionMode } from 'hooks/contexts/useLocationContext';
import { useSetFeasibilityParentId } from 'hooks/contexts/useSecondaryPlotsContext';
import { useChangeSelectedTab, useSelectedTab, useTabs, } from 'hooks/contexts/useTabsContext';
import useSyncNavigation from 'hooks/navigation/useSyncNavigation';
import { filtersKey } from 'utils/filters';
import TabsScrollButton from './TabsScrollButton';
const MAX_TABS = 5;
const CompactTabScroll = () => {
    const tabs = useTabs();
    const syncNavigation = useSyncNavigation();
    const selectedTab = useSelectedTab();
    const changeSelectedTab = useChangeSelectedTab();
    const canScroll = tabs?.length > 1;
    const resetPlotSelectionMode = useResetPlotSelectionMode();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const toggleFilter = useToggleFilter();
    const handleTabChange = (newTab) => {
        resetPlotSelectionMode();
        toggleFilter(filtersKey.MAP_PLOTS, false);
        if (newTab.type === PLOT_TAB_TYPE) {
            setFeasibilityParentId(newTab.id);
        }
        changeSelectedTab(newTab.id);
        syncNavigation(newTab);
    };
    const [currentTabIndex, tabsToShow, moreTabs] = useMemo(() => {
        const selectedTabIndex = tabs.findIndex((tab) => {
            return tab?.id === selectedTab;
        });
        const currentTabIndex = selectedTabIndex > -1 ? selectedTabIndex : 0;
        let start = Math.max(0, currentTabIndex - Math.floor(MAX_TABS / 2));
        const end = Math.min(tabs.length, start + MAX_TABS);
        if (end === tabs.length) {
            start = Math.max(0, end - MAX_TABS);
        }
        const tabsToShow = [...tabs].slice(start, end);
        const moreTabs = [];
        if (tabs.length > MAX_TABS) {
            if (start > 0) {
                moreTabs.push(...tabs.slice(0, start));
            }
            if (end < tabs.length) {
                moreTabs.push(...tabs.slice(end));
            }
        }
        return [currentTabIndex, tabsToShow, moreTabs];
    }, [tabs, selectedTab]);
    return (<HStack flex="1 2" minWidth="200px" alignItems="stretch" spacing={0}>
      {canScroll && (<TabsScrollButton type="left" disabled={currentTabIndex === 0} handleClick={() => {
                const newTabIndex = currentTabIndex - 1;
                if (newTabIndex >= 0) {
                    const newTab = tabs[newTabIndex];
                    handleTabChange(newTab);
                }
            }}/>)}

      <Box scrollBehavior="smooth" borderTopRadius="md" display="flex" overflowX="hidden" overflowY="hidden">
        {tabsToShow.map((tab) => (<Box key={tab.id + '_' + tab.type} flex={tab.id === selectedTab ? '1 0 auto' : 'auto'} minWidth={tab.id === selectedTab ? 'auto' : '50px'}>
            <TabItem tab={tab}/>
          </Box>))}
      </Box>

      {moreTabs.length && <ShowMoreTabsButton tabs={moreTabs}/>}

      {canScroll && (<TabsScrollButton type="right" disabled={currentTabIndex === tabs?.length - 1} handleClick={() => {
                const newTabIndex = currentTabIndex + 1;
                if (newTabIndex <= tabs?.length - 1) {
                    const newTab = tabs[newTabIndex];
                    handleTabChange(newTab);
                }
            }}/>)}
    </HStack>);
};
export default CompactTabScroll;
