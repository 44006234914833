import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOPlotBuildingsInfo, } from 'hooks/plot/usePlotBuildings';
export const fetchProjectBuildings = (projectId) => () => get(iots.array(IOPlotBuildingsInfo), `${projectId}/project-buildings`);
export const QUERY_KEY = 'project-buildings';
export const getQueryKey = (projectId) => [QUERY_KEY, projectId];
const useProjectBuildings = (projectId, enabled) => {
    const { data, isInitialLoading } = useQuery({
        queryKey: getQueryKey(projectId),
        queryFn: fetchProjectBuildings(projectId),
        enabled: !!projectId && enabled,
    });
    return { data, isInitialLoading };
};
export default useProjectBuildings;
