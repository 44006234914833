import * as React from 'react';
import { memo } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import Filter, { UNKNOWN_LAYER } from 'components/Filter';
import FilterCategoryDocument from 'components/FilterCategoryDocument';
import PLUSidebarContent from 'components/sidebarContent/PLUSidebarContent';
import { FROM_FILTER } from 'utils/constants';
import { getFilterKey } from 'utils/filters';
import FilterCategoryContent from './FilterCategoryContent';
import FilterCategoryDisclosure from './FilterCategoryDisclosure';
import FilterCategoryEmptyMessage from './FilterCategoryEmptyMessage';
const FiltersCategory = ({ category, id, parentCategory, skeleton, filtersUpdateDate, }) => {
    if (!category)
        return null;
    if (!category?.layers?.length) {
        if (skeleton)
            return <Box width="100%">{skeleton}</Box>;
    }
    return (<Stack alignItems="start" spacing={1}>
      {/* Display PLU Info based on selectedTown directly */}
      {id === 'impacted_plots' && <PLUSidebarContent />}
      {category.content?.map((item, index) => (<FilterCategoryContent key={index} item={item} categoryId={id} filtersUpdateDate={filtersUpdateDate}/>))}
      {category.layers?.map((filter, index) => {
            let filterKey = getFilterKey(filter);
            if (filterKey === UNKNOWN_LAYER) {
                filterKey = null;
            }
            return (<Box key={filterKey || `${UNKNOWN_LAYER}_${index}`} width="full">
            {category.content_by_layer &&
                    category.content_by_layer[filterKey] && (<Box marginBottom="0.2em" width="full">
                  <FilterCategoryContent key={filterKey} item={category.content_by_layer[filterKey]} categoryId={category.content_by_layer[filterKey]} filtersUpdateDate={filtersUpdateDate}/>
                </Box>)}
            <Filter filterKey={filterKey || UNKNOWN_LAYER} filter={filter} parentCategory={parentCategory} id={id}/>
            {category.content_after_by_layer &&
                    category.content_after_by_layer[filterKey] && (<Box marginTop={1} width="full">
                  <FilterCategoryContent key={filterKey} item={category.content_after_by_layer[filterKey]} categoryId={category.content_after_by_layer[filterKey]} filtersUpdateDate={filtersUpdateDate}/>
                </Box>)}
          </Box>);
        })}
      {category.content_after && (<FilterCategoryContent item={category.content_after} categoryId={id} filtersUpdateDate={filtersUpdateDate}/>)}
      {!category?.layers?.length && (<FilterCategoryEmptyMessage id={id} category={category}/>)}
      {category.documents && (<FilterCategoryDocument documentKeys={category.documents} from={FROM_FILTER}/>)}
      {category.feasibility && (<FilterCategoryDisclosure title="sidebar.feasibility.title" value={`sidebar.feasibility.${category.feasibility}`} parentCategory={parentCategory}/>)}
      {category.find_out_more && (<FilterCategoryDisclosure title="sidebar.find_out_more.title" value={`sidebar.find_out_more.${category.find_out_more}`} parentCategory={parentCategory}/>)}
      {category.project_impact && (<FilterCategoryDisclosure title="sidebar.project_impact.title" value={`sidebar.project_impact.${category.project_impact}`} parentCategory={parentCategory}/>)}
    </Stack>);
};
export default memo(FiltersCategory);
