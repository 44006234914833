import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IORPGsInfo } from 'types/api';
const fetchPlotRPG = (plotId, siteId) => () => get(iots.array(IORPGsInfo), `plot-rpg/${plotId}`, { siteId });
const QUERY_KEY = 'plot-rpg';
const getQueryKey = (plotId, siteId) => [
    QUERY_KEY,
    plotId,
    siteId,
];
const usePlotRPG = (plot, siteId, enabled) => {
    const { data: plotRPG, isInitialLoading } = useQuery({
        queryKey: getQueryKey(plot?.id, siteId),
        queryFn: fetchPlotRPG(plot?.id, siteId),
        enabled: !!plot?.townId && !!plot?.id && enabled,
    });
    return { plotRPG, isInitialLoading };
};
export default usePlotRPG;
