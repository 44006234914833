import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link, Text, VStack } from '@chakra-ui/react';
import { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import { capitalize } from 'lodash';
import CreateContactButton from 'components/buttons/CreateContactButton';
import { formatOccupantCellValue, getOccupantFieldName, } from 'components/export/plot/occupantFormatter';
import SirenLink from 'components/links/SirenLink';
import ContactDrawer, { CREATE_CONTACT_DEFAULT_VALUES, } from 'components/modals/contactModal/ContactDrawer';
import StaffModal from 'components/modals/StaffModal';
import Table from 'components/table/Table';
import { getPlotSkeleton } from 'components/tabs/plots/PlotPannelSkeletons';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { BUTTON_CONTACT_OCCUPANT } from 'hooks/crm/useContact';
import usePlotOccupants from 'hooks/plot/usePlotOccupants';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { CO_OWNER_PLOT_TAB, OCCUPANT_PLOT_TAB } from 'types/api';
import { ACTION_CREATE } from 'utils/constants';
import { inDateRange } from 'utils/search';
import { formatDate } from '../../../../utils/formatDate';
import StaffItem from './staff/StaffItem';
import PlotCoOwner from './PlotCoOwner';
export const formatOccupantContactInfo = (occupant) => {
    return {
        ...CREATE_CONTACT_DEFAULT_VALUES,
        name: occupant?.companyName,
        lastname: capitalize(occupant?.companyName ?? ''),
        address: occupant?.address,
        postalCode: occupant?.postalCode,
        town: occupant?.town,
        company: occupant.siren ? capitalize(occupant.companyName) : '',
        siren: occupant.siren,
        type: [5], // tenant
    };
};
const EXPORTED_FIELDS = [
    'companyName',
    'siren',
    'companyActivity',
    'staff',
    'form',
    'category',
    'decreeDate',
    'tefen',
    'address',
    'ape_code',
];
const formatExportHeader = (t) => (header) => getOccupantFieldName({ fieldName: header, t });
const formatExportValue = (cellValue, fieldName) => {
    return formatOccupantCellValue({ cellValue, fieldName });
};
const PlotOccupant = ({ plot }) => {
    const { t } = useTranslation();
    const { hasPlotTab } = useSiteConfiguration();
    const selectedTown = useSelectedTown();
    const { occupants, isInitialLoading: isLoadingOccupants } = usePlotOccupants(plot, hasPlotTab(OCCUPANT_PLOT_TAB));
    const [contactToCreate, setContactToCreate] = React.useState(null);
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'companyName',
                        header: () => t(`tabs.plots.section.occupant.columns.company_name`),
                        cell: (info) => info.getValue(),
                        meta: {
                            minW: '100px',
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'siren',
                        header: () => t(`tabs.plots.section.occupant.columns.siren`),
                        cell: (info) => {
                            return (<SirenLink siren={info.getValue()} analyticDetail="Pappers Occupants" showExternalLink/>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'companyActivity',
                        header: () => t(`tabs.plots.section.occupant.columns.company_activity`),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                        meta: {
                            minW: '300px',
                        },
                    },
                    {
                        accessorKey: 'staff',
                        accessorFn: (row) => {
                            return row.staff[0]?.firstname + ' ' + row.staff[0]?.name;
                        },
                        header: () => t(`tabs.plots.section.occupant.columns.staff`),
                        cell: ({ row }) => {
                            if (row.original.staff.length > 0) {
                                if (row.original.staff.length === 1) {
                                    return (<StaffItem occupant={row.original} staff={row.original.staff[0]} index={0} setContactToCreate={setContactToCreate} flexDirection="column" widthLabel="100%"/>);
                                }
                                else {
                                    return (<StaffModal occupant={row.original} setContactToCreate={setContactToCreate} triggerItem={(onOpen) => (<Link color="red.500" size="sm" onClick={onOpen}>
                          {row.original.staff.length} {t(`global.managers`)}
                        </Link>)} staff={row.original.staff}/>);
                                }
                            }
                            else {
                                return t(`global.ND`);
                            }
                        },
                    },
                    {
                        accessorKey: 'form',
                        header: () => t(`tabs.plots.section.occupant.columns.form`),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                        meta: {
                            width: '90px',
                        },
                    },
                    {
                        accessorKey: 'category',
                        header: () => t(`tabs.plots.section.occupant.columns.category`),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'decreeDate',
                        header: () => t(`tabs.plots.section.occupant.columns.decree_date`),
                        cell: (info) => formatDate(info.getValue()),
                        filterFn: inDateRange,
                        meta: {
                            width: '90px',
                        },
                    },
                    {
                        accessorKey: 'tefen',
                        header: () => t(`tabs.plots.section.occupant.columns.tefen`),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                        meta: {
                            width: '90px',
                        },
                    },
                    {
                        accessorKey: 'address',
                        header: () => t(`tabs.plots.section.occupant.columns.address`),
                        cell: ({ row }) => {
                            return <>{row.original.address.toLowerCase()}</>;
                        },
                        meta: {
                            minW: '200px',
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'ape_code',
                        header: () => t(`tabs.plots.section.occupant.columns.ape_code`),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'action',
                        header: () => '',
                        cell: (info) => {
                            return (<VStack>
                  <CreateContactButton setContactToCreate={setContactToCreate} newContact={formatOccupantContactInfo(info.row.original)} analyticsCategory={ANALYTICS_PLOT_TAB} analyticsAction="Création contact - sous onglet occupants"/>
                </VStack>);
                        },
                        enableGlobalFilter: false,
                        enableColumnFilter: false,
                        enableSorting: false,
                    },
                ],
            },
        ];
    }, []);
    const skeleton = getPlotSkeleton();
    if (isLoadingOccupants)
        return skeleton;
    if (!isLoadingOccupants && !occupants) {
        return (<Box margin={4}>
        <Text textStyle="paragraph">
          {t('tabs.plots.section.occupant.not_available')}
        </Text>
      </Box>);
    }
    return (<VStack alignItems="flex-start" padding={4}>
      <Box width="100%">
        <Table id="plot_occupant" variant="striped" columns={columns} data={occupants || []} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} analyticsAction={t('tabs.plots.section.occupant.title')} exportTitle={t('tabs.plots.section.occupant.title') +
            ' - ' +
            selectedTown?.name +
            ' ' +
            plot.plotNumber} exportName={selectedTown?.name +
            '_' +
            plot.plotNumber +
            '_' +
            t('tabs.plots.export.filename.occupant')}/>
      </Box>
      {hasPlotTab(CO_OWNER_PLOT_TAB) && <PlotCoOwner plotId={plot?.id}/>}
      <ContactDrawer action={ACTION_CREATE} contact={contactToCreate} button={BUTTON_CONTACT_OCCUPANT} handleClose={() => setContactToCreate(null)} triggerItem={() => <></>}/>
    </VStack>);
};
export default PlotOccupant;
