import * as React from 'react';
import { memo, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { STREET_VIEW_MODE_FULL } from 'context/LayoutContext';
import { useStreetViewMode } from 'hooks/contexts/useLayoutContext';
import useCenterCross from 'hooks/map/useCenterCross';
import LayersButtons from './LayersButtons';
const ToolbarWrapper = ({ map }) => {
    const streetViewMode = useStreetViewMode();
    const toggleCenterCross = useCenterCross();
    useEffect(() => {
        // clean center cross when unmouting component
        return () => toggleCenterCross(false);
    }, []);
    return (<Box className="map-toolbar-container" ref={(el) => {
            map?.controls[google.maps.ControlPosition.RIGHT_TOP].push(el);
        }}>
      {streetViewMode !== STREET_VIEW_MODE_FULL && (<>
          <Box className={`no-print map-toolbar ` +
                (streetViewMode
                    ? 'map-toolbar-layer-streetview'
                    : 'map-toolbar-layer')}>
            <LayersButtons />
          </Box>
        </>)}
    </Box>);
};
export default memo(ToolbarWrapper);
