import { useCallback, useMemo, useState } from 'react';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filterKeyNotReallyThematic, filtersKey, } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
import { fetchThematicMapLegend } from './ThematicMapLayers';
export const FILTERS_THEMATIC_V2 = {
    // real estate
    [filtersKey.THEMATIC_MAP_REAL_ESTATE_POTENTIAL]: 224,
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilter) => (coord, zoom) => {
    if (filterKeyNotReallyThematic.includes(enabledFilter) ||
        !Object.keys(FILTERS_THEMATIC_V2).includes(enabledFilter)) {
        return null;
    }
    if (enabledFilter === filtersKey.THEMATIC_MAP_REAL_ESTATE_POTENTIAL) {
        return getLayerBoundingTileUrlKelfoncier(map, 'carte_generale', 'zone_pinel', zoom, coord, tileWidth, tileHeight);
    }
};
const useThematicMapV2Layers = () => {
    const filters = useFilters();
    const [legends, setLegends] = useState({});
    const enabledMap = useMemo(() => filters[filtersKey.THEMATIC_MAPS] || null, [filters]);
    const getLegends = useCallback(async (enabledFilters) => {
        if (!enabledMap || !enabledFilters.includes(filtersKey.THEMATIC_MAPS)) {
            return {};
        }
        const enabledFilter = filters[filtersKey.THEMATIC_MAPS];
        if (!Object.keys(FILTERS_THEMATIC_V2).includes(enabledFilter)) {
            return {};
        }
        const newLegends = { ...legends };
        if (newLegends[enabledMap]) {
            return newLegends[enabledMap];
        }
        let enabledLegends = {};
        await fetchThematicMapLegend(FILTERS_THEMATIC_V2[enabledMap]).then((result) => {
            const resultLegends = {};
            result.forEach((legend) => {
                const legendId = `@${enabledMap}@${legend.color}@${legend.label}`;
                resultLegends[legendId] = {
                    ...legend,
                    layerKey: enabledMap,
                };
            });
            newLegends[enabledMap] = resultLegends;
            enabledLegends = { ...resultLegends };
        });
        setLegends(newLegends);
        return enabledLegends;
    }, [legends, enabledMap]);
    const getTileUrl = useCallback((map, tileWidth, tileHeight) => getLayerTileUrl(map, tileWidth, tileHeight, enabledMap), [enabledMap]);
    createTilesLayerHook({
        getLayerTileUrl: getTileUrl,
        position: CST_POSITIONS[filtersKey.THEMATIC_MAPS_V2],
        getLegends,
        refreshParam: enabledMap,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.THEMATIC_MAPS);
};
const ThematicMapV2Layers = () => {
    useThematicMapV2Layers();
    return null;
};
export default ThematicMapV2Layers;
