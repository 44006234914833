import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import TeamProjectModal from 'components/modals/TeamProjectModal';
import { tableFiltersKey } from 'context/FiltersContext';
import { useTableFilters } from 'hooks/contexts/useFiltersContext';
import useTeamProjects from 'hooks/crm/useTeamProjects';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
import { getProjectStatus, getProjectStatusLabel, getProjetStatusOrder, } from '../../utils/personalization';
const legends = {};
const useTeamProjectsLayer = ({ setProject, setIsOpen, }) => {
    const { teamProjects } = useTeamProjects();
    const tableFilters = useTableFilters();
    const userEmailFilter = tableFilters[tableFiltersKey.PLOT_USER_EMAIL];
    const projectLayerItems = useMemo(() => {
        if (!teamProjects) {
            return [];
        }
        const items = [];
        teamProjects?.forEach((teamProject) => {
            if (userEmailFilter && !userEmailFilter.includes(teamProject.userEmail)) {
                return;
            }
            teamProject.plots.forEach((plot) => {
                items.push({
                    id: plot.id.toString(),
                    layerKey: filtersKey.TEAM_PROJECTS,
                    swkt: plot.swkt,
                    color: plot.color,
                    userEmail: teamProject.userEmail,
                    projectStatus: teamProject.status,
                    projectId: teamProject.id,
                    townId: teamProject.townId,
                });
            });
            legends[getProjetStatusOrder(teamProject.status)] = {
                ...getProjectStatus(teamProject.status),
                borderWidth: 2,
                borderColor: '#000',
                layerKey: filtersKey.TEAM_PROJECTS,
                label: getProjectStatusLabel(teamProject.status),
            };
        });
        return items;
    }, [teamProjects, userEmailFilter]);
    const handleClick = useCallback((item) => {
        setProject(item);
        setIsOpen(true);
    }, [teamProjects]);
    createLayersHook({
        providedData: projectLayerItems,
        legends: legends,
        onClick: handleClick,
    })(filtersKey.TEAM_PROJECTS);
};
const TeamProjectsLayer = () => {
    const [project, setProject] = useState();
    const [isOpen, setIsOpen] = useState(false);
    useTeamProjectsLayer({ setProject, setIsOpen });
    return (<TeamProjectModal project={{
            projectTownId: project?.townId,
            projectId: project?.projectId,
            label: project?.label,
            lat: project?.lat,
            lng: project?.lng,
            userEmail: project?.userEmail,
        }} isOpen={isOpen} onClose={() => setIsOpen(false)}/>);
};
export default TeamProjectsLayer;
