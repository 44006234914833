import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_generale', 'contours_communes', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.towns_limits = {
        layerKey: filtersKey.TOWNS_LIMITS,
        label: 'towns_limits',
        needsTranslate: true,
        color: '#FFFFFE',
        borderColor: '#5500FF',
        borderWidth: 3,
        borderOpacity: 1,
    };
    return legends;
};
const useTownsLimitsLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.TOWNS_LIMITS],
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.TOWNS_LIMITS);
};
const TownsLimitsLayer = () => {
    useTownsLimitsLayer();
    return null;
};
export default TownsLimitsLayer;
