import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, FormLabel, HStack, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_PLOT } from 'analytics/useAnalytics';
import { t } from 'i18next';
import SelectInput from 'components/inputs/SelectInput';
import { DUPLICATED_CHECK_OPTIONS } from 'components/modals/contactModal/importContactsSteps/ThirdStep';
import useImportPlots from 'hooks/import/useImportPlots';
import useCustomToast from 'hooks/useCustomToast';
const ThirdStep = ({ children, fileData, selectedTab, importData, handleLastStep, trackPrefix = '', }) => {
    const { trackEvent } = useAnalytics();
    const methods = useForm({
        defaultValues: {
            duplicateCheck: 'lastname_town',
            courierDate: '',
        },
        mode: 'onSubmit',
    });
    const { handleSubmit } = methods;
    const { finishImportPlots, isLoading } = useImportPlots();
    const { getErrorToast } = useCustomToast();
    const [selectedTabData, setSelectedTabData] = useState();
    const [selectedFields, setSelectedFields] = useState({});
    const formRef = useRef(null);
    useEffect(() => {
        const tabData = fileData.tabs.filter((tab) => tab.tabTitle === selectedTab)[0];
        if (importData) {
            importData.fields.forEach((field, index) => {
                if (field.fieldDb !== '') {
                    tabData.content[index].fieldDb = field.fieldDb;
                }
            });
        }
        setSelectedTabData(tabData);
        const selectedFieldsDefault = {};
        tabData.content.forEach((field, index) => {
            if (field.fieldDb !== '') {
                selectedFieldsDefault[index] = field.fieldDb;
            }
        });
        setSelectedFields(selectedFieldsDefault);
    }, [selectedTab, importData]);
    const handleOnChange = (index) => (e) => {
        setSelectedFields((prev) => ({ ...prev, [index]: e.target.value }));
    };
    const onSubmit = (data) => {
        let hasPostalCode = false;
        let hasTown = false;
        const fields = selectedTabData.content.map((tabContent, index) => {
            if (data?.fieldsDb[index] === 'postalCode_plot') {
                hasPostalCode = true;
            }
            else if (data?.fieldsDb[index] === 'town_plot') {
                hasTown = true;
            }
            return {
                fieldFile: tabContent.fieldFile,
                fieldDb: data?.fieldsDb[index],
            };
        });
        if (!hasPostalCode && !hasTown) {
            getErrorToast({
                title: t('plot.import.error_title'),
                description: t('plot.import.error_no_postal_code_and_town'),
                duration: 10000,
            });
            return;
        }
        const importPlotsData = {
            fields,
            importId: fileData.importId,
            duplicateCheck: data.duplicateCheck,
            tab: selectedTab,
        };
        finishImportPlots(importPlotsData, handleLastStep);
        trackEvent({
            category: ANALYTICS_IMPORT_PLOT,
            action: trackPrefix + "Terminer l'import",
        });
    };
    const propsFileLabel = {
        width: '33%',
        marginRight: 0,
        marginBottom: 0,
    };
    const propsHeader = {
        width: '33%',
        fontSize: '14px',
        fontWeight: 'bold',
    };
    const getFieldOptions = ({ fieldDb }) => {
        const options = [
            { value: '', label: 'Aucun', hide: false, selected: false },
        ];
        const fields = fileData.availableFields;
        fields.forEach((field) => {
            options.push({
                value: field,
                label: t(`plot.fields.import.db.${field}`),
                hide: Object.values(selectedFields).includes(field),
                selected: fieldDb === field,
            });
        });
        return options;
    };
    return (<>
      <Text fontWeight="bold">{t('plot.import.third_step_title')}</Text>
      <HStack>
        <Text {...propsHeader}>{t('global.import.fileValue')}</Text>
        <Text {...propsHeader}>{t('global.import.DBValue')}</Text>
        <Text {...propsHeader}>{t('global.import.firstLineValue')}</Text>
      </HStack>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} ref={formRef}>
          {selectedTabData?.content.map((tabContent, index) => (<HStack key={index} marginTop={2}>
              <FormLabel {...propsFileLabel} data-cy="file_label">
                {tabContent.fieldFile}
              </FormLabel>
              <SelectInput name={`fieldsDb[${index}]`} widthFormControl={'33%'} defaultValue={tabContent.fieldDb} options={getFieldOptions(tabContent)} onChange={handleOnChange(index)} style={{
                fontWeight: `${selectedFields[index] ? '' : 'bold'}`,
                backgroundColor: `${selectedFields[index] ? '' : '#ffe5a9'}`,
            }}/>
              <FormLabel width={'33%'} data-cy="file_value">
                {tabContent.value}
              </FormLabel>
            </HStack>))}

          <VStack marginTop={3} alignItems="flex-start">
            <SelectInput name="duplicateCheck" label={t('contact.fields.import.update_fields')} options={DUPLICATED_CHECK_OPTIONS} formControlDisplay="flex" formLabelWidth="32%" width="32%"/>
            <Text as="p" fontSize="12px" marginTop={2} lineHeight={1.2}>
              {t('plot.fields.import.info_more')}
              <br />
              {t('plot.fields.import.info_contact_type')}
            </Text>
          </VStack>

          <HStack marginTop={5} justifyContent="flex-end">
            <Box> {children} </Box>
            <Box>
              <Button size="sm" type="submit" variant="primary" isLoading={isLoading} loadingText={t('global.status.importing')} data-cy="third-step-import-plot">
                {t('global.actions.finish')}
              </Button>
            </Box>
          </HStack>
        </form>
      </FormProvider>
    </>);
};
export default ThirdStep;
