import { useCallback, useMemo, useState } from 'react';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import { IODPEInfo } from 'components/infoWindow/DPEInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useComputedBounds from 'hooks/map/useComputedBounds';
import DPENewImg from 'images/dpe/dpe_new.png';
import DPE_A from 'images/dpe/marker_A.png';
import { filtersKey } from 'utils/filters';
import { formatInfoWindowContent, getMarkerImgDPE, IODPEMarkerItem, isBuildingTypeAllowed, isTimePeriodAndGESOrDPEAllowed, } from './DPEOldLayer';
import { getBbox } from './POILayers';
const fetchDPENew = (townId, townScot, selectedTown, bbox) => () => get(iots.array(IODPEMarkerItem), `${townId}/DPE-new/${bbox}`);
const fetchDPENewInfo = (item) => {
    return post(iots.array(IODPEInfo), `DPE-new-info`, { ids: item.id });
};
const LEGENDS = {
    dpe_new: {
        label: 'dpe',
        layerKey: filtersKey.DPE_NEW,
        needsTranslate: true,
        image: DPE_A,
    },
};
const getMarkerImg = (marker) => {
    const markerImg = getMarkerImgDPE(marker);
    if (markerImg === null) {
        return DPENewImg;
    }
    return markerImg;
};
const useDPENewLayer = () => {
    const computedBounds = useComputedBounds();
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.DPE]?.timePeriod ?? null;
        const labelDPE = extraFilters?.[filtersKey.DPE]?.labelDPE ?? [];
        const labelGES = extraFilters?.[filtersKey.DPE]?.labelGES ?? [];
        const buildingType = extraFilters?.[filtersKey.DPE]?.buildingType ?? null;
        return (isTimePeriodAndGESOrDPEAllowed(marker, date, labelGES, labelDPE) &&
            isBuildingTypeAllowed(marker, buildingType));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const DPEInfo = await fetchDPENewInfo(marker);
        const content = formatInfoWindowContent(DPEInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const bbox = useMemo(() => getBbox(computedBounds), [computedBounds]);
    createLayersHook({
        fetch: fetchDPENew,
        markerImg: getMarkerImg,
        legends: LEGENDS,
        getInfoWindowContent,
        allowLayer: allowMarker,
        extraParams: [bbox],
        disableQuery: !bbox,
    })(filtersKey.DPE_NEW);
};
const DPENewLayer = () => {
    useDPENewLayer();
    return null;
};
export default DPENewLayer;
