import { useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Menu, MenuButton, MenuItem, MenuList, Spinner, } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import { exportDataToXLSX } from 'components/export/excelUtils';
import { exportDataToPdf } from 'components/export/pdfUtils';
import { useMap } from 'hooks/contexts/useMapContext';
import useCurrentSite from 'hooks/useCurrentSite';
import useExportXLSX from 'hooks/useExportXLSX';
import DownloadIcon from 'images/icons/Download';
import { getImageMap } from 'utils/image';
export const EXPORT_TYPE_PROJECT = 'project';
export const EXPORT_TYPE_CONTACT = 'contact';
export const EXPORT_TYPE_REAL_ESTATE_TAXES = 'realEstateTaxes';
export const ExportItemButton = ({ children, isDisabled = false, onExport, id, }) => {
    return (<MenuItem textStyle="paragraph" color="colorMode.font900" isDisabled={isDisabled} onClick={onExport} data-cy={id}>
      {children}
    </MenuItem>);
};
const ExportButton = ({ fileName = 'export', isDisabled, isDisabledCurrent, getSheets, exportType = null, exportParams = {}, children, analyticsEvent, exportItems, exportButtonTitle, exportWithImage = false, buttonPDFText = '', buttonXLSXText = '', }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const exportXLSX = useExportXLSX();
    const map = useMap();
    const { currentSite } = useCurrentSite();
    const [isExporting, setIsExporting] = useState(false);
    const PDFText = buttonPDFText ? buttonPDFText : t('table.export_pdf');
    const XLSXText = buttonXLSXText ? buttonXLSXText : t('table.export_xlsx');
    const exportData = async (type) => {
        let action = analyticsEvent.action;
        setIsExporting(true);
        let imageBase64 = null;
        if (exportWithImage) {
            const image = await getImageMap(map);
            const canvasWidth = image.element.clientWidth;
            if (canvasWidth !== 0) {
                // Transformation en base64
                imageBase64 = image.base64;
            }
        }
        if (null !== exportType) {
            const dataToExport = [];
            switch (exportType) {
                case EXPORT_TYPE_PROJECT:
                    exportXLSX(`project/` +
                        exportParams.projectId +
                        `/export/` +
                        type.toLowerCase() +
                        (exportParams.landPointId
                            ? '?landPointId=' +
                                exportParams.landPointId +
                                '&siteId=' +
                                currentSite.legacyId
                            : '?siteId=' + currentSite.legacyId), {}, 'post', { imageBase64 }).then(() => setIsExporting(false));
                    break;
                case EXPORT_TYPE_CONTACT:
                    exportXLSX(`contact/` +
                        exportParams.contactId +
                        `/export/` +
                        type.toLowerCase()).then(() => setIsExporting(false));
                    break;
                case EXPORT_TYPE_REAL_ESTATE_TAXES:
                    for (const [k, v] of Object.entries(exportParams)) {
                        dataToExport.push({
                            id: k,
                            label: t(`modals.real_estate_tax.${k}`).replaceAll('*', ''),
                            value: v,
                        });
                    }
                    exportXLSX(`real-estate-taxes/export/${type.toLowerCase()}`, {}, 'post', { data: dataToExport, fileName }).then(() => setIsExporting(false));
                    break;
            }
        }
        else {
            if (type === 'XLSX') {
                action += ' Excel';
                setTimeout(() => {
                    exportDataToXLSX(fileName, getSheets());
                    setIsExporting(false);
                }, 200);
            }
            else {
                action += ' PDF';
                // Le PDF peut être un peu long a générer
                setTimeout(() => {
                    exportDataToPdf(fileName, getSheets());
                    setIsExporting(false);
                }, 200);
            }
        }
        if (analyticsEvent) {
            trackEvent({
                category: analyticsEvent.category,
                action: action,
            });
        }
    };
    return (<Menu isLazy>
      <MenuButton size="sm" leftIcon={<DownloadIcon />} as={Button} color="colorMode.revertBase" rightIcon={<ChevronDownIcon />} backgroundColor="colorMode.base" isDisabled={isDisabled || isExporting} data-cy="export_menu_button">
        {isExporting ? (<Spinner width="15px" height="15px"/>) : exportButtonTitle ? (exportButtonTitle) : (t('table.export'))}
      </MenuButton>

      <MenuList backgroundColor="colorMode.background900" borderRadius="md" padding={0} overflow="hidden" zIndex={10}>
        {exportItems ? (exportItems) : (<>
            <ExportItemButton key="export_single_pdf_button" isDisabled={isDisabledCurrent} onExport={() => exportData('PDF')} id="export_single_pdf_button">
              {PDFText}
            </ExportItemButton>
            <ExportItemButton key="export_single_xlsx_button" isDisabled={isDisabledCurrent} onExport={() => exportData('XLSX')} id="export_single_xlsx_button">
              {XLSXText}
            </ExportItemButton>
          </>)}
        {children && children}
      </MenuList>
    </Menu>);
};
export default ExportButton;
