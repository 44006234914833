import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Table, Td, Text, Th, Tr } from '@chakra-ui/react';
import * as iots from 'io-ts';
import LogoBouygues from 'images/logo/bouygues_telecom.svg';
import LogoFree from 'images/logo/free.svg';
import LogoOrange from 'images/logo/orange.svg';
import LogoSfr from 'images/logo/sfr.svg';
import { CELL_TOWER_GENERATION_VALUES, CELL_TOWER_OPERATOR_VALUES, CELL_TOWER_STATUS_VALUES, CELL_TOWER_SYSTEM_VALUES, } from 'utils/constants';
export const IOCellTowerInfoOperatorDetail = iots.type({
    id: iots.string,
    systemId: iots.number,
    generationId: iots.number,
    statusId: iots.number,
    heights: iots.array(iots.string),
});
export const IOCellTowerInfoOperator = iots.type({
    id: iots.number,
    details: iots.array(IOCellTowerInfoOperatorDetail),
});
export const IOCellTowerInfo = iots.type({
    height: iots.number,
    operators: iots.array(IOCellTowerInfoOperator),
});
const CellTowerInfoWindow = ({ info, }) => {
    const { t } = useTranslation();
    const getLogo = (operatorId) => {
        switch (operatorId) {
            case 1:
                return LogoBouygues;
            case 2:
                return LogoFree;
            case 3:
                return LogoOrange;
            case 4:
                return LogoSfr;
        }
    };
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.height && (<Box style={{ fontWeight: 'bold' }}>
          {t('cell_tower.height')} : {info.height} m
        </Box>)}
      {info.operators.map((operator) => {
            return (<Table key={operator.id} style={{ width: '100%' }}>
            <Tr>
              <Th colSpan={4}>
                {operator.id &&
                    CELL_TOWER_OPERATOR_VALUES[operator.id] &&
                    CELL_TOWER_OPERATOR_VALUES[operator.id].icon ? (<img src={getLogo(operator.id)} alt="operateur" style={{ height: '30px', margin: 'auto' }}/>) : (t(CELL_TOWER_OPERATOR_VALUES[operator.id].label))}
              </Th>
            </Tr>
            <Tr>
              <Th style={{ textAlign: 'left', paddingRight: '4px' }}>
                {t('cell_tower.generation.label')}
              </Th>
              <Th style={{ textAlign: 'left', paddingRight: '4px' }}>
                {t('cell_tower.system.label')}
              </Th>
              <Th style={{ textAlign: 'left', paddingRight: '4px' }}>
                {t('cell_tower.status.label')}
              </Th>
              <Th style={{ textAlign: 'left' }}>
                {t('cell_tower.heights.label')}
              </Th>
            </Tr>
            {operator.details.map((item) => {
                    return (<Tr key={item.id}>
                  <Td>
                    <Text>
                      {item.generationId &&
                            CELL_TOWER_GENERATION_VALUES[item.generationId] &&
                            t(CELL_TOWER_GENERATION_VALUES[item.generationId].label)}
                    </Text>
                  </Td>
                  <Td style={{ paddingRight: '4px' }}>
                    <Text>
                      {item.systemId &&
                            CELL_TOWER_SYSTEM_VALUES[item.systemId] &&
                            t(CELL_TOWER_SYSTEM_VALUES[item.systemId].label)}
                    </Text>
                  </Td>
                  <Td style={{ paddingRight: '4px' }}>
                    <Text>
                      {item.statusId &&
                            CELL_TOWER_STATUS_VALUES[item.statusId] &&
                            t(CELL_TOWER_STATUS_VALUES[item.statusId].label)}
                    </Text>
                  </Td>
                  <Td>
                    <Text>
                      {item.heights ? item.heights.join(' m, ') + ' m' : ''}
                    </Text>
                  </Td>
                </Tr>);
                })}
            <Tr>
              <Td colSpan={4}>&nbsp;</Td>
            </Tr>
          </Table>);
        })}
    </Box>);
};
export default CellTowerInfoWindow;
