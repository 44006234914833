import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
const fetchTeamStatistics = (dateStart, dateEnd) => () => get(null, `team-statistics`, { dateStart, dateEnd });
const fetchTeamStatisticsGlobal = () => () => get(null, `team-statistics/global`);
const fetchTeamStatisticsUtilisation = (dateStart, dateEnd) => () => get(null, `team-statistics/utilisation`, { dateStart, dateEnd });
const useTeamStatistics = (dateStart, dateEnd) => {
    const { data: teamStatistics, isInitialLoading: isLoadingTeamStatistics, error: errorTeamStatistics, } = useQuery({
        queryKey: ['team-statistics', { dateStart, dateEnd }],
        queryFn: fetchTeamStatistics(dateStart, dateEnd),
        enabled: !!dateStart,
    });
    const { data: teamStatisticsGlobal, isInitialLoading: isLoadingTeamStatisticsGlobal, error: errorTeamStatisticsGlobal, } = useQuery({
        queryKey: ['team-statistics/global'],
        queryFn: fetchTeamStatisticsGlobal(),
    });
    const { data: teamStatisticsUtilisation, isInitialLoading: isLoadingTeamStatisticsUtilisation, error: errorTeamStatisticsUtilisation, } = useQuery({
        queryKey: ['team-statistics-utilisation', { dateStart, dateEnd }],
        queryFn: fetchTeamStatisticsUtilisation(dateStart, dateEnd),
        enabled: !!dateStart,
    });
    return {
        teamStatistics,
        isLoadingTeamStatistics,
        errorTeamStatistics,
        teamStatisticsGlobal,
        isLoadingTeamStatisticsGlobal,
        errorTeamStatisticsGlobal,
        teamStatisticsUtilisation,
        isLoadingTeamStatisticsUtilisation,
        errorTeamStatisticsUtilisation,
    };
};
export default useTeamStatistics;
