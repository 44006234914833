import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.ZAER_SOLAR_PV_GROUND]: 'zaer_solaire_pv_sol',
    [filtersKey.ZAER_SOLAR_PV_ROOF]: 'zaer_solaire_pv_toit',
    [filtersKey.ZAER_SOLAR_PV_SHADOW_PARKING]: 'zaer_solaire_pv_ombre',
    [filtersKey.ZAER_SOLAR_THERMIC]: 'zaer_solaire_thermique',
    [filtersKey.ZAER_WIND]: 'zaer_eolien',
    [filtersKey.ZAER_GEOTHERMAL]: 'zaer_geothermie',
    [filtersKey.ZAER_BIOMETHANE]: 'zaer_biomethane',
    [filtersKey.ZAER_BIOMASS]: 'zaer_biomasse',
    [filtersKey.ZAER_HYDROELECTRICITY]: 'zaer_hydroelectricite',
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const layers = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_zaer_final.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        layers +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:3857&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.ZAER_SOLAR_PV_GROUND)) {
        legends.zaer_solar_pv_ground = {
            layerKey: filtersKey.ZAER_SOLAR_PV_GROUND,
            label: 'zaer_solar_pv_ground',
            needsTranslate: true,
            color: '#fedc34',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_SOLAR_PV_ROOF)) {
        legends.zaer_solar_pv_roof = {
            layerKey: filtersKey.ZAER_SOLAR_PV_ROOF,
            label: 'zaer_solar_pv_roof',
            needsTranslate: true,
            color: '#bf7000',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_SOLAR_PV_SHADOW_PARKING)) {
        legends.zaer_solar_pv_shadow_parking = {
            layerKey: filtersKey.ZAER_SOLAR_PV_SHADOW_PARKING,
            label: 'zaer_solar_pv_shadow_parking',
            needsTranslate: true,
            color: '#fefc34',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_SOLAR_THERMIC)) {
        legends.zaer_solar_thermic = {
            layerKey: filtersKey.ZAER_SOLAR_THERMIC,
            label: 'zaer_solar_thermic',
            needsTranslate: true,
            color: '#f69822',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_WIND)) {
        legends.zaer_wind = {
            layerKey: filtersKey.ZAER_WIND,
            label: 'zaer_wind',
            needsTranslate: true,
            color: '#528beb',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_GEOTHERMAL)) {
        legends.zaer_geothermal = {
            layerKey: filtersKey.ZAER_GEOTHERMAL,
            label: 'zaer_geothermal',
            needsTranslate: true,
            color: '#b2b14e',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_BIOMETHANE)) {
        legends.zaer_biomethane = {
            layerKey: filtersKey.ZAER_BIOMETHANE,
            label: 'zaer_biomethane',
            needsTranslate: true,
            color: '#f52bfc',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_BIOMASS)) {
        legends.zaer_biomass = {
            layerKey: filtersKey.ZAER_BIOMASS,
            label: 'zaer_biomass',
            needsTranslate: true,
            color: '#55fc2e',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.ZAER_HYDROELECTRICITY)) {
        legends.zaer_hydroelectricity = {
            layerKey: filtersKey.ZAER_HYDROELECTRICITY,
            label: 'zaer_hydroelectricity',
            needsTranslate: true,
            color: '#1e2afb',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    return legends;
};
export const ZAER_FILTERS = [
    filtersKey.ZAER_SOLAR_PV_GROUND,
    filtersKey.ZAER_SOLAR_PV_ROOF,
    filtersKey.ZAER_SOLAR_PV_SHADOW_PARKING,
    filtersKey.ZAER_SOLAR_THERMIC,
    filtersKey.ZAER_WIND,
    filtersKey.ZAER_GEOTHERMAL,
    filtersKey.ZAER_BIOMETHANE,
    filtersKey.ZAER_BIOMASS,
    filtersKey.ZAER_HYDROELECTRICITY,
];
const useZAERLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS.ZAER_FILTERS,
        getLegends,
    })(ZAER_FILTERS);
};
const ZAERLayers = () => {
    useZAERLayers();
    return null;
};
export default ZAERLayers;
