import { memo, useEffect } from 'react';
import * as React from 'react';
import { Box, Image } from '@chakra-ui/react';
import { STREET_VIEW_MODE_FIFTY, STREET_VIEW_MODE_FIFTY_VERTICAL, STREET_VIEW_MODE_FULL, } from 'context/LayoutContext';
import { useResetFilters, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useStreetViewMode } from 'hooks/contexts/useLayoutContext';
import { useIsLoadingTown, useResetPlotSelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useMap, useMapCenter, usePrecision, } from 'hooks/contexts/useMapContext';
import { useSetPlotSearchParams } from 'hooks/contexts/usePlotSearchContext';
import useComputedZoom from 'hooks/map/useComputedZoom';
import useCurrentSite from 'hooks/useCurrentSite';
import { usePrevious } from 'hooks/usePrevious';
import LogoKelFoncier from 'images/logo_kelFoncier.png';
import { SITE_ID_ENERGY } from 'utils/constants';
import { filtersKey, INITIAL_FILTERS } from 'utils/filters';
import { centerAndZoomOnCoord } from 'utils/map';
const getFullScreenStreetViewStyles = (streetViewIsFullScreen) => {
    if (!streetViewIsFullScreen) {
        return {};
    }
    return {
        height: '100px',
        width: '150px',
        zIndex: 20,
        bottom: '25px',
        left: '25px',
        border: '2px solid #FBFAF9',
        borderRadius: 'md',
        className: 'hide-street-view-trigger',
    };
};
const MapBlock = ({ refMap, }) => {
    const precision = usePrecision();
    const map = useMap();
    const streetViewMode = useStreetViewMode();
    const mapCenter = useMapCenter();
    const toggleFilter = useToggleFilter();
    const selectedTown = useSelectedTown();
    const isLoadingTown = useIsLoadingTown();
    const previousSelectedTown = usePrevious(selectedTown, true);
    const setPlotSearchParams = useSetPlotSearchParams();
    const resetPlotSelectionMode = useResetPlotSelectionMode();
    const resetFilters = useResetFilters();
    const { currentSite } = useCurrentSite();
    const computedZoom = useComputedZoom();
    const streetViewIsFullScreen = streetViewMode === STREET_VIEW_MODE_FULL;
    const currentSiteIsNotEnergy = currentSite.legacyId !== SITE_ID_ENERGY;
    // town selected
    useEffect(() => {
        if (!map || isLoadingTown) {
            return;
        }
        if (selectedTown) {
            // Si pas de ville précédente sélectionné on active ces calques
            if (!previousSelectedTown?.id) {
                toggleFilter(filtersKey.TOWNS, false);
                toggleFilter(filtersKey.PLOTS, true);
                if (currentSiteIsNotEnergy) {
                    toggleFilter(filtersKey.PLU, selectedTown.hasPLU);
                }
            }
            if (mapCenter.current) {
                centerAndZoomOnCoord(map, mapCenter.current, precision);
            }
            return;
        }
    }, [selectedTown, map]);
    // When town changes
    useEffect(() => {
        if (selectedTown && selectedTown.id !== previousSelectedTown?.id) {
            setPlotSearchParams(null);
            resetPlotSelectionMode();
        }
    }, [selectedTown]);
    // When changes currentSite
    useEffect(() => {
        if (selectedTown) {
            resetFilters([filtersKey.TOWN, filtersKey.PLOTS]);
        }
        else {
            resetFilters(Object.keys(INITIAL_FILTERS));
        }
    }, [currentSite]);
    return (<>
      <Box id="map" position={streetViewIsFullScreen ? 'absolute' : 'relative'} height={streetViewMode === STREET_VIEW_MODE_FIFTY
            ? '50%'
            : streetViewIsFullScreen
                ? '100px'
                : '100%'} width={streetViewMode === STREET_VIEW_MODE_FIFTY_VERTICAL
            ? '50%'
            : streetViewIsFullScreen
                ? '150px'
                : '100%'} {...getFullScreenStreetViewStyles(streetViewIsFullScreen)}>
        <Box id="googleMap" width="100%" height="100%" className={`zoom-${computedZoom}`}>
          <Box width="100%" height="100%" ref={refMap} borderRadius={streetViewIsFullScreen && 'md'}/>
        </Box>
      </Box>
      <Image id="logoKelfoncier" alt="Logo KelFoncier" src={LogoKelFoncier} objectFit="contain" maxWidth="130px" maxHeight="60px"/>
    </>);
};
export default memo(MapBlock);
