import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Button } from '@chakra-ui/react';
import useAnalytics from 'analytics/useAnalytics';
import { menuItems } from 'components/sidebar/utils';
import { PLOT_SELECTION_PROJECT } from 'context/LocationContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useSetPlotSelectionMode } from 'hooks/contexts/useLocationContext';
import { useToggleMenu } from 'hooks/contexts/useMenuContext';
import { useSetFeasibilityParentId } from 'hooks/contexts/useSecondaryPlotsContext';
import { useShowProject } from 'hooks/contexts/useTabsContext';
import useProject from 'hooks/crm/useProject';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
const CreateProjectButton = ({ plotIds, townId, variant = 'link-primary', icon = false, analyticsCategory, analyticsAction, ...buttonProps }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { hasDashboardItem } = useSiteConfiguration();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const setPlotSelectionMode = useSetPlotSelectionMode();
    const showProject = useShowProject();
    const toggleMenu = useToggleMenu();
    const toggleFilter = useToggleFilter();
    const { addProject, isAddingProject } = useProject();
    const createProject = (plotIds, townId) => {
        addProject({
            plotIds: plotIds,
            townshipId: townId,
        }, {
            onSuccess: (createdProject) => {
                setFeasibilityParentId(createdProject.id);
                showProject({
                    resource: {
                        id: createdProject.id,
                        label: createdProject.label,
                        townId: townId,
                        lat: createdProject.lat,
                        lng: createdProject.lng,
                    },
                });
                setPlotSelectionMode(PLOT_SELECTION_PROJECT);
                toggleFilter(filtersKey.MAP_PLOTS, true);
            },
        });
    };
    return (hasDashboardItem(filtersKey.USER_PROJECTS) && (<Button isDisabled={isAddingProject} variant={variant} onClick={() => {
            trackEvent({
                category: analyticsCategory,
                action: analyticsAction,
            });
            toggleMenu(menuItems?.LAYERS_TREE, false);
            createProject(plotIds, townId);
        }} leftIcon={icon ? <AddIcon fill="currentColor"/> : null} {...buttonProps}>
        {t('global.create_project')}
      </Button>));
};
export default CreateProjectButton;
