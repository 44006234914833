import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControl, FormLabel, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MANAGER } from 'analytics/useAnalytics';
import useShareProject from 'hooks/crm/useShareProject';
import useCustomToast from 'hooks/useCustomToast';
const ShareProjectModal = ({ project }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { getInfoToast } = useCustomToast();
    const { shareableTeam, isLoading, shareProject, isLoadingShare } = useShareProject(project.id);
    const { register, handleSubmit, formState: { isSubmitting }, reset, } = useForm({
        defaultValues: {
            members: [],
            copyContact: true,
            copyComment: true,
            copyDocument: true,
            copyPhoto: true,
        },
    });
    const onSubmit = (form) => {
        const membersSelected = form.members;
        if (membersSelected.length === 0 || !membersSelected) {
            getInfoToast({
                title: t('modals.share_project.no_members_selected'),
                position: 'top',
                id: 'no_members_selected',
            });
            return;
        }
        trackEvent({
            category: ANALYTICS_MANAGER,
            action: `Partager un projet`,
        });
        shareProject({
            projectId: project.id,
            users: membersSelected,
            copyContact: form.copyContact,
            copyComment: form.copyComment,
            copyDocument: form.copyDocument,
            copyPhoto: form.copyPhoto,
        });
        reset();
    };
    if (isLoading) {
        return <Spinner />;
    }
    if (shareableTeam?.available?.length === 0 &&
        shareableTeam?.unavailable?.length === 0) {
        return;
    }
    return (<>
      <Button variant="primary" onClick={onOpen}>
        {t('modals.share_project.button_title')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h3" variant="h3">
              {t('modals.share_project.title')}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading || isSubmitting ? (<Spinner />) : (<VStack alignItems="start" spacing={4}>
                <Box>
                  <Heading as="h4" variant="h4">
                    {t('modals.share_project.available_team_title')}
                  </Heading>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {shareableTeam?.available &&
                Object.values(shareableTeam?.available).map((team, index) => (<FormControl display="flex" key={index} paddingTop={1}>
                            <Checkbox {...register(`members`)} value={team.email}/>
                            <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                              {`${team.email} ${team.username}`}
                            </FormLabel>
                          </FormControl>))}
                    <Heading as="h4" variant="h4" marginTop={3}>
                      {t('modals.share_project.what_to_share')} :
                    </Heading>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyContact`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_project.contact')}
                      </FormLabel>
                    </FormControl>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyComment`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_project.comment')}
                      </FormLabel>
                    </FormControl>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyDocument`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_project.document')}
                      </FormLabel>
                    </FormControl>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyPhoto`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_project.photo')}
                      </FormLabel>
                    </FormControl>

                    <Button type="submit" variant="primary" marginY={4} size="sm" disabled={!shareableTeam?.available?.length} isLoading={isLoadingShare}>
                      {t('modals.share_project.button_submit')}
                    </Button>
                  </form>
                </Box>
                {shareableTeam?.unavailable && (<Box>
                    <Heading as="h4" variant="h4">
                      {t('modals.share_project.unavailable_team_title')}
                    </Heading>
                    {shareableTeam.unavailable.map((member) => (<Text textStyle="paragraph" paddingTop={1} key={member.email}>
                        {`${member.email} ${member.username} `}{' '}
                        {t('modals.share_project.shared_on', {
                        date: member.dateSharing,
                    })}
                      </Text>))}
                  </Box>)}
                <Text textStyle="paragraph">
                  {t('modals.share_project.help')}
                </Text>
              </VStack>)}
          </ModalBody>

          <ModalFooter>
            <Button variant="tertiary" onClick={onClose}>
              {t('modals.share_project.button_close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>);
};
export default ShareProjectModal;
