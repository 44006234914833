import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_environnement', 'carte_des_sols_inrae', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.alocrisols = {
        layerKey: filtersKey.SOIL,
        label: 'Alocrisols',
        needsTranslate: false,
        color: '#ed98f9',
    };
    legends.andosols = {
        layerKey: filtersKey.SOIL,
        label: 'Andosols',
        needsTranslate: false,
        color: '#690d04',
    };
    legends.arenosols = {
        layerKey: filtersKey.SOIL,
        label: 'Arénosols',
        needsTranslate: false,
        color: '#e7e5cc',
    };
    legends.brunisols = {
        layerKey: filtersKey.SOIL,
        label: 'Brunisols',
        needsTranslate: false,
        color: '#b78137',
    };
    legends.brunisols_redoxisols = {
        layerKey: filtersKey.SOIL,
        label: 'Brunisols-Rédoxisols',
        needsTranslate: false,
        color: '#995a3e',
    };
    legends.calcisols = {
        layerKey: filtersKey.SOIL,
        label: 'Calcisols',
        needsTranslate: false,
        color: '#f8b43d',
    };
    legends.calcosols = {
        layerKey: filtersKey.SOIL,
        label: 'Calcosols',
        needsTranslate: false,
        color: '#fdfa5c',
    };
    legends.colluviosols = {
        layerKey: filtersKey.SOIL,
        label: 'Colluviosols',
        needsTranslate: false,
        color: '#bcfa5a',
    };
    legends.colluviosols_redoxisols = {
        layerKey: filtersKey.SOIL,
        label: 'Colluviosols-Rédoxisols',
        needsTranslate: false,
        color: '#379858',
    };
    legends.dolomitosols = {
        layerKey: filtersKey.SOIL,
        label: 'Dolomitosols',
        needsTranslate: false,
        color: '#f9b7b2',
    };
    legends.fersialsols = {
        layerKey: filtersKey.SOIL,
        label: 'Fersialsols',
        needsTranslate: false,
        color: '#e8041d',
    };
    legends.fluviosols = {
        layerKey: filtersKey.SOIL,
        label: 'Fluviosols',
        needsTranslate: false,
        color: '#c2',
    };
    legends.histosols = {
        layerKey: filtersKey.SOIL,
        label: 'Histosols',
        needsTranslate: false,
        color: '#153c92',
    };
    legends.lithosols = {
        layerKey: filtersKey.SOIL,
        label: 'Lithosols',
        needsTranslate: false,
        color: '#e9e9e9',
    };
    legends.luvisols = {
        layerKey: filtersKey.SOIL,
        label: 'Luvisols',
        needsTranslate: false,
        color: '#ecceb0',
    };
    legends.luvisols_redoxisols = {
        layerKey: filtersKey.SOIL,
        label: 'Luvisols-Rédoxisols',
        needsTranslate: false,
        color: '#dfb869',
    };
    legends.neoluvisols = {
        layerKey: filtersKey.SOIL,
        label: 'Néoluvisols',
        needsTranslate: false,
        color: '#cc511f',
    };
    legends.neoluvisols_redoxisols = {
        layerKey: filtersKey.SOIL,
        label: 'Néoluvisols-Rédoxisols',
        needsTranslate: false,
        color: '#ae423d',
    };
    legends.organosols = {
        layerKey: filtersKey.SOIL,
        label: 'Organosols',
        needsTranslate: false,
        color: '#3d3d3d',
    };
    legends.pelosols = {
        layerKey: filtersKey.SOIL,
        label: 'Pélosols',
        needsTranslate: false,
        color: '#9fb8d5',
    };
    legends.peyrosols = {
        layerKey: filtersKey.SOIL,
        label: 'Peyrosols',
        needsTranslate: false,
        color: '#c5c5c5',
    };
    legends.planosols = {
        layerKey: filtersKey.SOIL,
        label: 'Planosols',
        needsTranslate: false,
        color: '#b491b0',
    };
    legends.podzosols = {
        layerKey: filtersKey.SOIL,
        label: 'Podzosols',
        needsTranslate: false,
        color: '#be37e5',
    };
    legends.rankosols = {
        layerKey: filtersKey.SOIL,
        label: 'Rankosols',
        needsTranslate: false,
        color: '#8d8d8d',
    };
    legends.redoxisols = {
        layerKey: filtersKey.SOIL,
        label: 'Rédoxisols',
        needsTranslate: false,
        color: '#60c7fb',
    };
    legends.reductisols = {
        layerKey: filtersKey.SOIL,
        label: 'Pélosols',
        needsTranslate: false,
        color: '#3795f8',
    };
    legends.regosols = {
        layerKey: filtersKey.SOIL,
        label: 'Régosols',
        needsTranslate: false,
        color: '#ecdddc',
    };
    legends.rendisols = {
        layerKey: filtersKey.SOIL,
        label: 'Rendisols',
        needsTranslate: false,
        color: '#fbd694',
    };
    legends.rendosols = {
        layerKey: filtersKey.SOIL,
        label: 'Rendosols',
        needsTranslate: false,
        color: '#fefcbc',
    };
    legends.sodisalisols = {
        layerKey: filtersKey.SOIL,
        label: 'Sodisalisols',
        needsTranslate: false,
        color: '#e5fec1',
    };
    legends.thalassosols = {
        layerKey: filtersKey.SOIL,
        label: 'Thalassosols',
        needsTranslate: false,
        color: '#c1fde1',
    };
    legends.veracrisols = {
        layerKey: filtersKey.SOIL,
        label: 'Véracrisols',
        needsTranslate: false,
        color: '#9b1c6e',
    };
    legends.vertisols = {
        layerKey: filtersKey.SOIL,
        label: 'Vertisols',
        needsTranslate: false,
        color: '#8c8e32',
    };
    legends.no_available = {
        layerKey: filtersKey.SOIL,
        label: 'not_available_in_progress',
        needsTranslate: true,
        color: '#FFFFFF',
        borderColor: '#000000',
        borderWidth: 1,
        borderOpacity: 1,
    };
    return legends;
};
const useSoilLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.SOIL],
        getLegends,
        opacity: 0.8,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.SOIL);
};
const SoilLayer = () => {
    useSoilLayer();
    return null;
};
export default SoilLayer;
