import * as React from 'react';
import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Button, HStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import useAnalytics, { ANALYTICS_IMPORT_CONTACT } from 'analytics/useAnalytics';
import { t } from 'i18next';
import * as yup from 'yup';
import UploadInput from 'components/inputs/UploadInput';
import useImportContacts from 'hooks/import/useImportContacts';
import { FILE_EXCEL } from 'utils/constants';
import { checkFileSize } from 'utils/files';
const ACCEPTED_FILE_FORMATS = '.xls,.xlsx';
const FirstStep = ({ children, handleFileData, trackPrefix = '', }) => {
    const { trackEvent } = useAnalytics();
    const getDefaultValues = () => {
        return {
            file: null,
        };
    };
    const getValidationSchema = (t) => {
        const properties = {
            file: yup
                .mixed()
                .required(t('global.fields.error.required'))
                .test('is-valid-size', t('global.fields.error.file_size'), (fileList) => checkFileSize({ fileList, maxSize: 10000000 })),
        };
        return yup.object().shape(properties);
    };
    const defaultValues = getDefaultValues();
    const validationSchema = getValidationSchema(t);
    const methods = useForm({
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: 'onSubmit',
    });
    const { handleSubmit } = methods;
    const { importContacts, isLoadingImport } = useImportContacts();
    const [fileError, setFileError] = useState(null);
    const onSubmit = (formData) => {
        importContacts(formData, {
            onSuccess: (response) => handleFileData(response),
            onError: (error) => setFileError(error),
        });
        trackEvent({
            category: ANALYTICS_IMPORT_CONTACT,
            action: trackPrefix + 'Charger un fichier',
        });
    };
    return (<FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <UploadInput name="file" acceptedFileFormat={ACCEPTED_FILE_FORMATS} fileError={fileError} typeFile={FILE_EXCEL}/>
        <HStack marginTop={2} justifyContent="flex-end">
          <Box> {children} </Box>
          <Box>
            <Button type="submit" size="sm" variant="primary" isLoading={isLoadingImport} loadingText={t('global.status.importing')} data-cy="first-step-import-contact">
              {t('contact.actions.import')}
            </Button>
          </Box>
        </HStack>
      </form>
    </FormProvider>);
};
export default FirstStep;
