import { useEffect, useMemo, useRef } from 'react';
import * as React from 'react';
import { useStreetViewMode } from 'hooks/contexts/useLayoutContext';
import { useDrawingMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useMap, useSetPLUGPUInfoData } from 'hooks/contexts/useMapContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const PLU_GPU_ZOOM_LIMIT = 12;
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.PLU_GPU)) {
        legends.plu = {
            layerKey: filtersKey.PLU_GPU,
            label: 'plu_gpu',
            needsTranslate: true,
            color: '#FFFFFE',
            borderColor: '#000000',
            borderWidth: 1,
            borderOpacity: 1,
        };
    }
    return legends;
};
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coords, zoom) => {
    const boundingBox = getTileBoundingBox(coords, zoom, map, tileWidth, tileHeight);
    return ('https://data.geopf.fr/wms-v/ows?' +
        'SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap' +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&TRANSPARENT=TRUE&LAYERS=du,psmv' +
        '&CRS=EPSG%3A3857&STYLES=&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&BBOX=' +
        encodeURIComponent(boundingBox));
};
const usePLUGPULayer = ({ townId, isSelectedTown, }) => {
    const selectedTown = useSelectedTown();
    const map = useMap();
    const setPLUGPUInfoData = useSetPLUGPUInfoData();
    const streetViewMode = useStreetViewMode();
    const drawingMode = useDrawingMode();
    const infoWindowListener = useRef(null);
    useEffect(() => {
        infoWindowListener?.current?.remove();
        if (!map || !isSelectedTown) {
            return;
        }
        infoWindowListener.current = map.addListener('click', (event) => {
            if (streetViewMode || drawingMode) {
                return;
            }
            const marker = new google.maps.Marker({
                icon: '',
                position: new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()),
                map: map,
                visible: false,
            });
            const coords = new google.maps.Point(marker.getPosition().lat(), marker.getPosition().lng());
            setPLUGPUInfoData(coords);
        });
        return () => {
            infoWindowListener?.current?.remove();
            setPLUGPUInfoData(null);
        };
    }, [map, streetViewMode, drawingMode, townId, isSelectedTown]);
    // Le contour
    const layersData = useMemo(() => {
        return [
            {
                id: selectedTown?.id,
                layerKey: filtersKey.PLU_GPU,
                type: VECTOR_TYPES.polygon,
                swkt: selectedTown?.swkt,
                borderColor: '#000000',
                borderWidth: 5,
                opacity: 0,
            },
        ];
    }, [selectedTown]);
    createLayersHook({
        providedData: layersData,
        legends: {},
    })(filtersKey.PLU_GPU);
    return [
        createTilesLayerHook({
            getLayerTileUrl,
            position: CST_POSITIONS[filtersKey.PLU_GPU],
            getLegends,
        })(filtersKey.PLU_GPU),
    ];
};
const PLUGPULayer = ({ townId, isSelectedTown, }) => {
    usePLUGPULayer({ townId, isSelectedTown });
    return null;
};
const PLUGPULayerWrapper = ({ computedZoom, townId, isSelectedTown, }) => {
    if (computedZoom < PLU_GPU_ZOOM_LIMIT) {
        return null;
    }
    return <PLUGPULayer townId={townId} isSelectedTown={isSelectedTown}/>;
};
export default PLUGPULayerWrapper;
