import * as React from 'react';
import { useEffect, useState } from 'react';
import { Box, Button, HStack, Select, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_CONTACT } from 'analytics/useAnalytics';
import { t } from 'i18next';
const SecondStep = ({ children, fileData, handleSelectedTab, trackPrefix = '', }) => {
    const { trackEvent } = useAnalytics();
    const [fileTabs, setFileTabs] = useState([]);
    const [selectTab, setSelectTab] = useState(null);
    useEffect(() => {
        if (fileData) {
            setFileTabs(fileData?.tabs.map((tab, index) => ({
                id: index,
                title: tab.tabTitle,
            })));
            setSelectTab(fileData?.tabs[0].tabTitle);
        }
    }, [fileData]);
    const handleSelect = (e) => setSelectTab(e.target.value);
    const handleButton = () => {
        handleSelectedTab(selectTab);
        trackEvent({
            category: ANALYTICS_IMPORT_CONTACT,
            action: trackPrefix + 'Sélectionner un onglet',
        });
    };
    return (<>
      <VStack width="full" spacing={1} alignItems="flex-start">
        <Text fontWeight="bold">{t('contact.second_step_title')}</Text>
        <Select name="tabTitle" onChange={handleSelect} defaultValue={0} data-cy="select-tab-import-contact">
          {fileTabs.map((tab, key) => (<option key={key} value={tab.title}>
              {tab.title}
            </option>))}
        </Select>
      </VStack>
      <HStack marginTop={2} justifyContent="flex-end">
        <Box> {children} </Box>
        <Box>
          <Button size="sm" variant="primary" isDisabled={!selectTab} loadingText={t('global.status.importing')} onClick={handleButton} data-cy="second-step-import-contact">
            {t('global.actions.next')}
          </Button>
        </Box>
      </HStack>
    </>);
};
export default SecondStep;
