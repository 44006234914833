import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_ONGLET } from 'analytics/useAnalytics';
import i18n from 'i18n';
import snakeCase from 'lodash/snakeCase';
import { getBuildingsFieldName } from 'components/export/plot/buildingsFormatter';
import Table from 'components/table/Table';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useSelectedBuilding, useSetSelectedBuilding, } from 'hooks/contexts/useTabsContext';
import usePlotBuildings from 'hooks/plot/usePlotBuildings';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { BUILDING_PLOT_TAB } from 'types/api';
import { getBuildingDestinationFromCode } from 'utils/constants';
import { getPlotSkeleton } from '../PlotPannelSkeletons';
export const TABLE_HEADERS = [
    'footprint',
    'floorArea',
    'purpose',
    'constructionYear',
    'eavesHeight',
    'levelsNumber',
    'footprintFullPlot',
    'accommodationNumber',
    'floorAreaFullPlot',
    'roofMaterials',
    'wallsMaterials',
    'nature',
    'use',
    'subUse',
    'lightweightBuild',
    'buildingId',
];
const formatExportHeader = (t) => (header) => getBuildingsFieldName({ fieldName: header, t });
const formatExportValue = (value, field) => {
    if (field === 'purpose') {
        return getBuildingDestinationFromCode(value);
    }
    return value;
};
export const columnsBuilding = [
    {
        id: 'columns',
        columns: TABLE_HEADERS.map((columnKey) => {
            return {
                accessorKey: columnKey,
                accessorFn: (row) => {
                    if (columnKey === 'purpose') {
                        return getBuildingDestinationFromCode(row[columnKey]);
                    }
                    return row[columnKey];
                },
                header: () => i18n.t(`tabs.plots.section.building.columns.${snakeCase(columnKey.toString())}`),
                cell: ({ getValue }) => {
                    if (columnKey === 'constructionYear') {
                        return getValue() > 0 ? getValue() : '';
                    }
                    return getValue();
                },
                enableColumnFilter: false,
                enableSorting: false,
            };
        }),
    },
];
const PlotBuilding = ({ plot }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const { hasPlotTab } = useSiteConfiguration();
    const selectedTown = useSelectedTown();
    const { data: buildings, isInitialLoading: isLoadingBuildings } = usePlotBuildings(plot?.id, plot?.townId, hasPlotTab(BUILDING_PLOT_TAB));
    const selectedBuilding = useSelectedBuilding();
    const setSelectedBuilding = useSetSelectedBuilding();
    const isRowHighlighted = (building) => {
        return building.buildingId === selectedBuilding;
    };
    const onRowSelection = (selectedRows) => {
        trackEvent({
            category: ANALYTICS_ONGLET,
            action: 'Bâtiment clic ligne tableau',
        });
        selectedRows.forEach((selectedRow) => {
            setSelectedBuilding(selectedRow.original.buildingId);
        });
    };
    const skeleton = getPlotSkeleton();
    if (isLoadingBuildings)
        return skeleton;
    if (!isLoadingBuildings && !buildings) {
        return (<Box margin={4}>
        <Text textStyle="paragraph">
          {t('tabs.plots.section.building.not_available')}
        </Text>
      </Box>);
    }
    return (<VStack alignItems="flex-start" padding={4}>
      <Box width="100%">
        <Table id="plot_buildings" variant="buildings" columns={columnsBuilding} isRowHighlighted={isRowHighlighted} onRowSelection={onRowSelection} data={Object.values(buildings)} enabledMultiRowSelection={false} exportedFields={TABLE_HEADERS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} analyticsAction={t('tabs.plots.section.building.title')} exportTitle={t('tabs.plots.section.building.title') +
            ' - ' +
            selectedTown?.name +
            ' ' +
            plot.plotNumber} exportName={selectedTown?.name +
            '_' +
            plot.plotNumber +
            '_' +
            t('tabs.plots.export.filename.building')}/>
      </Box>

      <Text textStyle="small">{t('tabs.plots.section.building.source')}</Text>
    </VStack>);
};
export default PlotBuilding;
