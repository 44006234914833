import { useMemo } from 'react';
import pickBy from 'lodash/pickBy';
import { usePlotSummary } from 'hooks/contexts/useLegendContext';
import { usePlots, useSelectedTab } from 'hooks/contexts/useTabsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
const SELECTED_PLOT_COLOR = '#FF0000';
const LEGEND_PLOT_COLOR = '#0000FF';
const useSelectedPlotsLayer = () => {
    const selectedTab = useSelectedTab();
    const plots = usePlots();
    const plotSummary = usePlotSummary();
    const selectedPlots = useMemo(() => {
        const newSelectedPlots = {
            ...pickBy(plots, (plot) => plot.layerKey === filtersKey.SELECTED_PLOTS),
        };
        if (plotSummary) {
            newSelectedPlots[plotSummary?.id] = {
                ...plotSummary,
                borderColor: LEGEND_PLOT_COLOR,
            };
        }
        if (selectedTab && newSelectedPlots[selectedTab]) {
            newSelectedPlots[selectedTab] = {
                ...newSelectedPlots[selectedTab],
                borderColor: SELECTED_PLOT_COLOR,
            };
        }
        return Object.values(newSelectedPlots);
    }, [plots, selectedTab, plotSummary]);
    createLayersHook({
        providedData: selectedPlots,
        alwaysEnabled: true,
        updateOnChange: true,
    })(filtersKey.SELECTED_PLOTS);
};
const SelectedPlotsLayer = () => {
    useSelectedPlotsLayer();
    return null;
};
export default SelectedPlotsLayer;
