import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_generale', 'reseaux_de_froid', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.cold_network = {
        layerKey: filtersKey.COLD_NETWORK,
        label: 'cold_network',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#3236A8',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const useColdNetworkLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.COLD_NETWORK],
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.COLD_NETWORK);
};
const ColdNetworkLayer = () => {
    useColdNetworkLayer();
    return null;
};
export default ColdNetworkLayer;
