import snakeCase from 'lodash/snakeCase';
import { getFeasibilityRows } from 'components/tabs/plots/PlotSections/feasibility/calculator';
import { BUILDING_PERMIT_PLOT_TAB, BUILDING_PLOT_TAB, BUSINESS_PLOT_TAB, DPE_PLOT_TAB, ENERGY_DISTRIBUTION_POST_PLOT_TAB, ENERGY_SOURCE_POST_PLOT_TAB, FEASIBILITY_ADDITIONAL_PLOT_TAB, FEASIBILITY_PLOT_TAB, NEIGHBORHOOD_PLOT_TAB, OCCUPANT_PLOT_TAB, OWNER_PLOT_TAB, PLOT_PLOT_TAB, PLOT_RULES_PLOT_TAB, PLU_ADDITIONAL_REGULATION_PLOT_TAB, PLU_REGULATION_PLOT_TAB, RPG_PLOT_TAB, SALES_PLOT_TAB, } from 'types/api';
import { getPlotExportTitle } from 'utils/plots';
import { formatBuildingPermitCellValue, formatBuildingPermitData, getBuildingPermitName, } from './buildingPermitFormatter';
import { formatBuildingCellValue, formatBuildingData, getBuildingsFieldName, } from './buildingsFormatter';
import { formatBusinessData, getBusinessName } from './BusinessFormatter';
import { formatDPECellValue, formatDPEData, getDPEName } from './DPEFormatter';
import { formatDistributionPostsData, formatSourcePostData, } from './energyFormatter';
import { formatFeasibilityData, getFeasibilityFieldName, } from './feasibilityFormatter';
import { formatNeighborhoodData } from './neighborhoodFormatter';
import { formatOccupantCellValue, formatOccupantData, getOccupantFieldName, } from './occupantFormatter';
import { formatOwnersCellValue, formatOwnersData, getOwnersFieldName, } from './ownersFormatter';
import { formatPlotCellValue, formatPlotData, formatPlotRulesData, } from './plotFormatter';
import { formatPluRegulation, formatPluRegulationCellValue, getPluRegulationFieldName, } from './pluRegulationFormatter';
import { formatRPGCellValue, formatRPGData, getRPGFieldName, } from './RPGFormatter';
import { formatSalesCellValue, formatSalesData } from './salesFormatter';
export const EXPORTED_FIELDS = {
    feasibility: getFeasibilityRows(),
};
export const HORIZONTAL_PAGE_SECTIONS = [
    ENERGY_SOURCE_POST_PLOT_TAB,
    ENERGY_DISTRIBUTION_POST_PLOT_TAB,
    BUILDING_PLOT_TAB,
    RPG_PLOT_TAB,
    SALES_PLOT_TAB,
    OWNER_PLOT_TAB,
    OCCUPANT_PLOT_TAB,
];
export const HORIZONTAL_TABLE_SECTIONS = [
    ENERGY_SOURCE_POST_PLOT_TAB,
    ENERGY_DISTRIBUTION_POST_PLOT_TAB,
    BUILDING_PLOT_TAB,
    RPG_PLOT_TAB,
    SALES_PLOT_TAB,
    OWNER_PLOT_TAB,
    OCCUPANT_PLOT_TAB,
];
export const MULTI_SHEETS_SECTIONS = [NEIGHBORHOOD_PLOT_TAB];
export const getFieldName = ({ activeSection, t }) => (fieldName) => {
    switch (activeSection) {
        case PLU_REGULATION_PLOT_TAB:
        case PLU_ADDITIONAL_REGULATION_PLOT_TAB:
            return getPluRegulationFieldName({ fieldName });
        case BUILDING_PLOT_TAB:
            return getBuildingsFieldName({ fieldName, t });
        case RPG_PLOT_TAB:
            return getRPGFieldName({ fieldName, t });
        case FEASIBILITY_PLOT_TAB:
        case FEASIBILITY_ADDITIONAL_PLOT_TAB:
            return getFeasibilityFieldName({ fieldName, t });
        case OWNER_PLOT_TAB:
            return getOwnersFieldName({ fieldName, t });
        case OCCUPANT_PLOT_TAB:
            return getOccupantFieldName({ fieldName, t });
        case BUILDING_PERMIT_PLOT_TAB:
            return getBuildingPermitName({ fieldName, t });
        case DPE_PLOT_TAB:
            return getDPEName({ fieldName, t });
        case BUSINESS_PLOT_TAB:
            return getBusinessName({ fieldName, t });
    }
    return t(`tabs.plots.export.header.${snakeCase(fieldName)}`, fieldName);
};
export const getCellValue = ({ activeSection, t }) => (cellValue, fieldName) => {
    switch (activeSection) {
        case PLU_REGULATION_PLOT_TAB:
        case PLU_ADDITIONAL_REGULATION_PLOT_TAB:
            return formatPluRegulationCellValue({ cellValue, fieldName });
        case PLOT_PLOT_TAB:
            return formatPlotCellValue({ cellValue, fieldName });
        case PLOT_RULES_PLOT_TAB:
            return formatPlotCellValue({ cellValue, fieldName }); // for rules export same as plotInfo
        case SALES_PLOT_TAB:
            return formatSalesCellValue({ cellValue, fieldName, t });
        case OWNER_PLOT_TAB:
            return formatOwnersCellValue({ cellValue, fieldName, t });
        case OCCUPANT_PLOT_TAB:
            return formatOccupantCellValue({ cellValue, fieldName });
        case BUILDING_PLOT_TAB:
            return formatBuildingCellValue({ cellValue, fieldName });
        case BUILDING_PERMIT_PLOT_TAB:
            return formatBuildingPermitCellValue({ cellValue, fieldName, t });
        case DPE_PLOT_TAB:
            return formatDPECellValue({ cellValue, fieldName });
        case RPG_PLOT_TAB:
            return formatRPGCellValue({ cellValue, fieldName, t });
        default:
            return cellValue;
    }
};
export const getSectionPlotData = (plotData, t) => {
    const { activeSection, occupant } = plotData;
    // Pour certain onglet on souhaite retourner le fait qu'il n'y a pas de résultat
    let formatData = [];
    switch (activeSection) {
        case PLOT_PLOT_TAB:
            formatData = formatPlotData(plotData);
            break;
        case ENERGY_SOURCE_POST_PLOT_TAB:
            formatData = formatSourcePostData(plotData);
            break;
        case ENERGY_DISTRIBUTION_POST_PLOT_TAB:
            formatData = formatDistributionPostsData(plotData);
            break;
        case RPG_PLOT_TAB:
            formatData = formatRPGData(plotData);
            break;
        case PLOT_RULES_PLOT_TAB:
            formatData = formatPlotRulesData(plotData);
            break;
        case FEASIBILITY_PLOT_TAB:
            return formatFeasibilityData(plotData);
        case FEASIBILITY_ADDITIONAL_PLOT_TAB:
            return formatFeasibilityData({
                feasibility: plotData.feasibilityAdditional,
                feasibilityPlots: plotData.feasibilityPlotsAdditional,
                t: plotData.t,
            });
        case PLU_REGULATION_PLOT_TAB:
            return formatPluRegulation(plotData);
        case PLU_ADDITIONAL_REGULATION_PLOT_TAB:
            return formatPluRegulation({
                pluRegulation: plotData.pluAdditionalRegulation,
            });
        case OWNER_PLOT_TAB:
            formatData = formatOwnersData(plotData);
            break;
        case BUILDING_PLOT_TAB:
            formatData = formatBuildingData(plotData);
            break;
        case SALES_PLOT_TAB:
            formatData = formatSalesData(plotData);
            break;
        case NEIGHBORHOOD_PLOT_TAB:
            formatData = formatNeighborhoodData(plotData);
            break;
        case OCCUPANT_PLOT_TAB:
            formatData = formatOccupantData(occupant);
            break;
        case BUILDING_PERMIT_PLOT_TAB:
            formatData = formatBuildingPermitData(plotData);
            break;
        case DPE_PLOT_TAB:
            formatData = formatDPEData(plotData);
            break;
        case BUSINESS_PLOT_TAB:
            formatData = formatBusinessData(plotData);
            break;
        default:
            return null;
    }
    return formatData && formatData.length !== 0
        ? formatData
        : t('tabs.plots.export.empty.' + activeSection);
};
export const getSingleSectionSheets = (plotData) => {
    const { t, activeSection } = plotData;
    const getData = () => getSectionPlotData(plotData, t);
    return MULTI_SHEETS_SECTIONS.includes(activeSection)
        ? getData()
        : [
            {
                getData,
                sheetTitle: (t(`tabs.plots.export.title.${activeSection}`)
                    ? t(`tabs.plots.export.title.${activeSection}`) + ' - '
                    : '') +
                    getPlotExportTitle(plotData.plot, plotData.selectedTown.name),
                formatHeader: getFieldName({ activeSection, t }),
                formatValue: getCellValue({ activeSection, t }),
                modePage: HORIZONTAL_PAGE_SECTIONS.includes(activeSection)
                    ? 'horizontal'
                    : 'vertical',
                mode: HORIZONTAL_TABLE_SECTIONS.includes(activeSection)
                    ? 'horizontal'
                    : 'vertical',
            },
        ];
};
export const getAllSectionsSheets = (sectionFiltered, plotData) => {
    return sectionFiltered.reduce((acc, section) => {
        const sectionsSheets = getSingleSectionSheets({
            ...plotData,
            activeSection: section,
        });
        return [...acc, ...sectionsSheets];
    }, []);
};
