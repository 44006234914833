import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, useDisclosure, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_TELECOMMANDE_ACTIONS, } from 'analytics/useAnalytics';
import ExportButton, { EXPORT_TYPE_REAL_ESTATE_TAXES, } from 'components/buttons/ExportButton';
import useCurrentSite from 'hooks/useCurrentSite';
import { SITE_ID_ENERGY, SITE_ID_PV } from 'utils/constants';
import Form from './Form';
import FormEnergy from './FormEnergy';
const RealEstateTaxesDrawer = ({ defaultValues = {}, townName, name, }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { currentSite } = useCurrentSite();
    const [dataToExport, setDataToExport] = React.useState();
    const getDataToExport = (data) => setDataToExport(data);
    const fileName = `${townName || ''}_${name || ''}_taxe_amenagement`.replaceAll(' ', '_');
    return (<>
      <Button size="sm" variant="outline" onClick={() => {
            onOpen();
            trackEvent({
                category: ANALYTICS_TELECOMMANDE_ACTIONS,
                action: 'Calculer la taxe d’aménagement',
            });
        }}>
        {t('modals.real_estate_tax.calculate_real_estate_tax')}
      </Button>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h3" variant="h3">
              {t('modals.real_estate_tax.calculate_real_estate_tax_project')}
            </Heading>
            <ExportButton fileName={fileName} exportType={EXPORT_TYPE_REAL_ESTATE_TAXES} exportParams={dataToExport} analyticsEvent={{
            category: ANALYTICS_CATEGORY_DOWNLOAD,
            action: "Taxe d'aménagement",
        }}/>
          </DrawerHeader>
          <DrawerBody>
            {currentSite.legacyId === SITE_ID_ENERGY ||
            currentSite.legacyId === SITE_ID_PV ? (<FormEnergy defaultValues={defaultValues} getDataToExport={getDataToExport}/>) : (<Form defaultValues={defaultValues} getDataToExport={getDataToExport}/>)}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>);
};
export default memo(RealEstateTaxesDrawer);
