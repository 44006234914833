import useAnalytics from 'analytics/useAnalytics';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import { useShowProject } from 'hooks/contexts/useTabsContext';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import { centerAndZoomOnCoord } from 'utils/map';
export default ({ projectId, projectLabel, projectTownIds, lat, lng, analyticOrigin, analyticDetail, }) => {
    const { trackEvent } = useAnalytics();
    const showProject = useShowProject();
    const selectedTown = useSelectedTown();
    const navigateToTown = useNavigateToTown();
    const map = useMap();
    return () => {
        const center = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        if (!projectTownIds.includes(selectedTown?.id)) {
            navigateToTown({
                lat: lat.toString(),
                lng: lng.toString(),
                centerMapOnLat: lat.toString(),
                centerMapOnLng: lng.toString(),
            });
        }
        else {
            centerAndZoomOnCoord(map, center, 2);
        }
        showProject({
            resource: {
                id: projectId,
                label: projectLabel,
                townId: projectTownIds[0],
                lat,
                lng,
            },
        });
        if (analyticOrigin && analyticDetail) {
            trackEvent({
                category: analyticOrigin,
                action: analyticDetail,
            });
        }
    };
};
