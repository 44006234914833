import * as React from 'react';
import BuildingPermit from 'components/tabs/plot-project/Sections/BuildingPermit';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useBuildingPermitLayers from 'hooks/filter/useBuildingPermitLayers';
import useProjectBuildingPermit from 'hooks/project/useProjectBuildingPermit';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PROJECT_BUILDING_PERMIT } from 'types/api';
const ProjectBuildingPermitSection = ({ project, }) => {
    const selectedTown = useSelectedTown();
    const { hasProjectTab } = useSiteConfiguration();
    const { currentSite } = useCurrentSite();
    const { buildingsPermits, isLoading } = useProjectBuildingPermit(project?.id, hasProjectTab(PROJECT_BUILDING_PERMIT));
    const { buildingPermitLayers, isLoading: isLoadingLayers } = useBuildingPermitLayers(selectedTown?.id, currentSite.legacyId);
    return (<BuildingPermit buildingsPermits={buildingsPermits} isLoading={isLoading} buildingPermitLayers={buildingPermitLayers} isLoadingLayers={isLoadingLayers}/>);
};
export default ProjectBuildingPermitSection;
