import * as React from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Stack, Text, useDisclosure } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_LAND_POINT, ANALYTICS_TABLE, } from 'analytics/useAnalytics';
import { snakeCase } from 'lodash';
import SeeMapButton from 'components/buttons/SeeMapButton';
import TeamProjectModal from 'components/modals/TeamProjectModal';
import Table from 'components/table/Table';
import SelectRows from 'components/tabs/utils/SelectRows';
import { tableFiltersKey } from 'context/FiltersContext';
import { CRM_TABS } from 'context/TabsContext';
import { useToggleFilter, useUpdateTableFilter, } from 'hooks/contexts/useFiltersContext';
import { useSetLocatedProject } from 'hooks/contexts/useLocationContext';
import { useToggleCRMTab } from 'hooks/contexts/useTabsContext';
import useTeamProjects from 'hooks/crm/useTeamProjects';
import useLandPoint from 'hooks/useLandPoint';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey, registerTableFilters } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
import { getPlotCodeFromStatus, getProjectStatusLabel, } from 'utils/personalization';
import { inDateRange } from 'utils/search';
const getColumnHeader = (column) => (<Translation>
    {(t) => (<Text align="center">{t(`tabs.team_project.columns.${column}`)}</Text>)}
  </Translation>);
const EXPORTED_FIELDS = [
    'userEmail',
    'label',
    'town',
    'department',
    'status',
    'surface',
    'plotCount',
    'rightToBuild',
    'lots',
    'turnover', // Chiffre d'affaires
    'updatedAt',
];
const formatExportHeader = (t) => (header) => t(`tabs.team_project.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'status') {
        return getProjectStatusLabel(value);
    }
    if (field === 'updatedAt') {
        return formatDate(value, 'extended');
    }
    return value;
};
const initialSort = [
    {
        id: 'updatedAt',
        desc: true,
    },
];
const TeamProjectsPanel = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { hasDashboardItem } = useSiteConfiguration();
    const { teamProjects, isLoading, error } = useTeamProjects();
    const { createLandPoint, isCreating } = useLandPoint({});
    const toggleFilter = useToggleFilter();
    const toggleCRMTab = useToggleCRMTab();
    const updateTableFilter = useUpdateTableFilter();
    const setLocatedProject = useSetLocatedProject();
    const [noTeamProjectsSelected, setNoTeamProjectsSelected] = useState(true);
    const [selectedTeamProjects, setSelectedTeamProjects] = useState({});
    const handleRowSelection = (rows, isAllSelected, isNoneSelected) => {
        setNoTeamProjectsSelected(isNoneSelected);
        const teams = {};
        rows.forEach((row) => {
            const teamKey = `${row.userEmail}-${row.projectId}`;
            const { userEmail, projectId } = row;
            return (teams[teamKey] = { userEmail, projectId });
        });
        setSelectedTeamProjects(teams);
        if (isAllSelected) {
            setNoTeamProjectsSelected(!isAllSelected);
            trackEvent({
                category: ANALYTICS_TABLE,
                action: 'Les projets de mes équipier - Tout sélectionner/désélectionner ',
            });
        }
    };
    useEffect(() => {
        toggleFilter(filtersKey.TEAM_PROJECTS, true);
        return () => {
            toggleFilter(filtersKey.TEAM_PROJECTS, false);
            setLocatedProject(null);
        };
    }, []);
    const [columnFilters, setColumnFilters] = React.useState([]);
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'userEmail',
                        header: ({ table }) => {
                            return (<>
                  <Stack direction="row">
                    <SelectRows {...{
                                checked: table
                                    .getFilteredRowModel()
                                    .rows.every((row) => row.getIsSelected()),
                                toggleRow: table.getToggleAllRowsSelectedHandler(),
                                setNoItemIsSelected: setNoTeamProjectsSelected,
                            }}/>
                    <Text as="span" variant="primary">
                      {getColumnHeader('user_email')}
                    </Text>
                  </Stack>
                </>);
                        },
                        cell: (info) => {
                            return (<Stack direction="row">
                  <SelectRows id="delete_plot_select" {...{
                                checked: info.row.getIsSelected(),
                                disabled: !info.row.getCanSelect(),
                                toggleRow: info.row.getToggleSelectedHandler(),
                            }}/>
                  <Text as="span">{info.row.original.userEmail}</Text>
                </Stack>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'label',
                        header: () => getColumnHeader('label'),
                        accessorFn: (row) => {
                            if (!row.label)
                                return t('table.empty_column');
                            return row.label;
                        },
                        cell: (info) => {
                            const project = info.row.original;
                            const disclosureProps = useDisclosure();
                            return (<TeamProjectModal project={{
                                    projectTownId: project.townId,
                                    projectId: project.id,
                                    label: project.label,
                                    userEmail: project.userEmail,
                                    lat: project.lat,
                                    lng: project.lng,
                                }} {...disclosureProps}/>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'town',
                        header: () => getColumnHeader('town'),
                        accessorFn: (row) => {
                            if (!row.town)
                                return t('table.empty_column');
                            return row.town;
                        },
                        cell: (info) => info.row.original.town,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'department',
                        header: () => getColumnHeader('department'),
                        cell: (info) => {
                            return <Box textAlign="center">{info.getValue()}</Box>;
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'status',
                        accessorFn: (row) => getProjectStatusLabel(row.status),
                        header: () => getColumnHeader('status'),
                        cell: (info) => getProjectStatusLabel(info.row.original.status),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'surface',
                        header: () => getColumnHeader('surface'),
                        cell: (info) => `${info.row.original.surface} m²`,
                        filterFn: 'inNumberRange',
                        enableGlobalFilter: false,
                    },
                    {
                        id: 'plot_count',
                        accessorKey: 'plotCount',
                        header: () => getColumnHeader('plot_count'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'rightToBuild',
                        header: () => getColumnHeader('rightToBuild'),
                        cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
                        filterFn: 'inNumberRange',
                        meta: {
                            minW: '85px',
                        },
                    },
                    {
                        accessorKey: 'lots',
                        header: () => getColumnHeader('lots'),
                        cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'turnover',
                        header: () => getColumnHeader('turnover'),
                        cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'updatedAt',
                        header: () => getColumnHeader('updated_at'),
                        cell: (info) => formatDate(info.row.original.updatedAt, 'extended'),
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                ],
            },
        ];
    }, [JSON.stringify(teamProjects)]);
    useEffect(() => {
        registerTableFilters({
            keysToRegister: [
                {
                    name: tableFiltersKey.PLOT_USER_EMAIL,
                    formatValue: (value) => value,
                },
                {
                    name: tableFiltersKey.PLOT_STATUS,
                    formatValue: (value) => value.map((status) => parseInt(getPlotCodeFromStatus(status))),
                },
            ],
            columnFilters,
            updateTableFilter,
        });
    }, [columnFilters]);
    const handleClick = () => {
        if (Object.keys(selectedTeamProjects).length) {
            const teamProjects = Object.values(selectedTeamProjects).map((teamProject) => {
                return {
                    projectId: teamProject.projectId,
                    participant: teamProject.userEmail,
                };
            });
            trackEvent({
                category: ANALYTICS_LAND_POINT,
                action: "Création d'un point foncier dans mes équipiers",
            });
            createLandPoint(teamProjects);
            toggleCRMTab({
                tabId: CRM_TABS.LAND_POINT,
            });
        }
    };
    // This page has restricted access so we don't want to show anything
    // if the query returns an error
    if (error) {
        return null;
    }
    return (<Stack paddingX={5} height="100%">
      <Table id="table-team-projects" handleRowSelection={handleRowSelection} initialSort={initialSort} columns={columns} data={teamProjects || []} isLoading={isLoading} headerTitle={<SeeMapButton action="Les projets de mon équipe"/>} emptyMessage={t(`tabs.team_plot.no_result`)} exportTitle={t('tabs.team_project.title')} analyticsAction="Les parcelles de mon équipe" exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} customColumnFilters={columnFilters} setCustomColumnFilters={setColumnFilters} footerExtend={hasDashboardItem('landPoint') && (<Button size="sm" variant="primary" isDisabled={noTeamProjectsSelected || isCreating} onClick={handleClick}>
              {t('tabs.team_plot.land_point_create')}
            </Button>)}/>
    </Stack>);
};
export default memo(TeamProjectsPanel);
