import * as React from 'react';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormControl, FormErrorMessage, FormLabel, Input, } from '@chakra-ui/react';
import ISO2Countries from 'data/ISO2_countries.json';
import TextInput from 'components/inputs/TextInput';
import useCustomToast from 'hooks/useCustomToast';
import { getDefaultCountry } from 'utils/site';
const CountryField = ({ id, required, noLabel = false, disableCountry = false, }) => {
    const { t } = useTranslation();
    const { getErrorToast } = useCustomToast();
    const { setValue, watch, getFieldState } = useFormContext();
    const selectedCountryValue = watch('country');
    const [countriesISO2, setCountriesISO2] = useState([]);
    const { error: townError } = getFieldState('town');
    const { error: countryError } = getFieldState('country');
    const [countryValue, setCountryValue] = useState(() => getDefaultCountry());
    const [contactCountryValue, setContactCountryValue] = useState(() => getDefaultCountry());
    useEffect(() => {
        setCountryValue(selectedCountryValue);
        if (selectedCountryValue &&
            selectedCountryValue !== 'FR' &&
            disableCountry) {
            getErrorToast({
                title: t('courier.block_send_courier'),
            });
        }
        if (selectedCountryValue) {
            setContactCountryValue(selectedCountryValue);
        }
    }, [selectedCountryValue]);
    useEffect(() => {
        if (ISO2Countries) {
            const countries = ISO2Countries.map((ISO2_country) => {
                return {
                    label: t(`contact.countries.${ISO2_country}`),
                    value: ISO2_country,
                };
            });
            setCountriesISO2(countries);
        }
        if (disableCountry) {
            setValue('country', contactCountryValue);
        }
    }, []);
    return (<>
      <FormControl width="30%" fontSize={13} isInvalid={Boolean(townError)}>
        {!noLabel && (<FormLabel>
            {`${t('contact.fields.country.label')} ${required ? '*' : ''}`}
          </FormLabel>)}
        {disableCountry ? (<>
            <Input size="md" disabled={disableCountry} value={t(`contact.countries.${contactCountryValue}`)}/>
            <TextInput type="hidden" name="country" value={contactCountryValue}/>
          </>) : (<Select name="country" loadingMessage={() => t('global.loading')} noOptionsMessage={() => t('global.no_options')} onChange={(selectedOption) => {
                if (selectedOption) {
                    setValue('country', selectedOption.value);
                }
                else {
                    setValue('country', '');
                }
            }} value={countryValue
                ? {
                    value: countryValue,
                    label: t(`contact.countries.${countryValue}`),
                }
                : null} options={countriesISO2} placeholder={`${t('contact.fields.country.label')}  ${required ? '*' : ''}`} id="contact_form_country" data-cy={`${id}_form_country`} styles={{
                control: (baseStyles) => ({
                    ...baseStyles,
                    borderColor: countryError?.message && 'red',
                }),
            }} isClearable/>)}
        {Boolean(countryError) && (<FormErrorMessage>{t(countryError?.message)}</FormErrorMessage>)}
      </FormControl>
    </>);
};
export default CountryField;
