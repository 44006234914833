import snakeCase from 'lodash/snakeCase';
import { formatFeasibilityData, getFeasibilityFieldName, } from 'components/export/plot/feasibilityFormatter';
import { FEASIBILITY_PLOT_TAB } from 'types/api';
const getFieldName = ({ activeSection, t }) => (fieldName) => {
    switch (activeSection) {
        case FEASIBILITY_PLOT_TAB:
            return getFeasibilityFieldName({ fieldName, t });
    }
    return t(`tabs.project.export.header.${snakeCase(fieldName)}`, fieldName);
};
const getCellValue = () => (cellValue) => {
    return cellValue;
};
const getFormattedPlotData = ({ activeSection, feasibility, feasibilityPlots, t, }) => {
    switch (activeSection) {
        case FEASIBILITY_PLOT_TAB:
            return formatFeasibilityData({ feasibility, feasibilityPlots, t });
        default:
            return null;
    }
};
export const getSingleSectionSheets = ({ activeSection, feasibility, feasibilityPlots, t, }) => {
    const getData = () => getFormattedPlotData({
        activeSection,
        feasibility,
        feasibilityPlots,
        t,
    });
    return [
        {
            getData,
            sheetTitle: t(`tabs.project.export.title.${activeSection}`),
            formatHeader: getFieldName({ activeSection, t }),
            formatValue: getCellValue(),
            modePage: activeSection === FEASIBILITY_PLOT_TAB
                ? 'horizontal'
                : 'vertical',
            mode: 'vertical',
        },
    ];
};
