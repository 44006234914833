import * as React from 'react';
import { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyIcon } from '@chakra-ui/icons';
import { Box, Button, HStack, IconButton, Link, SimpleGrid, Stack, Text, } from '@chakra-ui/react';
import InfoPopover from 'components/InfoPopover';
import SirenLink from 'components/links/SirenLink';
import ContactDrawer from 'components/modals/contactModal/ContactDrawer';
import useCustomToast from 'hooks/useCustomToast';
import { ACTION_EDIT, CONTACT_TYPES } from 'utils/constants';
const ContactInfo = ({ contact, isFetching, }) => {
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    const fields = [
        'titleStr',
        'lastname',
        'firstname',
        'company',
        'siren',
        'mobile',
        'landline',
        'primaryEmail',
        'address',
        'addressBP',
        'addressComplement',
        'postalCode',
        'town',
        'country',
        'addressesPlots',
        'vendor',
        'npr',
        'npai',
        'type',
    ];
    const copy = useCallback(async (text) => {
        await navigator.clipboard.writeText(text);
        getSuccessToast({
            title: t('tabs.contact.email_copied'),
            id: 'contact_email_copied',
        });
    }, [t]);
    return (<Box width="full" backgroundColor="colorMode.base" paddingY={2} paddingX={4}>
      <SimpleGrid columns={{ sm: 1, md: 2, lg: 3 }} spacing={4}>
        {fields.map((field) => {
            if (contact[field]) {
                if (Array.isArray(contact[field]) && contact[field].length === 0) {
                    return null;
                }
                const data = contact[field];
                return (<Box key={field}>
                <Text as="div" textStyle="paragraph" display="flex">
                  {t(`contact.fields.${field}.label`)}
                  {field === 'npai' && (<Box marginLeft={1}>
                      <InfoPopover withTitle={false}>
                        <Stack>
                          <Text>{t('contact.fields.npai.info')}</Text>
                        </Stack>
                      </InfoPopover>
                    </Box>)}
                </Text>
                {field === 'siren' ? (<SirenLink siren={contact[field]} analyticDetail="Pappers Fiche contact" id="contact_siren" showExternalLink/>) : field === 'primaryEmail' ? (<HStack>
                    <Link href={`mailto:${field}`} variant="primary" data-cy={`contact_` + field}>
                      {data}
                    </Link>
                    <IconButton size="xs" variant="secondary" onClick={() => {
                            copy(data);
                        }} icon={<CopyIcon />} aria-label="copy-button"/>
                  </HStack>) : field === 'type' ? (<Text textStyle="paragraph" fontWeight={500} data-cy={`contact_` + field}>
                    {data
                            .map((typeId) => {
                            return t(CONTACT_TYPES[typeId].label);
                        })
                            .join(', ')}
                  </Text>) : field === 'country' ? (<Text textStyle="paragraph" fontWeight={500} data-cy={`contact_` + field}>
                    {t('contact.countries.' + data)}
                  </Text>) : (<Text textStyle="paragraph" fontWeight={500} data-cy={`contact_` + field}>
                    {field === 'vendor' || field === 'npr' || field === 'npai'
                            ? data
                                ? t('global.yes')
                                : t('global.no')
                            : data}
                  </Text>)}
              </Box>);
            }
            return null;
        })}
      </SimpleGrid>

      <Box justifySelf="end" alignSelf="end" paddingTop={4}>
        <ContactDrawer action={ACTION_EDIT} contact={contact} triggerItem={(onOpen) => (<Button variant="primary" onClick={onOpen} isDisabled={isFetching} data-cy="contact_edit_button">
              {t('global.actions.modify')}
            </Button>)}/>
      </Box>
    </Box>);
};
export default memo(ContactInfo);
