import { useCallback } from 'react';
import { DEFAULT_CAPARESEAU_ISODISTANCE } from 'components/sidebarContent/ElectricNetworkCapareseauIsodistanceSidebarContent';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrlParams = (map, tileWidth, tileHeight, enabledFilters, extraFilters) => (coord, zoom) => {
    const isoDistance = (extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]?.isoDistance ??
        DEFAULT_CAPARESEAU_ISODISTANCE) / 10;
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_electricite', 'iso_distance_postes_source_' +
        isoDistance.toString().replace('.', '_') +
        'k', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_capareseau = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU,
        label: 'electrical_networks_capareseau',
        needsTranslate: true,
        color: '#6AB0EF',
        opacity: 0.6,
        borderColor: '#6AB0EF',
        borderWidth: 2,
    };
    return legends;
};
const useElectricalNetworkCapareseauLayers = () => {
    const extraFilters = useExtraFilters();
    const getLayerTileUrl = useCallback((map, tileWidth, tileHeight, enabledFilters) => getLayerTileUrlParams(map, tileWidth, tileHeight, enabledFilters, extraFilters), [extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]?.isoDistance]);
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU],
        getLegends,
        refreshParam: extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]?.isoDistance ||
            DEFAULT_CAPARESEAU_ISODISTANCE,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU);
};
const ElectricalNetworkCapareseauLayers = () => {
    useElectricalNetworkCapareseauLayers();
    return null;
};
export default ElectricalNetworkCapareseauLayers;
