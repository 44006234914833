import { useContextSelector } from 'use-context-selector';
import LayoutContext from 'context/LayoutContext';
export const useTabsView = () => useContextSelector(LayoutContext, (state) => state.tabsView);
export const useSetTabsView = () => useContextSelector(LayoutContext, (state) => state.setTabsView);
export const useTabsSize = () => useContextSelector(LayoutContext, (state) => ({
    tabsWidth: state.tabsWidth,
    tabsHeight: state.tabsHeight,
}));
export const useLayout = () => useContextSelector(LayoutContext, (state) => state.layout);
export const useSetLayoutFull = () => useContextSelector(LayoutContext, (state) => state.setLayoutFull);
export const useSetLayoutHalf = () => useContextSelector(LayoutContext, (state) => state.setLayoutHalf);
export const useCloseLayout = () => useContextSelector(LayoutContext, (state) => state.closeLayout);
export const useToggleLayout = () => useContextSelector(LayoutContext, (state) => state.toggleLayout);
export const useChangeLayout = () => useContextSelector(LayoutContext, (state) => ({
    deployLayout: state.deployLayout,
    reduceLayout: state.reduceLayout,
}));
export const useSwitchDisplay = () => useContextSelector(LayoutContext, (state) => state.switchDisplay);
export const useStreetViewMode = () => useContextSelector(LayoutContext, (state) => state.streetViewMode);
export const useSetStreetViewVisible = () => useContextSelector(LayoutContext, (state) => state.setStreetViewVisible);
export const useToggleStreetView = () => useContextSelector(LayoutContext, (state) => state.toggleStreetView);
export const useToggleStreetViewLayout = () => useContextSelector(LayoutContext, (state) => state.toggleStreetViewLayout);
export const useMapSize = () => useContextSelector(LayoutContext, (state) => ({
    mapWidth: state.mapWidth,
    mapHeight: state.mapHeight,
}));
export const useIsNarrowView = () => useContextSelector(LayoutContext, (state) => state.isNarrowView);
