import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_fibre_datacenter', 'infrapedia_datacenter_points,infrapedia_datacenter_polygones', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.data_center = {
        layerKey: filtersKey.DATACENTER_INFRAPEDIA,
        label: 'datacenter',
        needsTranslate: true,
        color: '#0191FC',
        polygonType: VECTOR_TYPES.point,
        width: 10,
        height: 10,
    };
    legends.data_center = {
        layerKey: filtersKey.DATACENTER_INFRAPEDIA,
        label: 'datacenter_polygon',
        needsTranslate: true,
        color: '#0191FC',
        borderWidth: 1,
        borderColor: '#333333',
    };
    return legends;
};
const useDatacenterInfrapediaLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.DATACENTER_INFRAPEDIA],
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.DATACENTER_INFRAPEDIA);
};
const DatacenterInfrapediaLayer = () => {
    useDatacenterInfrapediaLayer();
    return null;
};
export default DatacenterInfrapediaLayer;
