import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import DrawingTool from 'components/map/Toolbar/DrawingTool';
import AltimetryModal from 'components/modals/AltimetryModal';
import { useMap } from 'hooks/contexts/useMapContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import MeasureIcon from 'images/icons/Measure';
import MountainIcon from 'images/icons/Mountain';
import SurfaceIcon from 'images/icons/Surface';
import { useMeasure } from 'utils/measure';
const MeasureTool = () => {
    const { t } = useTranslation();
    const map = useMap();
    const { trackEvent } = useAnalytics();
    const { hasMapControl } = useSiteConfiguration();
    const hasMeasureTools = hasMapControl('measure');
    const hasAltimetryTools = hasMapControl('altimetry');
    const hasDrawingTool = hasMapControl('drawing');
    const { drawingMode, toggleDrawingMode, showAltimetry, isLoadingAltimetry, altimetry, } = useMeasure(map, t);
    return (<>
      <VStack spacing={2} alignItems="flex-end">
        {hasMeasureTools && (<Button variant="ghost" size="sm" boxShadow="lg" isActive={drawingMode === 'distance'} onClick={() => {
                trackEvent({
                    category: ANALYTICS_MAP_BUTTONS,
                    action: 'Mesurer une distance',
                });
                toggleDrawingMode('distance');
            }} _active={{ bg: 'colorMode.sidebar800' }}>
            <HStack justifyContent="center" alignItems="center">
              <Icon as={MeasureIcon} width={5} height={5} fill="currentColor"/>
              <Text>{t('toolbar.measure.distance')}</Text>
            </HStack>
          </Button>)}

        {hasMeasureTools && (<Button variant="ghost" size="sm" boxShadow="lg" isActive={drawingMode === 'area'} width="100%" onClick={() => {
                trackEvent({
                    category: ANALYTICS_MAP_BUTTONS,
                    action: 'Mesurer une surface',
                });
                toggleDrawingMode('area');
            }} _active={{ bg: 'colorMode.sidebar800' }}>
            <HStack justifyContent="center" alignItems="center">
              <Icon as={SurfaceIcon} width={4} height={3} fill="currentColor"/>
              <Text>{t('toolbar.measure.area')}</Text>
            </HStack>
          </Button>)}

        {hasAltimetryTools && (<Button variant="ghost" size="sm" boxShadow="lg" isActive={drawingMode === 'altimetry'} width="100%" onClick={() => {
                trackEvent({
                    category: ANALYTICS_MAP_BUTTONS,
                    action: 'Mesurer l’altimétrie',
                });
                toggleDrawingMode('altimetry');
            }} _active={{ bg: 'colorMode.sidebar800' }}>
            <HStack justifyContent="center" alignItems="center">
              <Icon as={MountainIcon} width={5} height={5} fill="currentColor"/>
              <Text>{t('toolbar.measure.altimetry')}</Text>
            </HStack>
          </Button>)}

        {hasDrawingTool && <DrawingTool />}

        {hasMeasureTools && showAltimetry && (<AltimetryModal isLoading={isLoadingAltimetry} altimetry={altimetry} handleOnClose={() => {
                toggleDrawingMode('altimetry');
            }}/>)}
      </VStack>
    </>);
};
export default MeasureTool;
