import * as React from 'react';
import createVectorTilesLayerHook from 'hooks/layers/createVectorTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const OPTICAL_FIBER_ZOOM_LIMIT = 10;
const getLayerTileUrl = (enabledFilters, opacity) => {
    return {
        url: 'https://www.infrapedia.com/map/cables/{z}/{x}/{y}.pbf',
        cache: true,
        setSelected: true,
        opacity: opacity,
    };
};
const getLegends = () => {
    const legends = {};
    legends.optical_fiber = {
        layerKey: filtersKey.OPTICAL_FIBER,
        label: 'optical_fiber',
        needsTranslate: true,
        color: '#8856A7',
        polygonType: VECTOR_TYPES.polyline,
        width: 20,
        borderWidth: 4,
    };
    return legends;
};
const useOpticalFiberLayer = () => {
    createVectorTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.OPTICAL_FIBER],
    })(filtersKey.OPTICAL_FIBER);
};
const OpticalFiberLayer = () => {
    useOpticalFiberLayer();
    return null;
};
const OpticalFiberWrapper = ({ computedZoom }) => {
    if (computedZoom < OPTICAL_FIBER_ZOOM_LIMIT) {
        return null;
    }
    return <OpticalFiberLayer />;
};
export default OpticalFiberWrapper;
