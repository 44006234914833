import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionButton, AccordionIcon, Box, Checkbox, Heading, Text, } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import useAnalytics, { ANALYTICS_RACCOURCI } from 'analytics/useAnalytics';
import FilterTitle from 'components/FilterTitle';
import { getAllAccordions } from 'context/FiltersAccordionContext';
import { useUpdateAccordions } from 'hooks/contexts/useFiltersAccordionContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useFilterCategoryCheckbox from 'hooks/useFilterCategoryCheckbox';
import { accordionArrowStyle, sidebarAccordionBoxShadow, } from 'utils/accordionArrowStyle';
import { getTVA } from 'utils/formatText';
import { isSwissSite } from 'utils/site';
const backgroundColor = {
    0: 'colorMode.background900',
    1: 'colorMode.sidebar800',
    2: 'colorMode.sidebar700',
    3: 'colorMode.sidebar600',
    4: 'colorMode.sidebar500',
};
const FilterAccordionTitle = ({ level, title, tree, isExpanded, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const selectedTown = useSelectedTown();
    const isLoadingLayers = useIsFetching({ queryKey: ['legend'] }) > 0;
    const updateAccordions = useUpdateAccordions();
    const { isChecked, onCheck, hasCheckbox } = useFilterCategoryCheckbox(tree);
    const headingTag = useMemo(() => `h${level}`, [level]);
    const getTitle = () => {
        if (isSwissSite() &&
            (title === 'plu' || title === 'plu_graphic_constraints')) {
            return title + '_swiss';
        }
        return title;
    };
    return (<Heading as={headingTag} backgroundColor={backgroundColor[level]} zIndex={50 - level} borderBottom={level > 0 && !isExpanded ? '1px solid' : ''} borderColor={level > 0 && !isExpanded ? 'colorMode.background900' : ''} opacity={isLoadingLayers ? 0.4 : 1} data-cy="filter_accordion_title">
      <AccordionButton paddingY={level === 1 ? 3 : 1} paddingLeft={level > 2 ? 6 : 4} alignItems="center" position="relative" _expanded={{
            _after: {
                ...accordionArrowStyle,
                borderTopColor: backgroundColor[level],
            },
            boxShadow: level !== 1 && sidebarAccordionBoxShadow,
        }} _hover={{
            textDecoration: 'underline',
            _after: {
                borderTopColor: backgroundColor[level + 2],
            },
        }} onClick={() => {
            const children = level === 1 ? [title] : [...getAllAccordions(tree), title];
            updateAccordions(isExpanded
                ? { valuesToRemove: children }
                : { valuesToAdd: children });
        }}>
        {hasCheckbox && level !== 1 && (<Box onClick={(e) => e.stopPropagation()}>
            <Checkbox marginRight={2} verticalAlign="middle" isChecked={isChecked} onChange={(e) => {
                e.stopPropagation();
                if (!isChecked) {
                    trackEvent({
                        category: ANALYTICS_RACCOURCI,
                        action: `Urbanisme > ${t(`sidebar.categories.${title}`)}`,
                    });
                }
                onCheck(e);
            }} disabled={!!isLoadingLayers || !selectedTown} data-cy="filter_checkbox"/>
          </Box>)}
        <Box flex={1} textAlign="left" paddingLeft={!hasCheckbox ? '25px' : undefined}>
          <Text textStyle="h4" as="span" fontSize="13px" textOverflow="ellipsis" fontWeight={500} fontFamily={level > 0 && 'IBM-plex-sans, sans-serif'}>
            <FilterTitle title={t(`sidebar.categories.${getTitle()}`, {
            TVA: getTVA(selectedTown?.id ?? ''),
        })}/>
          </Text>
        </Box>
        <AccordionIcon color="colorMode.font700"/>
      </AccordionButton>
    </Heading>);
};
export default FilterAccordionTitle;
