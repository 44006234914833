import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { BASE_TILE_SIZE, formatZnieffInfo, getLayerCoordInfo } from 'utils/ign';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.ZNIEFF1', 'PROTECTEDAREAS.ZNIEFF1', zoom, coord);
};
const getDetails = (t) => () => async (coords, map) => {
    return await getLayerCoordInfo('PROTECTEDAREAS.ZNIEFF1', formatZnieffInfo, coords, map, t);
};
const getLegends = () => {
    const legends = {};
    legends.znieff_1 = {
        layerKey: filtersKey.ZNIEFF_TYPE1,
        label: 'znieff_1',
        needsTranslate: true,
        color: '#669300',
        opacity: 1,
    };
    return legends;
};
const useZnieffType1Layer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = useMemo(() => getDetails(t), [t]);
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ZNIEFF_TYPE1],
        getLegends,
        getInfoWindowContent,
        tileWidth: BASE_TILE_SIZE,
        tileHeight: BASE_TILE_SIZE,
    })(filtersKey.ZNIEFF_TYPE1);
};
const ZnieffType1Layer = () => {
    useZnieffType1Layer();
    return null;
};
export default ZnieffType1Layer;
