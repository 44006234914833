import { useEffect, useMemo, useState } from 'react';
import { useProjects, useSectionHash, useSetSelectedBuilding, } from 'hooks/contexts/useTabsContext';
import useProjectsPlotsBuildings from 'hooks/crm/useProjectsPlotsBuildings';
import createLayersHook from 'hooks/layers/createLayersHook';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PROJECT_BUILDING } from 'types/api';
import { filtersKey } from 'utils/filters';
const BUILDING_MODE = 'buildings';
const useSelectedProjectPlotsBuildingsLayer = () => {
    const setSelectedBuilding = useSetSelectedBuilding();
    const sectionHash = useSectionHash();
    const { hasProjectTab } = useSiteConfiguration();
    const [mode, setMode] = useState(null);
    const projects = useProjects();
    const { data: buildings } = useProjectsPlotsBuildings(projects, hasProjectTab(PROJECT_BUILDING));
    const legends = {
        selected_project_plots_buildings: {
            layerKey: filtersKey.SELECTED_PROJECT_PLOTS_BUILDINGS,
            label: 'selected_project_plots_buildings',
            needsTranslate: true,
            color: buildings?.[Object.keys(buildings)[0]]?.color,
            opacity: buildings?.[Object.keys(buildings)[0]]?.opacity,
        },
    };
    useEffect(() => {
        if (sectionHash === PROJECT_BUILDING) {
            setMode(BUILDING_MODE);
        }
        else {
            setMode(null);
            setSelectedBuilding(null);
        }
    }, [sectionHash]);
    const providedData = useMemo(() => {
        return Object.values(buildings || {});
    }, [mode, buildings]);
    createLayersHook({
        providedData,
        updateOnChange: true,
        alwaysEnabled: providedData.length > 0,
        legends,
    })(filtersKey.SELECTED_PROJECT_PLOTS_BUILDINGS);
};
const SelectedProjectPlotsBuildingsLayer = () => {
    useSelectedProjectPlotsBuildingsLayer();
    return null;
};
export default SelectedProjectPlotsBuildingsLayer;
