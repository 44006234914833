import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get, post } from 'api/client';
import { QUERY_KEY as TEAM_PROJECTS_QUERY_KEY } from 'hooks/crm/useTeamProjects';
import useCustomToast from 'hooks/useCustomToast';
import { IOTeamShareable } from 'types/api';
const fetchProjectShareableTeam = (projectId) => () => get(IOTeamShareable, `project-shareable-team/${projectId}`);
const shareProjectRequest = ({ projectId, users, copyContact = false, copyComment = false, copyDocument = false, copyPhoto = false, }) => post(null, `project/${projectId}/share`, {
    users,
    copyContact,
    copyComment,
    copyDocument,
    copyPhoto,
});
const useShareProject = (projectId) => {
    const { getSuccessToast } = useCustomToast();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const queryKey = ['project-shareable-team', projectId];
    const { data: shareableTeam, isInitialLoading: isLoading } = useQuery({
        queryKey,
        queryFn: fetchProjectShareableTeam(projectId),
        enabled: !!projectId,
    });
    const { mutate: shareProject, isPending: isLoadingShare } = useMutation({
        mutationFn: shareProjectRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
            queryClient.invalidateQueries({ queryKey: TEAM_PROJECTS_QUERY_KEY });
            getSuccessToast({
                title: t('modals.share_project.success_title'),
                description: t('modals.share_project.success_description'),
                id: 'project_share_success',
            });
        },
    });
    return { shareableTeam, isLoading, shareProject, isLoadingShare };
};
export default useShareProject;
