import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Divider, Heading, Stack, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_TELECOMMANDE_ACTIONS, } from 'analytics/useAnalytics';
import isEqual from 'lodash/isEqual';
import PlotsExportButton from 'components/buttons/PlotsExportButton';
import ResetButton from 'components/buttons/ResetButton';
import PlotsSearchSaveFilterModal from 'components/modals/PlotsSearchSaveFilterModal';
import FiltersCategory from 'components/sidebar/FiltersSideBar/FiltersCategory';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useIsLoading, usePlotSearchParams, useSearchResultsCount, useSetPlotSearchParams, } from 'hooks/contexts/usePlotSearchContext';
import { useToggleLayerTab } from 'hooks/contexts/useTabsContext';
import usePlotsSearchSavedFilters from 'hooks/plotsSearch/usePlotsSearchSavedFilters';
import { filtersKey, getTownPlotsInfo, } from 'utils/filters';
import { formatSearchData, PLOTS_SEARCH_DEFAUT_FORM_VALUES, searchHasFilter, } from 'utils/plotsSearch';
import { MainLayersFiltersSkeleton } from '../FiltersSideBar/MainLayersFilters';
import SavedPlotFilters from './SavedPlotFilters';
const PlotsSearchHeader = ({ mainLayers, isLoading, }) => {
    const { t } = useTranslation();
    const setPlotSearchParams = useSetPlotSearchParams();
    const searchData = usePlotSearchParams();
    const searchResultsCount = useSearchResultsCount();
    const { watch, reset, formState } = useFormContext();
    const { isSubmitting, isSubmitted } = formState;
    const selectedTown = useSelectedTown();
    const { deleteFilter, addFilter } = usePlotsSearchSavedFilters();
    const { isOpen: isOpenSaveModal, onOpen: onOpenSaveModal, onClose: onCloseSaveModal, } = useDisclosure();
    const [appliedFilter, setAppliedFilter] = useState(null);
    const formData = watch();
    const formattedCurrentSearchData = formatSearchData(formData, selectedTown?.hasRegulationAnalysis || false);
    const formattedSubmittedSearchData = formatSearchData(searchData?.values, selectedTown?.hasRegulationAnalysis || false);
    const townPlotsInfo = getTownPlotsInfo(mainLayers);
    const hasSearch = searchHasFilter(formattedSubmittedSearchData);
    const hasCurrentFilter = searchHasFilter(formattedCurrentSearchData);
    const submitBtnRef = useRef(null);
    const { trackEvent } = useAnalytics();
    const isLoadingSearchData = useIsLoading();
    const toggleLayerTab = useToggleLayerTab();
    const [initialValues] = useState(PLOTS_SEARCH_DEFAUT_FORM_VALUES);
    const formValues = watch();
    const isFormDirty = () => {
        const formatedFormValues = {};
        for (const property in formValues) {
            formatedFormValues[property] = isNaN(formValues[property])
                ? ''
                : formValues[property];
        }
        return JSON.stringify(initialValues) !== JSON.stringify(formatedFormValues);
    };
    const handleSaveFilter = (name) => {
        addFilter({ name, filters: formattedCurrentSearchData });
        onCloseSaveModal();
    };
    const resetFilter = () => {
        reset(PLOTS_SEARCH_DEFAUT_FORM_VALUES);
        setAppliedFilter(null);
    };
    useEffect(() => {
        if (appliedFilter) {
            submitBtnRef.current?.click();
        }
    }, [appliedFilter]);
    useEffect(() => {
        if (!appliedFilter) {
            return;
        }
        const { prefix: _prefix, section: _section, number: _number, filterByPlot: _filterByPlot, ...currentFilters } = formattedCurrentSearchData;
        // Pour gérer le cas où de nouveau filtre sont rajouté alors que dans les filtres
        // sauvegardés il n'y a pas ces nouveaux filtres
        for (const property in appliedFilter.filters) {
            if (currentFilters[property] &&
                currentFilters[property] != appliedFilter.filters[property] &&
                !isEqual(currentFilters[property], appliedFilter.filters[property])) {
                setAppliedFilter(null);
                break;
            }
        }
    }, [formData]);
    const nbPlotsToExport = hasSearch ? searchResultsCount : townPlotsInfo?.count;
    return (<Box>
      <VStack paddingTop={3} paddingX={3} width="full" justifyContent="center" alignItems="start">
        <Heading width="full" as="h4" variant="h3" marginBottom="3" display="flex" justifyContent="space-between">
          <Text color="colorMode.font900" as="span">
            {t('sidebar.header.title_filters')}
          </Text>
        </Heading>

        <Stack alignItems="start" spacing={1}>
          {isLoading || mainLayers?.layers.length === 0 ? (<MainLayersFiltersSkeleton />) : (<>
              <VStack spacing={1} alignItems="flex-start">
                {/* Filters */}
                <Box>
                  <FiltersCategory category={mainLayers} id="main_layers" level={1}/>
                </Box>
              </VStack>
            </>)}
        </Stack>
        {/* SUBMIT SEARCH */}
        <Button ref={submitBtnRef} size="sm" width="full" variant="primary" type="submit" form="search-plots-form" disabled={isSubmitting || !hasCurrentFilter} isLoading={isLoadingSearchData} onClick={() => trackEvent({
            category: ANALYTICS_TELECOMMANDE_ACTIONS,
            action: 'Afficher les parcelles',
        })} data-cy="submit_plot_search">
          {t('sidebar.search_plots.show_results')}
        </Button>
        {/* Search reset button */}
        {isFormDirty() && (<Box textAlign="center" width="100%" lineHeight={0.5}>
            <ResetButton label={t('sidebar.header.clear_filters')} onClick={() => {
                setPlotSearchParams(null);
                resetFilter();
                toggleLayerTab({
                    tabId: filtersKey.PLOTS_SEARCH,
                    closeTab: true,
                });
                trackEvent({
                    category: ANALYTICS_TELECOMMANDE_ACTIONS,
                    action: 'Effacer les filtres',
                });
            }} data-cy="clear_all_filters"/>
          </Box>)}

        {/* search results infos */}
        {isSubmitted && (<Stack width="100%" justifyContent="center">
            <Text textStyle="paragraph" textAlign="center">
              {t('sidebar.search_plots.nb_plots', {
                count: searchResultsCount,
                total: townPlotsInfo?.count,
            })}
            </Text>
            {nbPlotsToExport > 0 && (<PlotsExportButton isDisabled={!nbPlotsToExport} resultsLength={nbPlotsToExport} analyticDetail={nbPlotsToExport
                    ? 'Télécharger les 1000 premiers résultats'
                    : 'Télécharger les parcelles'} analyticOrigin={ANALYTICS_CATEGORY_DOWNLOAD} variant="link" bg="transparent"/>)}
          </Stack>)}
      </VStack>
      <Divider paddingTop={2} flex={1} color="colorMode.background500"/>

      {/* SAVED FAVORITES */}
      <SavedPlotFilters setAppliedFilter={setAppliedFilter} appliedFilter={appliedFilter} resetFilter={resetFilter} handleDelete={deleteFilter} hasSearch={hasSearch} isOpenSaveModal={isOpenSaveModal} onOpenSaveModal={onOpenSaveModal}/>
      <Divider flex={1} color="colorMode.background500"/>

      <PlotsSearchSaveFilterModal isOpen={isOpenSaveModal} onClose={onCloseSaveModal} onSave={handleSaveFilter}/>
    </Box>);
};
export default PlotsSearchHeader;
