import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useComputedBounds from 'hooks/map/useComputedBounds';
import { IOMarkerLayerItem, IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IOTransportsLayerItem = IOPolygonLayerItem;
export const POI_ZOOM_LIMIT = 12;
export const POI_FILTERS_PARENT = {
    [filtersKey.POI_TRANSPORT]: 1,
    [filtersKey.POI_COMMERCE_AND_SERVICES]: 2,
    [filtersKey.POI_HOBBIES]: 3,
    [filtersKey.POI_EDUCATION]: 4,
    [filtersKey.POI_CULTURE]: 5,
    [filtersKey.POI_HEALTHCARE]: 7,
    [filtersKey.POI_EARLY_CHILDHOOD]: 8,
    [filtersKey.POI_TOURISM]: 9,
    [filtersKey.POI_PUBLIC_SERVICE]: 10,
    [filtersKey.POI_RISKS]: 11,
    [filtersKey.POI_SPORTS_ACTIVITIES]: 201,
    [filtersKey.POI_RECREATIONAL_ACTIVITIES]: 202,
    [filtersKey.POI_TOURISTIC_PLACES]: 203,
    [filtersKey.POI_FESTIVAL]: 204,
    [filtersKey.POI_PLACES_OF_INTEREST]: 205,
};
export const POI_FILTERS = {
    [filtersKey.POI_TRANSPORT]: {
        [filtersKey.POI_TRANSPORT_BUS]: 1,
        [filtersKey.POI_TRANSPORT_STATION]: 16,
        [filtersKey.POI_TRANSPORT_SUBWAY]: 20,
        [filtersKey.POI_TRANSPORT_FERRY]: 21,
        [filtersKey.POI_TRANSPORT_TRAMWAY]: 22,
        [filtersKey.POI_TRANSPORT_PARKING]: 23,
        [filtersKey.POI_TRANSPORT_BICYCLE_PARKING]: 24,
        [filtersKey.POI_TRANSPORT_TAXI]: 28,
        [filtersKey.POI_TRANSPORT_GAS_STATION]: 32,
        [filtersKey.POI_TRANSPORT_BIKE_LOCATION]: 33,
        [filtersKey.POI_TRANSPORT_VELIB]: 34,
        [filtersKey.POI_TRANSPORT_AIRPORT]: 64,
        [filtersKey.POI_TRANSPORT_RER]: 65,
    },
    [filtersKey.POI_COMMERCE_AND_SERVICES]: {
        [filtersKey.POI_COMMERCE_AND_SERVICES_BAR]: 2,
        [filtersKey.POI_COMMERCE_AND_SERVICES_BUTCHER_SHOP]: 4,
        [filtersKey.POI_COMMERCE_AND_SERVICES_BAKERY]: 5,
        [filtersKey.POI_COMMERCE_AND_SERVICES_NEIGHBORHOOD_MARKET]: 19,
        [filtersKey.POI_COMMERCE_AND_SERVICES_RESTAURANT]: 26,
        [filtersKey.POI_COMMERCE_AND_SERVICES_POST_OFFICE]: 27,
        [filtersKey.POI_COMMERCE_AND_SERVICES_SUPERMARKET]: 29,
        [filtersKey.POI_COMMERCE_AND_SERVICES_MINIMARKET]: 30,
        [filtersKey.POI_COMMERCE_AND_SERVICES_BANK]: 49,
        [filtersKey.POI_COMMERCE_AND_SERVICES_TOBACCO_PRESS]: 50,
        [filtersKey.POI_COMMERCE_AND_SERVICES_OTHER_BUSINESS]: 75,
    },
    [filtersKey.POI_HOBBIES]: {
        [filtersKey.POI_HOBBIES_CINEMA]: 3,
        [filtersKey.POI_HOBBIES_FIELD_AND_SPORT_HALL]: 14,
        [filtersKey.POI_HOBBIES_PARC]: 15,
        [filtersKey.POI_HOBBIES_CASINO]: 18,
        [filtersKey.POI_HOBBIES_BEACH]: 66,
        [filtersKey.POI_HOBBIES_BODYBUILDING_ROOM]: 67,
    },
    [filtersKey.POI_EDUCATION]: {
        [filtersKey.POI_EDUCATION_COLLEGE]: 6,
        [filtersKey.POI_EDUCATION_KINDERGARTEN]: 7,
        [filtersKey.POI_EDUCATION_PRIMARY_SCHOOL]: 8,
        [filtersKey.POI_EDUCATION_HIGHER_EDUCATION]: 9,
        [filtersKey.POI_EDUCATION_HIGH_SCHOOL]: 10,
    },
    [filtersKey.POI_CULTURE]: {
        [filtersKey.POI_CULTURE_LIBRARY]: 12,
        [filtersKey.POI_CULTURE_MONUMENT]: 13,
        [filtersKey.POI_CULTURE_THEATER]: 31,
        [filtersKey.POI_CULTURE_OTHER]: 74,
    },
    [filtersKey.POI_HEALTHCARE]: {
        [filtersKey.POI_HEALTHCARE_HOSPITAL]: 17,
        [filtersKey.POI_HEALTHCARE_PHARMACY]: 25,
        [filtersKey.POI_HEALTHCARE_GENERAL_DOCTOR]: 35,
        [filtersKey.POI_HEALTHCARE_SPECIALIST_DOCTOR]: 36,
    },
    [filtersKey.POI_EARLY_CHILDHOOD]: {
        [filtersKey.POI_EARLY_CHILDHOOD_TOY_LIBRARY]: 47,
        [filtersKey.POI_EARLY_CHILDHOOD_CRECHE]: 63,
    },
    [filtersKey.POI_TOURISM]: {
        [filtersKey.POI_TOURISM_HOSTEL]: 51,
        [filtersKey.POI_TOURISM_CAMPING]: 52,
        [filtersKey.POI_TOURISM_RESIDENCE_VILLAGE_PARK]: 54,
    },
    [filtersKey.POI_PUBLIC_SERVICE]: {
        [filtersKey.POI_PUBLIC_SERVICE_CITY_HALL]: 48,
        [filtersKey.POI_PUBLIC_SERVICE_CAF]: 56,
        [filtersKey.POI_PUBLIC_SERVICE_POLICE]: 57,
        [filtersKey.POI_PUBLIC_SERVICE_CPAM]: 58,
        [filtersKey.POI_PUBLIC_SERVICE_GENDARMERIE]: 59,
        [filtersKey.POI_PUBLIC_SERVICE_JOB_CENTER]: 60,
        [filtersKey.POI_PUBLIC_SERVICE_BUSINESS_TAX_DEPARTMENT]: 61,
        [filtersKey.POI_PUBLIC_SERVICE_PERSONAL_TAX_DEPARTMENT]: 62,
        [filtersKey.POI_PUBLIC_SERVICE_PREFECTURE]: 68,
        [filtersKey.POI_PUBLIC_SERVICE_GREFFE]: 69,
    },
    [filtersKey.POI_RISKS]: {
        [filtersKey.POI_RISKS_RELAY_ANTENNAS]: 70,
        [filtersKey.POI_RISKS_POLLUTING_ESTABLISHMENT]: 71,
        [filtersKey.POI_RISKS_POLLUTED_SITE]: 72,
        [filtersKey.POI_RISKS_POTENTIALLY_POLLUTED_SITE]: 73,
    },
    [filtersKey.POI_SPORTS_ACTIVITIES]: {
        [filtersKey.POI_SPORTS_ACTIVITIES_GOLF]: 201,
        [filtersKey.POI_SPORTS_ACTIVITIES_VTT]: 202,
        [filtersKey.POI_SPORTS_ACTIVITIES_HIKING]: 203,
        [filtersKey.POI_SPORTS_ACTIVITIES_HORSE_RIDING]: 204,
        [filtersKey.POI_SPORTS_ACTIVITIES_ESCALATION]: 205,
        [filtersKey.POI_SPORTS_ACTIVITIES_BILLIARD_BOWLING_PAINTBALL]: 206,
        [filtersKey.POI_SPORTS_ACTIVITIES_MECHANICAL_SPORTS]: 207,
        [filtersKey.POI_SPORTS_ACTIVITIES_AIR_SPORTS]: 208,
        [filtersKey.POI_SPORTS_ACTIVITIES_FISHING]: 209,
        [filtersKey.POI_SPORTS_ACTIVITIES_SKI]: 210,
        [filtersKey.POI_SPORTS_ACTIVITIES_SWIMMING_AND_WATER_SPORTS]: 211,
        [filtersKey.POI_SPORTS_ACTIVITIES_TENNIS]: 212,
        [filtersKey.POI_SPORTS_ACTIVITIES_LEISURE_CENTER]: 213,
    },
    [filtersKey.POI_RECREATIONAL_ACTIVITIES]: {
        [filtersKey.POI_RECREATIONAL_ACTIVITIES_AMUSEMENT_PARK]: 230,
        [filtersKey.POI_RECREATIONAL_ACTIVITIES_AQUARIUM]: 231,
        [filtersKey.POI_RECREATIONAL_ACTIVITIES_ZOO]: 240,
        [filtersKey.POI_RECREATIONAL_ACTIVITIES_MALL]: 241,
    },
    [filtersKey.POI_TOURISTIC_PLACES]: {
        [filtersKey.POI_TOURISTIC_PLACES_UNAVOIDABLE]: 261,
        [filtersKey.POI_TOURISTIC_PLACES_MUSEUM]: 266,
    },
    [filtersKey.POI_FESTIVAL]: {
        [filtersKey.POI_FESTIVAL]: 270,
    },
    [filtersKey.POI_PLACES_OF_INTEREST]: {
        [filtersKey.POI_PLACES_OF_INTEREST_BEACH]: 251,
        [filtersKey.POI_PLACES_OF_INTEREST_SWIMMING_LAKE_RIVER]: 252,
        [filtersKey.POI_PLACES_OF_INTEREST_SEA]: 253,
        [filtersKey.POI_PLACES_OF_INTEREST_MOUNTAIN]: 254,
        [filtersKey.POI_PLACES_OF_INTEREST_CAMPAIGN]: 255,
        [filtersKey.POI_PLACES_OF_INTEREST_CITY]: 256,
        [filtersKey.POI_PLACES_OF_INTEREST_DOWNTOWN]: 257,
        [filtersKey.POI_PLACES_OF_INTEREST_LAKE]: 258,
        [filtersKey.POI_PLACES_OF_INTEREST_SKI_STATION]: 259,
        [filtersKey.POI_PLACES_OF_INTEREST_AGGLOMERATION]: 260,
    },
};
export const POI_FILTERS_KEY = Object.keys(POI_FILTERS);
export const POI_TRANSPORT_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_TRANSPORT]);
export const POI_COMMERCE_AND_SERVICES_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_COMMERCE_AND_SERVICES]);
export const POI_HOBBIES_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_HOBBIES]);
export const POI_EDUCATION_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_EDUCATION]);
export const POI_CULTURE_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_CULTURE]);
export const POI_HEALTHCARE_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_HEALTHCARE]);
export const POI_EARLY_CHILDHOOD_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_EARLY_CHILDHOOD]);
export const POI_TOURISM_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_TOURISM]);
export const POI_PUBLIC_SERVICE_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_PUBLIC_SERVICE]);
export const POI_RISKS_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_RISKS]);
export const POI_SPORTS_ACTIVITIES_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_SPORTS_ACTIVITIES]);
export const POI_RECREATIONAL_ACTIVITIES_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_RECREATIONAL_ACTIVITIES]);
export const POI_TOURISTIC_PLACES_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_TOURISTIC_PLACES]);
export const POI_FESTIVAL_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_FESTIVAL]);
export const POI_PLACES_OF_INTEREST_FILTERS_KEY = Object.keys(POI_FILTERS[filtersKey.POI_PLACES_OF_INTEREST]);
const TRANSPORTS_POLYGONS_QUERY_KEY = 'transports_polygons';
const IOPOIMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        icon: iots.string,
        name: iots.union([iots.string, iots.undefined, iots.null]),
        infoLine1: iots.union([iots.string, iots.undefined, iots.null]),
        infoLine2: iots.union([iots.string, iots.undefined, iots.null]),
        infoLine3: iots.union([iots.string, iots.undefined, iots.null]),
        infoLine4: iots.union([iots.string, iots.undefined, iots.null]),
    }),
], 'IOPOIMarkerItem');
const getInfoWindowContent = (item) => {
    if (item.parentLayerKey === 'poi_transport') {
        return;
    }
    let content = `<b>${item.name}</b></br>`;
    if (item.infoLine1) {
        content += `<p>${item.infoLine1}</p>`;
    }
    if (item.infoLine2) {
        content += `<p>${item.infoLine2}</p>`;
    }
    if (item.infoLine3) {
        content += `<p>${item.infoLine3}</p>`;
    }
    if (item.infoLine4) {
        content += `<p>${item.infoLine4}</p>`;
    }
    return content;
};
const getTransportsPolygonsLegends = (parentFilterName, filterName, transportsPolygonsData, t) => {
    const legends = {};
    if (transportsPolygonsData) {
        transportsPolygonsData.forEach((layer) => {
            const key = `${filterName}-${TRANSPORTS_POLYGONS_QUERY_KEY}-${layer.infoTitle}-${layer.infoLine1}`;
            const name = `${t(`info_window.poi_transports.${layer.infoTitle}`)} ${layer.infoLine1 ?? ''}`;
            if (!legends[key]) {
                legends[key] = {
                    layerKey: filterName,
                    parentLayerKey: parentFilterName,
                    label: name,
                    color: layer.color,
                    opacity: layer.opacity,
                    polygonType: layer.type,
                    borderWidth: layer.borderWidth,
                };
            }
        });
    }
    return legends;
};
export const getBbox = (bounds) => {
    if (!bounds) {
        return;
    }
    const northEastBounds = bounds.getNorthEast();
    const southWestBounds = bounds.getSouthWest();
    const lngMax = northEastBounds.lng();
    const lngMin = southWestBounds.lng();
    const latMax = northEastBounds.lat();
    const latMin = southWestBounds.lat();
    return `${lngMax}/${lngMin}/${latMax}/${latMin}`;
};
const usePOILayers = (parentFilterKey, POIFiltersKey) => {
    const computedBounds = useComputedBounds();
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const townId = selectedTown?.id;
    const queryClient = useQueryClient();
    const map = useMap();
    map?.getZoom();
    const getMarkerImg = (marker) => {
        try {
            return require(`../../images/POIImages/${marker.icon}`);
        }
        catch (e) {
            if (e instanceof Error) {
                //eslint-disable-next-line
                console.error("Can't load ", marker.icon);
            }
            else
                throw e;
        }
        return null;
    };
    const bbox = useMemo(() => getBbox(computedBounds), [computedBounds]);
    const fetchPOI = (townId, townScot, selectedTown, bbox) => () => {
        return get(iots.array(IOPOIMarkerItem), `${townId}/poi-parent-${POI_FILTERS_PARENT[parentFilterKey]}/${bbox}`);
    };
    createLayersHook({
        fetch: fetchPOI,
        markerImg: getMarkerImg,
        getInfoWindowContent,
        extraParams: [bbox],
        disableQuery: !bbox,
    })(Object.keys(POI_FILTERS[parentFilterKey]));
    POIFiltersKey.map((filterName) => {
        if (filterName === filtersKey.POI_TRANSPORT_TRAMWAY ||
            filterName === filtersKey.POI_TRANSPORT_SUBWAY ||
            filterName === filtersKey.POI_TRANSPORT_RER) {
            const fetchTransports = useMemo(() => (townId) => () => get(iots.array(IOTransportsLayerItem), `${townId}/transports-${POI_FILTERS[parentFilterKey][filterName]}/${bbox}`), [bbox]);
            const transportsPolygonsData = queryClient.getQueryData(['layer', filterName, townId, bbox, TRANSPORTS_POLYGONS_QUERY_KEY]);
            const transportsPolygonsLegends = getTransportsPolygonsLegends(parentFilterKey, filterName, transportsPolygonsData, t);
            createLayersHook({
                fetch: fetchTransports,
                legends: transportsPolygonsLegends,
                extraParams: [bbox, TRANSPORTS_POLYGONS_QUERY_KEY],
                disableQuery: !bbox,
            })(filterName, parentFilterKey);
        }
    });
};
const POILayers = ({ parentFilterKey, POIFiltersKey, computedZoom, }) => {
    if (computedZoom < POI_ZOOM_LIMIT) {
        return null;
    }
    usePOILayers(parentFilterKey, POIFiltersKey);
    return null;
};
export default POILayers;
