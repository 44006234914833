import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { SettingsIcon } from '@chakra-ui/icons';
import { Box, VStack } from '@chakra-ui/react';
import DashboardButton from 'components/buttons/DashboardButton';
import { menuItems } from 'components/sidebar/utils';
import { ROLES } from 'context/AuthContext';
import { CRM_TABS } from 'context/TabsContext';
import { useUserData } from 'hooks/contexts/useAuthContext';
import { useChangeLayout } from 'hooks/contexts/useLayoutContext';
import { useToggleMenu } from 'hooks/contexts/useMenuContext';
import { useToggleCRMTab, useToggleLayerTab, } from 'hooks/contexts/useTabsContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import LandPointIcon from 'images/icons/LandPoint';
import PenIcon from 'images/icons/Pen';
import PlotIcon from 'images/icons/Plot';
import ProjectIcon from 'images/icons/Project';
import ProtectionIcon from 'images/icons/Protection';
import StatisticIcon from 'images/icons/Statistic';
import { filtersKey } from 'utils/filters';
import { DashboardHistory } from './DashboardHistory/';
import DashboardTitle from './DashboardTitle';
import Prospect from './Prospect';
import Team from './Team';
const DashboardMenu = ({ isOpen }) => {
    const { t } = useTranslation();
    const userData = useUserData();
    const toggleCRMTab = useToggleCRMTab();
    const toggleLayerTab = useToggleLayerTab();
    const toggleMenu = useToggleMenu();
    const { deployLayout } = useChangeLayout();
    const { hasAnyDashboardItem, hasDashboardItem } = useSiteConfiguration();
    if (!isOpen)
        return null;
    if (!hasAnyDashboardItem()) {
        return null;
    }
    const showPersonalization = () => {
        return hasDashboardItem('personalization') && userData.customerNumber !== '';
    };
    return (<VStack overflowY="auto" height="100%" alignItems="stretch" paddingX={2} paddingY={2} spacing={5} data-cy="dashboard_panel">
      {/* Plots */}
      {(hasDashboardItem(filtersKey.USER_PLOTS) ||
            hasDashboardItem(filtersKey.USER_PROJECTS)) && (<Box>
          <DashboardTitle label={t(`tabs.dashboard.title_plots`)} icon={PlotIcon}/>
          <VStack display="flex">
            {hasDashboardItem(filtersKey.USER_PLOTS) && (<DashboardButton onClick={() => {
                    deployLayout();
                    toggleMenu(menuItems?.DASHBOARD, false);
                    toggleLayerTab({
                        tabId: CRM_TABS.USER_PLOTS,
                    });
                }} icon={PlotIcon} label={t(`tabs.dashboard.btn_plots`)} id="dashboard_button_user_plots"/>)}
            {hasDashboardItem(filtersKey.USER_PROJECTS) && (<DashboardButton onClick={() => {
                    deployLayout();
                    toggleMenu(menuItems?.DASHBOARD, false);
                    toggleLayerTab({
                        tabId: CRM_TABS.USER_PROJECTS,
                    });
                }} icon={ProjectIcon} label={t(`tabs.dashboard.btn_projects`)} id="dashboard_button_user_projects"/>)}
            {hasDashboardItem('landPoint') && (<DashboardButton onClick={() => {
                    deployLayout();
                    toggleCRMTab({
                        tabId: CRM_TABS.LAND_POINT,
                    });
                }} icon={LandPointIcon} label={t(`tabs.dashboard.btn_land_point`)} id="dashboard_button_land_point"/>)}
          </VStack>
        </Box>)}
      <Prospect isItemVisible={hasDashboardItem}/>
      {hasDashboardItem('historic') && <DashboardHistory />}

      {/* Administration */}
      {((hasDashboardItem('accessRightUser') &&
            userData?.roles?.includes(ROLES.ROLE_ADMIN)) ||
            showPersonalization()) && (<Box data-cy="dashboard_admin">
          <DashboardTitle icon={SettingsIcon} label={t(`tabs.dashboard.title_administrator`)}/>
          {hasDashboardItem('accessRightUser') &&
                userData?.roles?.includes(ROLES.ROLE_ADMIN) && (<DashboardButton onClick={() => {
                    deployLayout();
                    toggleCRMTab({ tabId: CRM_TABS.TEAM_ACCESS_RIGHTS });
                }} label={t('tabs.dashboard.user_access_rights')} icon={ProtectionIcon} id="dashboard_button_user_access_rights"/>)}
          {showPersonalization() && (<DashboardButton onClick={() => {
                    deployLayout();
                    toggleCRMTab({ tabId: CRM_TABS.PERSONALIZATION });
                }} label={t('tabs.dashboard.personalization')} icon={PenIcon} id="dashboard_button_personalization"/>)}
        </Box>)}
      {userData?.roles?.includes(ROLES.ROLE_MANAGER) && (<Team hasDashboardItem={hasDashboardItem}/>)}
      {/* Statistics */}
      {hasDashboardItem('myStats') && (<Box data-cy="dashboard_stats">
          <DashboardTitle label={t(`tabs.dashboard.title_stats`)} icon={StatisticIcon}/>
          <DashboardButton onClick={() => {
                deployLayout();
                toggleCRMTab({ tabId: CRM_TABS.MY_STATISTICS });
            }} label={t('tabs.dashboard.my_statistics')} icon={StatisticIcon} id="dashboard_button_my_statistics"/>
          <DashboardButton onClick={() => {
                deployLayout();
                toggleCRMTab({ tabId: CRM_TABS.MY_STATISTICS_CRM });
            }} label={t('tabs.dashboard.my_statistics_crm')} icon={StatisticIcon} id="dashboard_button_my_statistics_crm"/>
        </Box>)}
    </VStack>);
};
export default memo(DashboardMenu);
