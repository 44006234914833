import * as React from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '@chakra-ui/icons';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { Accordion, Box, Button, Center, IconButton, Spinner, } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import useAnalytics, { ANALYTICS_TELECOMMANDE_ACTIONS, } from 'analytics/useAnalytics';
import LegendItem from 'components/map/Legend/LegendItem/LegendItemWrapper';
import { useClearFilters, useFiltersConfig, } from 'hooks/contexts/useFiltersContext';
import { useLayersTree } from 'hooks/contexts/useLayersTreeContext';
import { useLegends } from 'hooks/contexts/useLegendContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { filtersKey } from 'utils/filters';
import { formatLayersNames, getNameAndPrefixForLayerKey, } from 'utils/formatLayersNames';
import PlotSummaryLegendItem from './LegendItem/PlotSummary/PlotSummaryLegendItem';
const LegendSkeleton = () => {
    return (<Center paddingY={1} data-cy="legend-skeleton" backgroundColor="colorMode.background900">
      <Spinner size="sm"/>
    </Center>);
};
const LegendList = ({ mapHeight }) => {
    const { t } = useTranslation();
    const legends = useLegends();
    const filtersConfig = useFiltersConfig();
    const layersTree = useLayersTree();
    const isLoadingLayers = useIsFetching({ queryKey: ['layer'] }) > 0;
    const isLoadingLegends = useIsFetching({ queryKey: ['legend'] }) > 0;
    const isLoadingLayerTree = useIsFetching({ queryKey: ['layersTree'] }) > 0;
    const [openedLegendsIndex, setOpenedLegendsIndex] = useState([]);
    const [displayLegends, setDisplayLegends] = useState(true);
    const [_, setPrevLegends] = useState({});
    const selectedTown = useSelectedTown();
    const clearFilters = useClearFilters();
    const { trackEvent } = useAnalytics();
    const isLoading = isLoadingLayers || isLoadingLegends;
    const layerNames = useMemo(() => {
        if (!isLoadingLayerTree && layersTree) {
            return formatLayersNames(layersTree);
        }
        return {};
    }, [JSON.stringify(layersTree), isLoadingLayerTree]);
    const legendsToDisplay = useMemo(() => {
        return Object.keys(legends)
            .reverse()
            .map((layerKey) => {
            const { name, prefix } = getNameAndPrefixForLayerKey(layerKey, layerNames, t, selectedTown?.id);
            return {
                layerKey,
                legends: legends[layerKey],
                prefix,
                legendName: name,
            };
        })
            .filter((legend) => legend.legendName) // Pour le cas où le layer n'est plus disponible
            .sort((a, b) => {
            if (a.layerKey === filtersKey.PLU)
                return -1;
            if (b.layerKey === filtersKey.PLU)
                return 1;
            if (a.layerKey === filtersKey.PLOTS)
                return -1;
            if (b.layerKey === filtersKey.PLOTS)
                return 1;
            return 0;
        });
    }, [legends, layerNames]);
    useEffect(() => {
        const openedIndex = [];
        let indexStart = 0;
        // only the first legend opened, my plot and my project
        Object.keys(legends).forEach((value, index) => {
            // necessarily first position
            if (value === filtersKey.PLU) {
                indexStart++;
                openedIndex.push(index);
            }
            // necessarily first or seconde position
            if (value === filtersKey.PLOTS) {
                indexStart++;
                openedIndex.push(index);
            }
            if (value === filtersKey.USER_PLOTS) {
                if (indexStart === index) {
                    indexStart++;
                }
                openedIndex.push(index);
            }
            if (value === filtersKey.USER_PROJECTS) {
                if (indexStart === index) {
                    indexStart++;
                }
                openedIndex.push(index);
            }
        });
        openedIndex.push(indexStart);
        // Open the legend only when new filter is checked
        setPrevLegends((prev) => {
            if (Object.keys(legends).length > Object.keys(prev).length) {
                setOpenedLegendsIndex(openedIndex);
            }
            return legends;
        });
    }, [Object.keys(legends).length]);
    const legendRef = useRef(null);
    const toggleDisplayLegend = () => {
        setDisplayLegends(!displayLegends);
    };
    return (<Box ref={legendRef} id="legends" boxShadow="sm" position="absolute" top={0} left={0} width="30%" minWidth="245px" maxWidth="275px" maxH={mapHeight} minH="0" overflow={legendRef?.current?.clientHeight >= mapHeight ? 'auto' : 'none'}>
      {isLoading && <LegendSkeleton />}
      {legendsToDisplay.length > 1 && (<Box width="100%" backgroundColor="colorMode.background900" position="relative">
          <Button _hover={{ color: 'colorMode.font900' }} background={'transparent'} width="100%" fontSize={12} borderRadius="0" rightIcon={displayLegends ? (<ChevronUpIcon fill="currentColor"/>) : (<ChevronDownIcon fill="currentColor"/>)} textAlign="center" height="auto" paddingY="2px" onClick={() => {
                toggleDisplayLegend();
                trackEvent({
                    category: ANALYTICS_TELECOMMANDE_ACTIONS,
                    action: displayLegends
                        ? 'Cacher la légende'
                        : 'Afficher la légende',
                });
            }}>
            {displayLegends
                ? t('sidebar.header.legend_hide')
                : t('sidebar.header.legend_show')}
          </Button>
          <Box position="absolute" zIndex={1} top="2px" right={0}>
            <IconButton icon={<CloseIcon width="10px" color="currentcolor"/>} variant="link" onClick={() => {
                clearFilters(false);
                trackEvent({
                    category: ANALYTICS_TELECOMMANDE_ACTIONS,
                    action: 'Effacer les cartes Urbanisme - légende',
                });
            }} color="colorMode.font700" minWidth={8} _hover={{
                color: 'red.500',
            }} borderRadius={0} aria-label="close"/>
          </Box>
        </Box>)}
      <Accordion display={displayLegends ? 'block' : 'none'} reduceMotion allowMultiple index={openedLegendsIndex} onChange={(e) => setOpenedLegendsIndex(e)} backgroundColor="colorMode.background900">
        {legendsToDisplay.map((legend) => (<LegendItem key={legend.layerKey} {...legend} filtersConfig={filtersConfig}/>))}
      </Accordion>
      <PlotSummaryLegendItem />
    </Box>);
};
export default LegendList;
