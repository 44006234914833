import html2canvas from 'html2canvas';
export const loadImageAsBase64 = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous'; // Nécessaire si l'image est sur un domaine différent
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            resolve(dataURL);
        };
        img.onerror = reject;
        img.src = url;
    });
};
export const getImageMap = async (map) => {
    const renderingType = map.getRenderingType();
    if (renderingType === 'RASTER') {
        return await getImageDOMMap(map);
    }
    return getImageWebGLMap(map);
};
const getImageWebGLMap = (map) => {
    const mapCanvas = map.getDiv().querySelector('canvas');
    if (mapCanvas) {
        return {
            element: mapCanvas,
            base64: mapCanvas.toDataURL('image/png'),
            title: '',
        };
    }
    else {
        return null;
    }
};
const getImageDOMMap = async (map) => {
    const mapElement = map.getDiv();
    if (mapElement) {
        const canvas = await html2canvas(mapElement, {
            useCORS: true,
            allowTaint: false,
            ignoreElements: (el) => ['map-toolbar', 'gmnoprint', 'gm-control-active'].some((cls) => el.classList.contains(cls)),
        });
        return {
            element: mapElement,
            base64: canvas.toDataURL('image/jpeg'),
            title: '',
        };
    }
    else {
        return null;
    }
};
