import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const INCLINE_ZOOM_LIMIT = 17;
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('ELEVATION.ELEVATIONGRIDCOVERAGE.THRESHOLD', 'ELEVATION.ELEVATIONGRIDCOVERAGE.THRESHOLD', zoom, coord);
};
const getLegends = () => {
    const legends = {};
    legends.incline_7_10 = {
        layerKey: filtersKey.INCLINE,
        label: 'incline_7_10',
        needsTranslate: true,
        color: '#2456BE',
        opacity: 0.8,
    };
    legends.incline_10_15 = {
        layerKey: filtersKey.INCLINE,
        label: 'incline_10_15',
        needsTranslate: true,
        color: '#3822C1',
        opacity: 0.8,
    };
    legends.incline_5_20 = {
        layerKey: filtersKey.INCLINE,
        label: 'incline_15_20',
        needsTranslate: true,
        color: '#C1541B',
        opacity: 0.8,
    };
    legends.incline_20 = {
        layerKey: filtersKey.INCLINE,
        label: 'incline_20',
        needsTranslate: true,
        color: '#CE0714',
        opacity: 0.8,
    };
    return legends;
};
const useInclineLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.INCLINE],
        getLegends,
        opacity: 0.42,
    })(filtersKey.INCLINE);
};
const InclineLayer = () => {
    useInclineLayer();
    return null;
};
export default InclineLayer;
