import { PLOT_TAB_TYPE } from 'context/TabsContext';
import { useMap } from 'hooks/contexts/useMapContext';
import { useChangeSelectedTab, usePlots } from 'hooks/contexts/useTabsContext';
import useSyncNavigation from 'hooks/navigation/useSyncNavigation';
export default () => {
    const plots = usePlots();
    const changeSelectedTab = useChangeSelectedTab();
    const syncNavigation = useSyncNavigation();
    const map = useMap();
    return (tab) => () => {
        changeSelectedTab(tab.id);
        syncNavigation(tab);
        if (tab.type === PLOT_TAB_TYPE) {
            const plot = plots[tab.id];
            if (plot) {
                const coordinates = new google.maps.LatLng(parseFloat(plot.lat), parseFloat(plot.lng));
                map.setCenter(coordinates);
            }
        }
    };
};
