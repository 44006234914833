import * as React from 'react';
import { useCallback, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get } from 'api/client';
import DataCenterInfoWindow from 'components/infoWindow/DataCenterInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import DataCenterMarker from 'images/data_center_mrae.png';
import { IODataCenterMarkerItem } from 'types/api';
import { filtersKey, LAST_SIX_MONTHS } from 'utils/filters';
const fetchDataCenter = () => () => get(iots.array(IODataCenterMarkerItem), `data-center/project`);
const getLegends = () => {
    const legends = {};
    legends.data_center_poi = {
        layerKey: filtersKey.DATACENTER_PROJECT,
        label: 'datacenter_project',
        needsTranslate: true,
        image: DataCenterMarker,
        width: '23px',
        height: '27px',
    };
    return legends;
};
const formatInfoWindowContent = (info) => {
    const content = <DataCenterInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
export const isTimePeriodAllowed = (marker, timePeriod) => {
    if (!timePeriod) {
        return true;
    }
    const date = new Date(marker.date);
    if (timePeriod === LAST_SIX_MONTHS) {
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const monthDiff = diff / (1000 * 3600 * 24 * 30);
        return monthDiff <= 6;
    }
    return date.getFullYear() === timePeriod;
};
const useDatacenterProjectLayer = () => {
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const content = formatInfoWindowContent(marker);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const allowMarker = useCallback((marker) => {
        const date = extraFilters?.[filtersKey.DATACENTER_PROJECT]?.timePeriod ?? null;
        return isTimePeriodAllowed(marker, date);
    }, [extraFilters]);
    createLayersHook({
        fetch: fetchDataCenter,
        markerImg: DataCenterMarker,
        legends: getLegends(),
        getInfoWindowContent,
        allowLayer: allowMarker,
    })(filtersKey.DATACENTER_PROJECT);
};
const DatacenterProjectLayer = () => {
    useDatacenterProjectLayer();
    return null;
};
const DatacenterProjectWrapper = () => {
    return <DatacenterProjectLayer />;
};
export default DatacenterProjectWrapper;
