import { useContextSelector } from 'use-context-selector';
import SecondaryPlotsContext from 'context/SecondaryPlotsContext';
export const useSecondaryPlotCoord = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.secondaryPlotCoord);
};
export const useSetSecondaryPlotCoord = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.setSecondaryPlotCoord);
};
export const usePlotsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.plotsHighlights);
};
export const useContactPlotsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.contactPlotsHighlights);
};
export const usePlotSourcePostsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.sourcePostsPlotHighlights);
};
export const usePlotDistributionPostsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.distributionPostsPlotHighlights);
};
export const useTogglePlotsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => ({
        togglePlotsHighlights: state.togglePlotsHighlights,
        arePlotsHighlighted: state.arePlotsHighlighted,
    }));
};
export const useToggleContactPlotsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => ({
        addContactPlotsHighlights: state.addContactPlotsHighlights,
        toggleContactPlotsHighlights: state.toggleContactPlotsHighlights,
        areContactPlotsHighlighted: state.areContactPlotsHighlighted,
        clearContactPlotsHighlights: state.clearContactPlotsHighlights,
    }));
};
export const useTogglePlotSourcePostsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => ({
        togglePlotSourcePostsHighlights: state.togglePlotSourcePostsHighlights,
        arePlotSourcePostsHighlighted: state.arePlotSourcePostsHighlighted,
        clearPlotSourcePostsHighlights: state.clearPlotSourcePostsHighlights,
    }));
};
export const useTogglePlotDistributionPostsHighlights = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => ({
        togglePlotDistributionPostsHighlights: state.togglePlotDistributionPostsHighlights,
        arePlotDistributionPostsHighlighted: state.arePlotDistributionPostsHighlighted,
        clearPlotDistributionPostsHighlights: state.clearPlotDistributionPostsHighlights,
    }));
};
export const useSetFeasibilityParentId = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.setFeasibilityParentId);
};
export const useGetFeasibilityPlots = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.getFeasibilityPlots);
};
export const useGetFeasibilityAdditionalPlots = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.getFeasibilityAdditionalPlots);
};
export const useCloseOneFeasibilityPlot = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.closeOneFeasibilityPlot);
};
export const useIsLoadingFeasibilityPlot = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.isLoadingFeasibilityPlot);
};
export const useIsLoadingFeasibilityAdditionalPlot = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.isLoadingFeasibilityAdditionalPlot);
};
export const useClearParentFeasibility = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.clearAllPlotFeasibility);
};
export const useResetFeasibility = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.resetFeasibility);
};
export const useInitPlotFeasibility = () => {
    return useContextSelector(SecondaryPlotsContext, (state) => state.initPlotFeasibility);
};
