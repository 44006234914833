import { keyBy, mapValues } from 'lodash';
import pick from 'lodash/pick';
export const formatPlotCellValue = ({ cellValue, fieldName }) => {
    if (fieldName === 'address') {
        return cellValue ? cellValue[0] : '';
    }
    if (fieldName === 'zone' || fieldName === 'subZone') {
        return cellValue.slice(1, cellValue.length - 1).replace(';', ' ');
    }
    return cellValue;
};
const fields = [
    'prefix',
    'section',
    'plotNumber',
    'address',
    'postCode',
    'surface',
    'zone',
    'subZone',
    'lng',
    'lat',
];
export const formatPlotData = ({ towns, plot }) => {
    const town = towns[plot.townId]?.name;
    const prefix = plot.cadastreId.slice(5, 8);
    const plotExportData = {
        town,
        prefix,
        ...pick(plot, fields),
    };
    return [plotExportData];
};
export const formatPlotRulesData = ({ towns, plot, plotLayers }) => {
    const town = towns[plot.townId]?.name;
    const prefix = plot.cadastreId.slice(5, 8);
    const plotExportData = {
        town,
        prefix,
        ...pick(plot, fields),
        ...mapValues(keyBy(plotLayers, 'name'), 'affected'),
    };
    return [plotExportData];
};
