import * as React from 'react';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLegends = () => {
    const legends = {};
    legends.co_owner = {
        layerKey: filtersKey.CO_OWNER,
        label: 'co_owner',
        needsTranslate: true,
        color: '#C2951D',
    };
    return legends;
};
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_generale', 'carte_des_coproprietes', zoom, coord, tileWidth, tileHeight);
};
const useCoOwnerLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.CO_OWNER],
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.CO_OWNER);
};
const CoOwnerLayer = () => {
    useCoOwnerLayer();
    return null;
};
const CoOwnerWrapper = () => {
    return <CoOwnerLayer />;
};
export default CoOwnerWrapper;
