import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useIsAuthenticated } from 'hooks/contexts/useAuthContext';
import { IOFilterUpdateDate } from 'types/api';
const QUERY_KEY = ['filter-date'];
export const fetchFilterUpdateDate = () => {
    return get(iots.array(IOFilterUpdateDate), `filter/date`);
};
const useFiltersUpdateDate = () => {
    const isAuthenticated = useIsAuthenticated();
    const { data: filtersUpdateDate, isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: () => fetchFilterUpdateDate(),
        enabled: isAuthenticated,
    });
    return {
        filtersUpdateDate,
        isLoading,
    };
};
export default useFiltersUpdateDate;
