import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
export const IODistributionPostInfo = iots.type({
    id: iots.string,
    distance: iots.number,
    color: iots.string,
    swkt: iots.string,
    type: iots.string,
});
export const fetchDistributionPosts = (plotId) => () => get(iots.array(IODistributionPostInfo), `${plotId}/distribution-posts`);
export const QUERY_KEY = 'plot-distribution-post';
export const getQueryKey = (plotId) => [QUERY_KEY, plotId];
const usePlotDistributionPost = (plotId, enabled) => {
    const { data: distributionPosts, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(plotId),
        queryFn: fetchDistributionPosts(plotId),
        enabled: !!plotId && enabled,
    });
    return { distributionPosts, isLoading };
};
export default usePlotDistributionPost;
