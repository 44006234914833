import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, FormControl, FormErrorMessage, Icon, Input, Spinner, Text, VStack, } from '@chakra-ui/react';
import DownloadIcon from 'images/icons/Download';
import useAnalytics, { ANALYTICS_CRM } from '../../analytics/useAnalytics';
const UploadInputWithSubmit = ({ register, errors, accepted_file_format, analyticAction, isLoading = false, children, isMulti = false, }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    return (<FormControl isInvalid={!!errors?.files}>
      <Box borderRadius="6px" border="1px dashed" borderColor={!errors?.files ? 'colorMode.font700' : 'red.500'} _hover={{ borderColor: 'colorMode.font900' }}>
        {isLoading ? (<Center height="115px">
            <Spinner />
          </Center>) : (<>
            <Input {...register('files')} type="file" height="115px" accept={accepted_file_format} opacity="0" cursor="pointer" position="absolute" multiple={isMulti}/>

            <VStack justifyContent="center" alignItems="center" padding={6}>
              <Center backgroundColor="colorMode.base" width="40px" height="40px" borderRadius="32px">
                <Icon as={DownloadIcon} width={5} height={5} fill="currentColor" alignSelf="center"/>
              </Center>

              <Text textStyle="paragraph" color="colorMode.font700" fontWeight={500} onClick={() => {
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: analyticAction,
                });
            }}>
                {children}
              </Text>
              <FormErrorMessage>{t(errors?.files?.message)}</FormErrorMessage>
            </VStack>
          </>)}
      </Box>
    </FormControl>);
};
export default UploadInputWithSubmit;
