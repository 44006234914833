import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import { SirenLink } from 'components/links/SirenLink';
import { formatAddress } from 'utils/formatText';
const OwnerIdentity = ({ owner }) => {
    const { t } = useTranslation();
    return (<>
      <Text textStyle="paragraph" fontWeight={600}>
        {owner.name}
      </Text>
      {owner.siren && (<Text textStyle="paragraph">
          <SirenLink siren={owner.siren} analyticDetail="Pappers Propriétaires" showExternalLink/>
        </Text>)}

      <Text textStyle="small">{formatAddress(owner)}</Text>
      {owner.levels.length > 0 && (<Text textStyle="small">
          {owner.levels.length === 1 ? (owner.levels[0] === 0 ? (t('tabs.plots.section.owner.level_0')) : (t('tabs.plots.section.owner.level', { level: owner.levels[0] }))) : (<Text as="span">
              {owner.levels.map((level, index) => {
                    if (level === 0) {
                        return t('tabs.plots.section.owner.level_0');
                    }
                    else {
                        const comma = index !== 0 ? ', ' : '';
                        return (comma +
                            t('tabs.plots.section.owner.level', { level: level }));
                    }
                })}
            </Text>)}
        </Text>)}
    </>);
};
export default OwnerIdentity;
