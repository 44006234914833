import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';
const fields = [
    'activity',
    'funds',
    'personMorale',
    'personPhysic',
    'publicationDate',
    'quality',
    'siren',
    'town',
    'url',
];
export const getBusinessName = ({ fieldName, t }) => {
    return t('business.' + snakeCase(fieldName));
};
export const formatBusinessData = ({ business }) => {
    if (!business)
        return null;
    const businessExportData = {
        ...pick(business, fields),
    };
    return [businessExportData];
};
