import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOBuildingPermitNewMarketInfo, } from 'components/infoWindow/BuildingPermitNewMarketInfoWindow';
export const fetchBuildingPermit = (projectId) => () => get(iots.array(IOBuildingPermitNewMarketInfo), `${projectId}/building-permit`);
export const QUERY_KEY = 'project-building-permit';
export const getQueryKey = (projectId) => [QUERY_KEY, projectId];
const useProjectBuildingPermit = (projectId, enabled) => {
    const { data: buildingsPermits, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(projectId),
        queryFn: fetchBuildingPermit(projectId),
        enabled: !!projectId && enabled,
    });
    return { buildingsPermits, isLoading };
};
export default useProjectBuildingPermit;
