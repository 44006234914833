import * as React from 'react';
import { useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, HStack, VStack } from '@chakra-ui/react';
import { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import { useInView } from 'framer-motion';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useResetPlotSelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { useInitPlotFeasibility, useSetFeasibilityParentId, } from 'hooks/contexts/useSecondaryPlotsContext';
import { useSectionHash, useSelectedTab, useSetSectionHashToUrl, } from 'hooks/contexts/useTabsContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { FEASIBILITY_ADDITIONAL_PLOT_TAB, FEASIBILITY_PLOT_TAB, PLOT_PLOT_TAB, PLU_ADDITIONAL_REGULATION_PLOT_TAB, PLU_REGULATION_PLOT_TAB, } from 'types/api';
import { filtersKey } from 'utils/filters';
import { SECTIONS, TOOLBAR_HEIGHT } from 'utils/plots';
import { hasOnlyFeasibilityAdditional } from 'utils/town';
import PanelNavigation from '../TabsNavigation/PanelNavigation';
import PlotExport from './PlotExport';
const PlotPanelContent = ({ plot, isLoading, }) => {
    const scrollRef = useRef();
    const isInView = useInView(scrollRef);
    const sectionHash = useSectionHash();
    const setSectionHashToUrl = useSetSectionHashToUrl();
    const resetPlotSelectionMode = useResetPlotSelectionMode();
    const initPlotFeasibility = useInitPlotFeasibility();
    const selectedTown = useSelectedTown();
    const selectedTab = useSelectedTab();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const { hasPlotTab, hasMainLayersTree } = useSiteConfiguration();
    const allowedSections = Object.keys(SECTIONS).filter((section) => {
        switch (section) {
            case PLU_ADDITIONAL_REGULATION_PLOT_TAB:
                if (selectedTown?.additionalPLU) {
                    section = PLU_REGULATION_PLOT_TAB;
                }
                break;
            case FEASIBILITY_ADDITIONAL_PLOT_TAB:
                if (selectedTown?.hasAdditionalFeasibility) {
                    section = FEASIBILITY_PLOT_TAB;
                }
                break;
            case FEASIBILITY_PLOT_TAB:
                // Si pas faisibilite principal mais faisabilité secondaire alors on cas la faisaibilité
                if (hasOnlyFeasibilityAdditional(selectedTown)) {
                    return false;
                }
        }
        // Et si dans le main on a activer le zonage du PLU
        // Cas : Plot-plu désactiver et main plu activer => plot-plu avec seulement la zone (cf energie)
        // Car : Plot-plu désactiver et main plu désactiver => plot-plu invisible
        if (section === PLU_REGULATION_PLOT_TAB &&
            hasMainLayersTree(filtersKey.PLU)) {
            return true;
        }
        return hasPlotTab(section);
    });
    const toggleFilter = useToggleFilter();
    const [dvfEnabled, setDvfEnabled] = useState(false);
    useEffect(() => {
        return () => {
            if (dvfEnabled) {
                toggleFilter(filtersKey.OLD_MARKET_DVF, false);
            }
        };
    }, [dvfEnabled]);
    useEffect(() => {
        if (!allowedSections.includes(sectionHash)) {
            if (allowedSections.length) {
                setSectionHashToUrl(allowedSections[0]);
            }
            else {
                setSectionHashToUrl(PLOT_PLOT_TAB);
            }
        }
    }, [allowedSections]);
    useEffect(() => {
        resetPlotSelectionMode();
        initPlotFeasibility(plot);
    }, []);
    useEffect(() => {
        if (selectedTab && selectedTab === plot?.id) {
            setFeasibilityParentId(plot?.id);
        }
    }, [selectedTab]);
    const handleChangeSection = (section) => {
        setSectionHashToUrl(section);
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };
    useLayoutEffect(() => {
        if (isInView && allowedSections.includes(sectionHash)) {
            setSectionHashToUrl(sectionHash);
            if (scrollRef.current) {
                scrollRef.current.scrollTop = 0;
            }
        }
    }, [isInView]);
    const Component = SECTIONS[sectionHash] || undefined;
    const methods = useForm();
    return (<Box height="100%" width="100%">
      <FormProvider {...methods}>
        <VStack height="100%" spacing={0}>
          <HStack width="100%" justifyContent="space-between" bg="white">
            <PanelNavigation isLoading={isLoading} handleChangeSection={handleChangeSection} allowedSections={allowedSections} panelTitle="plots" trackEventCategory={ANALYTICS_PLOT_TAB}/>

            <PlotExport plot={plot} activeSection={sectionHash} disabled={isLoading}/>
          </HStack>
          <Box ref={scrollRef} height={`calc(100% - ${TOOLBAR_HEIGHT})`} overflowY="auto" width="100%" id="plot-panel-scrollable" opacity={isLoading ? 0.6 : 1}>
            {Component && (<Component setDvfEnabled={setDvfEnabled} plot={plot} isLoading={isLoading}/>)}
          </Box>
        </VStack>
      </FormProvider>
    </Box>);
};
export default PlotPanelContent;
