import * as React from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, Checkbox, FormControl, FormLabel, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MANAGER } from 'analytics/useAnalytics';
import useSharePlot from 'hooks/crm/useSharePlot';
import useCustomToast from 'hooks/useCustomToast';
const SharePlotModal = ({ plot }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { getInfoToast } = useCustomToast();
    const { shareableTeam, isLoading, sharePlot } = useSharePlot(plot.id);
    const { register, handleSubmit, formState: { isSubmitting }, reset, } = useForm({
        defaultValues: {
            members: [],
            copyContact: true,
            copyComment: true,
            copyStatus: true,
            copyDocument: true,
            copyPhoto: true,
        },
    });
    const onSubmit = (form) => {
        const membersSelected = form.members;
        if (membersSelected.length === 0 || !membersSelected) {
            getInfoToast({
                title: t('modals.share_plot.no_members_selected'),
                position: 'top',
                id: 'no_members_selected',
            });
            return;
        }
        trackEvent({
            category: ANALYTICS_MANAGER,
            action: `Partager une parcelle`,
        });
        sharePlot({
            plotId: plot.id,
            townshipId: plot.townId,
            users: membersSelected,
            copyContact: form.copyContact,
            copyComment: form.copyComment,
            copyStatus: form.copyStatus,
            copyDocument: form.copyDocument,
            copyPhoto: form.copyPhoto,
        });
        reset();
    };
    if (isLoading) {
        return <Spinner />;
    }
    if (shareableTeam?.available?.length === 0 &&
        shareableTeam?.unavailable?.length === 0) {
        return;
    }
    return (<>
      <Button variant="primary" onClick={onOpen}>
        {t('modals.share_plot.button_title')}
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h3" variant="h3">
              {t('modals.share_plot.title')}
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading || isSubmitting ? (<Spinner />) : (<VStack alignItems="start" spacing={4}>
                <Box>
                  <Heading as="h4" variant="h4">
                    {t('modals.share_plot.available_team_title')}
                  </Heading>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {shareableTeam?.available &&
                Object.values(shareableTeam?.available).map((team, index) => (<FormControl display="flex" key={index} paddingTop={1}>
                            <Checkbox {...register(`members`)} value={team.email}/>
                            <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                              {`${team.email} ${team.username}`}
                            </FormLabel>
                          </FormControl>))}
                    <Heading as="h4" variant="h4" marginTop={3}>
                      {t('modals.share_plot.what_to_share')} :
                    </Heading>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyStatus`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_plot.status')}
                      </FormLabel>
                    </FormControl>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyContact`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_plot.contact')}
                      </FormLabel>
                    </FormControl>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyComment`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_plot.comment')}
                      </FormLabel>
                    </FormControl>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyDocument`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_plot.document')}
                      </FormLabel>
                    </FormControl>
                    <FormControl display="flex" paddingTop={1}>
                      <Checkbox {...register(`copyPhoto`)}/>
                      <FormLabel margin="0" paddingLeft={3} cursor="pointer">
                        {t('modals.share_plot.photo')}
                      </FormLabel>
                    </FormControl>

                    <Button type="submit" variant="primary" marginY={4} size="sm" disabled={!shareableTeam?.available?.length}>
                      {t('modals.share_plot.button_submit')}
                    </Button>
                  </form>
                </Box>
                {shareableTeam?.unavailable && (<Box>
                    <Heading as="h4" variant="h4">
                      {t('modals.share_plot.unavailable_team_title')}
                    </Heading>
                    {shareableTeam.unavailable.map((member) => (<Text textStyle="paragraph" paddingTop={1} key={member.email}>
                        {`${member.email} ${member.username} `}{' '}
                        {t('modals.share_plot.shared_on', {
                        date: member.dateSharing,
                    })}
                      </Text>))}
                  </Box>)}
                <Text textStyle="paragraph">{t('modals.share_plot.help')}</Text>
              </VStack>)}
          </ModalBody>

          <ModalFooter>
            <Button variant="tertiary" onClick={onClose}>
              {t('modals.share_plot.button_close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>);
};
export default SharePlotModal;
