import * as React from 'react';
import { useCallback } from 'react';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const DVF_ZOOM_LIMIT = 12;
const useOldMarketDvfLayer = () => {
    const extraFilters = useExtraFilters();
    const year = extraFilters?.[filtersKey.OLD_MARKET_DVF]?.timePeriod || '';
    const getLegends = useCallback(() => {
        const legends = {};
        legends.old_market_dvf_built = {
            layerKey: filtersKey.OLD_MARKET_DVF,
            label: 'old_market',
            needsTranslate: true,
            color: '#58bffa',
        };
        return legends;
    }, []);
    const getLayerTileUrl = useCallback((map, tileWidth, tileHeight) => (coord, zoom) => {
        const normCoord = getNormalizedCoordinates(coord, zoom);
        if (!normCoord) {
            return null;
        }
        return getLayerBoundingTileUrlKelfoncier(map, 'carte_generale', year ? 'dvf_' + year : 'dvf_aggregation_2014_2024', zoom, coord, tileWidth, tileHeight);
    }, [year]);
    createTilesLayerHook({
        getLayerTileUrl: getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filtersKey.OLD_MARKET_DVF],
        tileWidth: 1024,
        tileHeight: 1024,
        refreshParam: year,
    })(filtersKey.OLD_MARKET_DVF);
};
const OldMarketDvfLayer = () => {
    useOldMarketDvfLayer();
    return null;
};
const OldMarketDvfWrapper = ({ computedZoom }) => {
    if (computedZoom < DVF_ZOOM_LIMIT) {
        return null;
    }
    return <OldMarketDvfLayer />;
};
export default OldMarketDvfWrapper;
