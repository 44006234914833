import * as React from 'react';
import { memo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import snakeCase from 'lodash/snakeCase';
import Table from 'components/table/Table';
import { DEATH_SEX_VALUES } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import { arrSearchLike, inDateRange } from 'utils/search';
export const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.death.columns.${column}`)}
    </Translation>
  </Text>);
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.death.columns.${snakeCase(header)}`);
const DeathList = ({ data, isLoading, exportFields, }) => {
    const { t } = useTranslation();
    const columns = [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'dateDeath',
                    header: () => getColumnHeader('dateDeath'),
                    cell: (info) => formatDate(info.getValue()),
                    filterFn: inDateRange,
                    enableGlobalFilter: false,
                },
                {
                    accessorKey: 'name',
                    header: () => getColumnHeader('name'),
                    accessorFn: (row) => {
                        if (!row.name)
                            return t('table.empty_column');
                        return row.name;
                    },
                    cell: ({ row }) => row.original.name,
                    filterFn: arrSearchLike,
                },
                {
                    accessorKey: 'firstname',
                    header: () => getColumnHeader('firstname'),
                    accessorFn: (row) => {
                        if (!row.firstname)
                            return t('table.empty_column');
                        return row.firstname;
                    },
                    cell: ({ row }) => row.original.firstname,
                    filterFn: arrSearchLike,
                },
                {
                    accessorKey: 'sex',
                    header: () => getColumnHeader('sex'),
                    accessorFn: (row) => {
                        return DEATH_SEX_VALUES[row.sex].label;
                    },
                    cell: ({ row }) => {
                        return DEATH_SEX_VALUES[row.original.sex].label;
                    },
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'townDeathDepartment',
                    header: () => getColumnHeader('townDeathDepartment'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'townDeath',
                    header: () => getColumnHeader('townDeath'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'age',
                    header: () => getColumnHeader('age'),
                    cell: (info) => info.getValue(),
                    filterFn: 'inNumberRange',
                },
            ],
        },
    ];
    return (<Table variant="striped" id="death" columns={columns} data={data || []} isLoading={isLoading} exportTitle={exportFields ? t('tabs.extra_tabs.death.title') : ''} exportedFields={exportFields} withExport={!!exportFields} formatExportHeader={formatExportHeader(t)}/>);
};
export default memo(DeathList);
