import pick from 'lodash/pick';
const fields = [
    'DPE',
    'GES',
    'address',
    'addressComplementBuilding',
    'addressComplementHousing',
    'ceilingHeight',
    'configInstallationHeating',
    'dateEstablishment',
    'descriptionECS',
    'energyTypeECS',
    'heatingEnergy',
    'inertiaClass',
    'installationHeating',
    'livingSurfaceBuilding',
    'livingSurfaceHousing',
    'nbApartment',
    'numberDPE',
    'periodConstruction',
    'positionHousingInBuilding',
    'postalCode',
    'residenceName',
    'stage',
    'staircase',
    'tertiarySurfaceBuilding',
    'typeBuilding',
    'typeECS',
    'typeHeating',
    'typologyHousing',
    'yearConstruction',
];
export const formatDPECellValue = ({ cellValue, fieldName }) => {
    switch (fieldName) {
        case 'ceilingHeight':
        case 'livingSurfaceBuilding':
        case 'livingSurfaceHousing':
        case 'tertiarySurfaceBuilding':
            return cellValue + ' m²';
        default:
            return cellValue;
    }
};
export const getDPEName = ({ fieldName, t }) => {
    return t('dpe.infos.' + fieldName);
};
export const formatDPEData = ({ dpe }) => {
    if (!dpe)
        return null;
    return dpe.map((info) => {
        return pick(info, fields);
    });
};
