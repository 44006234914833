import * as React from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown, useTownError, } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import { hideLayer, toggleLayer } from 'hooks/layers/createTilesLayerHook';
import useComputedCenter from 'hooks/map/useComputedCenter';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getNormalizedCoordinates } from 'utils/map';
import { isSwissSite } from 'utils/site';
export const CADASTRE_ZOOM_LIMIT = 16;
const CST_POS = 2;
const fetchInseeId = (townId, coord) => () => get(iots.string, `district/${encodeURIComponent(coord.x)}/${encodeURIComponent(coord.y)}`);
const MARSEILLE = '13055';
const PARIS = '75056';
const LYON = '69123';
const useCadastreLayer = () => {
    const filterName = filtersKey.CADASTRE;
    const filters = useFilters();
    const selectedTown = useSelectedTown();
    const map = useMap();
    const computedCenter = useComputedCenter();
    const townError = useTownError();
    const townId = townError
        ? JSON.parse(townError?.detail)?.townId
        : selectedTown?.id?.toString();
    const isCityWithDistricts = useMemo(() => [MARSEILLE, LYON, PARIS]?.includes(townId?.toString()), [townId]);
    const isEnabled = useMemo(() => filters[filterName] || false, [filters, filterName]);
    const { data: inseeId, isInitialLoading: isLoadingInseeId } = useQuery({
        queryKey: [
            'inseeId',
            { selectedTownId: townId, center: computedCenter?.toString() },
        ],
        queryFn: fetchInseeId(townId, {
            x: computedCenter?.lat(),
            y: computedCenter?.lng(),
        }),
        enabled: !!townId && !!computedCenter && !!isEnabled && !!isCityWithDistricts,
    });
    const getLayerTileUrl = useCallback((map, tileWidth, tileHeight) => (coord, zoom) => {
        const normCoord = getNormalizedCoordinates(coord, zoom);
        if (!normCoord) {
            return null;
        }
        const bbox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
        const id = inseeId ? inseeId : selectedTown?.inseeCode;
        if (isSwissSite()) {
            return ('https://wms3.geo.admin.ch/' +
                '.wms?service=WMS&request=GetMap&VERSION=1.3&CRS=EPSG:3857&BBOX=' +
                bbox +
                '&WIDTH=' +
                tileWidth +
                '&HEIGHT=' +
                tileHeight +
                '&STYLES=&FORMAT=image/png&LAYERS=ch.kantone.cadastralwebmap-farbe&TRANSPARENT=TRUE');
        }
        else {
            return ('https://inspire.cadastre.gouv.fr/scpc/' +
                id +
                '.wms?service=WMS&request=GetMap&VERSION=1.3&CRS=EPSG:3857&BBOX=' +
                bbox +
                '&WIDTH=' +
                tileWidth +
                '&HEIGHT=' +
                tileHeight +
                '&STYLES=&FORMAT=image/png&LAYERS=CP.CadastralParcel,BU.Building,BORNE_REPERE,HYDRO,DETAIL_TOPO,CLOTURE&TRANSPARENT=TRUE');
        }
    }, [townId, inseeId]);
    useEffect(() => {
        if (!map) {
            return;
        }
        if (isLoadingInseeId) {
            hideLayer(map, CST_POS);
            return;
        }
        toggleLayer(isEnabled ? [filterName] : [], map, getLayerTileUrl, CST_POS);
    }, [isEnabled, map, isLoadingInseeId]);
    // cleanup
    useEffect(() => {
        return () => {
            hideLayer(map, CST_POS);
        };
    }, []);
};
const CadastreLayer = () => {
    useCadastreLayer();
    return null;
};
const CadastreLayerWrapper = ({ computedZoom }) => {
    if (computedZoom < CADASTRE_ZOOM_LIMIT) {
        return null;
    }
    return <CadastreLayer />;
};
export default CadastreLayerWrapper;
