import * as React from 'react';
import { useParams } from 'react-router-dom';
import { useUpdatePlot } from 'hooks/contexts/useTabsContext';
import usePlotByCoordinates from 'hooks/plot/usePlotByCoordinates';
import PlotPanelContent from './PlotPanelContent';
import { getPlotSkeleton } from './PlotPannelSkeletons';
const PlotPanel = () => {
    const { lat, lng, townId, plotId } = useParams();
    const coordinates = new google.maps.LatLng(parseFloat(lat), parseFloat(lng));
    const updatePlot = useUpdatePlot();
    const { data: plotData, isInitialLoading: isPlotLoading } = usePlotByCoordinates({
        coordinates,
        enabled: Boolean(lat && lng && townId),
        townId: townId,
        plotId: plotId,
        successCallback: (data) => {
            updatePlot(data);
        },
    });
    const skeleton = getPlotSkeleton(true);
    if (isPlotLoading)
        return skeleton;
    if (!!plotData && !isPlotLoading)
        return <PlotPanelContent plot={plotData}/>;
    return null;
};
export default PlotPanel;
