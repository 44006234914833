import * as React from 'react';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_generale', 'urbanisme_de_reve', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.egnima = {
        layerKey: filtersKey.PLU_EASTER_EGG,
        label: 'plu_easter_egg_enigma',
        needsTranslate: true,
        color: '#91522D',
        opacity: 1,
    };
    return legends;
};
const usePLUEasterEggLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PLU_EASTER_EGG],
        getLegends,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.PLU_EASTER_EGG);
};
const PLUEasterEggLayer = () => {
    usePLUEasterEggLayer();
    return null;
};
const PLUEasterEggLayerWrapper = () => {
    return <PLUEasterEggLayer />;
};
export default PLUEasterEggLayerWrapper;
