import * as React from 'react';
import { Box, HStack, VStack } from '@chakra-ui/react';
import ProjectPlotsTable from 'components/tabs/crmTabs/project/ProjectPlotsTable';
import AddPlotForm from 'components/tabs/utils/AddPlotForm';
import AddPlotFromMap from 'components/tabs/utils/AddPlotFromMap';
import { PLOT_SELECTION_PROJECT } from 'context/LocationContext';
import { PROJECT_MODE } from 'context/PlotSearchContext';
import { SPACING_PANEL } from 'utils/constants';
const ProjectPlotsSection = ({ projectId, projectPlots, isLoadingPlots, removePlot, removePlotFromProjectLoading, currentDeletedProjectPlot, landPointId, isUserOwner = true, }) => {
    return (<VStack width="full" spacing={SPACING_PANEL}>
      {isUserOwner && (<HStack width="full" spacing={4} alignItems="stretch">
          <Box minWidth="220px" backgroundColor="colorMode.base" flex="1" padding={4}>
            <AddPlotFromMap mode={PLOT_SELECTION_PROJECT}/>
          </Box>
          <Box minWidth="220px" backgroundColor="colorMode.base" flex="2" padding={4}>
            <AddPlotForm mode={PROJECT_MODE} withLabel={false} submitText="tabs.project.add_plot"/>
          </Box>
        </HStack>)}

      <ProjectPlotsTable isUserOwner={isUserOwner} landPointId={landPointId} projectId={projectId} projectPlots={projectPlots} isLoading={isLoadingPlots} removePlot={removePlot} isDeletingPlot={removePlotFromProjectLoading} currentDeletedProjectPlot={currentDeletedProjectPlot}/>
    </VStack>);
};
export default ProjectPlotsSection;
