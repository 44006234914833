import * as React from 'react';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex, HStack, IconButton, Image, Link, Spinner, Stack, Text, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import UploadInputWithSubmit from 'components/inputs/UploadInputWithSubmit';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import { useSectionHash } from 'hooks/contexts/useTabsContext';
import DownloadIcon from 'images/icons/Download';
import { resolver } from './DocumentList';
import PhotoModal from './PhotoModal';
const ACCEPTED_PHOTO_FORMATS = '.gif,.jpg,.jpeg,.png';
const PhotoList = ({ projectId, plotId, photos, deletePhoto, addPhoto, photosFiles, isLoadingPhoto, isUserOwner = true, isLoadingUpload = false, }) => {
    const { trackEvent } = useAnalytics();
    const sectionHash = useSectionHash();
    const { t } = useTranslation();
    const defaultValues = { plotId: plotId, projectId: projectId, files: null };
    const [show, setShow] = React.useState(false);
    const [initialSlide, setInitialSlide] = React.useState(0);
    const { register, handleSubmit, formState: { errors }, watch, } = useForm({
        mode: 'onChange',
        defaultValues: defaultValues,
        resolver,
    });
    const onSubmit = (data) => addPhoto(data);
    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            if (name === 'files' && type === 'change') {
                return handleSubmit(onSubmit)();
            }
        });
        return () => subscription.unsubscribe();
    }, [watch]);
    return (<Stack width="full" spacing={3} data-cy="photo_list">
      {isUserOwner ? (<Box as="form">
          <UploadInputWithSubmit register={register} errors={errors} accepted_file_format={ACCEPTED_PHOTO_FORMATS} isLoading={isLoadingUpload} analyticAction={'Télécharger une photo' + sectionHash === 'my_crm'
                ? ''
                : ' fiche projet'} isMulti={true}>
            <Text as="span" color="red.500" marginRight={1}>
              {t('tabs.photo.download_file')}
            </Text>
            {t('tabs.photo.or_dnd_files')}
          </UploadInputWithSubmit>
        </Box>) : (photos?.length === 0 && (<Box textAlign="center">{t('global.no_photo')}</Box>))}

      {photos?.length > 0 && (<HStack alignItems="flex-start" flexWrap="wrap" spacing={3} shouldWrapChildren>
          {photos.map((photo, index) => {
                return isLoadingPhoto ? (<Box key={photo.id} height="120px" width="140px" display="flex" alignItems="center" justifyContent="center" border="1px solid gray" borderTopLeftRadius="lg" borderTopRightRadius="lg">
                <Spinner />
              </Box>) : (<Box key={photo.id}>
                <Box height="120px" width="140px" onClick={() => {
                        setInitialSlide(index);
                        setShow(true);
                    }}>
                  <Image src={photosFiles?.[photo.id]} alt={photo.name} height="100%" width="100%" objectFit="contain" border="1px solid gray" borderTopLeftRadius="lg" borderTopRightRadius="lg" cursor="pointer"/>
                </Box>
                <Stack backgroundColor="colorMode.base" borderBottomLeftRadius="lg" borderBottomRightRadius="lg" spacing={0}>
                  <Flex padding={2} justifyContent="space-between" alignItems="center">
                    <Text textStyle="small" maxWidth="90px" noOfLines={1} fontWeight={500} textOverflow="ellipsis" width="68px" data-cy="image_plot_name">
                      {photo.name}
                    </Text>
                    <HStack onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}>
                      <DeleteButton id="plot_image_delete" mode="icon" confirmText={photo.plotId
                        ? t('tabs.photo.delete_plot_confirm')
                        : t('tabs.photo.delete_confirm')} handleDelete={() => {
                        deletePhoto({ document: photo });
                        trackEvent({
                            category: ANALYTICS_CRM,
                            action: 'Supprimer une photo' + sectionHash === 'my_crm'
                                ? ''
                                : ' fiche projet',
                        });
                    }} size="xs"/>
                    </HStack>
                    <Link display="block" href={photosFiles?.[photo.id]} isExternal download={photo.name} data-cy="plot_crm_image_file" onClick={() => {
                        trackEvent({
                            category: ANALYTICS_CRM,
                            action: 'Télécharger une photo' + sectionHash === 'my_crm'
                                ? ''
                                : ' fiche projet',
                        });
                    }}>
                      <CustomTooltip label={t('global.actions.download')}>
                        <IconButton size="xs" variant="ghost" color="colorMode.revertBase" icon={<DownloadIcon fill="currentColor"/>} aria-label={photo.name}/>
                      </CustomTooltip>
                    </Link>
                  </Flex>
                  {projectId && photo.plotId != 0 && photo.plotNumber && (<Text textAlign="center" as="div" fontSize="11px" padding={1} backgroundColor="colorMode.background800" borderBottomLeftRadius="lg" borderBottomRightRadius="lg">
                      {photo.plotNumber}
                    </Text>)}
                </Stack>
              </Box>);
            })}
        </HStack>)}
      <PhotoModal photos={photos} photosFiles={photosFiles} initialSlide={initialSlide} show={show} setShow={setShow}/>
    </Stack>);
};
export default PhotoList;
