import * as React from 'react';
import useAnalytics, { ANALYTICS_MAP_STICKY } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import LegendAccordion from './LegendAccordion';
const BasicLegendItem = ({ filterKey, label, filtersConfig, legends, handleClose, withHandleClose = true, forceFilterActive = false, }) => {
    const { trackEvent } = useAnalytics();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    if (!isFilterActive(filterKey) && !forceFilterActive) {
        return null;
    }
    return (<LegendAccordion id={filterKey} legendLabel={label} handleClose={withHandleClose
            ? () => {
                trackEvent({
                    category: ANALYTICS_MAP_STICKY,
                    action: `${label} off légende`,
                });
                toggleFilter(filterKey, false);
                handleClose && handleClose();
            }
            : null} filtersConfig={filtersConfig} legends={legends}/>);
};
export default BasicLegendItem;
