import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormErrorMessage, HStack, Input, Text, VStack, } from '@chakra-ui/react';
import CountryField from 'components/modals/contactModal/CountryField';
import PostalCodeTownFields from 'components/modals/contactModal/PostalCodeTownFields';
const SenderForm = () => {
    const { t } = useTranslation();
    const { register, formState: { errors }, } = useFormContext();
    return (<VStack width="full" spacing={4} alignItems="flex-start">
      <FormControl isInvalid={!!errors?.civility}>
        <Input type="text" width="full" placeholder={`${t(`courier.fields.title.placeholder`)} *`} data-cy="sender_form_civility" {...register('civility')}/>
        <FormErrorMessage>
          {t(errors?.firstname?.message)}
        </FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.firstname}>
        <Input type="text" width="full" placeholder={`${t(`courier.fields.firstname.placeholder`)} *`} data-cy="sender_form_firstname" {...register('firstname')}/>
        <FormErrorMessage>{t(errors?.firstname?.message)}</FormErrorMessage>
      </FormControl>

      <FormControl isInvalid={!!errors?.lastname}>
        <Input type="text" width="full" placeholder={`${t(`courier.fields.lastname.placeholder`)} *`} data-cy="sender_form_lastname" {...register('lastname')}/>
        <FormErrorMessage>{t(errors?.lastname?.message)}</FormErrorMessage>
      </FormControl>

      <FormControl>
        <Input type="text" width="full" placeholder={t(`courier.fields.company.placeholder`)} {...register('company')}/>
      </FormControl>

      <FormControl>
        <Input type="text" width="full" placeholder={t(`courier.fields.address.placeholder`)} data-cy="sender_form_address" {...register('address')}/>
      </FormControl>

      <HStack width="full" alignItems="flex-start">
        <PostalCodeTownFields noLabel={true} required id="sender"/>
        <CountryField noLabel={true} id="sender" disableCountry={true}/>
      </HStack>

      <FormControl>
        <Input type="text" width="full" placeholder={t(`courier.fields.addressBP.placeholder`)} {...register('addressBP')}/>
      </FormControl>

      <FormControl>
        <Input type="text" width="full" placeholder={t(`courier.fields.addressComplement.placeholder`)} {...register('addressComplement')}/>
      </FormControl>

      <Text fontSize={12}>* {t(`courier.mandatory_field`)}</Text>
    </VStack>);
};
export default SenderForm;
