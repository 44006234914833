import { useMemo } from 'react';
import { useQueries } from '@tanstack/react-query';
import { useSelectedBuilding } from 'hooks/contexts/useTabsContext';
import { SELECTED_BUILDING_BORDER, } from 'hooks/plot/usePlotBuildings';
import { fetchProjectBuildings, getQueryKey } from './useProjectBuildings';
const useProjectsPlotsBuildings = (projects, enabled) => {
    const selectedBuilding = useSelectedBuilding();
    const results = useQueries({
        queries: Object.keys(projects).map((projectId) => {
            return {
                queryKey: getQueryKey(projectId),
                queryFn: fetchProjectBuildings(projectId),
                enabled: !!projectId && enabled,
            };
        }),
    });
    const buildingsIds = [];
    results.forEach((result) => {
        const data = (result.data || []);
        data.forEach((building) => {
            buildingsIds.push(building.buildingId);
        });
    });
    const isLoading = results.some((result) => result.isLoading);
    const data = useMemo(() => {
        const buildings = {};
        results.forEach((result) => {
            const data = result.data || [];
            data.forEach((building) => {
                buildings[building.buildingId] = {
                    ...building,
                    ...(building.buildingId === selectedBuilding
                        ? SELECTED_BUILDING_BORDER
                        : {}),
                };
            });
        });
        return buildings;
    }, [JSON.stringify(buildingsIds), selectedBuilding]);
    return { data, isLoading };
};
export default useProjectsPlotsBuildings;
