import { useMutation } from '@tanstack/react-query';
import { getFile } from 'api/client';
const generateBuildingConsumptionRequest = (data) => {
    return getFile(`building-consumption/${data.lat}/${data.lng}/radius/${data.radius}/generate`, null, { plotNumber: data.plotNumber }, 'post');
};
const useBuildingConsumption = () => {
    const { mutate: generateBuildingConsumption, isPending: isLoading } = useMutation({
        mutationFn: generateBuildingConsumptionRequest,
    });
    return {
        generateBuildingConsumption,
        isLoading,
    };
};
export default useBuildingConsumption;
