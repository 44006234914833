import * as React from 'react';
import { useState } from 'react';
import { Box, Button, Center, HStack, Link, Spinner, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_PUBLIPOSTAGE, } from 'analytics/useAnalytics';
import { t } from 'i18next';
import DeleteButton from 'components/buttons/DeleteButton';
import useImportPublipostage from 'hooks/import/useImportPublipostage';
import DownloadIcon from 'images/icons/Download';
import { DEFAULT_TEMPLATE_FILE_COURIER, FROM_COURIER } from 'utils/constants';
export const NB_TEMPLATE_MAX = 50;
const TemplateFilePublipostage = ({ templates, isFetching, handleFileData, showButtons = true, fromPanel = '', }) => {
    const { trackEvent } = useAnalytics();
    const { selectTemplatePublipostage, isSelectingTemplate, selectDefaultTemplatePublipostage, deleteTemplatePublipostage, isDeletingTemplate, } = useImportPublipostage();
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const onDelete = (id) => {
        setSelectedTemplate(id);
        deleteTemplatePublipostage(id, {
            onSuccess: () => {
                setSelectedTemplate(null);
            },
        });
    };
    const onSelect = (id) => {
        setSelectedTemplate(id);
        selectTemplatePublipostage(id, {
            onSuccess: (response) => {
                handleFileData(response);
                setSelectedTemplate(null);
            },
        });
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Selection template',
        });
    };
    const selectDefaultFile = () => {
        selectDefaultTemplatePublipostage(null, {
            onSuccess: (response) => {
                handleFileData(response);
                setSelectedTemplate(null);
            },
        });
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Selection template par défaut',
        });
    };
    return (<VStack alignItems="flex-start" justifyContent="flex-start" spacing={1} position="relative">
      {fromPanel !== FROM_COURIER && (<HStack justifyContent="flex-end" width="100%">
          <Box textAlign="right">
            <Button size="sm" variant="primary" onClick={selectDefaultFile}>
              {t('publipostage.actions.use_kelfoncier_file')}
            </Button>
            <Text fontSize="small" marginTop={2}>
              <Link variant="secondary" isExternal href={DEFAULT_TEMPLATE_FILE_COURIER} onClick={() => {
                trackEvent({
                    category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
                    action: 'Modèle word',
                });
            }} display="flex" alignItems="center">
                <DownloadIcon /> {t('publipostage.mailing_model')}
              </Link>
            </Text>
          </Box>
        </HStack>)}
      <Box w="full" maxHeight={fromPanel === FROM_COURIER ? '150px' : ''} overflowY={fromPanel === FROM_COURIER ? 'auto' : 'visible'}>
        {templates?.length > 0 && (<Box fontSize="13px" color="black" paddingTop={2} paddingBottom={1}>
            {t('publipostage.previous_file')}
          </Box>)}
        {isFetching ? (<Center width="full">
            <Spinner />
          </Center>) : (<VStack spacing="2px">
            {templates?.map((template) => (<Box width="full" key={template.id} backgroundColor="white" padding={1}>
                {isDeletingTemplate && selectedTemplate === template.id ? (<Center width="full">
                    <Spinner size="sm"/>
                  </Center>) : (<HStack justifyContent="space-between" width="full">
                    <Link variant="primary" href={template.pathAndFilename} target="_blank" fontSize="12px" textOverflow="ellipsis" overflow="hidden" width="100%" noOfLines={1}>
                      {template.title}
                    </Link>
                    {showButtons && (<HStack>
                        <DeleteButton size="sm" fontSize="12px" mode="icon" height={4} minWidth={4} handleDelete={() => onDelete(template.id)}/>
                        <Button size="xs" variant="outline" isLoading={selectedTemplate === template.id &&
                            isSelectingTemplate} loadingText={t('global.status.generation')} onClick={() => onSelect(template.id)} data-cy="import_previous_button" marginLeft={2} disabled={isSelectingTemplate}>
                          {t('global.actions.next')}
                        </Button>
                      </HStack>)}
                  </HStack>)}
              </Box>))}
          </VStack>)}
      </Box>
    </VStack>);
};
export default TemplateFilePublipostage;
