import * as React from 'react';
import { memo, useRef } from 'react';
import { Box, Center, HStack, Spinner } from '@chakra-ui/react';
import { ANALYTICS_PERSONALIZATION } from 'analytics/useAnalytics';
import PersonalizationPlotStatus from 'components/tabs/personalization/PersonalizationSections/PersonalisationPlotStatus';
import PanelNavigation from 'components/tabs/TabsNavigation/PanelNavigation';
import { useSelectedSection, useSetSelectedSection, } from 'hooks/contexts/useTabsContext';
import useUserPersonalization from 'hooks/personalization/useUserPersonalization';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { IOPersonalizationTab, PERSONALIZATION_PLOT_STATUS, PERSONALIZATION_PLOT_TAG, PERSONALIZATION_PROJECT_STATUS, } from 'types/api';
import { TOOLBAR_HEIGHT } from 'utils/plots';
import PersonalizationProjectStatus from './PersonalizationSections/PersonalisationProjectStatus';
import PersonalizationPlotTag from './PersonalizationSections/PersonalizationPlotTag';
const SECTIONS = {
    [PERSONALIZATION_PLOT_STATUS]: PersonalizationPlotStatus,
    [PERSONALIZATION_PROJECT_STATUS]: PersonalizationProjectStatus,
    [PERSONALIZATION_PLOT_TAG]: PersonalizationPlotTag,
};
const UserPersonalizationPanel = () => {
    const { userPersonalization, isLoading } = useUserPersonalization();
    const scrollRef = useRef();
    const { hasPersonalization } = useSiteConfiguration();
    const selectedSection = useSelectedSection();
    const setSelectedSection = useSetSelectedSection();
    const defaultSectionSelected = IOPersonalizationTab.is(selectedSection)
        ? selectedSection
        : PERSONALIZATION_PLOT_STATUS;
    const allowedSections = Object.keys(SECTIONS).filter((section) => {
        switch (section) {
            case PERSONALIZATION_PLOT_STATUS:
                return (hasPersonalization(PERSONALIZATION_PLOT_STATUS) &&
                    userPersonalization?.plotStatus);
            case PERSONALIZATION_PROJECT_STATUS:
                return (hasPersonalization(PERSONALIZATION_PROJECT_STATUS) &&
                    userPersonalization?.projectStatus);
            case PERSONALIZATION_PLOT_TAG:
                return hasPersonalization(PERSONALIZATION_PLOT_TAG);
            default:
                return false;
        }
    });
    const handleChangeSection = (section) => {
        setSelectedSection(section);
        if (scrollRef.current) {
            scrollRef.current.scrollTop = 0;
        }
    };
    const Component = SECTIONS[defaultSectionSelected] || undefined;
    if (isLoading) {
        return (<Center height="100%" width="100%">
        <Spinner size="lg"/>
      </Center>);
    }
    return (<>
      <HStack width="100%" justifyContent="space-between" bg="white">
        {Object.keys(SECTIONS).length > 1 && (<PanelNavigation isLoading={false} allowedSections={allowedSections} handleChangeSection={handleChangeSection} panelTitle="personalization" trackEventCategory={ANALYTICS_PERSONALIZATION} section={defaultSectionSelected}/>)}
      </HStack>
      <Box ref={scrollRef} height={`calc(100% - ${TOOLBAR_HEIGHT})`} overflowY="auto" width="100%">
        {isLoading ? (<Center height="100vh">
            <Spinner />
          </Center>) : (Component && <Component />)}
      </Box>
    </>);
};
export default memo(UserPersonalizationPanel);
