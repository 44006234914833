import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import moment from 'moment';
import { del, get, patch, post } from 'api/client';
import { useUpdateContactInfo } from 'hooks/contexts/useTabsContext';
import { QUERY_KEY as COURIER_QUERY_KEY } from 'hooks/courier/useCourier';
import { QUERY_KEY as CONTACT_LIST_QUERY_KEY } from 'hooks/crm/useContactList';
import { QUERY_KEY as PLOT_CONTACT_QUERY_KEY } from 'hooks/crm/usePlotContact';
import { QUERY_KEY as PLOT_HISTORY_QUERY_KEY } from 'hooks/crm/usePlotHistory';
import { QUERY_KEY as USER_PLOTS_QUERY_KEY } from 'hooks/crm/useUserPlotsList';
import { QUERY_KEY as USER_PROJECTS_QUERY_KEY } from 'hooks/crm/useUserProjectsList';
import { QUERY_KEY as DEATH_QUERY_KEY } from 'hooks/filter/useDeath';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOContact, IOEmpty } from 'types/api';
import { getPlotStatusQueryKey } from './usePlotStatus';
import { getProjectPlotQueryKey, getProjectQueryKey } from './useProject';
import { getProjectContactsQueryKey } from './useProjectContacts';
import { CONTACT_QUERY_KEY as USER_STATS_CRM_CONTACT_QUERY_KEY } from './useUserStatisticsCRM';
export const BUTTON_CONTACT_OWNER = 'owner';
export const BUTTON_CONTACT_OCCUPANT = 'occupant';
const fetchContact = (id) => () => get(IOContact, 'contact/' + id);
const createContactRequest = ({ data, townshipId }) => post(iots.type({
    newContactId: iots.string,
}), `legacy/contact/create`, { ...data, townshipId });
const updateContactRequest = (data) => post(IOEmpty, `legacy/contact/${data.id}/edit`, data);
const patchContactRequest = (data) => patch(IOEmpty, `contact/${data.id}`, data);
const deleteContactRequest = (contactId) => del(IOEmpty, `legacy/contact/${contactId}/delete`);
const deleteContactsRequest = (contactsIds) => post(IOEmpty, `legacy/contacts/delete`, {
    contactsIds: contactsIds.join(','),
});
const addNoteToContactRequest = ({ id, note }) => post(IOEmpty, `legacy/contact/${id}/add-note`, { note });
const deleteNoteFromContactRequest = ({ contactId, noteId }) => del(IOEmpty, `legacy/contact/${contactId}/delete-note/${noteId}`);
const updateNoteFromContactRequest = ({ contactId, noteId, note }) => patch(IOEmpty, `contact/${contactId}/note/${noteId}`, { note });
const addContactToProjectRequest = ({ contactId, projectId }) => post(IOEmpty, `project/${projectId}/contacts/${contactId}`);
const removeContactFromProjectRequest = ({ contactId, projectId }) => del(IOEmpty, `legacy/contact/${contactId}/remove-from-project/${projectId}`);
const addContactToPlotRequest = ({ contactId, plotId, fromProjectId = null, duplicateDataFromPlotId = null, }) => post(IOEmpty, `legacy/contact/${contactId}/add-to-plot/${plotId}`, {
    fromProjectId: fromProjectId,
    duplicateDataFromPlotId: duplicateDataFromPlotId,
});
const removeContactFromPlotsRequest = ({ contactId, plotIds, fromProjectId = null, }) => post(IOEmpty, `legacy/contact/${contactId}/remove-from-plot/` + plotIds.join(';'), { fromProjectId: fromProjectId });
export const CONTACT_QUERY_KEY = 'contact';
const useContact = (id, fetch = true) => {
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const { getSuccessToast } = useCustomToast();
    const updateContactInfo = useUpdateContactInfo();
    const onErrorCallback = useOnError();
    const onSuccessCallback = () => {
        queryClient.invalidateQueries({ queryKey: CONTACT_LIST_QUERY_KEY });
    };
    const invalidateCouriers = () => {
        queryClient.invalidateQueries({ queryKey: COURIER_QUERY_KEY });
    };
    const invalidateDeath = () => {
        queryClient.invalidateQueries({ queryKey: [DEATH_QUERY_KEY] });
    };
    const invalidateContact = (contactId) => {
        queryClient.invalidateQueries({ queryKey: [CONTACT_QUERY_KEY, contactId] });
    };
    const updateProjectUpdateDate = (projectId) => {
        const now = moment();
        const projectQueryKey = getProjectQueryKey(projectId);
        // Update date
        queryClient.setQueryData(projectQueryKey, (oldData) => {
            return { ...oldData, updatedAt: now.format('YYYY-MM-DD HH:mm:ss') };
        });
    };
    const { data: contact, isFetching: isFetchingContact } = useQuery({
        queryKey: [CONTACT_QUERY_KEY, id],
        queryFn: fetchContact(id),
        enabled: !!id && fetch,
    });
    const { mutate: addContact } = useMutation({
        mutationFn: createContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            onSuccessCallback();
            queryClient.invalidateQueries({ queryKey: [PLOT_CONTACT_QUERY_KEY] });
            getSuccessToast({
                title: t('tabs.contact.create_success'),
                id: 'create_contact',
            });
        },
    });
    const { mutate: updateContact } = useMutation({
        mutationFn: updateContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, variables) => {
            updateContactInfo({
                contactId: variables?.id,
                newInfo: variables,
            });
            getSuccessToast({
                title: t('tabs.contact.update_success'),
                id: 'modify_contact',
            });
            invalidateContact(variables?.id);
            invalidateCouriers();
            invalidateDeath();
            // Pour le filtre "Uniquement avec des actions" dans MyPlotsPanel
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            onSuccessCallback();
        },
    });
    const { mutate: patchContact } = useMutation({
        mutationFn: patchContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (_data, variables) => {
            getSuccessToast({
                title: t('tabs.contact.update_success'),
                id: 'modify_contact',
            });
            invalidateContact(variables?.id);
            invalidateCouriers();
            invalidateDeath();
            onSuccessCallback();
        },
    });
    const { mutate: deleteContact, isPending: isDeletingContact } = useMutation({
        mutationFn: deleteContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            onSuccessCallback();
            queryClient.invalidateQueries({ queryKey: [PLOT_CONTACT_QUERY_KEY] });
            getSuccessToast({
                title: t('tabs.contact.delete_success', {
                    count: 1,
                }),
                id: 'delete_contact',
            });
        },
    });
    const { mutate: deleteContacts, isPending: isDeletingContacts } = useMutation({
        mutationFn: deleteContactsRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            onSuccessCallback();
            queryClient.invalidateQueries({ queryKey: [PLOT_CONTACT_QUERY_KEY] });
            getSuccessToast({
                title: t('tabs.contact.delete_success', {
                    count: variables.length,
                }),
                id: 'delete_contacts',
            });
        },
    });
    const { mutate: addNoteToContact } = useMutation({
        mutationFn: addNoteToContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            invalidateContact(variables?.id);
            onSuccessCallback();
        },
    });
    const { mutate: deleteNoteFromContact } = useMutation({
        mutationFn: deleteNoteFromContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            invalidateContact(variables?.contactId);
            invalidateCouriers();
            onSuccessCallback();
        },
    });
    const { mutate: updateNoteFromContact } = useMutation({
        mutationFn: updateNoteFromContactRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            invalidateContact(variables?.contactId);
            onSuccessCallback();
        },
    });
    const { mutate: addContactToProject } = useMutation({
        mutationFn: addContactToProjectRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            onSuccessCallback();
            invalidateContact(variables?.contactId);
            updateProjectUpdateDate(variables.projectId);
            queryClient.invalidateQueries({
                queryKey: getProjectContactsQueryKey(variables.projectId),
            });
            queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
        },
    });
    const { mutate: removeContactFromProject, isPending: isRemovingContactFromProject, variables: removeContactFromProjectVariables, } = useMutation({
        mutationFn: removeContactFromProjectRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            onSuccessCallback();
            invalidateContact(variables?.contactId);
            updateProjectUpdateDate(variables.projectId);
            queryClient.invalidateQueries({
                queryKey: getProjectContactsQueryKey(variables.projectId),
            });
            queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
        },
    });
    const { mutate: addContactToPlot } = useMutation({
        mutationFn: addContactToPlotRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            invalidateContact(variables?.contactId);
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            queryClient.invalidateQueries({
                queryKey: [USER_STATS_CRM_CONTACT_QUERY_KEY],
            });
            queryClient.invalidateQueries({
                queryKey: [PLOT_CONTACT_QUERY_KEY, { plotId: variables.plotId }],
            });
            queryClient.invalidateQueries({
                queryKey: [PLOT_HISTORY_QUERY_KEY, { plotId: variables.plotId }],
            });
            queryClient.invalidateQueries({
                queryKey: getPlotStatusQueryKey(variables.plotId),
            });
            if (variables.townId && variables.plotProjectsIds) {
                variables.plotProjectsIds.forEach((projectId) => {
                    queryClient.invalidateQueries({
                        queryKey: getProjectContactsQueryKey(projectId),
                    });
                    updateProjectUpdateDate(projectId);
                });
                queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
            }
            if (variables.fromProjectId) {
                queryClient.invalidateQueries({
                    queryKey: getProjectPlotQueryKey(variables.fromProjectId),
                });
                updateProjectUpdateDate(variables.fromProjectId);
                queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
            }
            onSuccessCallback();
        },
    });
    const { mutate: removeContactFromPlots } = useMutation({
        mutationFn: removeContactFromPlotsRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            invalidateContact(variables?.contactId);
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            variables.plotIds.forEach((plotId) => {
                queryClient.invalidateQueries({
                    queryKey: [PLOT_CONTACT_QUERY_KEY, { plotId: plotId }],
                });
                queryClient.invalidateQueries({
                    queryKey: [PLOT_HISTORY_QUERY_KEY, { plotId: plotId }],
                });
            });
            if (variables.townId && variables.plotProjectsIds) {
                variables.plotProjectsIds.forEach((projectId) => {
                    queryClient.invalidateQueries({
                        queryKey: getProjectContactsQueryKey(projectId),
                    });
                    updateProjectUpdateDate(projectId);
                });
                queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
            }
            if (variables.fromProjectId) {
                queryClient.invalidateQueries({
                    queryKey: getProjectPlotQueryKey(variables.fromProjectId),
                });
                updateProjectUpdateDate(variables.fromProjectId);
                queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
            }
            onSuccessCallback();
        },
    });
    return {
        contact,
        isFetchingContact,
        updateContact,
        patchContact,
        addContact,
        deleteContact,
        deleteContacts,
        addNoteToContact,
        deleteNoteFromContact,
        updateNoteFromContact,
        addContactToProject,
        removeContactFromProject,
        removeContactFromProjectVariables,
        addContactToPlot,
        removeContactFromPlots,
        isDeletingContact,
        isDeletingContacts,
        isRemovingContactFromProject,
    };
};
export default useContact;
