import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_electricite', 'elec_htb_haute_tension_sous_terrain', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_htb_zero = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_zero',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#888888',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_45 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_45',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#000000',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_63 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_63',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#BF0054',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_90 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_90',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF8000',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_150 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_150',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#003DB0',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_225 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_225',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#009414',
        borderWidth: 5,
        opacity: 1,
    };
    legends.electrical_networks_htb_400 = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND,
        label: 'electrical_networks_htb_400',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#FF0000',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const useElectricalNetworksHTBUndergroundLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND],
        getLegends,
        opacity: 0.6,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND);
};
const ElectricalNetworksHTBUndergroundLayer = () => {
    useElectricalNetworksHTBUndergroundLayer();
    return null;
};
export default ElectricalNetworksHTBUndergroundLayer;
