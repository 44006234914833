import * as React from 'react';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, Button, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, ANALYTICS_CRM, ANALYTICS_LAND_POINT, ANALYTICS_TABLE, } from 'analytics/useAnalytics';
import * as iots from 'io-ts';
import { snakeCase } from 'lodash';
import DeleteButton from 'components/buttons/DeleteButton';
import { ExportItemButton } from 'components/buttons/ExportButton';
import ProjectButton from 'components/buttons/ProjectButton';
import SeeMapButton from 'components/buttons/SeeMapButton';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import ProjectStatusSelect from 'components/inputs/ProjectStatusSelect';
import Table from 'components/table/Table';
import TableExportButton from 'components/table/TableExportButton';
import SelectRows from 'components/tabs/utils/SelectRows';
import { CRM_TABS } from 'context/TabsContext';
import { useUserData } from 'hooks/contexts/useAuthContext';
import { useToggleCRMTab } from 'hooks/contexts/useTabsContext';
import useUserProjectsList from 'hooks/crm/useUserProjectsList';
import useExportPDF, { PROJECTS_CONTACTS_EXPORT_PDF } from 'hooks/useExportPDF';
import useExportXLSX, { PROJECTS_CONTACTS_EXPORT_XLSX, } from 'hooks/useExportXLSX';
import useLandPoint from 'hooks/useLandPoint';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { CONTACT_FILTER_WITHOUT_CONTACT, CONTACT_FILTERS_OPTIONS, getProjectDestinationFromCode, } from 'utils/constants';
import { contactsOptionsFiltration } from 'utils/contact';
import { calculateAndGetElapsedTime, formatDate } from 'utils/formatDate';
import { getProjectStatusLabel } from 'utils/personalization';
import { inDateRange } from 'utils/search';
export const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.user_projects.columns.${column}`)}
    </Translation>
  </Text>);
const EXPORTED_FIELDS = [
    'label',
    'town',
    'department',
    'status',
    'surface',
    'plotCount',
    'contactCount',
    'rightToBuild',
    'lots',
    'turnover', // chiffre d'affaire
    'destination',
    'modifiedAt',
];
const HEADER_COLORS = {
    label: '#F0FAF8',
    town: '#F0FAF8',
    department: '#F0FAF8',
    status: '#F0FAF8',
    surface: '#F0FAF8',
    modified_at: '#F0FAF8',
    last_update: '#F0FAF8',
    plot_count: '#F0FAF8',
    contact_count: '#F0FAF8',
    rightToBuild: '#F0FAF8',
    lots: '#F0FAF8',
    turnover: '#F0FAF8',
    destination: '#F0FAF8',
};
const IOProjectSelected = iots.type({
    projectId: iots.string,
    userEmail: iots.string,
});
const formatExportHeader = (t) => (header) => t(`tabs.user_projects.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'status') {
        return getProjectStatusLabel(value);
    }
    if (field === 'destination') {
        const formatDestination = [];
        if (value) {
            value.split(',').forEach((destination) => {
                formatDestination.push(getProjectDestinationFromCode(destination));
            });
        }
        return formatDestination.join(' ; ');
    }
    return value;
};
const MyProjectsPanel = () => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const { hasDashboardItem } = useSiteConfiguration();
    const { userProjects, isInitialLoading: isLoadingUserProjectList, removeProjects, } = useUserProjectsList();
    const toggleCRMTab = useToggleCRMTab();
    const userData = useUserData();
    const { createLandPoint, isCreating } = useLandPoint({});
    const exportXLSX = useExportXLSX();
    const exportPDF = useExportPDF();
    const [initialSelect, setInitialSelect] = useState(false);
    const [noProjectsSelected, setNoProjectsSelected] = useState(true);
    const [selectedProjects, setSelectedProjects] = useState({});
    const [projectsFiltered, setProjectsFiltered] = useState(userProjects);
    const selectContactInputRef = useRef(null);
    const handleRowSelection = (rows, isAllSelected, isNoneSelected) => {
        setNoProjectsSelected(isNoneSelected);
        setInitialSelect(false);
        const projects = {};
        rows.forEach((row) => {
            return (projects[row.id] = {
                userEmail: userData.email,
                projectId: row.id,
            });
        });
        setSelectedProjects(projects);
        if (isAllSelected) {
            setNoProjectsSelected(!isAllSelected);
            trackEvent({
                category: ANALYTICS_TABLE,
                action: 'Mes projets - Tout sélectionner/désélectionner ',
            });
        }
    };
    const projectsFiltration = (options) => {
        const optionsSelected = [];
        options.forEach((option) => {
            optionsSelected.push(option.value);
        });
        if (optionsSelected.length === 0) {
            return userProjects;
        }
        return userProjects.filter((project) => {
            const contacts = project.contacts.filter((contact) => {
                return contactsOptionsFiltration(optionsSelected, contact);
            });
            if (optionsSelected.includes(CONTACT_FILTER_WITHOUT_CONTACT)) {
                if (optionsSelected.length === 1) {
                    return contacts.length <= 0;
                }
                else {
                    return 0;
                }
            }
            return contacts.length > 0;
        });
    };
    useEffect(() => {
        if (!userProjects)
            return;
        const options = selectContactInputRef.current.getValue();
        setProjectsFiltered(projectsFiltration(options));
    }, [userProjects]);
    const onChangeContactFilter = (options) => {
        setProjectsFiltered(projectsFiltration(options));
        trackEvent({
            category: ANALYTICS_CRM,
            action: 'Mes projets filtre contact',
        });
    };
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'label',
                        header: ({ table }) => {
                            return (<>
                  <Stack direction="row">
                    <SelectRows {...{
                                checked: table
                                    .getFilteredRowModel()
                                    .rows.every((row) => row.getIsSelected()),
                                toggleRow: table.getToggleAllRowsSelectedHandler(),
                                setNoItemIsSelected: setNoProjectsSelected,
                            }}/>
                    <Text as="span" variant="primary">
                      {getColumnHeader('label')}
                    </Text>
                  </Stack>
                </>);
                        },
                        cell: (info) => {
                            const userProject = info.row.original;
                            return (<Stack direction="row">
                  <SelectRows {...{
                                checked: info.row.getIsSelected(),
                                disabled: !info.row.getCanSelect(),
                                toggleRow: info.row.getToggleSelectedHandler(),
                            }}/>
                  <ProjectButton data-cy="project_button" projectId={userProject.id} projectLabel={userProject.label} projectTownIds={userProject.townIds} lat={userProject.lat} lng={userProject.lng} analyticOrigin={ANALYTICS_CRM} analyticDetail={'Ouvrir fiche projet mes projets'} textAlign="left">
                    {info.getValue()}
                  </ProjectButton>
                </Stack>);
                        },
                        meta: {
                            minW: '150px',
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'town',
                        header: () => getColumnHeader('town'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'department',
                        header: () => getColumnHeader('department'),
                        cell: (info) => {
                            return <Box textAlign="center">{info.getValue()}</Box>;
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'status',
                        accessorFn: (row) => getProjectStatusLabel(row.status),
                        header: () => getColumnHeader('status'),
                        cell: (info) => {
                            return (<Box width={200}>
                  <ProjectStatusSelect townshipId={info.row.original.townId} projectId={info.row.original.id} status={info.row.original.status} plotIds={info.row.original.plotIds} onChange={() => {
                                    trackEvent({
                                        category: ANALYTICS_CRM,
                                        action: 'Statut du projet mes projets',
                                    });
                                }}/>
                </Box>);
                        },
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'surface',
                        header: () => getColumnHeader('surface'),
                        cell: (info) => info.getValue(),
                        filterFn: 'inNumberRange',
                    },
                    {
                        id: 'modified_at',
                        accessorKey: 'modifiedAt',
                        header: () => getColumnHeader('modified_at'),
                        cell: (info) => {
                            return (<Text textAlign="center">
                  {formatDate(info.getValue(), 'short')}
                </Text>);
                        },
                        enableGlobalFilter: false,
                        filterFn: inDateRange,
                    },
                    {
                        id: 'last_update',
                        accessorKey: 'modifiedAt',
                        header: () => getColumnHeader('last_update'),
                        cell: (info) => {
                            const date = info.getValue().split(' ')[0];
                            const now = new Date();
                            return (<Text fontWeight="bold">
                  {calculateAndGetElapsedTime(t, date, now, true)}
                </Text>);
                        },
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        id: 'plot_count',
                        accessorKey: 'plotCount',
                        header: () => getColumnHeader('plot_count'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        id: 'contact_count',
                        accessorKey: 'contactCount',
                        header: () => getColumnHeader('contact_count'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'rightToBuild',
                        header: () => getColumnHeader('rightToBuild'),
                        cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
                        filterFn: 'inNumberRange',
                        meta: {
                            minW: '85px',
                        },
                    },
                    {
                        accessorKey: 'lots',
                        header: () => getColumnHeader('lots'),
                        cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'turnover',
                        header: () => getColumnHeader('turnover'),
                        cell: (info) => (info.getValue() !== 0 ? info.getValue() : ''),
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'destination',
                        accessorFn: (row) => {
                            const formatDestination = [];
                            if (row.destination) {
                                row.destination.split(',').forEach((destination) => {
                                    formatDestination.push(getProjectDestinationFromCode(destination));
                                });
                            }
                            return formatDestination.join(' ; ');
                        },
                        header: () => getColumnHeader('destination'),
                        cell: (info) => info.getValue(),
                        filterFn: 'arrIncludesSome',
                    },
                ],
            },
        ];
    }, []);
    const handleClick = () => {
        if (Object.keys(selectedProjects).length) {
            const projects = Object.values(selectedProjects).map((project) => {
                return {
                    projectId: project.projectId,
                    participant: project.userEmail,
                };
            });
            trackEvent({
                category: ANALYTICS_LAND_POINT,
                action: "Création d'un point foncier dans mes projets",
            });
            createLandPoint(projects);
            toggleCRMTab({
                tabId: CRM_TABS.LAND_POINT,
            });
        }
    };
    const removeSelectedProjects = () => {
        if (Object.keys(selectedProjects).length) {
            removeProjects({
                projects: Object.values(selectedProjects).map((project) => {
                    return {
                        projectId: project.projectId,
                    };
                }),
            }, {
                onSuccess: () => {
                    setSelectedProjects({});
                    setInitialSelect(true);
                },
            });
        }
    };
    const exportContactsButtons = (<>
      <ExportItemButton key="export_project_contact_pdf" onExport={async () => {
            await exportPDF(PROJECTS_CONTACTS_EXPORT_PDF, {}, 'post', {
                projectIds: Object.values(selectedProjects).map((p) => p.projectId),
            });
            trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: t('tabs.user_projects.export_contact_action') + ' PDF',
            });
        }} id="export_project_contact_pdf">
        {t('table.export_pdf')}
      </ExportItemButton>
      <ExportItemButton key="export_project_contact_excel" onExport={async () => {
            await exportXLSX(PROJECTS_CONTACTS_EXPORT_XLSX, {}, 'post', {
                projectIds: Object.values(selectedProjects).map((p) => p.projectId),
            });
            trackEvent({
                category: ANALYTICS_CATEGORY_DOWNLOAD,
                action: t('tabs.user_projects.export_contact_action') + ' Excel',
            });
        }} id="export_project_contact_excel">
        {t('table.export_xlsx')}
      </ExportItemButton>
    </>);
    return (<Stack marginX={5} height="100%">
      <Table id="table-projects" variant="striped" initialSelect={initialSelect} initialPagination={{
            pageIndex: 0,
            pageSize: 20,
        }} data={projectsFiltered || []} headerTitle={<Stack spacing={2} direction={'row'} flexWrap="wrap">
            <Box width="250px">
              <Select ref={selectContactInputRef} placeholder={t('tabs.project.contact.filters.placeholder')} closeMenuOnSelect={false} isMulti options={CONTACT_FILTERS_OPTIONS ?? []} styles={multiSelectStyles} isLoading={isLoadingUserProjectList} onChange={onChangeContactFilter} loadingMessage={() => t('global.loading')} menuPlacement="top"/>
            </Box>
            <SeeMapButton action="Mes projets"/>
            {hasDashboardItem('landPoint') && (<Button size="sm" variant="primary" isDisabled={noProjectsSelected || isCreating} onClick={handleClick}>
                {t('tabs.user_projects.land_point_create', {
                    count: noProjectsSelected
                        ? 0
                        : Object.keys(selectedProjects).length,
                })}
              </Button>)}
            <DeleteButton id="my_plots_delete_button" size="sm" variant="outlineDelete" handleDelete={removeSelectedProjects} disabled={noProjectsSelected} showIcon confirmText={t('tabs.user_projects.remove_selection_confirm', {
                count: Object.keys(selectedProjects)?.length,
            })} label={t('tabs.user_projects.remove_selection', {
                count: !noProjectsSelected
                    ? Object.keys(selectedProjects).length
                    : 0,
            })}/>
          </Stack>} handleRowSelection={handleRowSelection} columns={columns || []} isLoading={isLoadingUserProjectList} backgroundColorHeader={HEADER_COLORS} exportTitle={t('tabs.user_projects.export_title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} initialSort={[{ id: 'modified_at', desc: true }]} analytics={{
            getColumnName: (id) => t(`tabs.user_projects.columns.${id}`),
            tableName: t('tabs.extra_tabs.user_projects.tab_title'),
        }} exportButtonTitle={t('sidebar.search_projects.count_download_results', {
            count: Object.values(selectedProjects).length || 0,
        })} footerExtend={<TableExportButton exportItems={exportContactsButtons} exportButtonTitle={t('tabs.user_projects.export_contact_button')} isLoading={isLoadingUserProjectList} analyticsAction={'Exportation les contacts des projets'} dataFiltered={Object.values(selectedProjects)}/>}/>
    </Stack>);
};
export default memo(MyProjectsPanel);
