import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IORPGsInfo } from 'types/api';
export const fetchProjectRPG = (projectId) => () => get(iots.array(IORPGsInfo), `${projectId}/project-rpg`);
export const QUERY_KEY = 'project-rpg';
export const getQueryKey = (projectId) => [QUERY_KEY, projectId];
const useProjectRPG = (projectId, enabled) => {
    const { data: rpg, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(projectId),
        queryFn: fetchProjectRPG(projectId),
        enabled: !!projectId && enabled,
    });
    return { rpg, isLoading };
};
export default useProjectRPG;
