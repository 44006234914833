import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Box, Skeleton, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PERSONALIZATION, ANALYTICS_PLOT_TAB, } from 'analytics/useAnalytics';
import PlotButton from 'components/buttons/PlotButton';
import { menuItems } from 'components/sidebar/utils';
import PlotStatus from 'components/tabs/plots/PlotSections/info/PlotStatus';
import { useClearPlotSummary, useIsLoadingPlotSummary, usePlotSummary, } from 'hooks/contexts/useLegendContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useToggleMenu } from 'hooks/contexts/useMenuContext';
import useUserSettings from 'hooks/personalization/useUserSettings';
import { OPEN_PLOT_ONE_CLICK, OPEN_PLOT_TWO_CLICK } from 'types/api';
import { FROM_PLOT_SUMMARY } from 'utils/constants';
import LegendAccordion from '../LegendAccordion';
import PlotSummaryInfo from './PlotSummaryInfo';
import PlotSummaryProjects from './PlotSummaryProjects';
const PlotSummarySkeleton = () => {
    return (<VStack alignItems="flex-start">
      <Skeleton height="20px" width="60%"/>
      <Skeleton height="10px" width="80%"/>
      <Skeleton height="10px" width="40%"/>
      <Skeleton height="10px" width="80%"/>
      <Skeleton height="30px" width="100%"/>
    </VStack>);
};
const PlotSummaryLegendItem = () => {
    const { t } = useTranslation();
    const clearPlotSummary = useClearPlotSummary();
    const plotSummary = usePlotSummary();
    const { trackEvent } = useAnalytics();
    const isLoadingPlotSummary = useIsLoadingPlotSummary();
    const toggleMenu = useToggleMenu();
    const selectedTown = useSelectedTown();
    const { patchUserSettings } = useUserSettings();
    const { userSettings } = useUserSettings();
    const handleClick = () => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Ouverture en 1 clic',
        });
        patchUserSettings({ openPlot: OPEN_PLOT_ONE_CLICK });
    };
    if (plotSummary || isLoadingPlotSummary)
        return (<Accordion defaultIndex={[0]} allowMultiple borderColor="transparent" backgroundColor="colorMode.background900">
        <LegendAccordion id="plot_legend" handleClose={() => {
                clearPlotSummary();
            }} legendLabel={t('legends.my_plot')} isLoading={isLoadingPlotSummary} skeleton={<PlotSummarySkeleton />} borderColor="transparent">
          <>
            {!!plotSummary && (<VStack alignItems="flex-start" spacing={4} data-cy="plot_legend_summary">
                <PlotSummaryInfo />

                <VStack alignItems="flex-start" spacing={2} width="100%">
                  <PlotStatus plotId={plotSummary?.id}/>

                  <PlotSummaryProjects plot={plotSummary}/>
                </VStack>

                <PlotButton lat={plotSummary.lat} lng={plotSummary.lng} townId={plotSummary.townId} plotId={plotSummary.id} variant="outline" width="100%" onOpenPlot={() => {
                    if (plotSummary.townId === selectedTown.id) {
                        toggleMenu(menuItems?.LAYERS_TREE, false);
                    }
                    trackEvent({
                        category: ANALYTICS_PLOT_TAB,
                        action: 'Voir la parcelle',
                    });
                }} from={FROM_PLOT_SUMMARY} id="plot_legend">
                  {t('legends.plot_info.see_plot')}
                </PlotButton>
                {userSettings?.openPlot === OPEN_PLOT_TWO_CLICK && (<Box onClick={handleClick} width="full" textAlign="center" cursor="pointer">
                    {t('legends.plot_info.open_plot_one_click')}
                  </Box>)}
              </VStack>)}
          </>
        </LegendAccordion>
      </Accordion>);
    return null;
};
export default React.memo(PlotSummaryLegendItem);
