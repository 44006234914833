import * as iots from 'io-ts';
import { get } from 'api/client';
import { latLngToTileValues } from 'utils/geo';
export const hasData = (response) => {
    const regex = /<body>[\n\r\s]*<\/body>/g;
    return response.match(regex) === null;
};
export const BASE_TILE_SIZE = 256;
const ID_REGEX = /<p>Identifiant : <strong>(FR)?\d+<\/strong><\/p>/gm;
const NATURA_URL_REGEX = /(?<start><p>.*)https?:\/\/inpn.mnhn.fr\/site\/natura2000\/(?<id>FR\d+)(?<end>.*<\/p>)/gm;
const NATURA_PDF_URL = 'https://inpn.mnhn.fr/docs/natura2000/fsdpdf/';
const NATURA_SUMMARY_URL = 'https://inpn.mnhn.fr/site/natura2000/';
const ZNIEFF_URL_REGEX = /(?<start><p>.*)https?:\/\/inpn.mnhn.fr\/zone\/znieff\/(?<id>\d+)(?<end>.*<\/p>)/gm;
const ZNIEFF_PDF_URL = 'https://inpn.mnhn.fr/docs/ZNIEFF/znieffpdf/';
const ZNIEFF_SUMMARY_URL = 'https://inpn.mnhn.fr/zone/znieff/';
export const getLayerCoordInfo = (layer, formatCallback, coords, map, t) => {
    const { tileRow, tileCol, i, j } = latLngToTileValues(coords, map, BASE_TILE_SIZE, BASE_TILE_SIZE);
    return get(iots.string, 'https://data.geopf.fr/wmts?' +
        '&SERVICE=WMTS&REQUEST=GetFeatureInfo&VERSION=1.0.0' +
        '&LAYER=' +
        layer +
        '&STYLE=' +
        layer +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&INFOFORMAT=' +
        encodeURIComponent('text/html') +
        '&TILEMATRIXSET=PM' +
        '&TILEMATRIX=' +
        map.getZoom() +
        '&TILEROW=' +
        tileRow +
        '&TILECOL=' +
        tileCol +
        '&I=' +
        i +
        '&J=' +
        j, {}, true, {}, false).then((response) => {
        return hasData(response) ? formatCallback(response, t) : '';
    });
};
export const formatNatura2000Info = (response, t) => {
    let info = response.replaceAll('NATURA', 'Natura');
    info = info.replace(ID_REGEX, '');
    info = info.replace(NATURA_URL_REGEX, (match, start, id, end) => {
        const pdfUrl = `${start}${NATURA_PDF_URL}${id}.pdf${end}`.replaceAll('Fiche technique', t('info_window.natura_2000.pdf'));
        const summaryUrl = `${start}${NATURA_SUMMARY_URL}${id}${end}`.replaceAll('Fiche technique', t('info_window.natura_2000.summary'));
        return pdfUrl + summaryUrl;
    });
    return info;
};
export const formatZnieffInfo = (response, t) => {
    let info = response.replace(ID_REGEX, '');
    info = info.replace(ZNIEFF_URL_REGEX, (match, start, id, end) => {
        const pdfUrl = `${start}${ZNIEFF_PDF_URL}${id}.pdf${end}`.replaceAll('Fiche technique', t('info_window.znieff.pdf'));
        const summaryUrl = `${start}${ZNIEFF_SUMMARY_URL}${id}${end}`.replaceAll('Fiche technique', t('info_window.znieff.summary'));
        return pdfUrl + summaryUrl;
    });
    return info;
};
