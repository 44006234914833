import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getNormalizedCoordinates } from 'utils/map';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.COMMERCIAL_LINEAR]: 'apur_lineaire_commercial',
    [filtersKey.URBAN_INTENSITY_PLACES]: 'apur_centralite',
};
export const COMMERCIAL_BUILDINGS_FILTERS = Object.keys(URL_KEYS);
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    const bbox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const urlParts = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_apur.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        urlParts.join(',') +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:3857&STYLES=&BBOX=' +
        bbox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.URBAN_INTENSITY_PLACES)) {
        legends.commercial_buildings_local = {
            layerKey: filtersKey.URBAN_INTENSITY_PLACES,
            label: 'commercial_buildings_local',
            needsTranslate: true,
            color: '#ffff00',
            opacity: 0.6,
        };
        legends.commercial_buildings_mixte = {
            layerKey: filtersKey.URBAN_INTENSITY_PLACES,
            label: 'commercial_buildings_mixte',
            needsTranslate: true,
            color: '#ff8700',
            opacity: 0.6,
        };
        legends.commercial_buildings_global = {
            layerKey: filtersKey.URBAN_INTENSITY_PLACES,
            label: 'commercial_buildings_global',
            needsTranslate: true,
            color: '#ff0000',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.COMMERCIAL_LINEAR)) {
        legends.commercial_linear = {
            layerKey: filtersKey.COMMERCIAL_LINEAR,
            label: 'commercial_linear',
            needsTranslate: true,
            color: '#7d7dff',
            polygonType: 'POLYLIGNE',
            borderWidth: 6,
            borderOpacity: 1,
        };
    }
    return legends;
};
const useCommercialBuildingsLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS.COMMERCIAL_BUILDINGS_FILTERS,
        getLegends,
    })(COMMERCIAL_BUILDINGS_FILTERS);
};
const CommercialBuildingsLayers = () => {
    useCommercialBuildingsLayers();
    return null;
};
export default CommercialBuildingsLayers;
