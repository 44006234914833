import seedRandom from 'seedrandom';
export const getRandomHexColor = (seed) => {
    const base = seed ? seedRandom(seed)() : Math.random();
    return ('#' +
        Math.floor(base * 16777215)
            .toString(16)
            .padStart(6, '0'));
};
// Fonction pour déterminer si la couleur est foncée ou claire
export const getContrastColor = (hex) => {
    // Convertir la couleur HEX en RGB
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);
    // Calculer la luminosité
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;
    return brightness > 128 ? '#333333' : 'white';
};
// Voir import {ColorService} from "react-color-palette";
/*
declare class ColorServiceStatic {
    convert<M extends keyof IColor, C extends IColor[M]>(model: M, color: C): IColor;
    toHex(value: string): IColor["hex"];
    toRgb(value: string): IColor["rgb"];
    toHsv(value: string): IColor["hsv"];
    hex2rgb(hex: IColor["hex"]): IColor["rgb"];
    rgb2hsv({ r, g, b, a }: IColor["rgb"]): IColor["hsv"];
    hsv2rgb({ h, s, v, a }: IColor["hsv"]): IColor["rgb"];
    rgb2hex({ r, g, b, a }: IColor["rgb"]): IColor["hex"];
}
declare const ColorService: ColorServiceStatic;
 */
