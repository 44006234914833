import * as React from 'react';
import { forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CloseButton, HStack, Text, Tooltip, useMultiStyleConfig, useTab, VStack, } from '@chakra-ui/react';
import { CRM_TABS, FILTER_TAB_TYPE, PLOT_TAB_TYPE, } from 'context/TabsContext';
import { useUpdateExtraFilter } from 'hooks/contexts/useFiltersContext';
import { useDeleteTab, useSelectedTab } from 'hooks/contexts/useTabsContext';
import useClickTab from 'hooks/navigation/useClickTab';
import { filtersKey } from 'utils/filters';
import CRMTabItem from './CRMTabItem';
import FilterResultTabItem, { FilterResultsTabKeys, } from './FilterResultsTabItem';
import PlotTabItem from './PlotTabItem';
export const TabItemTitle = ({ title, detail, }) => {
    return (<VStack alignItems="flex-start" spacing={0}>
      <Text textStyle="paragraph" color="colorMode.font900" fontWeight={700} textOverflow="ellipsis" noOfLines={1} data-cy="tab_title">
        {title}
      </Text>
      {detail && (<Text textStyle="paragraph" lineHeight="initial">
          {detail}
        </Text>)}
    </VStack>);
};
export const TabItem = ({ tab }) => {
    if (tab.type === PLOT_TAB_TYPE)
        return <PlotTabItem tab={tab}/>;
    if (tab.type === FILTER_TAB_TYPE && FilterResultsTabKeys?.includes(tab?.id))
        return <FilterResultTabItem tab={tab}/>;
    return <CRMTabItem tab={tab}/>;
};
const TabItemWrapper = forwardRef(({ id, tab, isLoading, children, tooltipContent, ...rest }, ref) => {
    const selectedTab = useSelectedTab();
    const tabProps = useTab({ ...rest, ref });
    const isSelected = selectedTab === tab?.id;
    const styles = useMultiStyleConfig('Tabs', tabProps);
    const deleteTab = useDeleteTab();
    const updateExtraFilter = useUpdateExtraFilter();
    const isDisabled = !isSelected && isLoading;
    const { t } = useTranslation();
    const onClickTab = useClickTab();
    const tabStyles = useMemo(() => ({
        ...styles.tab,
        borderColor: 'transparent!important',
        _hover: !isDisabled
            ? { backgroundColor: 'colorMode.background900' }
            : {},
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        paddingX: 2,
        paddingY: 1,
        height: '100%',
    }), [styles.tab, isDisabled]);
    if (isSelected) {
        tabStyles.backgroundColor = 'colorMode.background900';
    }
    else {
        tabStyles.backgroundColor = 'colorMode.background600';
    }
    const onClose = (e) => {
        e.preventDefault();
        e.stopPropagation();
        deleteTab(tab);
        if (tab.id === filtersKey.AGRICULTURE_REARING_COMPANY) {
            updateExtraFilter(`${filtersKey.AGRICULTURE_REARING_COMPANY}.codeAPE`, '');
        }
    };
    const dataCy = id ??
        'tab_' + (Object.values(CRM_TABS).includes(tab?.id) ? tab.id : tab.type);
    return (<HStack __css={tabStyles} {...tabProps} onClick={onClickTab(tab)} alignItems="center" boxShadow="inset -4px 0px 4px -4px rgb(0 0 0 / 20%)" borderTopRadius="md" justifyContent="space-between" minHeight="45px" data-cy={dataCy}>
        {tooltipContent ? (<Tooltip label={tooltipContent} placement="top">
            <Box>{children}</Box>
          </Tooltip>) : (children)}

        <CloseButton color="colorMode.font700" variant="link" size="sm" aria-label={t(`global.close`)} onClick={onClose} data-cy={dataCy + '_close'} disabled={isDisabled}/>
      </HStack>);
});
TabItemWrapper.displayName = 'TabItemWrapper';
export default TabItemWrapper;
