import * as React from 'react';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import CompactTabScroll from './CompactTabScroll';
import MultipleTabScroll from './MultipleTabScroll';
const TasbScroll = () => {
    const tabsView = useTabsView();
    if (tabsView === TABS_VIEW_HALF_VERTICAL)
        return <CompactTabScroll />;
    return <MultipleTabScroll />;
};
export default TasbScroll;
