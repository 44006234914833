import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { AccordionItem, Text } from '@chakra-ui/react';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { filtersKey, getFilterKey, lastNode, } from 'utils/filters';
const DEPTS_WITH_PREEMPTION_DATA = [
    '01',
    '06',
    '13',
    '21',
    '25',
    '26',
    '42',
    '51',
    '54',
    '59',
    '63',
    '67',
    '68',
    '74',
    '83',
    '91',
    '92',
    '93',
    '94',
    '95',
];
const DEPTS_WITH_NO_ARCHEOLOGICAL_DATA = ['08', '10', '51', '52', '59', '62'];
const MESSAGES = [
    {
        category: filtersKey.PPR_FLOODING,
        getMessageKey: () => 'ppr_flooding.no_ppr_flooding',
    },
    {
        category: filtersKey.PPR_NATURAL,
        layerPrefix: 'ppr_natural_layer',
        getMessageKey: () => 'ppr_natural.no_natural_ppr',
    },
    {
        category: filtersKey.PPR_NATURAL,
        layerPrefix: 'ppr_forest_fires_layer',
        getMessageKey: () => 'ppr_natural.no_forest_fires_ppr',
    },
    {
        category: filtersKey.PPR_GROUND_MOVEMENTS,
        layerPrefix: filtersKey.PPR_LANDSLIDE,
        getMessageKey: () => 'ppr_ground_movements.no_landslide_ppr',
    },
    {
        category: filtersKey.PPR_GROUND_MOVEMENTS,
        layerPrefix: 'ppr_ground_movements_clay',
        getMessageKey: () => 'ppr_ground_movements.no_ground_movements_clay_ppr',
    },
    {
        category: filtersKey.PPR_GROUND_MOVEMENTS,
        layerPrefix: 'ppr_ground_movements_earthquake',
        getMessageKey: () => 'ppr_ground_movements.no_ground_movements_earthquake_ppr',
    },
    {
        category: filtersKey.PPR_GROUND_MOVEMENTS,
        layerPrefix: 'ppr_ground_movements_other',
        getMessageKey: () => 'ppr_ground_movements.no_ground_movements_other_ppr',
    },
    {
        category: filtersKey.PPR_TECHNOLOGICAL,
        layerPrefix: 'ppr_technological_layer',
        getMessageKey: (selectedTown) => selectedTown?.docs.technologicalPPR
            ? 'ppr_technological.technological_ppr_not_available'
            : 'ppr_technological.no_technological_ppr',
    },
    {
        category: filtersKey.SCOT,
        layerPrefix: filtersKey.SCOT,
        getMessageKey: () => 'scot.no_scot',
    },
    {
        category: filtersKey.URBAN_DEVELOPMENT_PROJECTS,
        layerPrefix: filtersKey.ZAC,
        getMessageKey: () => 'urban_development_projects.no_zac',
    },
    {
        category: filtersKey.PREEMPTION_AND_EPF,
        layerPrefix: 'preemption',
        getMessageKey: (selectedTown) => {
            let id = selectedTown?.id?.toString();
            if (id?.length < 5) {
                id = `0${id}`;
            }
            const department = id?.slice(0, 2);
            return DEPTS_WITH_PREEMPTION_DATA.includes(department)
                ? 'preemption.no_preemption'
                : 'preemption.undefined';
        },
    },
    {
        category: filtersKey.ARCHEOLOGICAL_AREA,
        getMessageKey: (selectedTown) => {
            let id = selectedTown?.id?.toString();
            if (id?.length < 5) {
                id = '0' + id;
            }
            const dept = id?.substring(0, 2);
            return DEPTS_WITH_NO_ARCHEOLOGICAL_DATA.includes(dept)
                ? 'archeological_area.not_available'
                : 'archeological_area.no_data';
        },
    },
    {
        category: filtersKey.PATRIMONY, // ONLY_SWISS
        getMessageKey: () => 'patrimony.no_data',
    },
    {
        category: filtersKey.HEATING_NETWORK,
        getMessageKey: () => 'heating_network.not_available',
    },
    {
        category: filtersKey.NEW_MARKET_PRICES,
        getMessageKey: () => 'new_market_prices.no_data',
    },
    {
        category: filtersKey.WASTELAND,
        getMessageKey: () => 'wasteland.no_wasteland',
    },
    {
        category: filtersKey.SENIOR_AND_STUDENTS_RESIDENCES,
        layerPrefix: filtersKey.STUDENT_RESIDENCES,
        getMessageKey: () => 'senior_and_students_residences.no_student_residences',
    },
    {
        category: filtersKey.SENIOR_AND_STUDENTS_RESIDENCES,
        layerPrefix: filtersKey.SENIOR_RESIDENCES,
        getMessageKey: () => 'senior_and_students_residences.no_senior_residences',
    },
    {
        category: filtersKey.SHRINKAGE_SWELLING_OF_CLAYS,
        getMessageKey: () => 'shrinkage_swelling_of_clays.no_shrinkage',
    },
    {
        category: filtersKey.QUARRIES_AND_CLOSED_OPERATIONS,
        layerPrefix: filtersKey.STONE_QUARRY,
        getMessageKey: () => 'quarries_and_closed_operations.no_quarries',
    },
    {
        category: filtersKey.UNDERGROUND_CAVITIES,
        getMessageKey: () => 'underground_cavities.no_underground_cavities',
    },
    {
        category: filtersKey.AIRBORNE_NOISE,
        getMessageKey: () => 'airborne_noise.no_data',
    },
    {
        category: filtersKey.LIGHT_VAT_NEIGHBORHOODS,
        getMessageKey: () => 'light_vat_neighborhoods.no_data',
    },
    {
        category: filtersKey.PARKING_SPACE_EXEMPTION,
        getMessageKey: () => 'parking_space_exemption.no_station',
    },
    {
        category: filtersKey.CITY_PROGRAMS,
        layerPrefix: filtersKey.HEART_OF_TOWN,
        getMessageKey: () => 'city_programs.heart_of_town.no_data',
    },
    {
        category: filtersKey.CITY_PROGRAMS,
        layerPrefix: filtersKey.CITIES_OF_TOMORROW,
        getMessageKey: () => 'city_programs.cities_of_tomorrow.no_data',
    },
    {
        category: filtersKey.ORT,
        layerPrefix: filtersKey.ORT,
        getMessageKey: () => 'ort.no_data',
    },
    {
        category: filtersKey.NO_BUILDING_ALLOWED_ENVIRONMENTAL,
        layerPrefix: filtersKey.NO_BUILDING_ALLOWED_ENVIRONMENTAL,
        getMessageKey: () => 'no_data_2',
    },
    {
        category: filtersKey.NATURA_2000,
        layerPrefix: filtersKey.NATURA_2000,
        getMessageKey: () => 'no_data_2',
    },
];
const FilterCategoryEmptyMessage = ({ id, category, paddingTop = 0, paddingBottom = 0, paddingX = 0 }) => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const messages = MESSAGES.map((message) => {
        if (id === message.category) {
            const layers = lastNode(category)
                ? message.layerPrefix
                    ? category.layers?.filter((layer) => {
                        const layerKey = getFilterKey(layer);
                        return layerKey.startsWith(message.layerPrefix);
                    })
                    : category.layers || []
                : Object.keys(category);
            if (layers?.length === 0) {
                return message.getMessageKey(selectedTown);
            }
            return null;
        }
    }).filter((messageKey) => !!messageKey);
    return messages.length === 0 ? null : (<AccordionItem border="none" paddingTop={paddingTop} paddingBottom={paddingBottom} paddingX={paddingX}>
      {messages.map((messageKey) => (<Text backgroundColor="colorMode.sidebar500" textStyle="paragraph" key={messageKey}>
          {t(`sidebar.empty.${messageKey}`)}
        </Text>))}
    </AccordionItem>);
};
export default FilterCategoryEmptyMessage;
