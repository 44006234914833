import * as React from 'react';
import { FormProvider } from 'react-hook-form';
import { VStack } from '@chakra-ui/react';
import RealEstateTaxesDrawer from 'components/modals/realEstateTaxesModal/RealEstateTaxesDrawer';
import ProjectFeasibilityTable from 'components/tabs/crmTabs/project/ProjectFeasibilityTable';
import { computeAccommodationsNumber, computeFloorSpace, computeSocialAccommodationsNumber, } from 'components/tabs/plots/PlotSections/feasibility/calculator';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { FEASIBILITY_PLOT_TAB, PLOT_INFO_REAL_ESTATE_TAX, } from 'types/api';
const FeasibilitySection = ({ project, feasibilityPlots, feasibilityMainPlot, secondaryPlots, isInitialLoading, methods, }) => {
    const { hasPlotInfo, hasPlotTab } = useSiteConfiguration();
    const selectedTown = useSelectedTown();
    const { getValues } = methods;
    const sourceData = getValues();
    const socialAccommodationsNumber = computeSocialAccommodationsNumber({
        sourceData,
    });
    const numberMainResidence = computeAccommodationsNumber({ sourceData }) - socialAccommodationsNumber;
    const averageAccommodationSize = getValues('averageAccommodationSize');
    const socialHousingExceptPLAISurface = averageAccommodationSize * socialAccommodationsNumber;
    const realEstateTaxDefaultValues = {
        mainResidenceSurface: (computeFloorSpace({ sourceData }) - socialHousingExceptPLAISurface).toFixed(0),
        numberMainResidence,
        socialHousingExceptPLAISurface,
        numberSocialHousingExceptPLAI: socialAccommodationsNumber,
    };
    return project && hasPlotTab(FEASIBILITY_PLOT_TAB) ? (<>
      <FormProvider {...methods}>
        <ProjectFeasibilityTable feasibilityPlots={feasibilityPlots} feasibilityMainPlot={feasibilityMainPlot} secondaryPlots={secondaryPlots} isInitialLoading={isInitialLoading}/>
      </FormProvider>
      {hasPlotInfo(PLOT_INFO_REAL_ESTATE_TAX) && (<VStack backgroundColor="colorMode.base" padding={4} alignItems="start" borderRadius="lg" width="full" marginTop={2}>
          <RealEstateTaxesDrawer defaultValues={realEstateTaxDefaultValues} townName={selectedTown?.name} name={project?.label}/>
        </VStack>)}
    </>) : null;
};
export default FeasibilitySection;
