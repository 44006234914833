import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_electricite', 'elec_bt_basse_tension_aerien', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_bt_aerial = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL,
        label: 'electrical_networks_bt_aerial',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.polyline,
        color: '#65FA41',
        borderWidth: 5,
        opacity: 1,
    };
    return legends;
};
const useElectricalNetworksBTAerialLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL],
        getLegends,
        opacity: 0.8,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL);
};
const ElectricalNetworksBTAerialLayer = () => {
    useElectricalNetworksBTAerialLayer();
    return null;
};
export default ElectricalNetworksBTAerialLayer;
