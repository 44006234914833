import { useCallback } from 'react';
import { DEFAULT_CAPARESEAU_ISODISTANCE } from 'components/sidebarContent/ElectricNetworkCapareseauIsodistanceSidebarContent';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrlParams = (map, tileWidth, tileHeight, enabledFilters, extraFilters) => (coord, zoom) => {
    const isoDistance = (extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR]
        ?.isoDistance ?? DEFAULT_CAPARESEAU_ISODISTANCE) / 10;
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_electricite', 'iso_distance_postes_source_auto_' +
        isoDistance.toString().replace('.', '_') +
        'k', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_capareseau_car = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR,
        label: 'electrical_networks_capareseau_car',
        needsTranslate: true,
        color: '#5403FB',
        opacity: 0.6,
        borderColor: '#5403FB',
        borderWidth: 2,
    };
    return legends;
};
const useElectricalNetworkCapareseauCarLayers = () => {
    const extraFilters = useExtraFilters();
    const getLayerTileUrl = useCallback((map, tileWidth, tileHeight, enabledFilters) => getLayerTileUrlParams(map, tileWidth, tileHeight, enabledFilters, extraFilters), [extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR]?.isoDistance]);
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR],
        getLegends,
        refreshParam: extraFilters?.[filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR]
            ?.isoDistance || DEFAULT_CAPARESEAU_ISODISTANCE,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR);
};
const ElectricalNetworkCapareseauCarLayers = () => {
    useElectricalNetworkCapareseauCarLayers();
    return null;
};
export default ElectricalNetworkCapareseauCarLayers;
