import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as iots from 'io-ts';
import { get, post } from 'api/client';
import DPEInfoWindow, { IODPEInfo, } from 'components/infoWindow/DPEInfoWindow';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import useComputedBounds from 'hooks/map/useComputedBounds';
import DPEOldImg from 'images/dpe/dpe_old.png';
import DPE_A from 'images/dpe/marker_A.png';
import DPE_A_multi from 'images/dpe/marker_A_multi.png';
import DPE_B from 'images/dpe/marker_B.png';
import DPE_B_multi from 'images/dpe/marker_B_multi.png';
import DPE_C from 'images/dpe/marker_C.png';
import DPE_C_multi from 'images/dpe/marker_C_multi.png';
import DPE_D from 'images/dpe/marker_D.png';
import DPE_D_multi from 'images/dpe/marker_D_multi.png';
import DPE_E from 'images/dpe/marker_E.png';
import DPE_E_multi from 'images/dpe/marker_E_multi.png';
import DPE_F from 'images/dpe/marker_F.png';
import DPE_F_multi from 'images/dpe/marker_F_multi.png';
import DPE_G from 'images/dpe/marker_G.png';
import DPE_G_multi from 'images/dpe/marker_G_multi.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey, LAST_MONTH_AVAILABLE, LAST_SIX_MONTHS, } from 'utils/filters';
import { getBbox } from './POILayers';
export const IODPEMarkerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        id: iots.string,
        data: iots.array(iots.string),
        buildingType: iots.array(iots.string),
    }),
], 'IODPEMarkerItem');
const ID_DPE = 1;
const ID_GES = 2;
const fetchDPEOld = (townId, townScot, selectedTown, bbox) => () => get(iots.array(IODPEMarkerItem), `${townId}/DPE-old/${bbox}`);
const fetchDPEOldInfo = (item, filterDate, filterGES, filterDPE, filterBuilding) => {
    return post(iots.array(IODPEInfo), `DPE-old-info`, {
        ids: item.id,
        filterDate,
        filterGES,
        filterDPE,
        filterBuilding,
    });
};
export const formatInfoWindowContent = (info) => {
    const content = <DPEInfoWindow info={info}/>;
    return ReactDOMServer.renderToStaticMarkup(content);
};
export const isTimePeriodAndGESOrDPEAllowed = (marker, timePeriod, labelGES, labelDPE) => {
    if (labelGES.length === 0 && labelDPE.length === 0) {
        return isTimePeriodAllowed(marker, timePeriod);
    }
    let labelToCheck = [];
    let id = 0;
    if (labelGES.length !== 0) {
        if (!timePeriod || timePeriod.value === '') {
            return isGESAllowed(marker, labelGES);
        }
        labelToCheck = labelGES;
        id = ID_GES;
    }
    else if (labelDPE.length !== 0) {
        if (!timePeriod || timePeriod.value === '') {
            return isDPEAllowed(marker, labelDPE);
        }
        labelToCheck = labelDPE;
        id = ID_DPE;
    }
    const length = marker.data.length;
    let find = false;
    for (let i = 0; i < length; i++) {
        const dataTab = marker.data[i].split('|');
        if (labelToCheck.filter((label) => label.value === dataTab[id]).length === 0) {
            continue;
        }
        const date = new Date(dataTab[0]);
        find = !timePeriod ? true : checkTimePeriod(date, timePeriod.value);
        if (find) {
            return find;
        }
    }
    return find;
};
export const isTimePeriodAllowed = (marker, timePeriod) => {
    if (!timePeriod || timePeriod.value === '') {
        return true;
    }
    let find = false;
    const length = marker.data.length;
    for (let i = 0; i < length; i++) {
        const dataTab = marker.data[i].split('|');
        const date = new Date(dataTab[0]);
        find = checkTimePeriod(date, timePeriod.value);
        if (find) {
            return true;
        }
    }
    return false;
};
export const isGESAllowed = (marker, labelGES) => {
    if (labelGES.length === 0) {
        return true;
    }
    const length = marker.data.length;
    for (let i = 0; i < length; i++) {
        const dataTab = marker.data[i].split('|');
        if (labelGES.filter((label) => label.value === dataTab[ID_GES]).length > 0) {
            return true;
        }
    }
    return false;
};
export const isDPEAllowed = (marker, labelDPE) => {
    if (labelDPE.length === 0) {
        return true;
    }
    const length = marker.data.length;
    for (let i = 0; i < length; i++) {
        const dataTab = marker.data[i].split('|');
        if (labelDPE.filter((label) => label.value === dataTab[ID_DPE]).length > 0) {
            return true;
        }
    }
    return false;
};
const checkTimePeriod = (dateMarker, timePeriod) => {
    const regex = new RegExp(`${LAST_MONTH_AVAILABLE}_\\d{4}-\\d{2}-\\d{2}`);
    const date = new Date(dateMarker);
    if (timePeriod === LAST_SIX_MONTHS) {
        const now = new Date();
        const diff = now.getTime() - date.getTime();
        const monthDiff = diff / (1000 * 3600 * 24 * 30);
        return monthDiff <= 6;
    }
    else if (regex.test(timePeriod)) {
        const time = timePeriod.replace(`${LAST_MONTH_AVAILABLE}_`, '');
        const dateCheck = new Date(time);
        const firstDayLastMonth = new Date(dateCheck.getFullYear(), dateCheck.getMonth(), 1);
        return firstDayLastMonth.getTime() <= date.getTime();
    }
    else {
        return date.getFullYear() === parseInt(timePeriod);
    }
};
export const isBuildingTypeAllowed = (marker, buildingType) => {
    if (!buildingType || buildingType.value === '') {
        return true;
    }
    return marker.buildingType.includes(buildingType.value);
};
const LEGENDS = {
    dpe_old: {
        label: 'dpe',
        layerKey: filtersKey.DPE_OLD,
        needsTranslate: true,
        image: DPE_A,
    },
};
export const getMarkerImgDPE = (marker) => {
    const data = marker.data;
    const length = data.length;
    const types = [];
    for (let i = 0; i < length; i++) {
        const dpe = data[i].split('|');
        types.push(dpe[1]);
    }
    const uniqueTypes = [...new Set(types)];
    let type = 'all';
    if (uniqueTypes.length === 1) {
        type = uniqueTypes[0];
    }
    // La première date est la plus récente
    if (type === 'all') {
        const dpe_multi = data[0].split('|');
        type = dpe_multi[1] + '_multi';
    }
    switch (type) {
        case 'A':
            return DPE_A;
        case 'B':
            return DPE_B;
        case 'C':
            return DPE_C;
        case 'D':
            return DPE_D;
        case 'E':
            return DPE_E;
        case 'F':
            return DPE_F;
        case 'G':
            return DPE_G;
        case 'A_multi':
            return DPE_A_multi;
        case 'B_multi':
            return DPE_B_multi;
        case 'C_multi':
            return DPE_C_multi;
        case 'D_multi':
            return DPE_D_multi;
        case 'E_multi':
            return DPE_E_multi;
        case 'F_multi':
            return DPE_F_multi;
        case 'G_multi':
            return DPE_G_multi;
        default:
            return null;
    }
};
const getMarkerImg = (marker) => {
    const markerImg = getMarkerImgDPE(marker);
    if (markerImg === null) {
        return DPEOldImg;
    }
    return markerImg;
};
const useDPEOldLayer = () => {
    const computedBounds = useComputedBounds();
    const extraFilters = useExtraFilters();
    const [markerInfo, setMarkerInfo] = useState({});
    const date = extraFilters?.[filtersKey.DPE]?.timePeriod ?? null;
    const labelGES = extraFilters?.[filtersKey.DPE]?.labelGES ?? [];
    const labelDPE = extraFilters?.[filtersKey.DPE]?.labelDPE ?? [];
    const buildingType = extraFilters?.[filtersKey.DPE]?.buildingType ?? null;
    const allowMarker = useCallback((marker) => {
        return (isTimePeriodAndGESOrDPEAllowed(marker, date, labelGES, labelDPE) &&
            isBuildingTypeAllowed(marker, buildingType));
    }, [extraFilters]);
    const getInfoWindowContent = async (marker) => {
        if (markerInfo[marker.id]) {
            return markerInfo[marker.id];
        }
        const DPEInfo = await fetchDPEOldInfo(marker, date ? date.value : '', labelGES.map((label) => label.value), labelDPE.map((label) => label.value), buildingType ? buildingType.value : '');
        const content = formatInfoWindowContent(DPEInfo);
        setMarkerInfo({
            ...markerInfo,
            [marker.id]: content,
        });
        return content;
    };
    const bbox = useMemo(() => getBbox(computedBounds), [computedBounds]);
    createLayersHook({
        fetch: fetchDPEOld,
        markerImg: getMarkerImg,
        legends: LEGENDS,
        getInfoWindowContent,
        allowLayer: allowMarker,
        extraParams: [bbox],
        disableQuery: !bbox,
    })(filtersKey.DPE_OLD);
};
const DPEOldLayer = () => {
    useDPEOldLayer();
    return null;
};
export default DPEOldLayer;
