import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import { useExtraFilters, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { DPE_BUILDING_TYPE_OPTIONS, DPE_DPE_OPTIONS, DPE_GES_OPTIONS, } from 'utils/constants';
import { filtersKey, getTimePeriodLastMonthOptions, LAST_MONTH_AVAILABLE, LAST_SIX_MONTHS, OLDER_YEAR, } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
import { capitalizeFirstLetter } from 'utils/text';
const DPESidebarContent = ({ filterUpdateDate, }) => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const timePeriodSelectedOption = extraFilters?.[filtersKey.DPE]?.timePeriod || null;
    const updateDate = new Date(filterUpdateDate?.updateDate);
    const timePeriodDisplayOptions = getTimePeriodLastMonthOptions(2021, updateDate.getFullYear()).map((option) => {
        return {
            value: option === LAST_MONTH_AVAILABLE
                ? LAST_MONTH_AVAILABLE + '_' + filterUpdateDate?.updateDate
                : option,
            label: option === LAST_SIX_MONTHS
                ? t(`global.select.time_period.last_six_months`)
                : option === LAST_MONTH_AVAILABLE
                    ? capitalizeFirstLetter(formatDate(filterUpdateDate?.updateDate, 'shortMonthLetter'))
                    : option === OLDER_YEAR
                        ? `${option} ${t('global.select.time_period.and_before')}`
                        : option,
        };
    });
    timePeriodDisplayOptions.unshift({
        value: '',
        label: t('global.indifferent'),
    });
    const GESSelectedOption = extraFilters?.[filtersKey.DPE]?.labelGES || [];
    const GESDisplayOptions = DPE_GES_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const DPESelectedOption = extraFilters?.[filtersKey.DPE]?.labelDPE || [];
    const DPEDisplayOptions = DPE_DPE_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const buildingTypeSelectedOption = extraFilters?.[filtersKey.DPE]?.buildingType || '';
    const buildingTypeDisplayOptions = DPE_BUILDING_TYPE_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const handleTimePeriodChange = (inputText) => {
        updateExtraFilter(`${filtersKey.DPE}.timePeriod`, inputText);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('global.select.time_period.label')}`,
        });
    };
    const handleDPEChange = (inputText) => {
        updateExtraFilter(`${filtersKey.DPE}.labelDPE`, inputText);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('sidebar_content.dpe.dpe.label')}`,
        });
    };
    const handleGESChange = (inputText) => {
        updateExtraFilter(`${filtersKey.DPE}.labelGES`, inputText);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('sidebar_content.dpe.ges.label')}`,
        });
    };
    const handleBuildingTypeChange = (inputText) => {
        updateExtraFilter(`${filtersKey.DPE}.buildingType`, inputText);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.DPE}`)} ${t('sidebar_content.dpe.building_type.label')}`,
        });
    };
    const methods = useForm();
    return (<Box width="full">
      <FormProvider {...methods}>
        <form>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('global.select.time_period.label')}&nbsp;:
            </FormLabel>
            <Select placeholder={t('global.select.time_period.placeholder')} options={timePeriodDisplayOptions} onChange={handleTimePeriodChange} value={timePeriodSelectedOption} menuPortalTarget={document.body} styles={{
            ...multiSelectStyles,
            menuList: (provided) => ({
                ...provided,
                backgroundColor: 'var(--colorModeBase)',
                maxHeight: '150px',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (provided) => ({
                ...provided,
                fontSize: '12px',
                minHeight: '32px',
            }),
        }}/>
          </FormControl>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('sidebar_content.dpe.dpe.label')}&nbsp;:
            </FormLabel>
            <Select placeholder={t('global.indifferent')} options={DPEDisplayOptions} onChange={handleDPEChange} isMulti value={DPESelectedOption} menuPortalTarget={document.body} styles={{
            ...multiSelectStyles,
            menuList: (provided) => ({
                ...provided,
                backgroundColor: 'var(--colorModeBase)',
                maxHeight: '150px',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}/>
          </FormControl>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('sidebar_content.dpe.ges.label')}&nbsp;:
            </FormLabel>
            <Select placeholder={t('global.indifferent')} options={GESDisplayOptions} onChange={handleGESChange} isMulti value={GESSelectedOption} menuPortalTarget={document.body} styles={{
            ...multiSelectStyles,
            menuList: (provided) => ({
                ...provided,
                backgroundColor: 'var(--colorModeBase)',
                maxHeight: '150px',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}/>
          </FormControl>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
              {t('sidebar_content.dpe.building_type.label')}&nbsp;:
            </FormLabel>
            <Select placeholder={t('global.indifferent')} onChange={handleBuildingTypeChange} options={buildingTypeDisplayOptions} value={buildingTypeSelectedOption} menuPortalTarget={document.body} styles={{
            ...multiSelectStyles,
            menuList: (provided) => ({
                ...provided,
                backgroundColor: 'var(--colorModeBase)',
                maxHeight: '150px',
            }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            control: (provided) => ({
                ...provided,
                fontSize: '12px',
                minHeight: '32px',
            }),
        }}/>
          </FormControl>
        </form>
      </FormProvider>
    </Box>);
};
export default DPESidebarContent;
