import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_STICKY } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { filtersKey } from 'utils/filters';
import LegendAccordion from './LegendAccordion';
const PLUEasterEggLegendItem = ({ filtersConfig, legends, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    if (!isFilterActive(filtersKey.PLU_EASTER_EGG)) {
        return null;
    }
    return (<LegendAccordion id={filtersKey.PLU_EASTER_EGG} legendLabel={t('legends.plu_easter_egg')} handleClose={() => {
            trackEvent({
                category: ANALYTICS_MAP_STICKY,
                action: `${t('legends.plu_easter_egg')} off légende`,
            });
            toggleFilter(filtersKey.PLU_EASTER_EGG, false);
        }} filtersConfig={filtersConfig} legends={legends}>
      <Text textStyle="paragraph" dangerouslySetInnerHTML={{
            __html: t('legends.plu_easter_egg_enigma'),
        }}/>
    </LegendAccordion>);
};
export default PLUEasterEggLegendItem;
