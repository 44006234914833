import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Stack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import PlotsSearchForm from 'components/sidebar/PlotSearchSideBar/form/PlotsSearchForm';
import { useIsLoadingLayersTree, useMainLayers, } from 'hooks/contexts/useLayersTreeContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useCustomToast from 'hooks/useCustomToast';
import { PLOTS_SEARCH_DEFAUT_FORM_VALUES, } from 'utils/plotsSearch';
import PlotsSearchHeader from './PlotsSearchHeader';
const getValidationSchema = (t) => {
    const properties = {
        ownerSiren: yup
            .string()
            .test('len', t('sidebar.search_plots.error.siren_length'), (val) => {
            return val
                ? val?.toString().length === 9 &&
                    val?.toString().match(/^\d+$/) !== null
                : true;
        })
            .nullable(),
    };
    return yup.object().shape(properties);
};
const PlotSearchSideBar = ({ isOpen }) => {
    const { t } = useTranslation();
    const { getErrorToast } = useCustomToast();
    const isLoading = useIsLoadingLayersTree();
    const mainLayers = useMainLayers();
    const validationSchema = getValidationSchema(t);
    const methods = useForm({
        defaultValues: PLOTS_SEARCH_DEFAUT_FORM_VALUES,
        resolver: yupResolver(validationSchema),
    });
    const { formState: { errors }, } = methods;
    React.useEffect(() => {
        if (Object?.keys(errors)?.length) {
            getErrorToast({
                title: t('sidebar.search_plots.error.form_error'),
            });
        }
    }, [errors]);
    const selectedTown = useSelectedTown();
    React.useEffect(() => {
        if (selectedTown) {
            methods.reset(PLOTS_SEARCH_DEFAUT_FORM_VALUES);
        }
    }, [selectedTown]);
    if (!selectedTown) {
        return null;
    }
    return (<Stack height="100%" spacing="0" display={isOpen ? 'inherit' : 'none'}>
      <FormProvider {...methods}>
        <PlotsSearchHeader mainLayers={mainLayers} isLoading={isLoading}/>
        <Box overflowY="auto" height="100%" width="100%">
          <PlotsSearchForm />
        </Box>
      </FormProvider>
    </Stack>);
};
export default PlotSearchSideBar;
