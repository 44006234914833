import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get, put } from 'api/client';
import { useIsAuthenticated } from 'hooks/contexts/useAuthContext';
import { IOUserSettings } from 'types/api';
const QUERY_KEY = ['user-setttings'];
export const fetchSettings = () => {
    return get(IOUserSettings, `user/settings`);
};
const updateSettings = (settings) => {
    return put(null, `user/settings`, settings);
};
const useUserSettings = () => {
    const isAuthenticated = useIsAuthenticated();
    const queryClient = useQueryClient();
    const { data: userSettings, isLoading } = useQuery({
        queryKey: QUERY_KEY,
        queryFn: () => fetchSettings(),
        enabled: isAuthenticated,
    });
    const { mutate: updateUserSettings, isPending: isUpdating } = useMutation({
        mutationFn: updateSettings,
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey: QUERY_KEY });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: QUERY_KEY });
        },
    });
    const patchUserSettings = (settings) => {
        if (!isAuthenticated) {
            return;
        }
        updateUserSettings({
            ...(userSettings || {}),
            ...settings,
        });
    };
    return {
        userSettings,
        updateUserSettings,
        patchUserSettings,
        isLoading,
        isUpdating,
    };
};
export default useUserSettings;
