import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { HStack, Icon, Link, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_OPEN } from 'analytics/useAnalytics';
import LogoGoogleMap from 'images/logo/google_map.svg';
import LogoPageJaune from 'images/logo/pages_jaunes.png';
const getLinkWhitePage = (address) => {
    if (address && address !== 'undefined') {
        return `https://www.pagesjaunes.fr/pagesblanches/recherche?ou=${address}&univers=pagesblanches`;
    }
    else {
        return `https://www.pagesjaunes.fr/pagesblanches`;
    }
};
const getLinkGoogleMap = (address) => {
    if (address && address !== 'undefined') {
        return `https://www.google.com/maps/search/?api=1&query=${address}`;
    }
    else {
        return `https://www.google.fr/maps/preview`;
    }
};
const PlotOwnerPhysicalDetail = ({ plot, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const openAll = () => {
        trackEvent({
            category: ANALYTICS_OPEN,
            action: 'Tout ouvrir - Propriétaire',
        });
        document.getElementById('link_white_page').click();
        document.getElementById('link_google_map').click();
    };
    return (<HStack spacing={0}>
      <Text textStyle="paragraph">
        {t('tabs.plots.section.owner.physical_person')}
      </Text>
      <Icon as={ExternalLinkIcon} width="16px" height="16px" fill="red.500" color="red.500" marginRight="3px" marginLeft="7px" marginBottom="2px"/>
      <HStack spacing={1}>
        <Text textStyle="paragraph" color="red.500">
          {t('tabs.plots.section.owner.search_on')}
        </Text>
        <Link id="link_white_page" isExternal display="flex" variant="primary" href={getLinkWhitePage(encodeURI(plot.address[0]))} onClick={() => trackEvent({
            category: ANALYTICS_OPEN,
            action: 'Pages blanches - Propriétaire',
        })} style={{ marginRight: '3px' }}>
          <img width={16} src={LogoPageJaune} alt="Page Jaune" title="Page Jaune"/>
        </Link>

        <Link id="link_google_map" isExternal display="flex" variant="primary" href={getLinkGoogleMap(encodeURI(plot.address[0]))} onClick={() => trackEvent({
            category: ANALYTICS_OPEN,
            action: 'Google map - Propriétaire',
        })} style={{ marginRight: '3px' }}>
          <img width={12} src={LogoGoogleMap} alt="Google Maps" title="Google Maps"/>
        </Link>
        <Link isExternal display="flex" variant="primary" onClick={openAll}>
          {t('tabs.plots.section.owner.all')}
        </Link>
      </HStack>
    </HStack>);
};
export default PlotOwnerPhysicalDetail;
