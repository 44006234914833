import * as React from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Translation } from 'react-i18next';
import { Box, Checkbox, Heading, Text } from '@chakra-ui/react';
import { PlotDistributionPostsHighlightsSwitch } from 'components/buttons/PlotDistributionPostsHighlightsSwitch';
import Table from 'components/table/Table';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { useTogglePlotDistributionPostsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import usePlotDistributionPost from 'hooks/plot/usePlotDistributionPost';
import { filtersKey } from 'utils/filters';
import { formatNumberToLocale } from 'utils/formatNumber';
const getColumnHeaderDistribution = (column, suffix = null) => (<Text align="center" fontWeight="normal">
    <Translation>
      {(t) => t(`tabs.plots.section.energy.distribution_post.columns.${column}`)}
    </Translation>
    {suffix}
  </Text>);
const initialSort = [
    {
        id: 'distance',
        desc: false,
    },
];
const DistributionPostSection = ({ plotId, }) => {
    const { t, i18n } = useTranslation();
    const isFilterActive = useIsFilterActive();
    const toggleFilter = useToggleFilter();
    const { distributionPosts, isLoading: isLoadingDistribution } = usePlotDistributionPost(plotId, true);
    const { clearPlotDistributionPostsHighlights } = useTogglePlotDistributionPostsHighlights();
    const handleChange = (filterKey) => {
        toggleFilter(filterKey);
    };
    useEffect(() => {
        clearPlotDistributionPostsHighlights();
    }, [distributionPosts]);
    const columnsDistributionPost = [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'id',
                    header: () => getColumnHeaderDistribution('id'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'distance',
                    header: () => getColumnHeaderDistribution('distance'),
                    cell: (info) => formatNumberToLocale(info.getValue(), i18n.language),
                    filterFn: 'inNumberRange',
                },
                {
                    accessorKey: 'map',
                    header: () => getColumnHeaderDistribution('map'),
                    cell: (info) => (<PlotDistributionPostsHighlightsSwitch plotDistributionPostInfo={info.row.original}/>),
                    enableGlobalFilter: false,
                    enableColumnFilter: false,
                    enableSorting: false,
                },
            ],
        },
    ];
    return (<Box width="full">
      <Heading as="h4" variant="h4" marginBottom={1}>
        {t('tabs.plots.section.energy.distribution_post.title_panel')} :
      </Heading>
      <Checkbox key={filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT} data-id={filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT} isChecked={isFilterActive(filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT)} onChange={() => handleChange(filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
        <Text fontSize="14px">{t('sidebar.layers.poste-electrique')}</Text>
      </Checkbox>
      <Heading as="h4" variant="h4" marginTop={2} marginBottom={1} fontWeight="normal">
        {t('tabs.plots.section.energy.distribution_post.distribution_post_nearest')}{' '}
        :
      </Heading>
      <Table variant="simple" id="plots-distribution-posts" columns={columnsDistributionPost} data={distributionPosts || []} initialSort={initialSort} analyticsAction="Résultat de recherche" isLoading={isLoadingDistribution}/>
    </Box>);
};
export default DistributionPostSection;
