import * as React from 'react';
import { CheckIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { Button, Popover, PopoverContent, PopoverTrigger, useDisclosure, } from '@chakra-ui/react';
import { usePopoverOpenId, useSetPopoverOpenId, } from 'hooks/contexts/useMenuContext';
import { filtersKey } from 'utils/filters';
import { toolbarButtonsStyle } from './LayersButtons';
const PopoverMenu = ({ id, label, isActive, menuItems, showCheck, trigger = 'click', placement = 'bottom-end', children, selectDefault = () => ({}), handleClose = () => ({}), ...buttonProps }) => {
    const { onOpen, onClose } = useDisclosure();
    const popoverOpenId = usePopoverOpenId();
    const setPopoverOpenId = useSetPopoverOpenId();
    const handleCloseMenu = () => {
        handleClose();
        setPopoverOpenId(null);
        onClose();
    };
    return (<Popover isLazy placement={placement} trigger={trigger} isOpen={popoverOpenId === id} onOpen={onOpen} onClose={handleCloseMenu}>
      <PopoverTrigger>
        <Button {...toolbarButtonsStyle} backgroundColor={isActive ? 'colorMode.sidebar800' : 'colorMode.background900'} as={Button} rightIcon={<ChevronDownIcon />} data-cy={id} {...buttonProps} onClick={() => {
            selectDefault(filtersKey.CADASTRE);
            setPopoverOpenId(id);
        }}>
          {label}
        </Button>
      </PopoverTrigger>
      <PopoverContent width="auto">
        {menuItems?.map(({ label, isBold, isActive, handleClick }) => {
            return (<Button size="sm" key={label} borderRadius={0} leftIcon={showCheck ? (<CheckIcon color={isActive ? 'inherit' : 'transparent'} marginLeft="0.5rem"/>) : null} onClick={() => {
                    handleClick();
                    onClose();
                }} justifyContent="left" paddingLeft="0">
              {isBold ? <b>{label}</b> : label}
            </Button>);
        })}
        {children}
      </PopoverContent>
    </Popover>);
};
export default PopoverMenu;
