import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useExtraFilters, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { MRAE_TYPE_OPTIONS } from 'utils/constants';
import { filtersKey, getTimePeriodOptions, LAST_SIX_MONTHS, } from 'utils/filters';
const MRAESidebarContent = ({ filterUpdateDate, }) => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const timePeriodSelectedOption = extraFilters?.[filtersKey.MRAE]?.timePeriod || '';
    const updateDate = new Date(filterUpdateDate?.updateDate);
    const timePeriodDisplayOptions = getTimePeriodOptions(2022, updateDate.getFullYear()).map((option) => {
        return {
            value: option,
            label: option === LAST_SIX_MONTHS
                ? t(`global.select.time_period.last_six_months`)
                : option,
        };
    });
    const typeSelectedOption = extraFilters?.[filtersKey.MRAE]?.typeId || '0';
    const typeDisplayOptions = MRAE_TYPE_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const handleTimePeriodChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.MRAE}.timePeriod`, value === LAST_SIX_MONTHS ? value : parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.mrae`)} ${t('global.select.time_period.label')}`,
        });
    };
    const handleTypeChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.MRAE}.typeId`, parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.MRAE}`)} ${t(`sidebar_content.mrae.type.label`)}`,
        });
    };
    return (<Box width="full">
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('global.select.time_period.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('global.select.time_period.placeholder')} onChange={handleTimePeriodChange} value={timePeriodSelectedOption}>
          {timePeriodDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('sidebar_content.mrae.type.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('sidebar_content.mrae.type.placeholder')} onChange={handleTypeChange} value={typeSelectedOption}>
          {typeDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
    </Box>);
};
export default MRAESidebarContent;
