import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.WIND_BIRD_AVIFAUNA]: 'eolien_restriction_avifaune',
    [filtersKey.WIND_BIRD_BAT]: 'eolien_restriction_chiropteres',
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const urlParts = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_eolien_restriction.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        urlParts.join(',') +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:3857&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.WIND_BIRD_AVIFAUNA)) {
        legends.wind_avifauna_weak = {
            layerKey: filtersKey.WIND_BIRD_AVIFAUNA,
            label: 'wind_bird_weak',
            needsTranslate: true,
            color: '#FFFF00',
            opacity: 0.6,
        };
        legends.wind_avifauna_medium = {
            layerKey: filtersKey.WIND_BIRD_AVIFAUNA,
            label: 'wind_bird_medium',
            needsTranslate: true,
            color: '#FFC000',
            opacity: 0.6,
        };
        legends.wind_avifauna_strong = {
            layerKey: filtersKey.WIND_BIRD_AVIFAUNA,
            label: 'wind_bird_strong',
            needsTranslate: true,
            color: '#FF0000',
            opacity: 0.6,
        };
        legends.wind_avifauna_very_strong = {
            layerKey: filtersKey.WIND_BIRD_AVIFAUNA,
            label: 'wind_bird_very_strong',
            needsTranslate: true,
            color: '#A50021',
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.WIND_BIRD_BAT)) {
        legends.wind_bat_weak = {
            layerKey: filtersKey.WIND_BIRD_BAT,
            label: 'wind_bird_weak',
            needsTranslate: true,
            color: '#FFFF00',
            opacity: 0.6,
        };
        legends.wind_bat_medium = {
            layerKey: filtersKey.WIND_BIRD_BAT,
            label: 'wind_bird_medium',
            needsTranslate: true,
            color: '#FFC000',
            opacity: 0.6,
        };
        legends.wind_bat_strong = {
            layerKey: filtersKey.WIND_BIRD_BAT,
            label: 'wind_bird_strong',
            needsTranslate: true,
            color: '#FF0000',
            opacity: 0.6,
        };
        legends.wind_bat_very_strong = {
            layerKey: filtersKey.WIND_BIRD_BAT,
            label: 'wind_bird_very_strong',
            needsTranslate: true,
            color: '#A50021',
            opacity: 0.6,
        };
    }
    return legends;
};
export const WIND_BIRD_FILTERS = [
    filtersKey.WIND_BIRD_AVIFAUNA,
    filtersKey.WIND_BIRD_BAT,
];
const useWindBirdLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS.WIND_BIRD_FILTERS,
        getLegends,
    })(WIND_BIRD_FILTERS);
};
const WindBirdLayers = () => {
    useWindBirdLayers();
    return null;
};
export default WindBirdLayers;
