import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { IOBODACCBusinessInfo, } from 'components/infoWindow/BusinessInfoWindow';
export const fetchBusiness = (plotId) => () => get(IOBODACCBusinessInfo, `${plotId}/business`);
export const QUERY_KEY = 'plot-business';
export const getQueryKey = (plotId) => [QUERY_KEY, plotId];
const usePlotBusiness = (plotId, enabled) => {
    const { data: business, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(plotId),
        queryFn: fetchBusiness(plotId),
        enabled: !!plotId && enabled,
    });
    return { business, isLoading };
};
export default usePlotBusiness;
