import * as React from 'react';
import { Translation } from 'react-i18next';
import { Box, HStack, Spinner, Stack, Switch, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import { snakeCase } from 'lodash';
import ContactButton from 'components/buttons/ContactButton';
import PlotButton from 'components/buttons/PlotButton';
import Table from 'components/table/Table';
import useUserPlotsNews from 'hooks/news/useUserPlotsNews';
import { inDateRange } from 'utils/search';
const getColumnHeader = (column) => (<Text align="center">
    <Translation>{(t) => t(`tabs.plots_news.columns.${column}`)}</Translation>
  </Text>);
const getSign = (oldValue, newValue) => {
    return newValue > oldValue ? '+' : '';
};
const EXPORTED_FIELDS = [
    'township',
    'plotNumber',
    'modifiedAt',
    'newSZ',
    'newEMP',
    'newNIV',
    'newSDP',
    'newMIX',
];
const formatExportHeader = (t) => (header) => t(`tabs.plots_news.columns.${snakeCase(header)}`);
const COLUMNS = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'township',
                header: () => getColumnHeader('township'),
                accessorFn: (row) => {
                    if (!row.township)
                        return t('table.empty_column');
                    return row.township;
                },
                cell: (info) => {
                    return info.row.original.postalCode + ' ' + info.row.original.township;
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'plotNumber',
                header: () => getColumnHeader('plot_number'),
                accessorFn: (row) => {
                    if (!row.plotNumber)
                        return t('table.empty_column');
                    return row.plotNumber;
                },
                cell: (info) => {
                    return (<PlotButton lat={info.row.original.lat} lng={info.row.original.lng} townId={info.row.original.townId} variant="link-primary" plotId={info.row.original.plotId}>
              {info.row.original.plotNumber}
            </PlotButton>);
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'modifiedAt',
                header: () => getColumnHeader('modified_at'),
                cell: (info) => info.getValue(),
                filterFn: inDateRange,
            },
            {
                accessorKey: 'newSZ',
                accessorFn: (row) => {
                    if (row.oldSZ !== row.newSZ) {
                        return row.newSZ;
                    }
                    else {
                        return t('table.empty_column');
                    }
                },
                header: () => getColumnHeader('new_sz'),
                cell: (info) => {
                    if (info.row.original.oldSZ !== info.row.original.newSZ) {
                        return (<Box>
                {t('tabs.plots_news.sub_zone', {
                                oldSZ: info.row.original.oldSZ,
                                newSZ: info.row.original.newSZ,
                            })}
              </Box>);
                    }
                    else {
                        return <React.Fragment />;
                    }
                },
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'newEMP',
                header: () => getColumnHeader('new_emp'),
                cell: (info) => {
                    const oldEMP = info.row.original.oldEMP;
                    const newEMP = info.row.original.newEMP;
                    if (oldEMP !== newEMP) {
                        const sign = getSign(oldEMP, newEMP);
                        const diff = newEMP - oldEMP;
                        if (diff !== 0) {
                            return (<Box textAlign="left">
                  {t('tabs.plots_news.hold_allowed', {
                                    sign,
                                    diff,
                                    oldEMP,
                                    newEMP,
                                    count: Math.abs(diff),
                                })}
                </Box>);
                        }
                    }
                    else {
                        return <React.Fragment />;
                    }
                },
                enableGlobalFilter: false,
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'newNIV',
                header: () => getColumnHeader('new_niv'),
                cell: (info) => {
                    const oldNIV = info.row.original.oldNIV;
                    const newNIV = info.row.original.newNIV;
                    if (oldNIV !== newNIV) {
                        const sign = getSign(oldNIV, newNIV);
                        const diff = Math.round((newNIV - oldNIV) * 10) / 10;
                        if (diff !== 0) {
                            return (<Box textAlign="left">
                  {t('tabs.plots_news.level_allowed', {
                                    sign,
                                    diff,
                                    oldNIV,
                                    newNIV,
                                    levelDiff: t('global.level', { count: Math.abs(diff) }),
                                    levelOldNIV: t('global.level', { count: Math.abs(oldNIV) }),
                                    levelNewNIV: t('global.level', { count: Math.abs(newNIV) }),
                                })}
                </Box>);
                        }
                    }
                    else {
                        return <React.Fragment />;
                    }
                },
                enableGlobalFilter: false,
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'newSDP',
                header: () => getColumnHeader('new_sdp'),
                cell: (info) => {
                    const oldSDP = info.row.original.oldSDP;
                    const newSDP = info.row.original.newSDP;
                    if (oldSDP !== newSDP) {
                        const sign = getSign(oldSDP, newSDP);
                        const diff = newSDP - oldSDP;
                        if (diff !== 0) {
                            return (<Box textAlign="left">
                  {t('tabs.plots_news.floor_area', {
                                    sign,
                                    diff,
                                    oldSDP,
                                    newSDP,
                                })}
                </Box>);
                        }
                    }
                    else {
                        return <React.Fragment />;
                    }
                },
                enableGlobalFilter: false,
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'newMIX',
                header: () => getColumnHeader('new_mix'),
                cell: (info) => {
                    const oldMIX = info.row.original.oldMIX;
                    const newMIX = info.row.original.newMIX;
                    if (oldMIX !== newMIX) {
                        const sign = getSign(oldMIX, newMIX);
                        const diff = newMIX - oldMIX;
                        if (diff !== 0) {
                            return (<Box textAlign="left">
                  {t('tabs.plots_news.social_mix', {
                                    sign,
                                    diff,
                                    oldMIX,
                                    newMIX,
                                    count: Math.abs(diff),
                                })}
                </Box>);
                        }
                    }
                    else {
                        return <React.Fragment />;
                    }
                },
                enableGlobalFilter: false,
                filterFn: 'inNumberRange',
            },
            {
                accessorKey: 'contactIdsDeath',
                header: () => getColumnHeader('other'),
                accessorFn: (row) => {
                    const oldPinel = row.oldPinel;
                    const newPinel = row.newPinel;
                    if (oldPinel !== newPinel) {
                        return t('tabs.plots_news.pinel', {
                            oldPinel,
                            newPinel,
                        });
                    }
                    else {
                        return '';
                    }
                },
                cell: (info) => {
                    const oldPinel = info.row.original.oldPinel;
                    const newPinel = info.row.original.newPinel;
                    const contactIdsDeath = info.row.original.contactIdsDeath;
                    const contactDeathNode = contactIdsDeath.map((contactId) => {
                        return (<Box key={contactId}>
                <ContactButton contactId={contactId} size="sm" height="auto" padding={0} verticalAlign="baseline"/>{' '}
                {t('tabs.plots_news.contact_death')}
              </Box>);
                    });
                    return (<>
              {oldPinel !== newPinel && (<Box textAlign="left">
                  {t('tabs.plots_news.pinel', {
                                oldPinel,
                                newPinel,
                            })}
                </Box>)}
              {contactDeathNode}
            </>);
                },
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
                enableColumnFilter: false,
            },
        ],
    },
];
const UserPlotsNewsPanel = () => {
    const { isLoading, userPlotsNews, isLoadingSubscription, userMailPlotsNewsSubscription, setUserMailPlotsNewsSubscription, } = useUserPlotsNews();
    return (<Stack paddingX={5} width="100%">
      <HStack padding={3} backgroundColor="colorMode.background800">
        <Text>{t('tabs.plots_news.subscription_mail_alert')}</Text>
        {isLoadingSubscription ? (<Spinner />) : (<Switch data-cy="newsletter_subscription_switch" size="lg" isChecked={userMailPlotsNewsSubscription?.subscribe} onChange={(e) => {
                userMailPlotsNewsSubscription.subscribe = e.target.checked;
                setUserMailPlotsNewsSubscription({ subscribe: e.target.checked });
            }}/>)}
      </HStack>
      <Text textStyle="paragraph" color={'colorMode.font700'}>
        {t('tabs.plots_news.information')}
      </Text>
      <Table id={`table-user-plots-news`} columns={COLUMNS} data={userPlotsNews || []} isLoading={isLoading} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} exportTitle={t('tabs.plots_news.title')}/>
    </Stack>);
};
export default UserPlotsNewsPanel;
