import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, FormLabel, HStack, Select, Switch, } from '@chakra-ui/react';
import FormattedNumberInput from 'components/inputs/FormattedNumberInput';
import MultiSelectInput, { multiSelectStyles, selectSetValue, } from 'components/inputs/MultiSelectInput';
import useProject from 'hooks/crm/useProject';
import { PROJECT_DESTINATION_OPTIONS, PROJECT_ESTATE_VALUE, } from 'utils/constants';
import { numberWithSpaces } from 'utils/text';
const formatProjetValues = (project) => {
    if (project)
        return {
            ...project,
            label: project?.label,
            rightToBuild: project?.rightToBuild !== 0
                ? numberWithSpaces(project?.rightToBuild)
                : '',
            lots: project?.lots !== 0 ? numberWithSpaces(project?.lots) : '',
            turnover: project?.turnover !== 0 ? numberWithSpaces(project?.turnover) : '',
            estate: project?.estate,
            landCharge: project?.landCharge !== 0 ? numberWithSpaces(project?.landCharge) : '',
            copromotion: project?.copromotion,
            copromotionLots: project?.copromotionLots !== 0
                ? numberWithSpaces(project?.copromotionLots)
                : '',
            copromotionTurnover: project?.copromotionTurnover !== 0
                ? numberWithSpaces(project?.copromotionTurnover)
                : '',
            copromotionFloorArea: project?.copromotionFloorArea !== 0
                ? numberWithSpaces(project?.copromotionFloorArea)
                : '',
            destination: project?.destination.length > 0
                ? selectSetValue(PROJECT_DESTINATION_OPTIONS, project?.destination)
                : '',
        };
    return {};
};
const multiSelectStylesBig = {
    ...multiSelectStyles,
    control: (provided) => ({
        ...provided,
        minHeight: '40px',
        fontSize: '14px',
    }),
    placeholder: (provided) => {
        return {
            ...provided,
            fontSize: '13px',
        };
    },
};
const ProjectFinanceSection = ({ project, isLoading, projectId, projectTownId, isUserOwner = true, }) => {
    const { t } = useTranslation();
    const [copromotion, setCopromotion] = useState(project.copromotion);
    const { changeProjectInfo, changeLoading } = useProject(projectTownId, projectId);
    const methods = useForm({
        defaultValues: formatProjetValues(project) ?? {},
    });
    const { register, handleSubmit, reset } = methods;
    useEffect(() => {
        if (project) {
            reset(formatProjetValues(project));
            setCopromotion(project.copromotion);
        }
    }, [project]);
    const onSubmit = (field, value) => {
        if (project[field] != value && isUserOwner) {
            changeProjectInfo({
                projectId: project?.id,
                field,
                value: value,
            });
        }
    };
    return (<>
      <FormProvider {...methods}>
        <form>
          <HStack spacing={4} marginBottom={4}>
            <FormattedNumberInput name="rightToBuild" label={t('tabs.project.rightToBuild')} isDisabled={isLoading || changeLoading} placeholder={t('tabs.project.rightToBuild_placeholder')} handleBlur={(value) => onSubmit('rightToBuild', value)} disabled={!isUserOwner} required/>

            <FormattedNumberInput name="lots" label={t('tabs.project.lots')} isDisabled={isLoading || changeLoading} placeholder={t('tabs.project.lots_placeholder')} handleBlur={(value) => onSubmit('lots', value)} disabled={!isUserOwner} required/>

            <FormattedNumberInput name="turnover" label={t('tabs.project.turnover')} isDisabled={isLoading || changeLoading} placeholder={t('tabs.project.turnover_placeholder')} handleBlur={(value) => onSubmit('turnover', value)} disabled={!isUserOwner} required/>
          </HStack>
          <HStack spacing={4} marginBottom={4} alignItems="flex-start">
            <FormattedNumberInput name="landCharge" label={t('tabs.project.land_charge')} placeholder={t('tabs.project.land_charge_placeholder')} isDisabled={isLoading || changeLoading} handleBlur={(value) => onSubmit('landCharge', value)} disabled={!isUserOwner} required/>
            <MultiSelectInput name="destination" label={t('tabs.project.destination')} placeholder={t('tabs.project.destination_placeholder')} options={Object.values(PROJECT_DESTINATION_OPTIONS) ?? []} noOptionsMessage={({ inputValue }) => inputValue?.length
            ? t('global.no_result')
            : t('tabs.project.destination_placeholder')} styles={multiSelectStylesBig} onBlur={handleSubmit((data) => onSubmit('destination', Object.values(data.destination)
            .map((option) => option.value)
            .join(',')))} isDisabled={!isUserOwner}/>
            <FormControl>
              <FormLabel>{t('tabs.project.estate')}</FormLabel>
              <Select {...register('estate', {
        onChange: handleSubmit((data) => onSubmit('estate', data.estate)),
    })} isDisabled={!isUserOwner}>
                {Object.keys(PROJECT_ESTATE_VALUE).map((key) => (<option key={key} value={key}>
                    {t(PROJECT_ESTATE_VALUE[key].label)}
                  </option>))}
              </Select>
            </FormControl>
          </HStack>
          <FormControl display="flex" alignItems="center" marginBottom={2}>
            <FormLabel marginRight={2} marginBottom={0}>
              {t('tabs.project.copromotion')}
            </FormLabel>
            <Switch isChecked={copromotion} {...register('copromotion')} isDisabled={isLoading || changeLoading || !isUserOwner} onChange={(event) => {
            setCopromotion(event.target.checked);
            onSubmit('copromotion', event.target.checked ? 1 : 0);
        }}/>
          </FormControl>
          <HStack spacing={4} marginBottom={4} marginTop={1} display={copromotion ? 'flex' : 'none'}>
            <FormattedNumberInput name="copromotionFloorArea" label={t('tabs.project.copromotion_floor_area')} isDisabled={isLoading || changeLoading} placeholder={t('tabs.project.copromotion_floor_area_placeholder')} handleBlur={(value) => onSubmit('copromotionFloorArea', value)} required marginTop={1} disabled={!isUserOwner}/>
            <FormattedNumberInput name="copromotionLots" label={t('tabs.project.copromotion_lots')} isDisabled={isLoading || changeLoading} placeholder={t('tabs.project.copromotion_lots_placeholder')} handleBlur={(value) => onSubmit('copromotionLots', value)} required marginTop={1} disabled={!isUserOwner}/>
            <FormattedNumberInput name="copromotionTurnover" label={t('tabs.project.copromotion_turnover')} isDisabled={isLoading || changeLoading} placeholder={t('tabs.project.copromotion_turnover_placeholder')} handleBlur={(value) => onSubmit('copromotionTurnover', value)} required marginTop={1} disabled={!isUserOwner}/>
          </HStack>
        </form>
      </FormProvider>
    </>);
};
export default ProjectFinanceSection;
