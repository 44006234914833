import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPatrimonyLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        infoTitle: iots.string,
        infoLine1: iots.string,
    }),
]);
const fetchPatrimony = (townId) => () => get(iots.array(IOPatrimonyLayerItem), `${townId}/patrimony-swiss`);
const usePatrimonySwissLayer = () => {
    createLayersHook({
        fetch: fetchPatrimony,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PATRIMONY_SWISS);
};
const PatrimonySwissLayer = () => {
    usePatrimonySwissLayer();
    return null;
};
export default PatrimonySwissLayer;
