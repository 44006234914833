import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { FilterResultsTabKeys } from 'components/tabs/TabsNavigation/TabsItem/FilterResultsTabItem';
import { CONTACT_TAB_TYPE, FILTER_TAB_TYPE, PLOT_TAB_TYPE, PROJECT_TAB_TYPE, } from 'context/TabsContext';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useContacts, usePlots, useProjects, } from 'hooks/contexts/useTabsContext';
import { filtersKey } from 'utils/filters';
import { getRefinedPlotNumber } from 'utils/plots';
export default () => {
    const plots = usePlots();
    const { t } = useTranslation();
    const extraFilters = useExtraFilters();
    const sirens = extraFilters[filtersKey.COMPANY_OWNED_PLOTS]?.sirens;
    const manager = extraFilters[filtersKey.COMPANY_OWNED_MANAGER]
        ?.manager;
    const selectedTown = useSelectedTown();
    const projects = useProjects();
    const contacts = useContacts();
    const getCRMTabTitle = (tab) => {
        if (tab.type === PROJECT_TAB_TYPE) {
            return projects[tab.id].label;
        }
        if (tab.type === CONTACT_TAB_TYPE) {
            return `${contacts[tab.id].firstname} ${contacts[tab.id].lastname}`;
        }
        return t(`tabs.extra_tabs.${tab.id}.tab_title`);
    };
    const getFilterResultsTabDetail = (tab) => {
        if (tab.id === filtersKey.BUILDING_PERMIT_HOUSING ||
            tab.id === filtersKey.BUILDING_PERMIT_OFFICES ||
            tab.id === filtersKey.BUILDING_PERMIT_PLANNING_PERMIT ||
            tab.id === filtersKey.BANKRUPT_COMPANIES_PLOTS ||
            tab.id === filtersKey.BANKRUPT_COMPANIES ||
            tab.id === filtersKey.COMPANIES ||
            tab.id === filtersKey.CO_OWNER ||
            tab.id === filtersKey.DEATH) {
            return (t('tabs.department') +
                ' ' +
                selectedTown?.departmentName +
                ' (' +
                selectedTown?.department +
                ')');
        }
        if (tab.id === filtersKey.COMPANY_OWNED_PLOTS) {
            return sirens ? sirens[0] : '';
        }
        if (tab.id === filtersKey.COMPANY_OWNED_MANAGER) {
            return manager.firstname + ' ' + manager.name;
        }
        return '';
    };
    const getTitle = React.useCallback((tab) => {
        if (tab.type === PLOT_TAB_TYPE) {
            const currentPlot = plots[tab.id];
            if (currentPlot) {
                return currentPlot?.town;
            }
            return '';
        }
        if (tab.type === FILTER_TAB_TYPE &&
            FilterResultsTabKeys?.includes(tab?.id)) {
            return t(`tabs.extra_tabs.${tab.id}.tab_title`);
        }
        return getCRMTabTitle(tab);
    }, [plots]);
    const getDetail = React.useCallback((tab) => {
        if (tab.type === PLOT_TAB_TYPE) {
            const currentPlot = plots[tab.id];
            if (currentPlot?.cadastreId)
                return getRefinedPlotNumber(currentPlot?.cadastreId).toString();
            return '';
        }
        if (tab.type === FILTER_TAB_TYPE &&
            FilterResultsTabKeys?.includes(tab?.id)) {
            return getFilterResultsTabDetail(tab);
        }
    }, [plots]);
    return { getTitle, getDetail };
};
