import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Link } from '@chakra-ui/react';
import { formatDate } from 'utils/formatDate';
const DataCenterInfoWindow = ({ info, }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    return (<Box maxHeight="400px">
      {info.date && (<Box>
          {t('data_center.date')} : {formatDate(info.date)}
        </Box>)}
      {info.title && (<Box>
          {t('data_center.title')} : {info.title}
        </Box>)}
      {info.surface && (<Box>
          {t('data_center.surface')} : <b>{info.surface} m²</b>
        </Box>)}
      {info.company && (<Box>
          {t('data_center.company')} : <b>{info.company}</b>
        </Box>)}
      {info.project && (<Box>
          {t('data_center.project')} : {info.project}
        </Box>)}
      {info.sources && (<Box>
          <Link isExternal href={info.sources}>
            {t('data_center.source')}
          </Link>
        </Box>)}
    </Box>);
};
export default DataCenterInfoWindow;
