import { useEffect, useState } from 'react';
import { usePrevious } from '@chakra-ui/react';
import { useFilters, useToggleFilter } from 'hooks/contexts/useFiltersContext';
export const useContextPlotPostsHighlights = (layerKey) => {
    const filters = useFilters();
    const toggleFilter = useToggleFilter();
    const [highlights, setHighlights] = useState({});
    const filterEnabled = filters[layerKey];
    const previousFilterEnabled = usePrevious(filterEnabled);
    useEffect(() => {
        if (previousFilterEnabled && !filterEnabled) {
            clearHighlights();
        }
    }, [filterEnabled]);
    useEffect(() => {
        const hasHighlights = Object.keys(highlights).length;
        if (!filterEnabled && hasHighlights) {
            toggleFilter(layerKey, true);
            return;
        }
        if (filterEnabled && !hasHighlights) {
            toggleFilter(layerKey, false);
        }
    }, [highlights]);
    const arePlotsHighlighted = (plotsInfo) => filterEnabled &&
        plotsInfo.every((plotInfo) => {
            const key = plotInfo.id;
            return !!highlights[key];
        });
    const clearHighlights = () => {
        setHighlights({});
    };
    const removeHighlights = (sourcePostsInfo) => {
        setHighlights((highlights) => {
            const newHighlights = { ...highlights };
            sourcePostsInfo.forEach((postInfo) => {
                delete newHighlights[postInfo.id];
            });
            return newHighlights;
        });
    };
    const addHighlights = (postsInfo) => {
        setHighlights((highlights) => {
            const newHighlights = { ...highlights };
            postsInfo.forEach((postInfo) => {
                newHighlights[postInfo.id] = {
                    id: postInfo.id,
                    legend: postInfo.name ?? postInfo.id,
                    color: postInfo.color,
                    swkt: postInfo.swkt,
                    type: postInfo.type,
                };
            });
            return newHighlights;
        });
    };
    const toggleHighlights = (postsInfo, color, value) => {
        const show = value === undefined ? !arePlotsHighlighted(postsInfo) : value;
        // If all plots are already visible with the specified legend, we hide them, otherwise, we show them
        if (show) {
            addHighlights(postsInfo);
            return;
        }
        removeHighlights(postsInfo);
    };
    return {
        highlights,
        addHighlights,
        removeHighlights,
        toggleHighlights,
        clearHighlights,
        arePlotsHighlighted,
    };
};
export default useContextPlotPostsHighlights;
