import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Input, Stack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_SEARCH } from 'analytics/useAnalytics';
import SearchInputWrapper from 'components/inputs/SearchInputWrapper';
import { TABS_VIEW_HALF_VERTICAL } from 'context/LayoutContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
const Header = ({ titleElement, analytics, withGlobalSearch, globalFilter, setGlobalFilter, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const tabsView = useTabsView();
    return (<Stack spacing={2} justifyContent="space-between" direction={tabsView === TABS_VIEW_HALF_VERTICAL
            ? 'column'
            : { base: 'column', md: 'row' }}>
      <HStack flexWrap="wrap">{titleElement && titleElement}</HStack>
      {withGlobalSearch && (<Box width="30%">
          <SearchInputWrapper>
            <Input placeholder={t('global.search')} value={globalFilter ?? ''} onChange={(e) => {
                const value = e.target.value;
                trackEvent({
                    category: ANALYTICS_CATEGORY_SEARCH,
                    action: analytics.tableName,
                });
                setGlobalFilter(value.trim() !== '' ? value.trim() : null);
            }} boxShadow="sm" type="search"/>
          </SearchInputWrapper>
        </Box>)}
    </Stack>);
};
export default Header;
