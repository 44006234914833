import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormControl, FormLabel, HStack, Icon, Input, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import ProjectStatusSelect from 'components/inputs/ProjectStatusSelect';
import ShareProjectModal from 'components/modals/ShareProjectModal';
import NoteList from 'components/notes/NoteList';
import ProjectContactsTable from 'components/tabs/crmTabs/project/ProjectContactsTable';
import useProject from 'hooks/crm/useProject';
import useProjectContacts from 'hooks/crm/useProjectContacts';
import useProjectNotes from 'hooks/crm/useProjectNotes';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import ClockIcon from 'images/icons/Clock';
import ContactsIcon from 'images/icons/Contacts';
import PlotIcon from 'images/icons/Plot';
import SurfaceIcon from 'images/icons/Surface';
import ToDoIcon from 'images/icons/ToDo';
import { SPACING_PANEL } from 'utils/constants';
import { calculateAndGetElapsedTime, formatDate } from 'utils/formatDate';
import { numberWithSpaces } from 'utils/text';
const formatProjetValues = (project) => {
    if (project)
        return {
            ...project,
            label: project?.label,
            rightToBuild: project?.rightToBuild !== 0
                ? numberWithSpaces(project?.rightToBuild)
                : '',
            lots: project?.lots !== 0 ? numberWithSpaces(project?.lots) : '',
            turnover: project?.turnover !== 0 ? numberWithSpaces(project?.turnover) : '',
            estate: project?.estate,
        };
    return {};
};
const ProjectSection = ({ project, projectTownId, isLoading, projectPlots, isLoadingPlots, landPointId, isUserOwner = true, deleteProject, addNoteToProject, deletePlotNoteFromProject, removeNoteFromProject, updateNoteFromProject, onClose, }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const { hasDashboardItem } = useSiteConfiguration();
    const { changeProjectInfo, changeLoading } = useProject(projectTownId, project.id);
    const { projectContacts, isInitialLoading: isLoadingContact } = useProjectContacts(project?.id, landPointId);
    const { projectNotes, isInitialLoading: isLoadingNote } = useProjectNotes(project?.id, landPointId);
    const methods = useForm({
        defaultValues: formatProjetValues(project) ?? {},
    });
    const { register, handleSubmit, reset } = methods;
    useEffect(() => {
        if (project) {
            reset(formatProjetValues(project));
        }
    }, [project]);
    const projectSurface = useMemo(() => {
        let surface = 0;
        projectPlots?.forEach((plot) => {
            surface += plot.surface;
        });
        return surface;
    }, [projectPlots]);
    const projectPlotWithoutContact = useMemo(() => {
        let withoutContact = 0;
        projectPlots?.forEach((plot) => {
            if (plot.contacts.length === 0) {
                withoutContact += 1;
            }
        });
        return withoutContact;
    }, [projectPlots]);
    const onSubmit = (field, value) => {
        if (project[field] !== value && isUserOwner) {
            changeProjectInfo({
                projectId: project?.id,
                field,
                value: value,
            });
        }
    };
    return (<>
      <VStack spacing={SPACING_PANEL} alignItems="flex-start">
        <FormProvider {...methods}>
          <form style={{ width: '100%' }}>
            <HStack spacing={4} marginBottom={4} width="full">
              <FormControl width="50%">
                <FormLabel>{t('tabs.project.name')}</FormLabel>
                <Input isDisabled={isLoading || changeLoading || !isUserOwner} placeholder={t('tabs.project.name')} {...register('label', {
        onBlur: handleSubmit((data) => onSubmit('label', data.label)),
    })} data-cy="project_form_name"/>
              </FormControl>

              <Box width="50%">
                <FormControl>
                  <FormLabel>{t('tabs.project.status')}</FormLabel>
                  <ProjectStatusSelect townshipId={projectTownId} projectId={project?.id} status={project?.status} plotIds={projectPlots?.map((plot) => plot.id)} isDisabled={!isUserOwner} onChange={() => {
            trackEvent({
                category: ANALYTICS_CRM,
                action: 'Statut du projet fiche projet',
            });
        }}/>
                </FormControl>
              </Box>
            </HStack>
            <HStack spacing={4} fontSize="sm" marginBottom={3}>
              <Box display="flex" alignItems="center" width="32%">
                <Icon as={PlotIcon} fill="colorMode.font900" width="20px" height="20px"/>
                <Text as="span" marginLeft={2} data-cy="plot_form_plot_number">
                  <strong>{numberWithSpaces(projectPlots?.length ?? 0)}</strong>{' '}
                  {t('tabs.project.plot', { count: projectPlots?.length ?? 0 })}
                </Text>
              </Box>
              <Box display="flex" alignItems="center" width="32%">
                <Icon as={SurfaceIcon} fill="colorMode.font700" width="20px" height="20px"/>
                <Text as="span" marginLeft={2} fontWeight="bold">
                  {numberWithSpaces(projectSurface)} m²{' '}
                  {t('tabs.project.of_surface')}
                </Text>
              </Box>
              {projectContacts?.length && (<Box display="flex" alignItems="center" width="32%">
                  <Icon as={ContactsIcon} fill="colorMode.font900" width="20px" height="20px"/>
                  <Text as="span" marginLeft={2}>
                    <strong>{numberWithSpaces(projectContacts.length)}</strong>{' '}
                    {t('tabs.project.contact', {
                count: projectContacts.length,
            })}
                  </Text>
                </Box>)}
            </HStack>
            <HStack spacing={5}>
              <HStack spacing={2}>
                <Icon as={ClockIcon} fill="colorMode.font700" width="18px" height="18px"/>
                <Text as="p" fontSize="12px">
                  {t('tabs.project.last_update')}{' '}
                  <Text as="span" fontWeight="bold">
                    {calculateAndGetElapsedTime(t, project.updatedAt.split(' ')[0], new Date())}
                  </Text>{' '}
                  ({formatDate(project.updatedAt, 'short')})
                </Text>
              </HStack>
              {projectPlotWithoutContact > 0}
              {<HStack spacing={2}>
                  <Icon as={ToDoIcon} fill="colorMode.font700" width="20px" height="20px"/>
                  <Text as="p" fontSize="12px">
                    {t('tabs.project.plot_without_contact', {
                count: projectPlotWithoutContact,
            })}
                  </Text>
                </HStack>}
            </HStack>
          </form>
        </FormProvider>

        {hasDashboardItem('myContact') ? (<ProjectContactsTable isUserOwner={isUserOwner} project={project} projectContacts={projectContacts} projectPlots={projectPlots} landPointId={landPointId} isLoading={isLoading || isLoadingContact || isLoadingPlots}/>) : null}

        <NoteList title="tabs.project.notes" notes={projectNotes} withCounter modalId={project?.id} modalLabel="tabs.project.add_note" isLoading={isLoading || isLoadingNote} isUserOwner={isUserOwner} modalSubmit={(data, onSuccess) => {
            addNoteToProject(data, {
                onSuccess: () => {
                    onSuccess();
                    trackEvent({
                        category: ANALYTICS_CRM,
                        action: 'Créer une note fiche projet',
                    });
                },
            });
        }} onDeleteNote={(noteId, onSuccess) => {
            const note = projectNotes.find((note) => note.id === noteId);
            if (note?.plotNumber) {
                deletePlotNoteFromProject({
                    noteId,
                    plotId: note?.plotId,
                }, { onSuccess });
            }
            else {
                removeNoteFromProject({
                    projectId: project.id,
                    projectNoteId: noteId,
                }, { onSuccess });
            }
            trackEvent({
                category: ANALYTICS_CRM,
                action: 'Supprimer une note fiche projet',
            });
        }} onUpdateNote={(noteId, note, onSuccess) => {
            updateNoteFromProject({
                noteId,
                note: note,
                projectId: project.id,
            }, {
                onSuccess,
            });
        }}/>

        {!landPointId && (<HStack width="full" justifyContent="space-between">
            <HStack>
              <DeleteButton variant="outlineDelete" label={t('tabs.project.delete')} showIcon handleDelete={() => {
                trackEvent({
                    category: ANALYTICS_CRM,
                    action: 'Supprimer le projet',
                });
                deleteProject({ projectId: project?.id }, {
                    onSuccess: onClose,
                });
            }} isDisabled={isLoading} data-cy="project_panel_delete_project" id="delete_project"/>
              <ShareProjectModal project={project}/>
            </HStack>

            <Button variant="outline" onClick={onClose}>
              {t('tabs.project.btn_cancel')}
            </Button>
          </HStack>)}
      </VStack>
    </>);
};
export default ProjectSection;
