import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { get, post } from 'api/client';
import { QUERY_KEY_PLOT as TEAM_STATS_CRM_QUERY_KEY } from 'hooks/crm/useTeamStatisticsCRM';
import { QUERY_KEY as USER_PLOTS_QUERY_KEY } from 'hooks/crm/useUserPlotsList';
import { QUERY_KEY as USER_PROJECTS_QUERY_KEY } from 'hooks/crm/useUserProjectsList';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOEmpty, IOPersonalizationStatus, } from 'types/api';
const QUERY_KEY = 'perso_plot_status';
export const fetchPersonalizationPlotStatus = () => {
    return get(IOPersonalizationStatus, `personalization/plot/status`);
};
const updatePersonalizationPlotStatusRequest = (plotStatus) => post(IOEmpty, `personalization/plot/status`, plotStatus);
const usePersonalizationPlotStatus = () => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const onErrorCallback = useOnError();
    const { getSuccessToast } = useCustomToast();
    const { data: personalizationPlotStatus, isInitialLoading: isLoading } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: () => fetchPersonalizationPlotStatus(),
    });
    const { mutate: updatePersonalizationPlotStatus, isPending: isUpdateStatus } = useMutation({
        mutationFn: updatePersonalizationPlotStatusRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            getSuccessToast({
                title: t('personalization.plot.status_success'),
                id: 'personalization_plot_status',
            });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: USER_PROJECTS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            queryClient.invalidateQueries({ queryKey: TEAM_STATS_CRM_QUERY_KEY });
        },
    });
    return {
        personalizationPlotStatus,
        isLoading,
        updatePersonalizationPlotStatus,
        isUpdateStatus,
    };
};
export default usePersonalizationPlotStatus;
