import * as React from 'react';
import { Button, Icon, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_MAP_BUTTONS } from 'analytics/useAnalytics';
import { STREET_VIEW_MODE_FIFTY, STREET_VIEW_MODE_FIFTY_VERTICAL, STREET_VIEW_MODE_FULL, } from 'context/LayoutContext';
import { useStreetViewMode, useToggleStreetView, useToggleStreetViewLayout, } from 'hooks/contexts/useLayoutContext';
import FullscreenIcon from 'images/icons/Fullscreen';
import FullscreenExitIcon from 'images/icons/FullscreenExit';
import VerticalLayoutIcon from 'images/icons/VerticalLayout';
const StreeViewSizeControl = () => {
    const streetViewMode = useStreetViewMode();
    const toggleStreetView = useToggleStreetView();
    const toggleStreetViewLayout = useToggleStreetViewLayout();
    const { trackEvent } = useAnalytics();
    if (!streetViewMode) {
        return null;
    }
    return (<VStack zIndex="20" position="absolute" top="23px" right={streetViewMode === STREET_VIEW_MODE_FIFTY_VERTICAL
            ? 'calc(50% + 50px)'
            : '50px'}>
      {streetViewMode !== STREET_VIEW_MODE_FULL && (<Button aria-label="full-screen" variant="ghost" height={8} width="auto" paddingLeft="12px" paddingRight="14px" fontSize={12} boxShadow="lg" color="var(--colorModeFont-800)" leftIcon={<Icon as={VerticalLayoutIcon} width={5} height={5} fill="var(--colorModeFont-800)"/>} onClick={() => {
                toggleStreetViewLayout();
                if (streetViewMode === STREET_VIEW_MODE_FIFTY) {
                    trackEvent({
                        category: ANALYTICS_MAP_BUTTONS,
                        action: 'Mode ' + streetViewMode === STREET_VIEW_MODE_FIFTY
                            ? 'vertical '
                            : 'horizontal ' + ' streetview',
                    });
                }
            }}>
          Mode{' '}
          {streetViewMode === STREET_VIEW_MODE_FIFTY
                ? 'vertical'
                : 'horizontal'}
        </Button>)}
      <Button aria-label="full-screen" variant="ghost" height={8} width="auto" paddingLeft="12px" paddingRight="14px" fontSize={12} boxShadow="lg" color="var(--colorModeFont-800)" leftIcon={<Icon as={streetViewMode === STREET_VIEW_MODE_FULL
                ? FullscreenIcon
                : FullscreenExitIcon} width={5} height={5} fill="var(--colorModeFont-800)"/>} onClick={() => {
            toggleStreetView();
            if (streetViewMode === STREET_VIEW_MODE_FIFTY) {
                trackEvent({
                    category: ANALYTICS_MAP_BUTTONS,
                    action: 'Plein écran streetview',
                });
            }
        }}>
        Plein écran
      </Button>
    </VStack>);
};
export default StreeViewSizeControl;
