import { useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import pickBy from 'lodash/pickBy';
import { getLegendLayerKey } from 'context/LegendContext';
import { useFilters, useFiltersConfig, useIsHiddenFilter, } from 'hooks/contexts/useFiltersContext';
import { useAddLegend, useRemoveLegend } from 'hooks/contexts/useLegendContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useAddInfoWindow, useAddLayers, useCloseInfoWindow, useGetMap, useRemoveLayers, } from 'hooks/contexts/useMapContext';
import useOnError from 'hooks/useOnError';
import { IOMarkerLayerItem, IOPolygonLayerItem, } from 'types/api';
import { VECTOR_TYPES, } from 'types/types';
import { getBoundsFromSwkt, getContentAdvancedMarker, getLayerInfoFromLayerItem, } from 'utils/map';
const DEFAULT_LAYER_COLOR = '#00FF00';
const createLayerQueryHook = (fetch, extraParams = [], disabled = false, queryOptions = {}, selectedTown, currentTownId, availableWithoutTown = false) => {
    return (filterKeys, enabledFilters, townId, townScot) => {
        const queryFn = fetch ?? (() => () => null);
        const queryKey = ['layer', ...filterKeys];
        if (!availableWithoutTown) {
            queryKey.push(townId);
        }
        extraParams.forEach((param) => {
            if (param) {
                queryKey.push(param.toString());
            }
        });
        return useQuery({
            queryKey: queryKey,
            queryFn: queryFn(townId, townScot, selectedTown, ...extraParams),
            enabled: !!fetch &&
                enabledFilters.length > 0 &&
                (availableWithoutTown || !!townId || !!currentTownId) &&
                !disabled,
            ...queryOptions,
        });
    };
};
export const UNDEFINED_LAYER_KEY = 'not_defined';
const hasSameLatLngs = (latLngs1, latLngs2) => {
    if (!latLngs1) {
        return !latLngs2;
    }
    if (!latLngs2) {
        return false;
    }
    latLngs1.forEach((latLng, index) => {
        if (!(latLng instanceof google.maps.LatLng)) {
            if (!hasSameLatLngs(latLng, latLngs2[index])) {
                return false;
            }
        }
        if (latLng.lat() !== latLngs2[index].lat() ||
            latLng.lng() !== latLngs2[index].lng()) {
            return false;
        }
    });
    return true;
};
const hasSamePosition = (newVector, currentVector) => {
    if (newVector instanceof google.maps.marker.AdvancedMarkerElement &&
        currentVector instanceof google.maps.marker.AdvancedMarkerElement) {
        return (newVector?.position?.lat === currentVector?.position?.lat &&
            newVector?.position?.lng === currentVector?.position?.lng);
    }
    return true;
};
const isSameLayerInfo = (currentLayerInfo, newLayerInfo) => {
    if (newLayerInfo?.id !== currentLayerInfo?.id) {
        return false;
    }
    if ('vectors' in newLayerInfo && 'vectors' in currentLayerInfo) {
        return newLayerInfo?.vectors.every((newVector, index) => {
            const currentVector = currentLayerInfo?.vectors[index];
            return (newVector?.strokeColor === currentVector?.strokeColor &&
                newVector?.strokeWeight === currentVector?.strokeWeight &&
                newVector?.clickable === currentVector?.clickable &&
                newVector?.label?.text === currentVector?.label?.text &&
                hasSamePosition(newVector, currentVector) &&
                // @ts-expect-error wrong definition for gmaps vector
                hasSameLatLngs(newVector?.getPaths(), currentVector?.getPaths()));
        });
    }
    if (!('marker' in newLayerInfo && 'marker' in currentLayerInfo)) {
        return false;
    }
    const newMarker = newLayerInfo.marker;
    const currentMarker = currentLayerInfo.marker;
    if (newMarker?.position?.lat !== currentMarker?.position?.lat ||
        newMarker?.position?.lng !== currentMarker?.position?.lng) {
        return false;
    }
    // If needed in the future, this is where we can check for other
    // marker properties, such as icon or label
    if (!newLayerInfo?.polygon && !currentLayerInfo?.polygon) {
        return true;
    }
    return isSameLayerInfo(newLayerInfo.polygon, currentLayerInfo?.polygon);
};
export default ({ fetch = null, formatFetch = (filterKeys, data) => {
    return data;
}, providedData = null, legends = null, markerImg, getInfoWindowContent, eventInfoWindow = 'click', allowLayer, getMarkerLabel, extraParams = [], disableQuery = false, onClick, updateOnChange = false, alwaysEnabled = false, currentTownId = null, availableWithoutTown = false, }) => (filterKeys, parentFilterName) => {
    filterKeys = typeof filterKeys === 'string' ? [filterKeys] : filterKeys;
    const [layerData, setLayerData] = useState({
        layers: {},
        legends: {},
    });
    const previousLayerData = usePrevious(layerData);
    const [allowedLayers, setAllowedLayers] = useState(null);
    const getMap = useGetMap();
    const map = getMap();
    const addLayers = useAddLayers();
    const removeLayers = useRemoveLayers();
    const addInfoWindow = useAddInfoWindow();
    const closeInfoWindow = useCloseInfoWindow();
    const addLegend = useAddLegend();
    const removeLegend = useRemoveLegend();
    const filters = useFilters();
    const selectedTown = useSelectedTown();
    const isHiddenFilters = useIsHiddenFilter();
    const isHidden = isHiddenFilters(filterKeys);
    const boundsLimitedListener = useRef(null);
    const filtersConfig = useFiltersConfig();
    const defaultLayerKey = filterKeys.length === 1 ? filterKeys[0] : null;
    const previousOnClick = usePrevious(onClick);
    const enabledFilters = useMemo(() => alwaysEnabled
        ? filterKeys
        : filterKeys.filter((filterName) => !!filters[filterName]), [alwaysEnabled, filterKeys, filters]);
    const onError = useOnError();
    const queryHook = createLayerQueryHook(fetch, extraParams, disableQuery, { meta: { onError } }, selectedTown, currentTownId, availableWithoutTown);
    const { isLoading, data: apiData } = queryHook(filterKeys, enabledFilters, selectedTown?.id, selectedTown?.scot);
    const formattedData = useMemo(() => {
        return formatFetch(filterKeys, apiData);
    }, [apiData]);
    const sourceData = fetch ? formattedData : providedData;
    const [data, setData] = useState({
        data: sourceData,
        displayedItems: sourceData ? sourceData.map((item) => item.id) : [],
    });
    // Only display data located inside map bounds
    useEffect(() => {
        if (!map || sourceData === undefined) {
            return;
        }
        const sourceDataFormatted = JSON.parse(JSON.stringify(sourceData ?? [])).map((item) => {
            const isPolygon = IOPolygonLayerItem.is(item);
            if (isPolygon && item.swkt) {
                item.polygonBounds = getBoundsFromSwkt(item.swkt);
            }
            return item;
        });
        setData({
            data: sourceDataFormatted,
            displayedItems: sourceDataFormatted
                ? sourceDataFormatted.map((item) => item.id)
                : [],
        });
        boundsLimitedListener.current?.remove();
        if (sourceDataFormatted && sourceDataFormatted.length) {
            boundsLimitedListener.current = map.addListener('idle', () => {
                const displayedItems = sourceDataFormatted
                    .filter((item) => {
                    const isMarker = IOMarkerLayerItem.is(item);
                    // Seulement pour les type marker (ex: photovoltaic contient des marker (sans lat et lng)  mais aussi des polygon)
                    if (isMarker) {
                        const position = new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng));
                        return map.getBounds().contains(position);
                    }
                    else if (item.polygonBounds) {
                        return map.getBounds().intersects(item.polygonBounds);
                    }
                    return true;
                })
                    .map((item) => item.id);
                setData({ data: sourceDataFormatted, displayedItems });
            });
        }
        return () => {
            boundsLimitedListener.current?.remove();
        };
    }, [map, sourceData]);
    const toggleLegends = (legends, enabledFilters, alwaysEnabled) => {
        if (previousLayerData?.legends) {
            // Remove previous legends that are not shown anymore
            Object.keys(previousLayerData.legends).forEach((id) => {
                if (!legends[id]) {
                    removeLegend(getLegendLayerKey(previousLayerData.legends[id]), id);
                }
            });
        }
        const newLegends = alwaysEnabled
            ? legends
            : pickBy(legends, (legend) => enabledFilters.includes(legend.layerKey));
        Object.keys(legends).forEach((id) => {
            if (!newLegends[id]) {
                removeLegend(getLegendLayerKey(legends[id]), id);
            }
        });
        Object.keys(newLegends).forEach((id) => {
            addLegend(id, newLegends[id]);
        });
    };
    const isLayerAllowed = (key) => {
        if (!allowedLayers) {
            return true;
        }
        return allowedLayers.includes(key);
    };
    // Hide layer on legend btn click
    useEffect(() => {
        toggleLayers(layerData.layers, isHidden ? [] : enabledFilters, alwaysEnabled);
    }, [isHidden]);
    const toggleLayers = (layers, enabledFilters, alwaysEnabled) => {
        // remove previous layers that are not shown anymore
        if (previousLayerData?.layers) {
            const removedLayers = {};
            Object.keys(previousLayerData.layers).forEach((id) => {
                if (!(id in layers)) {
                    const layerKey = filterKeys.length === 1 && !allowLayer
                        ? defaultLayerKey
                        : previousLayerData.layers[id].layerKey;
                    if (!removedLayers[layerKey]) {
                        removedLayers[layerKey] = [];
                    }
                    removedLayers[layerKey].push(id);
                }
            });
            Object.keys(removedLayers).forEach((layerKey) => {
                removeLayers(layerKey, removedLayers[layerKey]);
            });
        }
        if (filterKeys.length === 1 && !allowLayer) {
            const visible = (alwaysEnabled || enabledFilters.includes(defaultLayerKey)) &&
                !isHidden;
            if (!visible &&
                Object.keys(layers).length > 0 &&
                getInfoWindowContent) {
                closeInfoWindow();
            }
            // Simple, most common case (only one layer, no allowLayer filter
            addLayers(layers, defaultLayerKey, visible);
            return;
        }
        const addedLayers = {};
        const hiddenLayers = {};
        Object.keys(layers).forEach((key) => {
            const layer = layers[key];
            const enabled = alwaysEnabled ||
                (layer.layerKey
                    ? enabledFilters.includes(layer.layerKey)
                    : enabledFilters.length > 0);
            const visible = enabled && isLayerAllowed(key) && !isHidden;
            if (visible) {
                if (!addedLayers[layer.layerKey]) {
                    addedLayers[layer.layerKey] = {};
                }
                addedLayers[layer.layerKey][key] = layer;
            }
            else {
                if (!hiddenLayers[layer.layerKey]) {
                    hiddenLayers[layer.layerKey] = {};
                }
                hiddenLayers[layer.layerKey][key] = layer;
            }
        });
        if (Object.keys(hiddenLayers).length > 0 && getInfoWindowContent) {
            closeInfoWindow();
        }
        Object.keys(addedLayers).map((layerKey) => {
            addLayers(addedLayers[layerKey], layerKey, true);
        });
        Object.keys(hiddenLayers).map((layerKey) => {
            addLayers(hiddenLayers[layerKey], layerKey, false);
        });
    };
    // When retrieving data, we format it
    useEffect(() => {
        if (map && (!fetch || !isLoading) && data.data) {
            const newLayerData = {
                layers: {},
                legends: {},
            };
            if (!Array.isArray(data.data)) {
                //eslint-disable-next-line
                console.error('Unexpected data retrieved for filters:', filterKeys);
                return;
            }
            if (process.env.NODE_ENV === 'development') {
                const ids = data.data.map((item) => item.id);
                ids.forEach((id, index) => {
                    if (id === null || id === undefined) {
                        //eslint-disable-next-line
                        console.error(`Null or undefined id in data retrieved for filters:`, filterKeys);
                        return;
                    }
                    if (ids.indexOf(id) !== index) {
                        //eslint-disable-next-line
                        console.error(`Duplicate id ${id} in data retrieved for filters:`, filterKeys);
                    }
                });
            }
            data.data.forEach((item) => {
                const isPolygon = IOPolygonLayerItem.is(item);
                const isMarker = IOMarkerLayerItem.is(item);
                const id = item.id;
                if (allowLayer && !isLayerAllowed(id)) {
                    return;
                }
                const layerColor = (isPolygon && (item.color || item.borderColor)) ||
                    DEFAULT_LAYER_COLOR;
                const layerKey = filterKeys.length === 1 && !allowLayer
                    ? defaultLayerKey ?? UNDEFINED_LAYER_KEY
                    : item.layerKey ?? defaultLayerKey ?? UNDEFINED_LAYER_KEY;
                const filterOpacity = filtersConfig[layerKey]?.opacity;
                const img = markerImg && (isMarker || item.type == VECTOR_TYPES.marker)
                    ? typeof markerImg === 'function'
                        ? markerImg(item)
                        : markerImg
                    : null;
                const imgString = typeof img === 'string' ? img : img?.path ?? '';
                if (item.legend) {
                    const legendId = `${item.layerKey}@${layerColor}@${imgString}@${item.legend}`.toLowerCase();
                    if (layerData.legends[legendId]) {
                        newLayerData.legends[legendId] = layerData.legends[legendId];
                    }
                    else {
                        if (img) {
                            newLayerData.legends[legendId] = {
                                layerKey,
                                parentLayerKey: item?.parentLayerKey ?? null,
                                image: img,
                                label: item.legend,
                                needsTranslate: item?.legendNeedsTranslate,
                            };
                        }
                        else {
                            newLayerData.legends[legendId] = {
                                layerKey,
                                parentLayerKey: item?.parentLayerKey ?? null,
                                color: layerColor,
                                opacity: filterOpacity ||
                                    (isPolygon && item.opacity) ||
                                    item?.legendOpacity ||
                                    undefined,
                                borderColor: (isPolygon && item.borderColor) || undefined,
                                borderWidth: (isPolygon && item.borderWidth) || undefined,
                                borderOpacity: (isPolygon && item.borderOpacity) || undefined,
                                borderRadius: (isPolygon && item.radius) || undefined,
                                label: item.legend,
                                needsTranslate: item?.legendNeedsTranslate,
                                legendOrder: item?.legendOrder,
                                polygonType: (isPolygon && item.type) || undefined,
                                legendWithCheckbox: item?.legendWithCheckbox,
                            };
                        }
                    }
                }
                if (!data.displayedItems.includes(id)) {
                    return;
                }
                // if layer is already added to the map, we keep it
                // In some cases we will still want to recompute new layers and,
                // if they are different from the previous ones, remove the existing
                // ones and add the new ones :
                // - if updateOnChange is set to true
                // Some layers can evolve in time with fresh data, while keeping the same ids,
                // such as SelectedPlotsBuildingLayer which is being updated each time
                // a new plot is opened or a building is selected.
                // - if onClick is set and has changed
                // In these cases we need to reset the click listener on the layers
                // which required the items, so we can't do it at the vectors level.
                const onClickChanged = previousOnClick !== onClick;
                if (layerData.layers[id] && !updateOnChange && !onClickChanged) {
                    newLayerData.layers[id] = layerData.layers[id];
                    return;
                }
                let newLayerInfo = null;
                if (isMarker && img) {
                    const position = new google.maps.LatLng(parseFloat(item.lat), parseFloat(item.lng));
                    // Pour qu'au premier chargement, on ne charge que celles qui sont visibles sur la carte
                    // Les autres seront déjà filtrées grâce à l'event bounds_changed
                    if (!map.getBounds().contains(position)) {
                        return;
                    }
                    const label = getMarkerLabel ? getMarkerLabel(item) : null;
                    const content = getContentAdvancedMarker({
                        label: label ? label.content : '',
                        labelClass: label
                            ? label.className || 'marker-label'
                            : 'marker-label',
                        image: img,
                        imageWidth: typeof img !== 'string' ? img.width ?? '24px' : '24px',
                        imageHeight: typeof img !== 'string' ? img.height ?? '' : '',
                    });
                    const marker = new google.maps.marker.AdvancedMarkerElement({
                        position,
                        map,
                        content,
                    });
                    if (onClick) {
                        marker.addListener('click', (e) => onClick(item, e));
                    }
                    if (getInfoWindowContent) {
                        addInfoWindow(marker, () => getInfoWindowContent(item), null, eventInfoWindow);
                    }
                    newLayerInfo = {
                        marker: marker,
                        layerKey,
                    };
                    // Pour le cas des cartofriche qui possèdent un marker
                    if (isPolygon || item?.withPolygon) {
                        newLayerInfo.polygon = getLayerInfoFromLayerItem(item, map, addInfoWindow, DEFAULT_LAYER_COLOR, null, null, eventInfoWindow, null, layerKey);
                    }
                }
                else {
                    newLayerInfo = getLayerInfoFromLayerItem(item, map, addInfoWindow, DEFAULT_LAYER_COLOR, getInfoWindowContent
                        ? (item) => () => getInfoWindowContent(item)
                        : undefined, eventInfoWindow, img, getMarkerLabel, layerKey, onClick ? (event) => onClick(item, event) : undefined);
                }
                const currentLayerInfo = layerData.layers[id];
                if (currentLayerInfo &&
                    !onClickChanged &&
                    isSameLayerInfo(currentLayerInfo, newLayerInfo)) {
                    newLayerData.layers[id] = currentLayerInfo;
                    // The temporary layer data that we just created is not gonna be used
                    // Juste in case, we ensure that the vectors will never be shown on the map
                    newLayerInfo.marker?.setMap(null);
                    newLayerInfo.polygon?.setMap(null);
                    newLayerInfo.vectors?.forEach((vector) => vector.setMap(null));
                }
                else {
                    removeLayers(layerKey, [id]);
                    newLayerData.layers[id] = newLayerInfo;
                }
            });
            setLayerData(newLayerData);
        }
    }, [data, updateOnChange, onClick, allowedLayers]);
    // When layer is enabled / disabled and when the formatted data is set, we show / hide it
    useEffect(() => {
        toggleLayers(layerData.layers, enabledFilters, alwaysEnabled);
        toggleLegends(legends || layerData.legends, enabledFilters, alwaysEnabled);
    }, [
        JSON.stringify(enabledFilters),
        layerData,
        allowedLayers,
        alwaysEnabled,
    ]);
    // If allowLayer is set, we need to store which layers are allowed
    useEffect(() => {
        if (allowLayer && data.data) {
            const allowed = [];
            data.data.forEach((item) => {
                if (allowLayer(item, enabledFilters)) {
                    allowed.push(item.id);
                }
            });
            setAllowedLayers(allowed);
        }
    }, [data, allowLayer]);
    const cleanup = () => {
        if (getInfoWindowContent) {
            closeInfoWindow();
        }
        const filters = filterKeys;
        filters.forEach((filter) => {
            removeLayers(filter);
            if (parentFilterName) {
                removeLegend(parentFilterName, filter);
            }
            else {
                removeLegend(filter);
            }
        });
    };
    useEffect(() => {
        return cleanup;
    }, []);
};
