import { filtersKey } from 'utils/filters';
export const CST_POSITIONS = {
    [filtersKey.OLD_CADASTRE]: 1,
    [filtersKey.PLU_GPU]: 2,
    PLU: 3,
    LABELS: 4,
    [filtersKey.THEMATIC_MAPS]: 5,
    [filtersKey.THEMATIC_MAPS_V2]: 6,
    [filtersKey.PLOTS]: 7,
    [filtersKey.TOWNS]: 8,
    [filtersKey.EPCI]: 10,
    [filtersKey.NEIGHBORHOODS]: 12,
    [filtersKey.TOWNS_LIMITS]: 13,
    [filtersKey.SCOT]: 14,
    ABF_FILTERS: 15,
    [filtersKey.PPR_EARTHQUAKE_HAZARD]: 16,
    [filtersKey.TERMITES]: 17,
    ROAD_RAILWAY_FILTERS: 18,
    [filtersKey.WAREHOUSES_AND_DENSE_LOGISTICS_AREAS]: 20,
    RAILWAY_NETWORK_FILTERS: 22,
    COMMERCIAL_BUILDINGS_FILTERS: 23,
    [filtersKey.HAZMAT_TRANSPORT_PIPELINES]: 24,
    [filtersKey.RADIOACTIVE_GAS]: 25,
    [filtersKey.THEMATIC_MAP_RENT_APARTMENT]: 26,
    [filtersKey.THEMATIC_MAP_RENT_HOUSE]: 27,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2013]: 28,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2014]: 29,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2015]: 30,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2016]: 31,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2017]: 32,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2018]: 33,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2019]: 34,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2020]: 35,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2021]: 36,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2022]: 37,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_2023]: 38,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_RPG_LAST]: 50,
    [filtersKey.WIND_TOWER]: 51,
    [filtersKey.WIND_POTENTIAL]: 52,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_ABANDONED_MOTORWAYS]: 53,
    [filtersKey.AVERAGE_SOLAR_IRRADIATION]: 55,
    [filtersKey.BIOTOP_PROTECTION]: 56,
    [filtersKey.BIOLOGICAL_RESERVE]: 57,
    [filtersKey.HUNTING_RESERVE]: 58,
    [filtersKey.NATIONAL_RESERVE]: 59,
    [filtersKey.NATURA_2000_SIC]: 60,
    [filtersKey.NATURA_2000_ZPS]: 61,
    [filtersKey.NATIONAL_PARK]: 62,
    [filtersKey.MARIN_PARK]: 63,
    [filtersKey.COASTLINE_CONSERVATOIRE]: 64,
    [filtersKey.REGIONAL_NATURAL_SPACE_CONSERVATOIRE]: 65,
    [filtersKey.BIOSPHERE_RESERVE]: 66,
    [filtersKey.REGIONAL_RESERVE]: 67,
    [filtersKey.REGIONAL_PARK]: 68,
    [filtersKey.ZNIEFF_TYPE1]: 69,
    [filtersKey.ZNIEFF_TYPE2]: 70,
    [filtersKey.PUBLIC_FOREST]: 71,
    [filtersKey.COMPENSATORY_MEASURES_FOR_BIODIVERSITY_DAMAGE]: 72,
    [filtersKey.LEVEL_CURVE]: 73,
    [filtersKey.INCLINE]: 74,
    [filtersKey.WIND_POTENTIAL_140]: 76,
    [filtersKey.WIND_POTENTIAL_160]: 77,
    [filtersKey.WIND_CONSTRAINT_ROAD]: 78,
    [filtersKey.WIND_CONSTRAINT_INCLINE]: 79,
    [filtersKey.ELECTRICAL_NETWORKS_HTB_AERIAL]: 80,
    [filtersKey.ELECTRICAL_NETWORKS_HTB_UNDERGROUND]: 81,
    [filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON]: 82,
    [filtersKey.RADAR]: 83,
    [filtersKey.WETLAND_AREA_POTENTIALLY]: 84,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_LAND_UNIT_AREA_PARKING_SPACE_500]: 85,
    [filtersKey.WIND_LARGE_FRENCH_SITE]: 86,
    [filtersKey.WIND_ZAER_EXCLUSION]: 87,
    [filtersKey.WIND_CONSTRAINT_HOUSING]: 88,
    [filtersKey.WIND_CONSTRAINT_RAILWAY]: 89,
    [filtersKey.PHOTOVOLTAIC_NOT_PRONE_AREA_PRAIRIE]: 90,
    [filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU]: 91,
    ZAER_FILTERS: 92,
    WIND_BIRD_FILTERS: 93,
    AERONAUTIC_FILTERS: 94,
    [filtersKey.WIND_UNESCO_NATURAL]: 95,
    [filtersKey.SRCE]: 96,
    [filtersKey.DRINKING_WATER_CAPTURE]: 97,
    [filtersKey.AERONAUTIC_SERVITUDE]: 98,
    [filtersKey.AERONAUTIC_SERVITUDE + '_2']: 99,
    // Position 100 - 160 réservé aux PLU adjacents
    [filtersKey.OPTICAL_FIBER]: 161,
    [filtersKey.DATACENTER_INFRAPEDIA]: 162,
    [filtersKey.WIND_POTENTIAL_50]: 163,
    [filtersKey.WIND_POTENTIAL_100]: 164,
    [filtersKey.PARKING_SPACE_500_750]: 165,
    [filtersKey.PARKING_SPACE_750_1000]: 166,
    [filtersKey.PARKING_SPACE_1000_1500]: 167,
    [filtersKey.PARKING_SPACE_1500_5000]: 168,
    [filtersKey.PARKING_SPACE_5000_sup]: 169,
    [filtersKey.OLD_MARKET_DVF]: 170,
    [filtersKey.NOISE_SENSIBILITY]: 171,
    [filtersKey.SOIL]: 172,
    [filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR]: 173,
    [filtersKey.CO_OWNER]: 174,
    [filtersKey.COLD_NETWORK]: 175,
    [filtersKey.GAS_NETWORK]: 176,
    [filtersKey.ELECTRICAL_NETWORKS_BT_AERIAL]: 177,
    [filtersKey.ELECTRICAL_NETWORKS_BT_UNDERGROUND]: 178,
    [filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT]: 179,
    [filtersKey.ELECTRICAL_NETWORKS_POST_HTA_BT]: 180,
    [filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_AERIAL]: 181,
    [filtersKey.ELECTRICAL_NETWORKS_MEDIUM_HTA_UNDERGROUND]: 182,
    [filtersKey.HEATING_NETWORK]: 183,
    [filtersKey.PPR_ICPE_INDUSTRIAL_RISK]: 184,
    [filtersKey.DEBROUSSAILLAGE]: 185,
    [filtersKey.FIBER_OPERATOR_ARTERIA]: 186,
    [filtersKey.FIBER_OPERATOR_TERRALPHA]: 187,
    [filtersKey.FIBER_OPERATOR_IELO]: 188,
    [filtersKey.FIBER_OPERATOR_VORTEX]: 189,
    [filtersKey.FIBER_OPERATOR_YVELINE_FIBRE]: 190,
    [filtersKey.FIBER_OPERATOR_CELESTE]: 191,
    [filtersKey.FIBER_OPERATOR_XP_FIBRE]: 192,
    [filtersKey.RPG_ISLET]: 193,
    [filtersKey.PLU_EASTER_EGG]: 194,
    [filtersKey.FOREST]: 195,
};
// Certains filtres sont regroupés (ZAER_FILTERS, WIND_BIRD_FILTERS, AERONAUTIC_FILTERS)
export const LAYERS_WITH_OPACITY = {
    ...CST_POSITIONS,
    [filtersKey.OPTICAL_FIBER]: false,
    [filtersKey.ABF_NO_BUILDING_ZONE]: CST_POSITIONS.ABF_FILTERS,
    [filtersKey.ABF_STRONGLY_REGULATED_AREA]: CST_POSITIONS.ABF_FILTERS,
    [filtersKey.ABF_ADVICE_REQUIRED_IF_VISIBLE]: CST_POSITIONS.ABF_FILTERS,
    [filtersKey.ABF_ADVICE_REQUIRED]: CST_POSITIONS.ABF_FILTERS,
    [filtersKey.ABF_REGIONAL_PREFECT_ADVICE_REQUIRED]: CST_POSITIONS.ABF_FILTERS,
    [filtersKey.ROAD_NOISE]: CST_POSITIONS.ROAD_RAILWAY_FILTERS,
    [filtersKey.RAILWAY_NOISE]: CST_POSITIONS.ROAD_RAILWAY_FILTERS,
    [filtersKey.RAILWAY_NETWORKS_FRANCE_RAILWAY_NETWORK]: CST_POSITIONS.RAILWAY_NETWORK_FILTERS,
    [filtersKey.RAILWAY_NETWORKS_IDF_RAILWAY_NETWORK]: CST_POSITIONS.RAILWAY_NETWORK_FILTERS,
    [filtersKey.COMMERCIAL_LINEAR]: CST_POSITIONS.COMMERCIAL_BUILDINGS_FILTERS,
    [filtersKey.URBAN_INTENSITY_PLACES]: CST_POSITIONS.COMMERCIAL_BUILDINGS_FILTERS,
    [filtersKey.ZAER_SOLAR_PV_GROUND]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_SOLAR_PV_ROOF]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_SOLAR_PV_SHADOW_PARKING]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_SOLAR_THERMIC]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_WIND]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_GEOTHERMAL]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_BIOMETHANE]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_BIOMASS]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.ZAER_HYDROELECTRICITY]: CST_POSITIONS.ZAER_FILTERS,
    [filtersKey.WIND_BIRD_AVIFAUNA]: CST_POSITIONS.WIND_BIRD_FILTERS,
    [filtersKey.WIND_BIRD_BAT]: CST_POSITIONS.WIND_BIRD_FILTERS,
    [filtersKey.AERONAUTIC_ZONE_CRT]: CST_POSITIONS.AERONAUTIC_FILTERS,
    [filtersKey.AERONAUTIC_ZONE_P]: CST_POSITIONS.AERONAUTIC_FILTERS,
    [filtersKey.AERONAUTIC_ZONE_ZMT]: CST_POSITIONS.AERONAUTIC_FILTERS,
    [filtersKey.THEMATIC_MAPS_REAL_ESTATE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_REAL_ESTATE_POTENTIAL]: CST_POSITIONS[filtersKey.THEMATIC_MAPS_V2],
    [filtersKey.THEMATIC_MAP_HOUSING_TAX]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_PROPERTY_TAX]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HLM_SOCIAL_HOUSING]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_OWNERS_PART]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_VACATION_HOME]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HOUSING_AGE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_VACANT_HOUSING]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_NO_CENTRAL_HEATING]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_SMALL_SURFACES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_BUSINESS_PROPERTY_TAX]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HOUSING_DENSITY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HOUSING_TYPE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_UNBUILT_LAND_PROPERTY_TAX]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_WASTE_COLLECTION_TAX]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_MONTHLY_INCOME]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_UNEMPLOYMENT]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_POPULATION_TYPE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_AVERAGE_AGE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_GENTRIFICATION]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_INFLUX_OF_FAMILIES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_POPULATION_DENSITY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_POPULATION_GROWTH]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_CHILDREN_AND_TEENAGERS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_TRANSPORTATION_TO_WORK]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_BACCALAUREATE_HOLDERS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_PLACE_OF_WORK]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_FERTILITY_RATE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_ANNUITIES_PENSIONS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_TAXABLE_HOUSEHOLDS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_SENIORS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_EXECUTIVES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_EMPLOYEES_AND_WORKERS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RETIREES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HOUSEHOLD_SIZE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RESIDENTS_UNDER_25]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RESIDENTS_FROM_25_TO_55]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RESIDENTS_FROM_55]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_ONE_CHILD_FAMILIES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_TWO_CHILDREN_FAMILIES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_THREE_CHILDREN_FAMILIES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_FOUR_OR_MORE_CHILDREN_FAMILIES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RAIN]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_SUNLIGHT_EXPOSURE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_SPORTS_GROUNDS_AND_HALLS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_GREEN_SPACES_AND_GARDENS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_FLOWER_TOWNS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HOUSEHOLDS_WITHOUT_A_CAR]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_LANDSCAPE_TYPE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_SUPERMARKETS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RESTAURANTS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_EDUCATION]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_STORES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_NIGHT_LIFE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_GREEN_SPACES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_PUBLIC_TRANSPORT]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_QUIETNESS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_SCHOOLS_AND_NURSERIES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HIGH_SCHOOL_RESULTS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HIGH_SCHOOL_SELECTIVITY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_HIGH_SCHOOLS_ADDED_VALUE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_GENERAL_PRACTITIONERS]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_TOURIST_RECEPTION_CAPACITY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2017_SECOND_ROUND_MACRON]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2017_SECOND_ROUND_LEPEN]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_SECOND_ROUND_HOLLANDE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_LEFT_RIGHT_BALANCE_OF_POWER]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_SARKOZY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_HOLLANDE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_LEPEN]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_MELENCHON]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_BAYROU]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_JOLY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_DUPOND_AIGNAN]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_POUTOU]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_ABSTENTION_RATE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_2012_SECOND_ROUND_SARKOZY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_INTENTIONAL_PHYSICAL_HARM]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_DAMAGE_TO_PROPERTY]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_BURGLARIES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_THEFTS_RELATED_TO_CARS_AND_MOTORCYCLES]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_PROPERTY_DESTRUCTION_AND_DEGRADATION]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RENT_APARTMENT]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
    [filtersKey.THEMATIC_MAP_RENT_HOUSE]: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
};
