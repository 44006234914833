export function numberWithSpaces(x) {
    const parts = x?.toString().split('.');
    if (parts) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
        return parts.join('.');
    }
}
export function capitalizeFirstLetter(str) {
    if (!str)
        return '';
    return str[0].toUpperCase() + str.slice(1);
}
