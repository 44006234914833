import * as React from 'react';
import { memo, useEffect, useMemo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import i18n from 'i18n';
import * as iots from 'io-ts';
import snakeCase from 'lodash/snakeCase';
import { get } from 'api/client';
import SirenLink from 'components/links/SirenLink';
import ContactDrawer from 'components/modals/contactModal/ContactDrawer';
import Table from 'components/table/Table';
import { useExtraFilters } from 'hooks/contexts/useFiltersContext';
import { useSetLayoutFull } from 'hooks/contexts/useLayoutContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { BUTTON_CONTACT_OCCUPANT } from 'hooks/crm/useContact';
import { ACTION_CREATE } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import { arrSearchLike } from 'utils/search';
import BankruptCompaniesOwnedPlotsButton from './BankruptCompaniesOwnedPlotsButton';
export const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.companies.columns.${column}`)}
    </Translation>
  </Text>);
export const IOCompaniesTableData = iots.type({
    department: iots.string,
    town: iots.string,
    companyName: iots.string,
    type: iots.string,
    groupType: iots.string,
    staffOlder: iots.union([iots.number, iots.null]),
    siren: iots.string,
    count: iots.number,
    countDepartment: iots.number,
});
const EXPORTED_FIELDS = [
    'count',
    'countDepartment',
    'companyName',
    'groupType',
    'type',
    'siren',
    'staffOlder',
    'department',
    'town',
];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.companies.columns.${snakeCase(header)}`);
const formatExportValue = (value, field, data) => {
    if (field === 'siren') {
        return data.siren;
    }
    if (field === 'groupType') {
        if (value === '')
            return '';
        return i18n.t('tabs.extra_tabs.companies.group_type.' + value);
    }
    return value;
};
const fetchCompanies = (department) => () => get(iots.array(IOCompaniesTableData), `${department}/companies`);
const CompaniesPanel = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const departmentName = selectedTown?.departmentName;
    const extraFilters = useExtraFilters();
    const setLayoutFull = useSetLayoutFull();
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['companies', department],
        queryFn: fetchCompanies(department),
        enabled: !!department,
    });
    const [contactToCreate, setContactToCreate] = React.useState(null);
    const [columnFilters, setColumnFilters] = useState([]);
    useEffect(() => {
        setLayoutFull();
    }, []);
    useEffect(() => {
        const newFilters = [];
        const updatedColumnFilters = [...columnFilters];
        if (extraFilters) {
            if (extraFilters[filtersKey.COMPANIES]?.type) {
                newFilters.push({
                    id: 'groupType',
                    value: [
                        t('tabs.extra_tabs.companies.group_type.' +
                            extraFilters[filtersKey.COMPANIES].type),
                    ],
                });
            }
        }
        if (newFilters?.length) {
            setColumnFilters([...updatedColumnFilters, ...newFilters]);
        }
    }, [JSON.stringify(extraFilters)]);
    const columns = useMemo(() => {
        return [
            {
                id: 'columns',
                columns: [
                    {
                        accessorKey: 'count',
                        header: () => getColumnHeader('count_owned_plots'),
                        cell: (info) => {
                            return (<BankruptCompaniesOwnedPlotsButton data={{
                                    siren: info.row.original.siren,
                                    countOwnedPlots: info.row.original.count,
                                }}/>);
                        },
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'countDepartment',
                        header: () => getColumnHeader('count_owned_plots_department'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'companyName',
                        header: () => getColumnHeader('company_name'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: arrSearchLike,
                    },
                    {
                        accessorKey: 'groupType',
                        accessorFn: (row) => {
                            if (row.groupType === '')
                                return t('table.empty_column');
                            return t('tabs.extra_tabs.companies.group_type.' + row.groupType);
                        },
                        header: () => getColumnHeader('group_type'),
                        cell: ({ row }) => {
                            if (row.original.groupType === '')
                                return '';
                            return t('tabs.extra_tabs.companies.group_type.' + row.original.groupType);
                        },
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'type',
                        accessorFn: (row) => {
                            if (row.type === '')
                                return t('table.empty_column');
                            return t('tabs.extra_tabs.companies.type.' + row.type);
                        },
                        header: () => getColumnHeader('type'),
                        cell: ({ row }) => row.original.type,
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'siren',
                        header: () => getColumnHeader('siren'),
                        cell: (info) => {
                            return (<SirenLink siren={info.getValue()} analyticDetail="Pappers Companies" showExternalLink/>);
                        },
                        enableGlobalFilter: false,
                        filterFn: arrSearchLike,
                    },
                    {
                        accessorKey: 'staffOlder',
                        header: () => getColumnHeader('staff_older'),
                        cell: (info) => {
                            if (info.getValue() === null) {
                                return t('global.ND');
                            }
                            else {
                                return info.getValue();
                            }
                        },
                        enableGlobalFilter: false,
                        filterFn: 'inNumberRange',
                    },
                    {
                        accessorKey: 'department',
                        header: () => getColumnHeader('department'),
                        cell: (info) => info.getValue(),
                        enableGlobalFilter: false,
                        filterFn: 'arrIncludesSome',
                    },
                    {
                        accessorKey: 'town',
                        accessorFn: (row) => {
                            if (!row.town)
                                return t('table.empty_column');
                            return row.town;
                        },
                        header: () => getColumnHeader('town'),
                        cell: ({ row }) => row.original.town,
                        enableGlobalFilter: false,
                        filterFn: arrSearchLike,
                    },
                ],
            },
        ];
    }, []);
    const initialSort = [
        {
            id: 'countDepartment',
            desc: true,
        },
    ];
    return (<Stack paddingX={4} height="100%">
      <Table variant="simple" id="companies" columns={columns} data={data || []} isLoading={isLoading} customColumnFilters={columnFilters} setCustomColumnFilters={setColumnFilters} initialSort={initialSort} exportTitle={t('tabs.extra_tabs.companies.tab_title') +
            '_' +
            departmentName +
            '_' +
            department} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} initialPagination={{
            pageIndex: 0,
            pageSize: 20,
        }}/>
      <ContactDrawer action={ACTION_CREATE} contact={contactToCreate} button={BUTTON_CONTACT_OCCUPANT} handleClose={() => setContactToCreate(null)} triggerItem={() => <></>}/>
    </Stack>);
};
export default memo(CompaniesPanel);
