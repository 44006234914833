import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Flex, Stack, Text, VStack } from '@chakra-ui/react';
import CreateProjectFromFeasibilityButton from 'components/buttons/CreateProjectFromFeasibilityButton';
import RealEstateTaxesDrawer from 'components/modals/realEstateTaxesModal/RealEstateTaxesDrawer';
import AddPlotFromMap from 'components/tabs/utils/AddPlotFromMap';
import { TABS_VIEW_FULL, TABS_VIEW_HALF } from 'context/LayoutContext';
import { PLOT_SELECTION_FEASIBILITY } from 'context/LocationContext';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useTabsView } from 'hooks/contexts/useLayoutContext';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useCloseOneFeasibilityPlot, useGetFeasibilityPlots, useIsLoadingFeasibilityPlot, } from 'hooks/contexts/useSecondaryPlotsContext';
import usePlotFeasibility from 'hooks/plot/usePlotFeasibility';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { FEASIBILITY_LIGHT_PLOT_TAB, PLOT_INFO_REAL_ESTATE_TAX, } from 'types/api';
import { SPACING_PANEL, TYPE_MODIFICATION_ANALYSE_MAJOR, TYPE_MODIFICATION_ANALYSE_MINOR, } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import { isGermanySite, isSwissSite } from 'utils/site';
import { getPlotSkeleton } from '../../PlotPannelSkeletons';
import { computeAccommodationsNumber, computeFloorSpace, computeSocialAccommodationsNumber, } from './calculator';
import PlotFeasibilityTable from './FeasibilityTable';
export const getFeasibilityWithCadastre = (feasability, plot) => ({
    ...feasability,
    section: plot?.section,
    zone: plot?.zone,
    number: plot?.number,
});
export const getNoFeasibility = (plot) => ({
    constructibleSurface: plot?.surface,
    section: plot?.section,
    zone: plot?.zone,
    number: plot?.number,
});
const PlotFeasibility = ({ plot }) => {
    const tabsView = useTabsView();
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const { hasPlotInfo, hasPlotTab } = useSiteConfiguration();
    const closeOneFeasibilityPlot = useCloseOneFeasibilityPlot();
    const isLoadingFeasibilityPlot = useIsLoadingFeasibilityPlot();
    const toggleFilter = useToggleFilter();
    const getFeasibilityPlots = useGetFeasibilityPlots();
    const feasibilityPlots = getFeasibilityPlots(plot.id);
    const { plotFeasibility, isLoadingPlotFeasibility } = usePlotFeasibility(plot?.townId, plot?.id, false);
    useEffect(() => {
        toggleFilter(filtersKey.FEASIBILITY_PLOTS, true);
        return () => {
            toggleFilter(filtersKey.FEASIBILITY_PLOTS, null);
        };
    }, [plot]);
    const feasibility = useMemo(() => plotFeasibility
        ? getFeasibilityWithCadastre(plotFeasibility, plot)
        : null, [plotFeasibility]);
    const { getValues } = useFormContext();
    const sourceData = getValues();
    const socialAccommodationsNumber = computeSocialAccommodationsNumber({
        sourceData,
    });
    const numberMainResidence = computeAccommodationsNumber({ sourceData }) - socialAccommodationsNumber;
    const averageAccommodationSize = getValues('averageAccommodationSize');
    const socialHousingExceptPLAISurface = averageAccommodationSize * socialAccommodationsNumber;
    const realEstateTaxDefaultValues = {
        mainResidenceSurface: (computeFloorSpace({ sourceData }) - socialHousingExceptPLAISurface).toFixed(0),
        numberMainResidence,
        socialHousingExceptPLAISurface,
        numberSocialHousingExceptPLAI: socialAccommodationsNumber,
    };
    const skeleton = getPlotSkeleton();
    if (isLoadingPlotFeasibility)
        return skeleton;
    return (<Stack alignItems="start" margin={4} spacing={SPACING_PANEL} data-cy="plot_feasibility_table">
      <Stack alignItems="flex-start" direction="column" spacing={4} id="PlotFeasibility" width="100%">
        <Flex width="full" flexWrap="wrap" bg="white" alignItems="center" padding={4}>
          <AddPlotFromMap mode={PLOT_SELECTION_FEASIBILITY}/>
          <Box marginLeft={5} marginTop={tabsView === TABS_VIEW_HALF || tabsView === TABS_VIEW_FULL ? 3 : 0}>
            <CreateProjectFromFeasibilityButton townId={selectedTown?.id} plot={plot}/>
          </Box>
        </Flex>
        {selectedTown?.typeModificationAnalyse ===
            TYPE_MODIFICATION_ANALYSE_MINOR && (<Text textStyle="paragraph">
            {t('tabs.plots.section.plot.feasibility_type_modification_analyse_minor')}
          </Text>)}
        {selectedTown?.typeModificationAnalyse ===
            TYPE_MODIFICATION_ANALYSE_MAJOR && (<Text textStyle="paragraph">
            {t('tabs.plots.section.plot.feasibility_type_modification_analyse_major')}
          </Text>)}
        <Box width="100%">
          <form>
            <PlotFeasibilityTable feasibility={feasibility} feasibilityPlots={feasibilityPlots} isLoadingFeasibilityPlot={isLoadingFeasibilityPlot} closeOneFeasibilityPlot={closeOneFeasibilityPlot} hasFeasibility={selectedTown?.hasFeasibility}/>
          </form>
        </Box>
      </Stack>
      <VStack backgroundColor="colorMode.base" padding={4} alignItems="start" borderRadius="lg" width="full">
        {selectedTown?.hasFeasibility &&
            !hasPlotTab(FEASIBILITY_LIGHT_PLOT_TAB) &&
            !isSwissSite() &&
            !isGermanySite() && (<Text textStyle="paragraph">
              {t('tabs.plots.section.feasibility.info')}
            </Text>)}
        {hasPlotInfo(PLOT_INFO_REAL_ESTATE_TAX) && (<RealEstateTaxesDrawer defaultValues={realEstateTaxDefaultValues} townName={selectedTown?.name} name={plot?.plotNumber}/>)}
      </VStack>
    </Stack>);
};
export default PlotFeasibility;
