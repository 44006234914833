import * as React from 'react';
import { Box } from '@chakra-ui/react';
import RPGSection from 'components/tabs/plot-project/Sections/RPGSection';
import usePlotRPG from 'hooks/plot/usePlotRPG';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { RPG_PLOT_TAB } from 'types/api';
const PlotRPG = ({ plot }) => {
    const { hasPlotTab } = useSiteConfiguration();
    const { currentSite } = useCurrentSite();
    const { plotRPG, isInitialLoading } = usePlotRPG(plot, currentSite.legacyId, hasPlotTab(RPG_PLOT_TAB));
    return (<Box width="full" padding="4">
      <RPGSection rpg={plotRPG} type="plots" isLoading={isInitialLoading}/>
    </Box>);
};
export default PlotRPG;
