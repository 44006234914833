import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { HStack, Input, Spinner, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PERSONALIZATION } from 'analytics/useAnalytics';
import ColorPalette from 'components/tools/ColorPalette';
import 'react-color-palette/css';
const PersonalizationStatus = ({ personalization, analyticsActionType, isLoading, statusDefault, setPersonalization, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const chosenColor = (id, color) => {
        const perso = [...personalization];
        perso[id].color = color;
        setPersonalization(perso);
    };
    const setLabel = (event, index) => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Changement du label ' + analyticsActionType,
        });
        const perso = [...personalization];
        perso[index].label = event.target.value;
        setPersonalization(perso);
    };
    return (<VStack spacing={1} marginBottom={2}>
      {isLoading ? (<Spinner />) : (personalization.map((perso, index) => {
            return (<HStack spacing={3} key={index} width="420px">
              <Input name="name[]" size="sm" onChange={(input) => setLabel(input, index)} value={perso.label} maxLength={64} width="250px"/>
              <ColorPalette id={index} analyticsAction={analyticsActionType} colorDefault={perso.color} isDefault={perso.statusId === statusDefault} isDefaultText={t('tabs.personalization.section.plot_status.default')} chosenColor={chosenColor}/>
            </HStack>);
        }))}
    </VStack>);
};
export default PersonalizationStatus;
