import { extendTheme } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';
// try to match the users system-color and fallback to light. after the user has toggled the value, this value will be used.
const config = {
    initialColorMode: 'light',
    useSystemColorMode: false,
};
const colors = {
    // colors not influenced by color mode
    gray: {
        900: '#212121',
        800: '#8E8E8E',
        700: '#B7B4AE',
        200: '#E1E1E1',
        100: '#F5F5F5',
    },
    red: {
        800: '#a81328',
        600: '#DF0423',
        500: '#E62641',
        400: '#ff3030',
        100: '#FFEEEE',
    },
    blue: {
        600: '#0254A6',
        500: '#1469BE',
        200: '#DCECFB',
        100: '#DFEBF9',
    },
    green: {
        600: '#21db7e',
        500: '#0BA2A2',
        100: '#E9F9F1',
    },
    yellow: {
        500: '#ffcc00',
        100: '#FFF8DC',
    },
    dark: {
        900: '#0C1B3C',
        800: '#0D1F43',
        700: '#0F2651',
        600: '#132C5E',
        500: '#16316A',
    },
    light: {
        900: '#FBFAF9',
        800: '#F5F4F0',
        700: '#EBE8E0',
        600: '#E4E1D7',
        500: '#D9D5C9',
    },
    sidebar: {
        800: '#86a9e1',
        700: '#C7DFF7',
        600: '#E3EFFB',
        500: '#F6F8FA',
    },
    // colors influenced by color mode
    black: '#2F3439',
    white: '#FFFFFF',
    colorMode: {
        base: 'var(--colorModeBase)',
        revertBase: 'var(--colorModeRevertBase)',
        background900: 'var(--colorModeBackground-900)',
        background800: 'var(--colorModeBackground-800)',
        background700: 'var(--colorModeBackground-700)',
        background600: 'var(--colorModeBackground-600)',
        background500: 'var(--colorModeBackground-500)',
        font900: 'var(--colorModeFont-900)',
        font800: 'var(--colorModeFont-800)',
        font700: 'var(--colorModeFont-700)',
        sidebar800: 'var(--colorModeSidebar-800)',
        sidebar700: 'var(--colorModeSidebar-700)',
        sidebar600: 'var(--colorModeSidebar-600)',
        sidebar500: 'var(--colorModeSidebar-500)',
    },
};
const fonts = {
    body: 'IBM-plex-sans, sans-serif',
    heading: 'dm-sans, serif',
    mono: 'dm-sans, monospace',
};
const headingStyles = {
    h1: {
        fontFamily: 'dm-sans, serif',
        fontSize: '2xl',
        lineHeight: '32px',
    },
    h2: {
        fontFamily: 'dm-sans, serif',
        fontSize: 'xl',
        lineHeight: '28px',
    },
    h3: {
        fontFamily: 'dm-sans, serif',
        fontSize: 'lg',
        lineHeight: '20px',
    },
    h4: {
        fontFamily: 'dm-sans, serif',
        fontSize: 'sm',
        lineHeight: '16px',
    },
    h5: {
        fontSize: '12px',
    },
};
const Text = {
    baseStyle: {
        textAlign: 'left',
        color: colors.colorMode.font800,
    },
};
const Heading = {
    baseStyle: {
        fontSize: '24px',
        lineHeight: '32px',
        color: colors.colorMode.font900,
    },
    variants: {
        ...headingStyles,
    },
};
const Input = {
    baseStyle: {
        field: {
            _autofill: {
                WebkitBoxShadow: '0 0 0 30px var(--colorModeBase) inset !important',
                WebkitTextFillColor: 'var(--colorModeFont-900) !important',
            },
            _disabled: {
                opacity: 0.6,
            },
        },
    },
    variants: {
        outline: {
            background: colors.colorMode.base,
            field: {
                fontFamily: 'dm-sans, serif',
                background: colors.colorMode.base,
                borderColor: '#F2F2F2',
                borderWidth: 1,
                color: colors.colorMode.font900,
                _invalid: {
                    borderColor: 'red.500',
                    boxShadow: 'none',
                },
            },
        },
    },
    sizes: {
        sm: {
            field: {
                borderRadius: 'md',
                fontSize: '11px',
                _placeholder: { textStyle: 'small' },
            },
        },
        md: {
            field: {
                fontSize: '13px',
                _placeholder: { textStyle: 'paragraph' },
            },
        },
    },
};
const NumberInput = Input;
const Textarea = {
    variants: {
        outline: {
            borderRadius: 'md',
            borderColor: '#F2F2F2',
            minHeight: '160px',
            backgroundColor: 'colorMode.base',
            fontSize: 'sm',
            fontFamily: 'dm-sans, serif',
            color: 'colorMode.font900',
        },
    },
};
const Checkbox = {
    baseStyle: {
        control: {
            backgroundColor: 'white',
            borderRadius: '4px',
            borderWidth: '1px',
            borderColor: 'colorMode.background500',
            _checked: {
                borderColor: 'blue.500',
            },
        },
    },
    variants: {
        'no-border': {
            control: {
                padding: '2px',
                border: 'none',
            },
        },
        primary: {
            control: {
                borderColor: 'red.500',
                _checked: {
                    borderColor: 'red.500',
                    backgroundColor: 'red.500',
                    _hover: {
                        borderColor: 'red.800',
                        backgroundColor: 'red.800',
                    },
                },
            },
        },
    },
};
const Select = {
    baseStyle: {
        field: {
            backgroundColor: 'colorMode.base',
            color: 'colorMode.font900',
            fontFamily: 'dm-sans, serif',
            _disabled: {
                opacity: 0.6,
            },
        },
    },
    sizes: {
        xs: {
            height: 6,
        },
        sm: {
            field: {
                fontSize: '11px',
                borderRadius: 'md',
            },
        },
        md: {
            field: {
                fontSize: '13px',
            },
        },
    },
    variants: {
        unstyled: {
            field: {
                backgroundColor: 'transparent',
            },
        },
    },
};
const IconButton = {
    baseStyle: {
        paddingLeft: '0',
        paddingRight: '0',
    },
};
const Tabs = {
    variants: {
        enclosed: {
            tablist: {
                position: 'absolute',
                right: 0,
                bottom: '100%',
                backgroundColor: colors.colorMode.background600,
                marginBottom: 0,
            },
            tab: {
                border: 'none',
                _selected: {
                    backgroundColor: colors.colorMode.background900,
                },
                _hover: {
                    backgroundColor: colors.colorMode.background900,
                },
                _disabled: {
                    backgroundColor: colors.colorMode.background800,
                    cursor: 'not-allowed',
                },
            },
        },
    },
};
const Button = {
    baseStyle: {
        fontFamily: 'dm-sans, serif',
        fontWeight: 700,
    },
    variants: {
        primary: {
            backgroundColor: 'red.500',
            color: 'white',
            _hover: {
                backgroundColor: 'red.600',
                _disabled: {
                    backgroundColor: 'red.600',
                },
            },
        },
        outline: (props) => ({
            borderColor: props.colorMode === 'light' ? 'red.500' : 'white',
            color: props.colorMode === 'light' ? 'red.500' : 'white',
            _hover: {
                backgroundColor: props.colorMode === 'light' ? 'red.500' : 'white',
                color: props.colorMode === 'dark' ? 'red.500' : 'white',
                _disabled: {
                    color: 'red.500',
                },
            },
        }),
        outlineDelete: (props) => ({
            borderWidth: 1,
            borderColor: props.colorMode === 'light' ? 'gray.600' : 'white',
            color: props.colorMode === 'light' ? 'gray.600' : 'white',
            _hover: {
                backgroundColor: props.colorMode === 'light' ? 'gray.900' : 'white',
                color: props.colorMode === 'dark' ? 'gray.900' : 'white',
                _disabled: {
                    color: 'gray.300',
                },
            },
        }),
        ghost: {
            color: colors.colorMode.font800,
            backgroundColor: colors.colorMode.background900,
            _hover: {
                backgroundColor: colors.colorMode.background800,
            },
        },
        ghostBorder: {
            color: colors.colorMode.font800,
            backgroundColor: colors.colorMode.background900,
            border: '1px solid',
            borderColor: colors.colorMode.background500,
            _hover: {
                backgroundColor: colors.colorMode.background800,
            },
        },
        secondary: {
            color: colors.colorMode.font800,
            backgroundColor: colors.colorMode.background800,
            _hover: {
                backgroundColor: colors.colorMode.background700,
            },
        },
        'link-primary': {
            fontFamily: 'IBM-plex-sans, sans-serif',
            color: 'red.500',
            fontWeight: 400,
            padding: 0,
            height: 'auto',
            textDecoration: 'underline',
            whiteSpace: 'wrap',
            minWidth: 0,
            _hover: {
                color: 'red.600',
            },
        },
        'link-secondary': {
            color: colors.colorMode.revertBase,
            fontWeight: 500,
            _hover: {
                color: 'blue.600',
                textDecoration: 'underline',
            },
        },
    },
    sizes: {
        xs: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontSize: '12px',
            fontWeight: 500,
        },
        sm: {
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '13px',
            fontWeight: 500,
        },
        md: {
            fontSize: '14px',
            paddingLeft: '18px',
            paddingRight: '18px',
        },
    },
};
const Modal = {
    baseStyle: {
        dialog: {
            backgroundColor: colors.colorMode.background900,
        },
        header: {
            paddingTop: '15px',
            paddingLeft: '15px',
            paddingRight: '50px',
            paddingBottom: '0',
        },
        body: {
            padding: '15px',
        },
    },
};
const FormLabel = {
    baseStyle: {
        fontFamily: 'dm-sans, serif',
        fontSize: '13px',
        fontWeight: 400,
        color: colors.colorMode.font800,
        lineHeight: '15px',
        marginBottom: 1,
    },
};
const FormError = {
    baseStyle: {
        text: {
            fontFamily: 'dm-sans, serif',
            fontSize: '13px',
            fontWeight: 400,
            color: 'red.500',
            lineHeight: '14px',
        },
    },
};
const Table = {
    baseStyle: {
        thead: {
            th: {
                color: colors.colorMode.font800,
                fontWeight: '700',
                fontSize: '13px',
                textAlign: 'left',
                textTransform: 'none',
                paddingX: 2,
                paddingY: 1,
            },
        },
        tbody: {
            borderBottomRadius: 'md',
            tr: {
                td: {
                    color: colors.colorMode.font800,
                    fontSize: '13px',
                    paddingY: 1,
                    paddingX: 2,
                    lineHeight: 1.1,
                },
            },
        },
    },
    variants: {
        simple: {
            thead: {
                th: {
                    whiteSpace: 'normal',
                    border: '1px solid',
                    borderColor: colors.colorMode.background700,
                    backgroundColor: colors.colorMode.background900,
                },
            },
            tbody: {
                tr: {
                    td: {
                        whiteSpace: 'normal',
                        borderLeft: '1px solid',
                        borderRight: '1px solid',
                        borderTop: 'none',
                        borderBottom: 'none',
                        borderColor: colors.colorMode.background700,
                    },
                    '.selected': {
                        backgroundColor: colors.colorMode.background600,
                        border: 'none',
                    },
                    '.row-title': {
                        backgroundColor: colors.colorMode.background700,
                    },
                },
            },
        },
        striped: {
            thead: {
                th: {
                    whiteSpace: 'normal',
                    border: '1px solid',
                    borderColor: colors.colorMode.background700,
                    boxShadow: 'md',
                    minWidth: '50px',
                },
            },
            tbody: {
                tr: {
                    td: {
                        whiteSpace: 'normal',
                        border: '1px solid',
                        borderColor: colors.colorMode.background700,
                    },
                    _odd: {
                        td: {
                            borderColor: colors.colorMode.background700,
                            backgroundColor: colors.colorMode.background900,
                        },
                    },
                    _even: {
                        td: {
                            backgroundColor: colors.colorMode.background800,
                        },
                    },
                },
            },
        },
        'with-borders': {
            thead: {
                th: {
                    borderColor: colors.colorMode.background500,
                    borderBottomWidth: '1px',
                },
            },
            td: {
                whiteSpace: 'normal',
                borderColor: colors.colorMode.background500,
                borderTopWidth: '1px',
                borderBottomWidth: '1px',
                borderRightWidth: '1px',
                padding: '14px',
            },
        },
        horizontal: {
            tbody: {
                tr: {
                    td: {
                        borderBottom: '1px solid',
                        borderRight: 'none',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderColor: colors.colorMode.background700,
                    },
                    '.row-title': {
                        backgroundColor: colors.colorMode.background700,
                    },
                },
            },
        },
        owner: {
            tbody: {
                tr: {
                    td: {
                        borderBottom: '1px solid',
                        borderRight: 'none',
                        borderTop: 'none',
                        borderLeft: 'none',
                        borderColor: colors.colorMode.background700,
                        paddingY: 1,
                        paddingLeft: 2,
                        paddingRight: 0,
                        '&:nth-last-of-type(1)': {
                            paddingRight: 2,
                        },
                    },
                    '.row-title': {
                        backgroundColor: colors.colorMode.background700,
                    },
                },
            },
        },
        faded: {
            tbody: {
                tr: {
                    td: {
                        backgroundColor: colors.colorMode.background700,
                        borderRight: '1px solid',
                        borderColor: colors.colorMode.background800,
                    },
                },
            },
        },
        feasibility: {
            thead: {
                borderBottom: '1px solid',
                borderColor: colors.colorMode.background500,
                th: {
                    borderColor: colors.colorMode.background500,
                    paddingX: 2,
                    paddingY: 2,
                },
            },
            tbody: {
                tr: {
                    borderColor: colors.colorMode.background500,
                    td: {
                        borderColor: colors.colorMode.background500,
                        paddingY: '2px',
                        input: {
                            height: 5,
                            paddingX: 2,
                        },
                    },
                    _odd: {
                        td: {
                            backgroundColor: colors.colorMode.base,
                        },
                    },
                    _even: {
                        td: {
                            backgroundColor: colors.colorMode.background900,
                        },
                    },
                },
            },
        },
        buildings: (props) => ({
            ...props.theme.components.Table.variants.simple(props),
            thead: {
                borderBottom: '1px solid',
                borderColor: colors.colorMode.background500,
                th: {
                    whiteSpace: 'normal',
                    paddingX: 4,
                    paddingY: 2,
                    minWidth: '50px',
                },
            },
        }),
    },
};
const Divider = {
    baseStyle: {
        border: '1px solid',
        borderColor: colors.colorMode.background700,
        opacity: 1,
    },
};
const Link = {
    variants: {
        primary: {
            fontSize: '13px',
            color: 'red.500',
            _hover: {
                color: 'red.600',
            },
        },
        secondary: {
            fontSize: '13px',
            color: colors.colorMode.revertBase,
            fontWeight: 500,
            _hover: {
                color: 'blue.600',
            },
        },
        tertiary: {
            fontSize: '11px',
            fontWeight: 500,
            color: colors.colorMode.font800,
            textDecoration: 'underline',
            _hover: {
                color: colors.colorMode.font900,
            },
        },
    },
};
const Popover = {
    baseStyle: {
        zIndex: 100,
    },
    _popper: {
        baseStyle: {
            zIndex: 100,
        },
    },
};
const Menu = {
    baseStyle: {
        list: {
            backgroundColor: colors.colorMode.background900,
        },
        item: {
            fontWeight: 500,
        },
    },
};
const Switch = {
    baseStyle: {
        track: {
            _checked: {
                backgroundColor: 'blue.500',
            },
        },
    },
};
const extendedTheme = extendTheme({
    config,
    components: {
        Text,
        Heading,
        Input,
        NumberInput,
        Checkbox,
        Select,
        Button,
        IconButton,
        Modal,
        FormLabel,
        Table,
        Divider,
        Tabs,
        Link,
        FormError,
        Popover,
        Menu,
        Switch,
        Textarea,
    },
    colors,
    fonts,
    textStyles: {
        ...headingStyles,
        paragraph: { fontSize: '13px', lineHeight: 1.2 },
        label: {
            fontSize: '13px',
            fontFamily: 'dm-sans, serif',
            lineHeight: '20px',
        },
        small: { fontSize: '11px', lineHeight: '14px' },
        tiny: {
            fontFamily: 'dm-sans, serif',
            fontSize: '0.63rem',
            lineHeight: 'shorter',
        },
        menu: { fontSize: '10px', lineHeight: '13px' },
    },
    styles: {
        global: (props) => ({
            ':root': {
                '--colorModeBase': mode(colors.white, colors.dark['600'])(props),
                '--colorModeRevertBase': mode(colors.blue['500'], colors.white)(props),
                '--colorModeBackground-900': mode(colors.light['900'], colors.dark['900'])(props),
                '--colorModeBackground-800': mode(colors.light['800'], colors.dark['800'])(props),
                '--colorModeBackground-700': mode(colors.light['700'], colors.dark['700'])(props),
                '--colorModeBackground-600': mode(colors.light['600'], colors.dark['600'])(props),
                '--colorModeBackground-500': mode(colors.light['500'], colors.dark['500'])(props),
                '--colorModeFont-900': mode(colors.black, colors.white)(props),
                '--colorModeFont-800': mode(colors.gray['900'], colors.gray['100'])(props),
                '--colorModeFont-700': mode(colors.gray['800'], colors.gray['200'])(props),
                '--colorModeSidebar-800': mode(colors.sidebar['800'], colors.dark['800'])(props),
                '--colorModeSidebar-700': mode(colors.sidebar['700'], colors.dark['700'])(props),
                '--colorModeSidebar-600': mode(colors.sidebar['600'], colors.dark['600'])(props),
                '--colorModeSidebar-500': mode(colors.sidebar['500'], colors.dark['500'])(props),
            },
            body: {
                lineHeight: 1.1,
                backgroundColor: colors.colorMode.background900,
            },
            // Scrollbars
            '&::-webkit-scrollbar': {
                width: '8px',
                height: '8px',
            },
            '&::-webkit-scrollbar-track': {
                width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
                backgroundColor: colors.colorMode.font700,
                borderRadius: '6px',
            },
            '.chakra-table__container::-webkit-scrollbar': {
                width: '10px',
                height: '10px',
            },
            '.chakra-table__container::-webkit-scrollbar-track': {
                width: '12px',
            },
            '.chakra-table__container::-webkit-scrollbar-thumb': {
                backgroundColor: colors.colorMode.background500,
                borderRadius: '6px',
            },
            // Google autocomplete field
            '.pac-container': {
                backgroundColor: colors.colorMode.background900,
                border: '1px',
                boxShadow: 'unset',
            },
            '.pac-item': {
                color: colors.colorMode.font700,
            },
            '.pac-item:hover': {
                backgroundColor: colors.colorMode.background500,
            },
            '.pac-item-selected': {
                backgroundColor: colors.colorMode.background500,
            },
            '.pac-item-query': {
                color: colors.colorMode.font900,
            },
            // Google info windows
            '.gm-style .gm-style-iw': {
                backgroundColor: colors.colorMode.background900,
                color: colors.colorMode.font900,
            },
            '.gm-style .gm-style-iw-t::after': {
                background: 'unset',
                backgroundColor: colors.colorMode.background900,
            },
            '.gm-style-iw-d': {
                overflowY: 'auto !important',
                paddingRight: '20px !important',
                paddingTop: '5px !important',
            },
            '.gm-style-iw-d a': {
                color: colors.blue['500'],
            },
            '.gm-style .gm-style-iw-c .gm-style-iw-d::-webkit-scrollbar-thumb': {
                backgroundColor: colors.colorMode.font700,
            },
            '.gm-style .gm-style-iw-d::-webkit-scrollbar-track, .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece': {
                backgroundColor: colors.colorMode.background900,
            },
            '.poi-info-window div, .poi-info-window a': {
                color: colors.colorMode.font900,
                backgroundColor: colors.colorMode.background900,
                paddingBottom: '2px',
                paddingRight: '2px',
            },
            // Google street view address
            '.gm-iv-address': {
                zIndex: 10,
                backgroundColor: colors.colorMode.background900,
                opacity: 1,
                borderRadius: '5px',
                marginTop: '24px',
                marginLeft: '24px',
                padding: '12px',
                height: 'auto',
            },
            '.gm-iv-address, .gm-iv-address a, .gm-iv-profile-url a .gm-iv-profile-link, .gm-iv-marker .gm-iv-marker-link, .gm-iv-short-address-description, .gm-iv-long-address-description': {
                fontFamily: 'dm-sans, serif',
                color: colors.colorMode.font900,
            },
            mark: {
                backgroundColor: 'var(--chakra-colors-red-300)',
                color: 'inherit',
            },
            // calendar
            '.fc .fc-col-header-cell': {
                borderColor: colors.colorMode.background800 + ' !important',
                color: colors.colorMode.font700,
                fontFamily: 'dm-sans, serif',
                fontSize: '13px',
                fontWeight: '500',
            },
            '.fc .fc-daygrid-day': {
                borderColor: colors.colorMode.background800 + ' !important',
            },
            '.fc .fc-day-today': {
                backgroundColor: colors.colorMode.sidebar700 + ' !important',
            },
            '.fc .fc-day-past': {
                backgroundColor: colors.colorMode.background900 + ' !important',
            },
            '.fc .fc-scrollgrid-section': {
                backgroundColor: colors.colorMode.base + ' !important',
            },
            '.fc .fc-daygrid-day-top': {
                flexDirection: 'row',
                color: colors.colorMode.font900,
                fontFamily: 'dm-sans, serif',
                fontSize: '13px',
                fontWeight: '700',
            },
            '.fc .fc-day-past .fc-daygrid-day-top': {
                color: colors.colorMode.font700,
            },
            '#search-plots-form .chakra-accordion .chakra-collapse': {
                overflow: 'visible !important', // pour éviter que le select des zone passe en dessous de "Recherche avancés des parcelles" (ca commune sans faisaibilité)
            },
        }),
    },
});
export default extendedTheme;
