import * as React from 'react';
import { memo } from 'react';
import { Stack } from '@chakra-ui/react';
import DeathList from 'components/death/DeathList';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useDeath from 'hooks/filter/useDeath';
const EXPORTED_FIELDS = ['dateDeath', 'name', 'firstname', 'sex', 'age'];
const DeathPanel = () => {
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const { deathDepartment, isLoadingDeathDepartment } = useDeath(department);
    return (<Stack paddingX={4} height="100%">
      <DeathList data={deathDepartment} isLoading={isLoadingDeathDepartment} exportFields={EXPORTED_FIELDS}/>
    </Stack>);
};
export default memo(DeathPanel);
