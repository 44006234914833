import { useCallback, useMemo, useState } from 'react';
import { get } from 'api/client';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filterKeyNotReallyThematic, filtersKey, } from 'utils/filters';
import { getNormalizedCoordinates } from 'utils/map';
import { getThematicBaseTileUrlHost2 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
export const FILTERS_THEMATIC = {
    // real estate
    [filtersKey.THEMATIC_MAP_HOUSING_TAX]: 82,
    [filtersKey.THEMATIC_MAP_PROPERTY_TAX]: 125,
    [filtersKey.THEMATIC_MAP_HLM_SOCIAL_HOUSING]: 79,
    [filtersKey.THEMATIC_MAP_OWNERS_PART]: 19,
    [filtersKey.THEMATIC_MAP_VACATION_HOME]: 21,
    [filtersKey.THEMATIC_MAP_HOUSING_AGE]: 20,
    [filtersKey.THEMATIC_MAP_VACANT_HOUSING]: 22,
    [filtersKey.THEMATIC_MAP_NO_CENTRAL_HEATING]: 23,
    [filtersKey.THEMATIC_MAP_SMALL_SURFACES]: 25,
    [filtersKey.THEMATIC_MAP_BUSINESS_PROPERTY_TAX]: 124,
    [filtersKey.THEMATIC_MAP_HOUSING_DENSITY]: 18,
    [filtersKey.THEMATIC_MAP_HOUSING_TYPE]: 17,
    [filtersKey.THEMATIC_MAP_UNBUILT_LAND_PROPERTY_TAX]: 84,
    [filtersKey.THEMATIC_MAP_WASTE_COLLECTION_TAX]: 87,
    // inhabitants
    [filtersKey.THEMATIC_MAP_MONTHLY_INCOME]: 26,
    [filtersKey.THEMATIC_MAP_UNEMPLOYMENT]: 32,
    [filtersKey.THEMATIC_MAP_POPULATION_TYPE]: 2,
    [filtersKey.THEMATIC_MAP_AVERAGE_AGE]: 7,
    [filtersKey.THEMATIC_MAP_GENTRIFICATION]: 234,
    [filtersKey.THEMATIC_MAP_INFLUX_OF_FAMILIES]: 235,
    [filtersKey.THEMATIC_MAP_POPULATION_DENSITY]: 3,
    [filtersKey.THEMATIC_MAP_POPULATION_GROWTH]: 93,
    [filtersKey.THEMATIC_MAP_CHILDREN_AND_TEENAGERS]: 4,
    [filtersKey.THEMATIC_MAP_TRANSPORTATION_TO_WORK]: 78,
    [filtersKey.THEMATIC_MAP_BACCALAUREATE_HOLDERS]: 53,
    [filtersKey.THEMATIC_MAP_PLACE_OF_WORK]: 77,
    [filtersKey.THEMATIC_MAP_FERTILITY_RATE]: 92,
    [filtersKey.THEMATIC_MAP_ANNUITIES_PENSIONS]: 28,
    [filtersKey.THEMATIC_MAP_TAXABLE_HOUSEHOLDS]: 27,
    [filtersKey.THEMATIC_MAP_SENIORS]: 6,
    [filtersKey.THEMATIC_MAP_EXECUTIVES]: 29,
    [filtersKey.THEMATIC_MAP_EMPLOYEES_AND_WORKERS]: 30,
    [filtersKey.THEMATIC_MAP_RETIREES]: 31,
    [filtersKey.THEMATIC_MAP_HOUSEHOLD_SIZE]: 15,
    [filtersKey.THEMATIC_MAP_RESIDENTS_UNDER_25]: 135,
    [filtersKey.THEMATIC_MAP_RESIDENTS_FROM_25_TO_55]: 136,
    [filtersKey.THEMATIC_MAP_RESIDENTS_FROM_55]: 137,
    [filtersKey.THEMATIC_MAP_ONE_CHILD_FAMILIES]: 220,
    [filtersKey.THEMATIC_MAP_TWO_CHILDREN_FAMILIES]: 221,
    [filtersKey.THEMATIC_MAP_THREE_CHILDREN_FAMILIES]: 222,
    [filtersKey.THEMATIC_MAP_FOUR_OR_MORE_CHILDREN_FAMILIES]: 223,
    // environment
    [filtersKey.THEMATIC_MAP_RAIN]: 100,
    [filtersKey.THEMATIC_MAP_SUNLIGHT_EXPOSURE]: 101,
    [filtersKey.THEMATIC_MAP_SPORTS_GROUNDS_AND_HALLS]: 103,
    [filtersKey.THEMATIC_MAP_GREEN_SPACES_AND_GARDENS]: 104,
    [filtersKey.THEMATIC_MAP_FLOWER_TOWNS]: 76,
    [filtersKey.THEMATIC_MAP_HOUSEHOLDS_WITHOUT_A_CAR]: 8,
    [filtersKey.THEMATIC_MAP_LANDSCAPE_TYPE]: 57,
    [filtersKey.THEMATIC_MAP_SUPERMARKETS]: 211,
    [filtersKey.THEMATIC_MAP_RESTAURANTS]: 212,
    [filtersKey.THEMATIC_MAP_EDUCATION]: 213,
    [filtersKey.THEMATIC_MAP_STORES]: 214,
    [filtersKey.THEMATIC_MAP_NIGHT_LIFE]: 215,
    [filtersKey.THEMATIC_MAP_GREEN_SPACES]: 216,
    [filtersKey.THEMATIC_MAP_PUBLIC_TRANSPORT]: 217,
    [filtersKey.THEMATIC_MAP_QUIETNESS]: 218,
    // education
    [filtersKey.THEMATIC_MAP_SCHOOLS_AND_NURSERIES]: 102,
    [filtersKey.THEMATIC_MAP_HIGH_SCHOOL_RESULTS]: 46,
    [filtersKey.THEMATIC_MAP_HIGH_SCHOOL_SELECTIVITY]: 47,
    [filtersKey.THEMATIC_MAP_HIGH_SCHOOLS_ADDED_VALUE]: 48,
    // stores
    [filtersKey.THEMATIC_MAP_GENERAL_PRACTITIONERS]: 110,
    [filtersKey.THEMATIC_MAP_TOURIST_RECEPTION_CAPACITY]: 106,
    // political
    [filtersKey.THEMATIC_MAP_2017_SECOND_ROUND_MACRON]: 1000,
    [filtersKey.THEMATIC_MAP_2017_SECOND_ROUND_LEPEN]: 1001,
    [filtersKey.THEMATIC_MAP_2012_SECOND_ROUND_HOLLANDE]: 122,
    [filtersKey.THEMATIC_MAP_LEFT_RIGHT_BALANCE_OF_POWER]: 121,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_SARKOZY]: 112,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_HOLLANDE]: 113,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_LEPEN]: 114,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_MELENCHON]: 115,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_BAYROU]: 116,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_JOLY]: 117,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_DUPOND_AIGNAN]: 118,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_POUTOU]: 119,
    [filtersKey.THEMATIC_MAP_2012_FIRST_ROUND_ABSTENTION_RATE]: 120,
    [filtersKey.THEMATIC_MAP_2012_SECOND_ROUND_SARKOZY]: 123,
    // safety
    [filtersKey.THEMATIC_MAP_INTENTIONAL_PHYSICAL_HARM]: 126,
    [filtersKey.THEMATIC_MAP_DAMAGE_TO_PROPERTY]: 128,
    [filtersKey.THEMATIC_MAP_BURGLARIES]: 129,
    [filtersKey.THEMATIC_MAP_THEFTS_RELATED_TO_CARS_AND_MOTORCYCLES]: 130,
    [filtersKey.THEMATIC_MAP_PROPERTY_DESTRUCTION_AND_DEGRADATION]: 131,
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilter) => (coord, zoom) => {
    if (filterKeyNotReallyThematic.includes(enabledFilter) ||
        !Object.keys(FILTERS_THEMATIC).includes(enabledFilter)) {
        return null;
    }
    const normalizedCoordinates = getNormalizedCoordinates(coord, zoom);
    if (!normalizedCoordinates) {
        return null;
    }
    const idTheme = FILTERS_THEMATIC[enabledFilter];
    const bound = Math.pow(2, zoom);
    return (getThematicBaseTileUrlHost2() +
        'cache/tms/1.0.0/DemoStat_statistiques_thematiques_' +
        `${idTheme}Tile@g/${zoom}/${normalizedCoordinates.x}/${bound - normalizedCoordinates.y - 1}.png`);
};
export const fetchThematicMapLegend = async (themeId) => get(null, `legacy/thematic-map-legend/${themeId}`);
const useThematicMapLayers = () => {
    const filters = useFilters();
    const [legends, setLegends] = useState({});
    const enabledMap = useMemo(() => filters[filtersKey.THEMATIC_MAPS] || null, [filters]);
    const getLegends = useCallback(async (enabledFilters) => {
        if (!enabledMap || !enabledFilters.includes(filtersKey.THEMATIC_MAPS)) {
            return {};
        }
        const enabledFilter = filters[filtersKey.THEMATIC_MAPS];
        if (filterKeyNotReallyThematic.includes(enabledFilter) ||
            !Object.keys(FILTERS_THEMATIC).includes(enabledFilter)) {
            return {};
        }
        const newLegends = { ...legends };
        if (newLegends[enabledMap]) {
            return newLegends[enabledMap];
        }
        let enabledLegends = {};
        await fetchThematicMapLegend(FILTERS_THEMATIC[enabledMap]).then((result) => {
            const resultLegends = {};
            result.forEach((legend) => {
                const legendId = `@${enabledMap}@${legend.color}@${legend.label}`;
                resultLegends[legendId] = {
                    ...legend,
                    layerKey: enabledMap,
                };
            });
            newLegends[enabledMap] = resultLegends;
            enabledLegends = { ...resultLegends };
        });
        setLegends(newLegends);
        return enabledLegends;
    }, [legends, enabledMap]);
    const getTileUrl = useCallback((map, tileWidth, tileHeight) => getLayerTileUrl(map, tileWidth, tileHeight, enabledMap), [enabledMap]);
    createTilesLayerHook({
        getLayerTileUrl: getTileUrl,
        position: CST_POSITIONS[filtersKey.THEMATIC_MAPS],
        getLegends,
        refreshParam: enabledMap,
    })(filtersKey.THEMATIC_MAPS);
};
const ThematicMapLayers = () => {
    useThematicMapLayers();
    return null;
};
export default ThematicMapLayers;
