import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, HStack, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_RACCOURCI } from 'analytics/useAnalytics';
import PlotInfoMultiText from 'components/tabs/plots/PlotSections/info/PlotInfoMultiText';
import PlotInfoText from 'components/tabs/plots/PlotSections/info/PlotInfoText';
import { usePlotSummary } from 'hooks/contexts/useLegendContext';
import { useSetSectionHashToUrl } from 'hooks/contexts/useTabsContext';
import usePlotFarming from 'hooks/plot/usePlotFarming';
import usePlotOwnersInfo from 'hooks/plot/usePlotOwnersInfo';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import SurfaceIcon from 'images/icons/Surface';
import { OWNER_PLOT_TAB, RPG_PLOT_TAB } from 'types/api';
import { getPlotCadastreId } from 'utils/plots';
const PlotSummaryInfo = () => {
    const { t } = useTranslation();
    const { hasPlotInfo, hasPlotTab } = useSiteConfiguration();
    const plotSummary = usePlotSummary();
    const { currentSite } = useCurrentSite();
    const { trackEvent } = useAnalytics();
    const setSectionHashToUrl = useSetSectionHashToUrl();
    const { plotFarming, isInitialLoading: isLoadingFarming } = usePlotFarming(hasPlotTab(RPG_PLOT_TAB), plotSummary);
    const { countOwner } = usePlotOwnersInfo(currentSite?.ownerSubscription, currentSite?.legacyId, plotSummary, hasPlotTab(OWNER_PLOT_TAB));
    return (<>
      <VStack spacing={1} alignItems="stretch">
        <Text fontWeight="bold" textStyle="paragraph" textAlign="left" data-cy="plot_cadastre_id">
          {getPlotCadastreId(plotSummary)}
        </Text>
        <Text textStyle="paragraph" data-cy="plot_address">
          {plotSummary?.address?.length ? plotSummary?.address[0] : ''}
        </Text>
      </VStack>
      <HStack>
        {hasPlotInfo('information') && hasPlotInfo('zone') && (<PlotInfoText value={plotSummary?.zone.split(';').join(' ')} label={t('legends.plot_info.zone')} id="plot_zone"/>)}
        <HStack>
          <SurfaceIcon width={20} height={20}/>
          <Text textStyle="paragraph" fontWeight={600} data-cy="plot_surface">
            {`${plotSummary?.surface} m²`}
          </Text>
        </HStack>
      </HStack>
      {hasPlotTab(RPG_PLOT_TAB) && (<PlotInfoMultiText values={plotFarming?.length > 0
                ? plotFarming?.map((value) => {
                    return value.name + ' (' + value.info + ')';
                })
                : [t('global.none_2').toLowerCase()]} label={t('legends.plot_info.farming')} id="plot_farming" isLoading={isLoadingFarming}/>)}
      {hasPlotTab(OWNER_PLOT_TAB) && countOwner > 0 && (<HStack spacing="3px" alignItems="center">
          <Text textStyle="paragraph">
            {t('tabs.plots.section.plot.owners')}
          </Text>
          <Button as={Link} to={{
                pathname: `/parcelle/${plotSummary.townId}/${plotSummary.lat}/${plotSummary.lng}/${plotSummary.id}`,
                hash: OWNER_PLOT_TAB,
            }} height="auto" minW="auto" padding={0} size="sm" variant="link-primary" textStyle="paragraph" onClick={() => {
                setSectionHashToUrl(OWNER_PLOT_TAB);
                trackEvent({
                    category: ANALYTICS_RACCOURCI,
                    action: 'Onglet Propriétaires Légende',
                });
            }}>
            {countOwner}
          </Button>
        </HStack>)}
    </>);
};
export default PlotSummaryInfo;
