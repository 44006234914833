import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_ONGLET } from 'analytics/useAnalytics';
import { snakeCase } from 'lodash';
import { getBuildingsFieldName } from 'components/export/plot/buildingsFormatter';
import Table from 'components/table/Table';
import { getPlotSkeleton } from 'components/tabs/plots/PlotPannelSkeletons';
import { columnsBuilding, TABLE_HEADERS, } from 'components/tabs/plots/PlotSections/PlotBuilding';
import { useSelectedBuilding, useSetSelectedBuilding, } from 'hooks/contexts/useTabsContext';
import useProjectBuildings from 'hooks/crm/useProjectBuildings';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PROJECT_BUILDING } from 'types/api';
import { getBuildingDestinationFromCode } from 'utils/constants';
const formatExportHeader = (t) => (header) => getBuildingsFieldName({ fieldName: header, t });
const formatExportValue = (value, field) => {
    if (field === 'purpose') {
        return getBuildingDestinationFromCode(value);
    }
    return value;
};
const ProjectBuilding = ({ project, }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const { hasProjectTab } = useSiteConfiguration();
    const { data: buildings, isInitialLoading: isLoadingBuildings } = useProjectBuildings(project?.id, hasProjectTab(PROJECT_BUILDING));
    const selectedBuilding = useSelectedBuilding();
    const setSelectedBuilding = useSetSelectedBuilding();
    const isRowHighlighted = (building) => {
        return building.buildingId === selectedBuilding;
    };
    const onRowSelection = (selectedRows) => {
        trackEvent({
            category: ANALYTICS_ONGLET,
            action: 'Bâtiment clic ligne tableau',
        });
        selectedRows.forEach((selectedRow) => {
            setSelectedBuilding(selectedRow.original.buildingId);
        });
    };
    const skeleton = getPlotSkeleton();
    if (isLoadingBuildings)
        return skeleton;
    if (!isLoadingBuildings && !buildings) {
        return (<Box margin={4}>
        <Text textStyle="paragraph">
          {t('tabs.project.section.building.not_available')}
        </Text>
      </Box>);
    }
    return (<Box width="100%">
      <Table id="project_buildings" variant="buildings" columns={columnsBuilding} isRowHighlighted={isRowHighlighted} onRowSelection={onRowSelection} data={Object.values(buildings)} enabledMultiRowSelection={false} exportedFields={TABLE_HEADERS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} analyticsAction={t('tabs.project.section.building.title') + ' projet'} exportTitle={t('tabs.project.section.building.title') + ' - ' + project.label} exportName={t('tabs.project.export.filename.building') +
            '_' +
            snakeCase(project.label)}/>
      <Text textStyle="small">{t('tabs.plots.section.building.source')}</Text>
    </Box>);
};
export default ProjectBuilding;
