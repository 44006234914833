import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, post } from 'api/client';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IODocument } from 'types/api';
import { TYPE_DOCUMENT, TYPE_PHOTO } from 'utils/document';
import { getPlotProjectsQueryKey } from './usePlotProjectsList';
import { getProjectFilesQueryKey } from './useProjectFiles';
const fetchPlotFiles = (plotId) => () => get(iots.array(IODocument), `legacy/plot/${plotId}/files`);
const deletePlotFile = ({ document }) => {
    return del(null, `legacy/plot/file/${document.id}/delete`);
};
export const QUERY_KEY = 'plot-files';
const addPlotDocument = ({ plotId, files, }) => {
    const formData = new FormData();
    Object.values(files).forEach((f) => {
        formData.append('files[]', f);
    });
    formData.append('typeId', TYPE_DOCUMENT.toString());
    return post(null, `plot/${plotId}/add-document`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
export const getPlotFileQueryKey = (plotId) => [QUERY_KEY, plotId];
const addPlotPhoto = ({ plotId, files, }) => {
    const formData = new FormData();
    Object.values(files).forEach((f) => {
        formData.append('files[]', f);
    });
    formData.append('typeId', TYPE_PHOTO.toString());
    return post(null, `plot/${plotId}/add-document`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const usePlotFiles = (plotId) => {
    const { t } = useTranslation();
    const { getSuccessToast, getErrorToast } = useCustomToast();
    const queryClient = useQueryClient();
    const { data: files, isInitialLoading: isLoading } = useQuery({
        queryKey: getPlotFileQueryKey(plotId),
        queryFn: fetchPlotFiles(plotId),
        enabled: !!plotId,
    });
    const onErrorCallback = useOnError();
    const onSuccessCallback = () => {
        const plotProjects = queryClient.getQueryData(getPlotProjectsQueryKey(plotId));
        // Invalidate project contain the plot
        plotProjects.forEach((project) => {
            queryClient.invalidateQueries({
                queryKey: getProjectFilesQueryKey(project.id),
            });
        });
        queryClient.invalidateQueries({ queryKey: getPlotFileQueryKey(plotId) });
    };
    const { mutate: addDocument, isPending: isLoadingAddDocument } = useMutation({
        mutationFn: addPlotDocument,
        onSuccess: () => {
            getSuccessToast({
                title: t('tabs.plots.section.my_crm.documents.document_added'),
                id: 'crm_document_added',
            });
            onSuccessCallback();
        },
        onError: (err) => {
            getErrorToast({
                title: t('global.error'),
            });
            onErrorCallback(err);
        },
    });
    const { mutate: addPhoto, isPending: isLoadingAddPhoto } = useMutation({
        mutationFn: addPlotPhoto,
        onSuccess: () => {
            getSuccessToast({
                title: t('tabs.plots.section.my_crm.documents.picture_added'),
                id: 'crm_picture_added',
            });
            onSuccessCallback();
        },
        onError: (err) => {
            getErrorToast({
                title: t('global.error'),
            });
            onErrorCallback(err);
        },
    });
    const { mutate: deleteFile } = useMutation({
        mutationFn: deletePlotFile,
        onError: (err) => {
            getErrorToast({
                title: t('global.error'),
            });
            onErrorCallback(err);
        },
        onSuccess: () => {
            onSuccessCallback();
            getSuccessToast({
                title: t('tabs.plots.section.my_crm.documents.file_deleted'),
                id: 'crm_file_deleted',
            });
        },
    });
    const documents = files?.filter((file) => file.typeId === TYPE_DOCUMENT) || [];
    const photos = files?.filter((file) => file.typeId === TYPE_PHOTO) || [];
    return {
        documents,
        photos,
        isLoading,
        deleteFile,
        addDocument,
        isLoadingAddDocument,
        addPhoto,
        isLoadingAddPhoto,
    };
};
export default usePlotFiles;
