import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const layers = [];
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_ARTERIA)) {
        layers.push('reseau_fibre_arteria');
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_TERRALPHA)) {
        layers.push('reseau_fibre_terralpha_polyline');
        layers.push('reseau_fibre_terralpha_point');
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_IELO)) {
        layers.push('reseau_fibre_ielo_fibre_region');
        layers.push('reseau_fibre_ielo_fibre_polyline');
        layers.push('reseau_fibre_ielo_fibre_point');
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_VORTEX)) {
        layers.push('reseau_fibre_vortex_region');
        layers.push('reseau_fibre_vortex_point');
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_YVELINE_FIBRE)) {
        layers.push('reseau_fibre_yvelisnes_fibre_region');
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_CELESTE)) {
        layers.push('reseau_fibre_celeste_fibre_region');
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_XP_FIBRE)) {
        layers.push('reseau_fibre_xp_fibre_region');
    }
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_fibre_datacenter', layers.join(','), zoom, coord, tileWidth, tileHeight);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_ARTERIA)) {
        legends.fiber_operator_arteria = {
            layerKey: filtersKey.FIBER_OPERATOR_ARTERIA,
            label: 'fiber_operator_polyline',
            needsTranslate: true,
            color: '#FD02BF',
            polygonType: VECTOR_TYPES.polyline,
            width: 20,
            borderWidth: 2,
        };
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_TERRALPHA)) {
        legends.fiber_operator_terralpha_polyline = {
            layerKey: filtersKey.FIBER_OPERATOR_TERRALPHA,
            label: 'fiber_operator_polyline',
            needsTranslate: true,
            color: '#8D8989',
            polygonType: VECTOR_TYPES.polyline,
            width: 20,
            borderWidth: 2,
        };
        legends.fiber_operator_terralpha_point = {
            layerKey: filtersKey.FIBER_OPERATOR_TERRALPHA,
            label: 'fiber_operator_point',
            needsTranslate: true,
            color: '#8D8989',
            polygonType: VECTOR_TYPES.point,
            width: 10,
            height: 10,
        };
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_IELO)) {
        legends.fiber_operator_ielo = {
            layerKey: filtersKey.FIBER_OPERATOR_IELO,
            label: 'fiber_operator_region',
            needsTranslate: true,
            color: '#FFFF00',
        };
        legends.fiber_operator_ielo_point = {
            layerKey: filtersKey.FIBER_OPERATOR_IELO,
            label: 'fiber_operator_point',
            needsTranslate: true,
            color: '#FFB200',
            polygonType: VECTOR_TYPES.point,
            width: 10,
            height: 10,
        };
        legends.fiber_operator_ielo_polyline = {
            layerKey: filtersKey.FIBER_OPERATOR_IELO,
            label: 'fiber_operator_polyline',
            needsTranslate: true,
            color: '#FFB200',
            polygonType: VECTOR_TYPES.polyline,
            width: 20,
            borderWidth: 2,
        };
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_VORTEX)) {
        legends.fiber_operator_vortex_point = {
            layerKey: filtersKey.FIBER_OPERATOR_VORTEX,
            label: 'fiber_operator_point',
            needsTranslate: true,
            color: '#096819',
            polygonType: VECTOR_TYPES.point,
            width: 10,
            height: 10,
        };
        legends.fiber_operator_vortex_region = {
            layerKey: filtersKey.FIBER_OPERATOR_VORTEX,
            label: 'fiber_operator_region',
            needsTranslate: true,
            color: '#20CB3F',
        };
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_YVELINE_FIBRE)) {
        legends.fiber_operator_yvelines_fibre = {
            layerKey: filtersKey.FIBER_OPERATOR_YVELINE_FIBRE,
            label: 'fiber_operator_polyline',
            needsTranslate: true,
            color: '#EC8080',
        };
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_CELESTE)) {
        legends.fiber_operator_celeste_weak = {
            layerKey: filtersKey.FIBER_OPERATOR_CELESTE,
            label: 'fiber_operator_weak',
            needsTranslate: true,
            color: '#C8DCF0',
        };
        legends.fiber_operator_celeste_medium = {
            layerKey: filtersKey.FIBER_OPERATOR_CELESTE,
            label: 'fiber_operator_medium',
            needsTranslate: true,
            color: '#73B2D8',
        };
        legends.fiber_operator_celeste_strong = {
            layerKey: filtersKey.FIBER_OPERATOR_CELESTE,
            label: 'fiber_operator_strong',
            needsTranslate: true,
            color: '#2979B9',
        };
        legends.fiber_operator_celeste_very_strong = {
            layerKey: filtersKey.FIBER_OPERATOR_CELESTE,
            label: 'fiber_operator_very_strong',
            needsTranslate: true,
            color: '#08306b',
        };
    }
    if (enabledFilters.includes(filtersKey.FIBER_OPERATOR_XP_FIBRE)) {
        legends.fiber_operator_xp_fibre_rip = {
            layerKey: filtersKey.FIBER_OPERATOR_XP_FIBRE,
            label: 'fiber_operator_xp_fibre_rip',
            needsTranslate: true,
            color: '#0070FF',
        };
        legends.fiber_operator_xp_fibre_amel = {
            layerKey: filtersKey.FIBER_OPERATOR_XP_FIBRE,
            label: 'fiber_operator_xp_fibre_amel',
            needsTranslate: true,
            color: '#E600A9',
        };
        legends.fiber_operator_xp_fibre_private = {
            layerKey: filtersKey.FIBER_OPERATOR_XP_FIBRE,
            label: 'fiber_operator_xp_fibre_private',
            needsTranslate: true,
            color: '#FF0000',
        };
    }
    return legends;
};
const useFiberOperatorLayer = (filterKey) => {
    createTilesLayerHook({
        getLayerTileUrl,
        getLegends,
        position: CST_POSITIONS[filterKey],
        opacity: 0.6,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filterKey);
};
const FiberOperatorLayer = ({ filterKey, }) => {
    useFiberOperatorLayer(filterKey);
    return null;
};
export default FiberOperatorLayer;
