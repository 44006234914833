import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormControl, FormLabel, HStack, Input, Stack } from '@chakra-ui/react';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import { multiSelectStyles } from 'components/inputs/MultiSelectInput';
import SearchCheckbox from 'components/inputs/SearchCheckbox';
import SelectInput from 'components/inputs/SelectInput';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { COOWNERS_LOTS_KEY, NOISE_SENSIBILITY_KEY, } from 'utils/plotsSearch';
import { getPlotSelectOptions } from './ActualBuildingSearch';
export const plotTypeSearchFields = [
    'undevelopedPlots',
    'roadLinear',
    'removeStreets',
    'coOwnerLots',
    'wallMaterial',
    'roofingMaterial',
    'noiseSensibility',
    'excludeDangerZone',
];
const PlotTypeSearch = ({ isLoading, hasSearchField, form, selectedTown, }) => {
    const { t } = useTranslation();
    const { control } = form;
    const { register } = useFormContext();
    return (<Stack alignItems="start" padding={4} boxShadow={accordionBoxShadow} bgColor="colorMode.sidebar500" spacing={2}>
      {hasSearchField('undevelopedPlots') && (<>
          <FormControl display="flex" isDisabled={isLoading}>
            <SearchCheckbox name="undevelopedPlots"/>
            <FormLabel margin="0" paddingLeft={3} cursor="pointer">
              {t('sidebar.search_plots.search_undeveloped_plots')}
            </FormLabel>
          </FormControl>
        </>)}

      {hasSearchField('roadLinear') && (<FormControl isDisabled={isLoading}>
          <FormLabel marginBottom={1}>
            {t('sidebar.search_plots.road_linear')}
          </FormLabel>
          <HStack>
            <AreaInputWrapper>
              <Input size="sm" placeholder="Min" type="number" {...register('roadLinearMin')}/>
            </AreaInputWrapper>
            <AreaInputWrapper>
              <Input size="sm" placeholder="Max" type="number" {...register('roadLinearMax')}/>
            </AreaInputWrapper>
          </HStack>
        </FormControl>)}

      {hasSearchField('coOwnerLots') && (<SelectInput isLoading={isLoading} name="coOwnerLots" size="sm" label={t('sidebar.search_plots.coOwnerLots')} options={getPlotSelectOptions(COOWNERS_LOTS_KEY, 'coOwnerLots', t)}/>)}

      {hasSearchField('noiseSensibility') && (<FormControl isDisabled={isLoading} data-cy="noise_sensibility">
          <FormLabel htmlFor="zones" marginBottom={1}>
            {t('sidebar.search_plots.noise_sensibility')}
          </FormLabel>
          <Controller name="noiseSensibility" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_noise_sensibility')} closeMenuOnSelect={false} isMulti options={getPlotSelectOptions(NOISE_SENSIBILITY_KEY, 'noise_sensibility', t)} styles={multiSelectStyles} loadingMessage={() => t('global.loading')}/>)}/>
        </FormControl>)}

      {hasSearchField('excludeDangerZone') && (<FormControl display="flex" isDisabled={isLoading}>
          <SearchCheckbox name="excludeDangerZone"/>
          <FormLabel margin="0" paddingLeft={3} cursor="pointer">
            {t('sidebar.search_plots.exclude_danger_zone')}
          </FormLabel>
        </FormControl>)}

      {hasSearchField('removeStreets') && selectedTown?.hasFeasibility && (<FormControl display="flex" isDisabled={isLoading}>
          <SearchCheckbox name="removeStreets"/>
          <FormLabel margin="0" paddingLeft={3} cursor="pointer">
            {t('sidebar.search_plots.remove_streets')}
          </FormLabel>
        </FormControl>)}
    </Stack>);
};
export default PlotTypeSearch;
