import pick from 'lodash/pick';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const URL_KEYS = {
    [filtersKey.AERONAUTIC_ZONE_CRT]: 'contraites_aeronautiques_zones_ctr',
    [filtersKey.AERONAUTIC_ZONE_P]: 'contraites_aeronautiques_zones_p',
    [filtersKey.AERONAUTIC_ZONE_ZMT]: 'contraites_aeronautiques_zones_zmt',
};
const getLayerTileUrl = (map, tileWidth, tileHeight, enabledFilters) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const urlParts = Object.values(pick(URL_KEYS, enabledFilters));
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_contraites_aeronautiques.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        urlParts.join(',') +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:3857&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.AERONAUTIC_ZONE_CRT)) {
        legends.aeronautic_zone_crt = {
            layerKey: filtersKey.AERONAUTIC_ZONE_CRT,
            label: 'aeronautic_zone_crt',
            needsTranslate: true,
            color: '#ffa532',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.AERONAUTIC_ZONE_P)) {
        legends.aeronautic_zone_p = {
            layerKey: filtersKey.AERONAUTIC_ZONE_P,
            label: 'aeronautic_zone_p',
            needsTranslate: true,
            color: '#fa3e3e',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    if (enabledFilters.includes(filtersKey.AERONAUTIC_ZONE_ZMT)) {
        legends.aeronautic_zone_zmt = {
            layerKey: filtersKey.AERONAUTIC_ZONE_ZMT,
            label: 'aeronautic_zone_zmt',
            needsTranslate: true,
            color: '#a049ff',
            borderColor: '#888888',
            borderWidth: 2,
            opacity: 0.6,
        };
    }
    return legends;
};
export const AERONAUTIC_FILTERS = [
    filtersKey.AERONAUTIC_ZONE_CRT,
    filtersKey.AERONAUTIC_ZONE_P,
    filtersKey.AERONAUTIC_ZONE_ZMT,
];
const useAeronauticLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS.AERONAUTIC_FILTERS,
        getLegends,
    })(AERONAUTIC_FILTERS);
};
const AeronauticLayers = () => {
    useAeronauticLayers();
    return null;
};
export default AeronauticLayers;
