import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_environnement', 'debroussaillement', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.debroussaillage = {
        layerKey: filtersKey.DEBROUSSAILLAGE,
        label: 'debroussaillage',
        needsTranslate: true,
        color: '#DF8ED8',
        opacity: 0.6,
    };
    return legends;
};
const useDebroussaillageLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.DEBROUSSAILLAGE],
        getLegends,
        opacity: 0.6,
        tileWidth: 1024,
        tileHeight: 1024,
    };
    createTilesLayerHook(options)(filtersKey.DEBROUSSAILLAGE);
};
const DebroussaillageLayer = () => {
    useDebroussaillageLayer();
    return null;
};
export default DebroussaillageLayer;
