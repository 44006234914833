import * as React from 'react';
import { TABS_VIEW_FULL } from 'context/LayoutContext';
import { STREET_VIEW_MODE_FULL } from 'context/LayoutContext';
import { useStreetViewMode, useTabsView } from 'hooks/contexts/useLayoutContext';
import ContextMenu from './ContextMenu';
import { Legend } from './Legend';
import { Toolbar } from './Toolbar';
const MapTools = ({ mapHeight }) => {
    const streetViewMode = useStreetViewMode();
    const tabsView = useTabsView();
    return (<>
      <Toolbar />
      {streetViewMode !== STREET_VIEW_MODE_FULL &&
            tabsView !== TABS_VIEW_FULL && (<>
            <Legend mapHeight={mapHeight}/>
            <ContextMenu />
          </>)}
    </>);
};
export default MapTools;
