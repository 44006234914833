import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, VStack } from '@chakra-ui/react';
import BuildingConsumptionSliderExport from 'components/inputs/BuildingConsumptionSliderExport';
import DistributionPostSection from 'components/tabs/plot-project/Sections/DistributionPostSection';
import SourcePostSection from 'components/tabs/plot-project/Sections/SourcePostSection';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
const PlotEnergy = ({ plot }) => {
    const { t } = useTranslation();
    const { hasLayersTree } = useSiteConfiguration();
    return (<VStack alignItems="flex-start" padding={4} color="var(--colorModeFont-800)">
      <SourcePostSection plotId={plot?.id}/>
      <DistributionPostSection plotId={plot?.id}/>
      {hasLayersTree(['regulatory', 'easements', 'energy', 'consumption']) && (<Box width="full">
          <Heading as="h4" variant="h4" marginBottom="3px">
            {t('tabs.plots.section.energy.consumption')}
          </Heading>
          <BuildingConsumptionSliderExport lat={plot?.lat} lng={plot?.lng} plotNumber={plot?.plotNumber}/>
        </Box>)}
    </VStack>);
};
export default PlotEnergy;
