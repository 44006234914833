import * as React from 'react';
import { memo, useState } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Button, HStack, Link, Stack, Text } from '@chakra-ui/react';
import * as iots from 'io-ts';
import snakeCase from 'lodash/snakeCase';
import SirenLink from 'components/links/SirenLink';
import Table from 'components/table/Table';
import SelectRows from 'components/tabs/utils/SelectRows';
import useUserPlotsList from 'hooks/crm/useUserPlotsList';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { filtersKey } from 'utils/filters';
import { formatDate } from 'utils/formatDate';
import { formatNumberToLocale } from 'utils/formatNumber';
import { arrayHasSome, inDateRange } from 'utils/search';
import CompanyOwnedPlotsCadastreButton from './CompanyOwnedPlotsCadastreButton';
export const IOCompanyOwned = iots.type({
    siren: iots.string,
    department: iots.string,
    town: iots.string,
    officialNoticeNumber: iots.union([iots.string, iots.null]),
    fileNumber: iots.union([iots.string, iots.null]),
    companyName: iots.string,
});
export const IOCompanyOwnedPlotsTableData = iots.intersection([
    IOCompanyOwned,
    iots.type({
        cadastre: iots.union([iots.string, iots.null]),
        goodType: iots.union([iots.string, iots.null]),
        surface: iots.union([iots.number, iots.null]),
        area: iots.union([iots.string, iots.null]),
        allowedLevels: iots.union([iots.number, iots.null]),
        buildingRights: iots.union([iots.number, iots.null]),
        ownershipType: iots.union([iots.string, iots.null]),
        plotId: iots.union([iots.string, iots.null]),
        townId: iots.union([iots.string, iots.null]),
        xy: iots.union([iots.string, iots.null]),
        judgmentDate: iots.union([iots.string, iots.null]),
    }),
]);
export const getColumnHeader = (column, suffix = null) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.company_owned_plots.columns.${column}`)}
    </Translation>
    {suffix}
  </Text>);
export const SIREN_COLUMN = {
    accessorKey: 'siren',
    header: () => getColumnHeader('siren'),
    cell: (info) => (<SirenLink siren={info.getValue()} analyticDetail="Pappers Propriétaires" showExternalLink/>),
    filterFn: arrayHasSome,
};
export const DEPARTMENT_COLUMN = {
    accessorKey: 'department',
    header: () => getColumnHeader('department'),
    cell: (info) => <Text textAlign="right">{info.getValue()}</Text>,
    filterFn: arrayHasSome,
    enableGlobalFilter: false,
};
export const TOWN_COLUMN = {
    accessorKey: 'town',
    header: () => getColumnHeader('town'),
    cell: (info) => info.getValue(),
    enableGlobalFilter: false,
    filterFn: arrayHasSome,
};
const getCadastreColumn = (setNoPlotsSelected) => ({
    accessorKey: 'cadastre',
    header: ({ table }) => {
        return (<>
        <Stack direction="row">
          <SelectRows {...{
            checked: table
                .getFilteredRowModel()
                .rows.every((row) => row.getIsSelected()),
            toggleRow: table.getToggleAllRowsSelectedHandler(),
            setNoItemIsSelected: setNoPlotsSelected,
        }}/>
          <Text as="span" variant="primary">
            {getColumnHeader('cadastre')}
          </Text>
        </Stack>
      </>);
    },
    cell: (info) => {
        const plotId = info.row.original.plotId;
        return (<HStack>
        {plotId !== '0' && (<SelectRows {...{
                checked: info.row.getIsSelected(),
                disabled: !info.row.getCanSelect(),
                toggleRow: info.row.getToggleSelectedHandler(),
            }}/>)}
        <CompanyOwnedPlotsCadastreButton data={info.row.original}/>
      </HStack>);
    },
    filterFn: 'arrIncludesSome',
});
const GOOD_TYPE_COLUMN = {
    accessorKey: 'goodType',
    header: () => getColumnHeader('good_type'),
    cell: (info) => info.getValue(),
    enableGlobalFilter: false,
    filterFn: 'arrIncludesSome',
};
const SURFACE_COLUMN = {
    accessorKey: 'surface',
    header: () => getColumnHeader('surface'),
    cell: (info) => info.getValue() ?? '',
    enableGlobalFilter: false,
    filterFn: 'inNumberRange',
};
const AREA_COLUMN = {
    accessorKey: 'area',
    header: () => getColumnHeader('area'),
    cell: (info) => info.getValue() ?? '',
    enableGlobalFilter: false,
    filterFn: 'arrIncludesSome',
};
const ALLOWED_LEVELS_COLUMN = {
    accessorKey: 'allowedLevels',
    header: () => getColumnHeader('allowed_levels', '*'),
    cell: (info) => info.getValue() ? (<Translation>
        {(t, { lng }) => formatNumberToLocale(info.getValue(), lng)}
      </Translation>) : (''),
    enableGlobalFilter: false,
    filterFn: 'inNumberRange',
};
const BUILDING_RIGHTS_COLUMN = {
    accessorKey: 'buildingRights',
    header: () => getColumnHeader('building_rights', '*'),
    cell: (info) => info.getValue(),
    enableGlobalFilter: false,
    filterFn: 'inNumberRange',
};
const OWNERSHIP_TYPE_COLUMN = {
    accessorKey: 'ownershipType',
    header: () => getColumnHeader('ownership_type'),
    cell: (info) => info.getValue(),
    enableGlobalFilter: false,
    filterFn: 'arrIncludesSome',
};
export const JUDGMENT_DATE_COLUMN = {
    accessorKey: 'judgmentDate',
    header: () => getColumnHeader('judgment_date'),
    cell: (info) => formatDate(info.getValue()),
    enableGlobalFilter: false,
    filterFn: inDateRange,
};
export const OFFICIAL_JOURNAL_COLUMN = {
    accessorKey: 'officialNoticeNumber',
    header: () => getColumnHeader('official_notice_number'),
    cell: (info) => {
        const label = info.getValue();
        const fileNumber = info.row.original.fileNumber?.replace('PCL_BX', '') || '';
        const officialNoticeNumber = info.row.original.officialNoticeNumber?.toString() || '';
        return !label || !fileNumber || !officialNoticeNumber ? null : (<Link variant="secondary" isExternal href={`https://www.bodacc.fr/annonce/detail-annonce/${fileNumber.substring(0, 1)}/${fileNumber.substring(1)}/${officialNoticeNumber}`}>
        <Translation>
          {(t) => t(`tabs.extra_tabs.company_owned_plots.open_judgment`)}
        </Translation>
      </Link>);
    },
    enableColumnFilter: false,
    enableGlobalFilter: false,
    enableSorting: false,
};
export const COMPANY_NAME_COLUMN = {
    accessorKey: 'companyName',
    header: () => getColumnHeader('company_name'),
    cell: (info) => info.getValue(),
    filterFn: 'arrIncludesSome',
};
const EXPORTED_FIELDS = [
    'siren',
    'department',
    'town',
    'plotId',
    'cadastre',
    'goodType',
    'surface',
    'area',
    'allowedLevels',
    'buildingRights',
    'ownershipType',
    'judgmentDate',
    'officialNoticeNumber',
    'companyName',
];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.company_owned_plots.columns.${snakeCase(header)}`);
const formatExportValue = (value, field, data) => {
    if (field === 'officialNoticeNumber') {
        if (!data.fileNumber || !data.officialNoticeNumber) {
            return '';
        }
        return `https://www.bodacc.fr/annonce/detail-annonce/${data.fileNumber.substring(0, 1)}/${data.fileNumber.substring(1)}/${data.officialNoticeNumber}`;
    }
    return value;
};
const CompanyOwnedPlotsTable = ({ id, category, data, isLoading, message, count }) => {
    const { t } = useTranslation();
    const { addPlots } = useUserPlotsList();
    const { hasDashboardItem } = useSiteConfiguration();
    const [selectedPlots, setSelectedPlots] = useState({});
    const [, setNoPlotsSelected] = useState(true);
    const handleRowSelection = (rows, isAllSelected, isNoneSelected) => {
        setNoPlotsSelected(isNoneSelected);
        const plots = {};
        rows.forEach((row) => {
            return (plots[row.plotId] = { plotId: row.plotId });
        });
        setSelectedPlots(plots);
        if (isAllSelected) {
            setNoPlotsSelected(!isAllSelected);
        }
    };
    const addSelectedPlots = () => {
        if (Object.keys(selectedPlots).length) {
            addPlots({ plots: Object.values(selectedPlots) }, {
                onSuccess: () => setSelectedPlots({}),
            });
        }
    };
    const getColumns = () => [
        {
            id: 'columns',
            columns: [
                getCadastreColumn(setNoPlotsSelected),
                DEPARTMENT_COLUMN,
                TOWN_COLUMN,
                {
                    accessorKey: 'siren',
                    header: () => getColumnHeader('siren'),
                    cell: (info) => info.getValue(),
                    filterFn: arrayHasSome,
                },
                GOOD_TYPE_COLUMN,
                SURFACE_COLUMN,
                AREA_COLUMN,
                ALLOWED_LEVELS_COLUMN,
                BUILDING_RIGHTS_COLUMN,
                OWNERSHIP_TYPE_COLUMN,
                JUDGMENT_DATE_COLUMN,
                OFFICIAL_JOURNAL_COLUMN,
                COMPANY_NAME_COLUMN,
            ],
        },
    ];
    return (<Stack paddingX={4} height="calc(100% - 20px)">
      <Table handleRowSelection={handleRowSelection} variant="simple" id={id} columns={getColumns()} data={data} isLoading={isLoading} emptyMessage={message} exportTitle={t(`tabs.extra_tabs.${category}.title`, { count })} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue} footerExtend={hasDashboardItem(filtersKey.USER_PLOTS) && (<Button leftIcon={<AddIcon />} size="sm" variant="primary" onClick={addSelectedPlots}>
              {t('global.add_plot_number', {
                count: Object.values(selectedPlots).length,
            })}
            </Button>)}/>
    </Stack>);
};
export default memo(CompanyOwnedPlotsTable);
