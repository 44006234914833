import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useExtraFilters, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { CELL_TOWER_GENERATION_OPTIONS, CELL_TOWER_OPERATOR_OPTIONS, CELL_TOWER_STATUS_OPTIONS, CELL_TOWER_SYSTEM_OPTIONS, } from 'utils/constants';
import { filtersKey } from 'utils/filters';
const CellTowerSidebarContent = () => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const statusSelectedOption = extraFilters?.[filtersKey.CELL_TOWER]?.statusId || '0';
    const statusDisplayOptions = CELL_TOWER_STATUS_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const operatorSelectedOption = extraFilters?.[filtersKey.CELL_TOWER]?.operatorId || '0';
    const operatorDisplayOptions = CELL_TOWER_OPERATOR_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const systemSelectedOption = extraFilters?.[filtersKey.CELL_TOWER]?.systemId || '0';
    const systemDisplayOptions = CELL_TOWER_SYSTEM_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const generationSelectedOption = extraFilters?.[filtersKey.CELL_TOWER]?.generationId || '0';
    const generationDisplayOptions = CELL_TOWER_GENERATION_OPTIONS.map((option) => {
        return {
            value: option.value,
            label: option.label,
        };
    });
    const handleStatusChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.CELL_TOWER}.statusId`, parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.CELL_TOWER}`)} ${t('sidebar_content.cell_tower.status.label')}`,
        });
    };
    const handleOperatorChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.CELL_TOWER}.operatorId`, parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.CELL_TOWER}`)} ${t(`sidebar_content.cell_tower.operator.label`)}`,
        });
    };
    const handleSystemChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.CELL_TOWER}.systemId`, parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.CELL_TOWER}`)} ${t(`sidebar_content.cell_tower.system.label`)}`,
        });
    };
    const handleGenerationChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${filtersKey.CELL_TOWER}.generationId`, parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.CELL_TOWER}`)} ${t(`sidebar_content.cell_tower.generation.label`)}`,
        });
    };
    return (<Box width="full">
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('sidebar_content.cell_tower.status.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('sidebar_content.cell_tower.status.placeholder')} onChange={handleStatusChange} value={statusSelectedOption}>
          {statusDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('sidebar_content.cell_tower.operator.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('sidebar_content.cell_tower.operator.placeholder')} onChange={handleOperatorChange} value={operatorSelectedOption}>
          {operatorDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('sidebar_content.cell_tower.system.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('sidebar_content.cell_tower.system.placeholder')} onChange={handleSystemChange} value={systemSelectedOption}>
          {systemDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
      <FormControl display="flex" alignItems="center" marginBottom={2}>
        <FormLabel textStyle="small" marginBottom={0} minWidth="80px">
          {t('sidebar_content.cell_tower.generation.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('sidebar_content.cell_tower.generation.placeholder')} onChange={handleGenerationChange} value={generationSelectedOption}>
          {generationDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
    </Box>);
};
export default CellTowerSidebarContent;
