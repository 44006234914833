import * as React from 'react';
import { Skeleton, VStack } from '@chakra-ui/react';
import { useIsFetching } from '@tanstack/react-query';
import { usePlots } from 'hooks/contexts/useTabsContext';
import useTabTitle from 'hooks/navigation/useTabTitle';
import { getPlotByCoordinatesQueryKey } from 'hooks/plot/usePlotByCoordinates';
import TabItemWrapper, { TabItemTitle, } from './TabItemWrapper';
const PlotTabItemSkeleton = () => {
    return (<VStack alignItems="stretch" width="100%">
      <Skeleton height="15px" width="60%" maxW="100px"/>
      <Skeleton height="10px" width="30%" maxW="60px"/>
    </VStack>);
};
const PlotTabItem = (props) => {
    const { tab } = props;
    const plots = usePlots();
    const currentPlot = plots[tab.id];
    const { getTitle, getDetail } = useTabTitle();
    const isFetchingPlot = useIsFetching({
        queryKey: [
            getPlotByCoordinatesQueryKey(currentPlot?.lat, currentPlot?.lng, currentPlot?.townId),
        ],
    });
    const isPlotTabLoading = !!isFetchingPlot;
    const title = getTitle(tab);
    const detail = getDetail(tab);
    return (<TabItemWrapper {...props} tooltipContent={`${title} - ${detail}`} isLoading={isPlotTabLoading}>
      {isPlotTabLoading || !detail || !title ? (<PlotTabItemSkeleton />) : (<TabItemTitle title={title} detail={detail}/>)}
    </TabItemWrapper>);
};
export default PlotTabItem;
