import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { IOPLUGPUDocument } from 'types/api';
const QUERY_KEY = 'plu-gpu-documents';
const fetchPLUGPUDocuments = (coords, signal) => {
    return get(IOPLUGPUDocument, `plu-gpu-documents/${coords.x}/${coords.y}`, {}, false, { signal }, (error) => {
        return error.response.status !== 404;
    });
};
export const getPLUGPUDocumentQueryKey = (coords) => [QUERY_KEY, coords];
const usePLUGPUDocuments = ({ coords }) => {
    const { data: PLUGPUDocuments, isInitialLoading: isInitialLoadingDocuments, error: errorDocuments, } = useQuery({
        queryKey: getPLUGPUDocumentQueryKey(coords),
        queryFn: ({ signal }) => fetchPLUGPUDocuments(coords, signal),
        enabled: !!coords,
    });
    return {
        PLUGPUDocuments,
        isInitialLoadingDocuments,
        errorDocuments,
    };
};
export default usePLUGPUDocuments;
