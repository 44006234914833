import * as React from 'react';
import { useState } from 'react';
import { Button } from '@chakra-ui/react';
import { t } from 'i18next';
import FirstStep from './importPlotsSteps/FirstStep';
import SecondStep from './importPlotsSteps/SecondStep';
import ThirdStep from './importPlotsSteps/ThirdStep';
import useImportPlotSteps from './importPlotsSteps/useImportPlotSteps';
const ImportPlotsForm = ({ closeDrawer }) => {
    const [step, setStep] = useState(1);
    const goToNextStep = () => setStep((prevStep) => prevStep + 1);
    const { handleFileData, handleSelectedTab, fileData, selectedTab, importData, } = useImportPlotSteps(goToNextStep);
    const PreviousButton = (<Button size="sm" variant="outline" isLoading={false} loadingText={t('global.status.creating')} onClick={() => setStep((prevStep) => prevStep - 1)} isDisabled={step === 1} data-cy="import_previous_button">
      {t('contact.actions.previous')}
    </Button>);
    return (<>
      {step === 1 && (<FirstStep handleFileData={handleFileData}>{PreviousButton}</FirstStep>)}
      {step === 2 && (<SecondStep handleSelectedTab={handleSelectedTab} fileData={fileData}>
          {PreviousButton}
        </SecondStep>)}
      {step === 3 && (<ThirdStep handleLastStep={closeDrawer} fileData={fileData} selectedTab={selectedTab} importData={importData}>
          {PreviousButton}
        </ThirdStep>)}
    </>);
};
export default ImportPlotsForm;
