import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAnalytics, { ANALYTICS_LAND_POINT } from 'analytics/useAnalytics';
import PopoverMenu from 'components/map/Toolbar/PopoverMenu';
import ToolbarButton from 'components/map/Toolbar/ToolbarButton';
import { HIGH_DEFINITION, MAP_DISPLAYS_SATELLITE, OBLIQUE, ROADMAP, SAT_IGN, SAT_IGN_TOPOGRAPHY, SATELLITE, } from 'context/MapContext';
import { useChangeMapDisplay } from 'hooks/contexts/useMapContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
export const MAP_DISPLAYS_TRACK = {
    [ROADMAP]: 'Plan',
    [SATELLITE]: 'Satellite',
    [SAT_IGN]: 'Satellite IGN',
    [SAT_IGN_TOPOGRAPHY]: 'IGN topographie',
    [OBLIQUE]: 'Vue oblique',
};
const MapDisplayButtons = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { hasMapControl } = useSiteConfiguration();
    const [mapDisplay] = useState(ROADMAP);
    const [mapDisplaySatellite] = useState(SATELLITE);
    const [lastSatelliteDisplay, setLastSatelliteDisplay] = useState(null);
    const changeMapDisplay = useChangeMapDisplay();
    const allowedSatelliteDisplays = MAP_DISPLAYS_SATELLITE.filter((display) => {
        if (display === SAT_IGN) {
            return hasMapControl('display_satign');
        }
        if (display === SAT_IGN_TOPOGRAPHY) {
            return hasMapControl('display_satign_road');
        }
        if (display === OBLIQUE) {
            return hasMapControl('display_oblique');
        }
        return display !== HIGH_DEFINITION;
    });
    return (<>
      <ToolbarButton label={t(`map.map_type.${ROADMAP}`)} isActive={mapDisplay === ROADMAP} handleClick={() => {
            trackEvent({
                category: ANALYTICS_LAND_POINT,
                action: MAP_DISPLAYS_TRACK[ROADMAP],
            });
            changeMapDisplay(ROADMAP);
        }} marginRight={2}/>

      {hasMapControl('map') && (<PopoverMenu id="mapModal" label={lastSatelliteDisplay
                ? t(`map.map_type.${lastSatelliteDisplay}`)
                : t(`map.map_type.${mapDisplaySatellite}`)} isActive={mapDisplay !== ROADMAP} menuItems={allowedSatelliteDisplays.map((display) => ({
                handleClick: () => {
                    trackEvent({
                        category: ANALYTICS_LAND_POINT,
                        action: MAP_DISPLAYS_TRACK[display],
                    });
                    changeMapDisplay(display);
                    setLastSatelliteDisplay(display);
                },
                label: t(`map.map_type.${display}`),
                isActive: mapDisplay === display,
            }))} borderRightRadius={0} trigger="hover" showCheck selectDefault={() => {
                if (mapDisplay === ROADMAP) {
                    changeMapDisplay(lastSatelliteDisplay || SATELLITE);
                }
            }}/>)}
    </>);
};
export default MapDisplayButtons;
