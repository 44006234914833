import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import DataCenterMarker from 'images/data_center.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
const IODataCenterLayerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        title: iots.string,
        url: iots.string,
    }),
], 'IODataCenterLayerItem');
const fetchDataCenter = () => () => get(iots.array(IODataCenterLayerItem), `data-center`);
const getLegends = () => {
    const legends = {};
    legends.data_center_poi = {
        layerKey: filtersKey.DATACENTER,
        label: 'datacenter',
        needsTranslate: true,
        image: DataCenterMarker,
        width: '23px',
        height: '27px',
    };
    return legends;
};
const useDatacenterLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (layerInfo) => {
        let content = '';
        if (layerInfo.title) {
            content = '<div>' + layerInfo.title + '</div>';
        }
        if (layerInfo.url) {
            content +=
                '<a href="' +
                    layerInfo.url +
                    '" target="_blank">' +
                    t('info_window.datacenter.url') +
                    '</a>';
        }
        return content;
    };
    createLayersHook({
        fetch: fetchDataCenter,
        markerImg: DataCenterMarker,
        getInfoWindowContent: getInfoWindowContent,
        legends: getLegends(),
    })(filtersKey.DATACENTER);
};
const DataCenterLayer = () => {
    useDatacenterLayer();
    return null;
};
export default DataCenterLayer;
