import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';
import { formatNumberToLocale } from 'utils/formatNumber';
const fieldsSource = [
    'status',
    'maxLevel',
    'realGrantDate',
    'workStartedDate',
    'workDoneDate',
    'requesterName',
    'requesterSiret',
    'requesterAddress',
    'requesterCategory',
    'lotAddress',
    'lotSurface',
    'operatingArea',
    'projectType',
    'extension',
    'elevation',
    'additionalLevel',
    'hotelSurface',
    'hotelSurfaceBefore',
    'hotelSurfaceCreatedByUseChange',
    'hotelSurfaceDemolished',
    'hotelSurfaceRemovedByUseChange',
    'housingSurface',
    'housingSurfaceBefore',
    'housingSurfaceCreatedByUseChange',
    'housingSurfaceDemolished',
    'housingSurfaceRemovedByUseChange',
    'officesSurface',
    'officesSurfaceBefore',
    'officesSurfaceCreatedByUseChange',
    'officesSurfaceDemolished',
    'officesSurfaceRemovedByUseChange',
    'storesSurface',
    'storesSurfaceBefore',
    'storesSurfaceCreatedByUseChange',
    'storesSurfaceDemolished',
    'storesSurfaceRemovedByUseChange',
    'craftworkSurface',
    'craftworkSurfaceBefore',
    'craftworkSurfaceCreatedByUseChange',
    'craftworkSurfaceDemolished',
    'craftworkSurfaceRemovedByUseChange',
    'industrySurface',
    'industrySurfaceBefore',
    'industrySurfaceCreatedByUseChange',
    'industrySurfaceDemolished',
    'industrySurfaceRemovedByUseChange',
    'farmingSurface',
    'farmingSurfaceBefore',
    'farmingSurfaceCreatedByUseChange',
    'farmingSurfaceDemolished',
    'farmingSurfaceRemovedByUseChange',
    'storageSurface',
    'storageSurfaceBefore',
    'storageSurfaceCreatedByUseChange',
    'storageSurfaceDemolished',
    'storageSurfaceRemovedByUseChange',
    'publicServiceSurface',
    'publicServiceSurfaceBefore',
    'publicServiceSurfaceCreatedByUseChange',
    'publicServiceSurfaceDemolished',
    'publicServiceSurfaceRemovedByUseChange',
    'publicServiceType',
    'nbRooms',
    'totalCreatedHousing',
    'totalCreatedCollectiveHousing',
    'totalCreatedIndividualHousing',
    'totalCreatedSocialRentalHousing',
    'totalCreatedSupportiveHousing',
    'totalCreatedPTZHousing',
    'servicedApartmentsType',
    'oneRoomHousing',
    'twoRoomsHousing',
    'threeRoomsHousing',
    'fourRoomsHousing',
    'fiveRoomsHousing',
    'sixRoomsAndMoreHousing',
    'demolishedHousing',
    'useType',
    'buildingPermitId',
    'median',
    'propertyDeveloper',
    'date',
    'exitPrice',
    'salePrice',
    'saleDate',
    'computedPropertyTaxes',
];
export const formatBuildingPermitCellValue = ({ cellValue, fieldName, t }) => {
    if (cellValue === null) {
        return null;
    }
    if (fieldName === 'status' ||
        fieldName === 'operatingArea' ||
        fieldName === 'publicServiceType' ||
        fieldName === 'requesterCategory' ||
        fieldName === 'servicedApartmentsType' ||
        fieldName === 'useType' ||
        fieldName === 'projectType' ||
        fieldName === 'permitType') {
        return t('info_window.building_permit_new_market.' +
            snakeCase(fieldName) +
            '.' +
            cellValue);
    }
    switch (cellValue) {
        case true:
            return t('global.yes');
        case false:
            return t('global.no');
        default:
            switch (fieldName) {
                case 'lotSurface':
                case 'hotelSurface':
                case 'hotelSurfaceBefore':
                case 'hotelSurfaceCreatedByUseChange':
                case 'hotelSurfaceDemolished':
                case 'hotelSurfaceRemovedByUseChange':
                case 'housingSurface':
                case 'housingSurfaceBefore':
                case 'housingSurfaceCreatedByUseChange':
                case 'housingSurfaceDemolished':
                case 'housingSurfaceRemovedByUseChange':
                case 'officesSurface':
                case 'officesSurfaceBefore':
                case 'officesSurfaceCreatedByUseChange':
                case 'officesSurfaceDemolished':
                case 'officesSurfaceRemovedByUseChange':
                case 'storesSurface':
                case 'storesSurfaceBefore':
                case 'storesSurfaceCreatedByUseChange':
                case 'storesSurfaceDemolished':
                case 'storesSurfaceRemovedByUseChange':
                case 'craftworkSurface':
                case 'craftworkSurfaceBefore':
                case 'craftworkSurfaceCreatedByUseChange':
                case 'craftworkSurfaceDemolished':
                case 'craftworkSurfaceRemovedByUseChange':
                case 'industrySurface':
                case 'industrySurfaceBefore':
                case 'industrySurfaceCreatedByUseChange':
                case 'industrySurfaceDemolished':
                case 'industrySurfaceRemovedByUseChange':
                case 'farmingSurface':
                case 'farmingSurfaceBefore':
                case 'farmingSurfaceCreatedByUseChange':
                case 'farmingSurfaceDemolished':
                case 'farmingSurfaceRemovedByUseChange':
                case 'storageSurface':
                case 'storageSurfaceBefore':
                case 'storageSurfaceCreatedByUseChange':
                case 'storageSurfaceDemolished':
                case 'storageSurfaceRemovedByUseChange':
                case 'publicServiceSurface':
                case 'publicServiceSurfaceBefore':
                case 'publicServiceSurfaceCreatedByUseChange':
                case 'publicServiceSurfaceDemolished':
                case 'publicServiceSurfaceRemovedByUseChange':
                    // remplace \u202f car dans jsPDF pose problème
                    return (formatNumberToLocale(cellValue, 'fr').replace(/\u202f/g, ' ') +
                        ' m²');
                case 'median':
                case 'exitPrice':
                    // remplace \u202f car dans jsPDF pose problème
                    return (formatNumberToLocale(cellValue, 'fr').replace(/\u202f/g, ' ') + ' €');
                default:
                    return cellValue;
            }
    }
};
export const getBuildingPermitName = ({ fieldName, t }) => {
    const field = snakeCase(fieldName);
    if (field === 'status' ||
        field === 'operating_area' ||
        field === 'public_service_type' ||
        field === 'requester_category' ||
        field === 'serviced_apartments_type' ||
        field === 'use_type' ||
        field === 'project_type' ||
        field === 'permit_type') {
        return t('info_window.building_permit_new_market.' + field + '.title');
    }
    return t('info_window.building_permit_new_market.' + field);
};
export const formatBuildingPermitData = ({ buildingsPermits }) => {
    if (!buildingsPermits)
        return null;
    return buildingsPermits.map((buildingsPermit) => {
        return pick(buildingsPermit, fieldsSource);
    });
};
