import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coords, zoom) => {
    const boundingBox = getTileBoundingBox(coords, zoom, map, tileWidth, tileHeight);
    return ('https://data.geopf.fr/wms-v/ows?' +
        'SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap' +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&TRANSPARENT=TRUE&LAYERS=TRANSPORTS.AIRNOISE' +
        '&CRS=EPSG%3A3857&STYLES=&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&BBOX=' +
        encodeURIComponent(boundingBox));
};
const getMarkerLayerTileUrl = (map, tileWidth, tileHeight) => (coords, zoom) => {
    const boundingBox = getTileBoundingBox(coords, zoom, map, tileWidth, tileHeight);
    return ('https://data.geopf.fr/wms-v/ows?' +
        'SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap' +
        '&FORMAT=' +
        encodeURIComponent('image/png') +
        '&TRANSPARENT=TRUE&LAYERS=TRANSPORTS.AIRNOISE.DOCS' +
        '&CRS=EPSG%3A3857&STYLES=&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&BBOX=' +
        encodeURIComponent(boundingBox));
};
const getLegends = () => {
    const legends = {};
    legends.aeronautic_servitude = {
        layerKey: filtersKey.AERONAUTIC_SERVITUDE,
        label: 'aeronautic_servitude',
        needsTranslate: true,
        color: '#FF0404',
        polygonType: VECTOR_TYPES.polyline,
        width: 20,
        borderWidth: 2,
    };
    return legends;
};
const useAeronauticServitudeLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.AERONAUTIC_SERVITUDE],
        getLegends,
        tileWidth: 2363,
        tileHeight: 1731,
    })(filtersKey.AERONAUTIC_SERVITUDE);
    createTilesLayerHook({
        getLayerTileUrl: getMarkerLayerTileUrl,
        position: CST_POSITIONS[filtersKey.AERONAUTIC_SERVITUDE + '_2'],
        getLegends,
        tileWidth: 2363,
        tileHeight: 1731,
    })(filtersKey.AERONAUTIC_SERVITUDE);
};
const AeronauticServitudeLayers = () => {
    useAeronauticServitudeLayers();
    return null;
};
export default AeronauticServitudeLayers;
