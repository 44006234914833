import { memo, useEffect, useState } from 'react';
import * as React from 'react';
import { Box } from '@chakra-ui/react';
import Layers from 'components/layers/Layers';
import { menuItems } from 'components/sidebar/utils';
import StreetViewContainer from 'components/StreetViewContainer';
import StreeViewSizeControl from 'components/StreeViewSizeControl';
import { STREET_VIEW_MODE_FIFTY_VERTICAL } from 'context/LayoutContext';
import { useStreetViewMode } from 'hooks/contexts/useLayoutContext';
import { useRefMap, useRefPanorama } from 'hooks/contexts/useMapContext';
import { useMenuItemSelected } from 'hooks/contexts/useMenuContext';
import { useInitMap } from 'hooks/useInitMap';
import MapBlock from './MapBlock';
import MapTools from './MapTools';
const MapContainer = () => {
    const refMap = useRefMap();
    const refPanorama = useRefPanorama();
    const menuItemSelected = useMenuItemSelected();
    useInitMap(refMap, refPanorama);
    const streetViewMode = useStreetViewMode();
    const [mapHeight, setMapHeight] = useState(null);
    useEffect(() => {
        if (!refMap.current)
            return;
        const resizeObserver = new ResizeObserver(() => {
            setMapHeight(refMap?.current?.clientHeight);
        });
        resizeObserver.observe(refMap.current);
        return () => resizeObserver.disconnect();
    }, []);
    return (<Box data-cy="map_container" display={menuItemSelected === menuItems.NEWS
            ? 'none'
            : STREET_VIEW_MODE_FIFTY_VERTICAL
                ? 'flex'
                : 'block'} position="relative" flex={1} flexDirection={streetViewMode === STREET_VIEW_MODE_FIFTY_VERTICAL ? 'row' : 'column'} width="full" height="100%">
      <StreetViewContainer refPanorama={refPanorama}/>
      <MapBlock refMap={refMap}/>
      <Box height="auto">
        <MapTools mapHeight={mapHeight}/>
      </Box>
      <StreeViewSizeControl />
      <Layers />
    </Box>);
};
export default memo(MapContainer);
