import ContactSection from 'components/tabs/crmTabs/contact/contactSections/ContactSection';
import CouriersSection from 'components/tabs/crmTabs/contact/contactSections/CouriersSection';
import { CONTACT_COURIERS, CONTACT_SECTION, } from 'types/api';
import { CONTACT_FILTER_MORALE_PERSON, CONTACT_FILTER_NO_CONTACT_NPR, CONTACT_FILTER_NO_USUFRUCTUARY, CONTACT_FILTER_OWNER_SELLER, CONTACT_FILTER_WITH_ACTIVITY, CONTACT_FILTER_WITH_PHONE_OR_EMAIL, CONTACT_FILTER_WITH_POSTALCODE, CONTACT_TYPE_OWNER, CONTACT_TYPE_USUFRUCTARY, } from './constants';
export const SECTIONS = {
    [CONTACT_SECTION]: ContactSection,
    [CONTACT_COURIERS]: CouriersSection,
};
export const TOOLBAR_HEIGHT = '32px';
export const contactsOptionsFiltration = (optionsSelected, contact) => {
    if (optionsSelected.includes(CONTACT_FILTER_WITH_POSTALCODE) &&
        !contact?.address) {
        return false;
    }
    if (optionsSelected.includes(CONTACT_FILTER_WITH_PHONE_OR_EMAIL) &&
        !contact?.mobile &&
        !contact?.landline &&
        !contact?.primaryEmail) {
        return false;
    }
    if (optionsSelected.includes(CONTACT_FILTER_MORALE_PERSON) &&
        !contact?.company &&
        !contact?.siren) {
        return false;
    }
    if (optionsSelected.includes(CONTACT_FILTER_OWNER_SELLER) &&
        (!contact ||
            !contact?.type.includes(CONTACT_TYPE_OWNER) ||
            !contact?.vendor)) {
        return false;
    }
    if (optionsSelected.includes(CONTACT_FILTER_WITH_ACTIVITY) &&
        !contact?.hasActivity) {
        return false;
    }
    if (optionsSelected.includes(CONTACT_FILTER_NO_CONTACT_NPR) &&
        contact &&
        contact.npr) {
        return false;
    }
    if (optionsSelected.includes(CONTACT_FILTER_NO_USUFRUCTUARY) &&
        contact?.type.includes(CONTACT_TYPE_USUFRUCTARY)) {
        return false;
    }
    return true;
};
