import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Center, Checkbox, Heading, Modal, ModalCloseButton, ModalContent, ModalOverlay, Spinner, Text, useDisclosure, VStack, } from '@chakra-ui/react';
import BuildingPermitNewMarketInfoWindow from 'components/infoWindow/BuildingPermitNewMarketInfoWindow';
import { useFilters, useIsFilterActive, useSetFilters, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import { filtersKey } from 'utils/filters';
const BuildingPermit = ({ buildingsPermits, isLoading, buildingPermitLayers, isLoadingLayers, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const filters = useFilters();
    const setFilters = useSetFilters();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const handleChange = (filterKey) => {
        toggleFilter(filterKey);
    };
    const onMultiCheck = (key) => {
        const newFilters = { ...filters };
        if (key === filtersKey.BUILDING_PERMIT_HOUSING) {
            newFilters[filtersKey.BUILDING_PERMIT_HOUSING_ALLOWED_PERMITS] = true;
            newFilters[filtersKey.BUILDING_PERMIT_HOUSING_WORK_STARTED] = true;
            newFilters[filtersKey.BUILDING_PERMIT_HOUSING_WORK_DONE] = true;
            newFilters[filtersKey.BUILDING_PERMIT_HOUSING_CANCELED_PERMITS] = true;
        }
        else if (key === filtersKey.BUILDING_PERMIT_OFFICES) {
            newFilters[filtersKey.BUILDING_PERMIT_OFFICES_ALLOWED_PERMITS] = true;
            newFilters[filtersKey.BUILDING_PERMIT_OFFICES_WORK_STARTED] = true;
            newFilters[filtersKey.BUILDING_PERMIT_OFFICES_WORK_DONE] = true;
            newFilters[filtersKey.BUILDING_PERMIT_OFFICES_CANCELED_PERMITS] = true;
        }
        else if (key === filtersKey.BUILDING_PERMIT_PLANNING_PERMIT) {
            newFilters[filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_ALLOWED_PERMITS] =
                true;
            newFilters[filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_STARTED] =
                true;
            newFilters[filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_DONE] = true;
            newFilters[filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_CANCELED_PERMITS] =
                true;
        }
        setFilters(newFilters);
    };
    const isEnabled = (key) => {
        if (key === filtersKey.BUILDING_PERMIT_HOUSING) {
            return (isFilterActive(filtersKey.BUILDING_PERMIT_HOUSING_ALLOWED_PERMITS) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_HOUSING_WORK_STARTED) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_HOUSING_WORK_DONE) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_HOUSING_CANCELED_PERMITS));
        }
        else if (key === filtersKey.BUILDING_PERMIT_OFFICES) {
            return (isFilterActive(filtersKey.BUILDING_PERMIT_OFFICES_ALLOWED_PERMITS) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_OFFICES_WORK_STARTED) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_OFFICES_WORK_DONE) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_OFFICES_CANCELED_PERMITS));
        }
        else if (key === filtersKey.BUILDING_PERMIT_PLANNING_PERMIT) {
            return (isFilterActive(filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_ALLOWED_PERMITS) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_STARTED) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_DONE) &&
                isFilterActive(filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_CANCELED_PERMITS));
        }
    };
    return (<VStack width="full" alignItems="flex-start" fontSize="13px" spacing={2} color="var(--colorModeFont-800)">
      {isLoadingLayers ? (<Center width="full" padding={8}>
          <Spinner />
        </Center>) : (Object.keys(buildingPermitLayers).map((key) => {
            const layerTree = key.split('.');
            const layerTreeId = layerTree.length - 1;
            return (<Box key={key} marginBottom={1}>
              <Heading as="h4" variant="h4" marginBottom="4px" display="flex">
                <Checkbox key={layerTree[layerTreeId]} isChecked={isEnabled(layerTree[layerTreeId])} onChange={() => onMultiCheck(layerTree[layerTreeId])} lineHeight="1" display="flex" alignItems="center" cursor="pointer" marginRight={2}/>
                {t(`sidebar.categories.${layerTree[layerTreeId]}`)} :
              </Heading>
              {buildingPermitLayers[key].layers.map((buildingPermit) => {
                    return (<Box marginBottom={1} marginLeft={5} key={buildingPermit.id}>
                    <Checkbox key={buildingPermit.id} data-id={buildingPermit.id} isChecked={isFilterActive(buildingPermit.id)} onChange={() => handleChange(buildingPermit.id)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
                      <Text fontSize="13px">
                        {t(`sidebar.layers.${buildingPermit.title}`)} (
                        {buildingPermit.count})
                      </Text>
                    </Checkbox>
                  </Box>);
                })}
            </Box>);
        }))}
      {isLoading ? (<Center width="full" padding={8}>
          <Spinner />
        </Center>) : buildingsPermits.length > 0 ? (buildingsPermits.map((buildingsPermit, index) => {
            return (<Box key={index} position="relative" width="full" padding={2} borderRadius="md" backgroundColor="var(--colorModeBackground-800)" borderWidth={1} borderColor="var(--colorModeBackground-600)">
              <Text backgroundColor="var(--colorModeBase)" padding={2} borderTopRightRadius="md" borderBottomLeftRadius="md" position="absolute" top="0" right="0">
                {t(`info_window.building_permit_new_market.status.${buildingsPermit.status}`)}
              </Text>
              <Box marginBottom={1}>
                <Text fontWeight="bold">
                  {t('tabs.plots.section.building_permit.type.' +
                    buildingsPermit.parentCategory)}
                </Text>
              </Box>
              {buildingsPermit.date && (<Box>
                  {t('info_window.building_permit_new_market.date')} {' : '}
                  {buildingsPermit.date}
                </Box>)}
              {buildingsPermit.workStartedDate && (<Box>
                  {t('info_window.building_permit_new_market.work_started_date')}{' '}
                  {' : '}
                  {buildingsPermit.workStartedDate}
                </Box>)}
              {buildingsPermit.requesterName && (<Box>
                  {t('info_window.building_permit_new_market.requester_name')}{' '}
                  {' : '}
                  {buildingsPermit.requesterName}
                </Box>)}
              <Button marginTop={2} size="sm" variant="primary" onClick={onOpen}>
                {t('tabs.plots.section.building_permit.more_info')}
              </Button>
              <Modal isOpen={isOpen} onClose={onClose} isCentered variant="wide" blockScrollOnMount={false}>
                <ModalOverlay />
                <ModalContent padding={5} alignItems="center" overflowY="auto" maxHeight="500px">
                  <ModalCloseButton />
                  <Box fontSize="13px" color="var(--colorModeFont-800)">
                    <BuildingPermitNewMarketInfoWindow info={buildingsPermit}/>
                  </Box>
                </ModalContent>
              </Modal>
            </Box>);
        })) : (<>{t('tabs.plots.section.building_permit.no_result')}</>)}
    </VStack>);
};
export default BuildingPermit;
