import { ROADMAP, SATELLITE } from 'context/MapContext';
import useUserSettings from 'hooks/personalization/useUserSettings';
import useCurrentSite from 'hooks/useCurrentSite';
import { LAYERS_KEY } from 'utils/formatLayersNames';
const useSiteConfiguration = () => {
    const { currentSite } = useCurrentSite();
    const configuration = currentSite?.configuration;
    const { userSettings } = useUserSettings();
    const hasFeature = (feature) => !configuration || configuration?.features?.includes(feature);
    const hasMapControl = (control) => !configuration || configuration?.map_controls?.includes(control);
    const hasDashboardItem = (item) => !configuration || configuration?.dashboard?.includes(item);
    const hasAnyDashboardItem = () => !configuration || configuration?.dashboard?.length > 0;
    const hasSearchField = (field) => !configuration || configuration?.foncier?.includes(field);
    const hasMenuItem = (menu) => !configuration || configuration?.menu?.includes(menu);
    const hasPlotTab = (tab) => !configuration || configuration.plot_tabs?.includes(tab);
    const hasProjectTab = (tab) => !configuration || configuration.project_tabs?.includes(tab);
    const hasPlotInfo = (item) => !configuration || configuration?.plot?.includes(item);
    const hasPersonalization = (item) => !configuration || configuration?.personalization?.includes(item);
    const hasMainLayersTree = (layer) => {
        if (!configuration) {
            return true;
        }
        const mainlayers = configuration?.layers_tree?.main_layers;
        if (Array.isArray(mainlayers)) {
            return mainlayers.includes(layer);
        }
        else {
            return false;
        }
    };
    const hasLayersTree = (layers) => {
        if (!configuration) {
            return true;
        }
        const length = layers.length;
        let currentConfiguration = configuration?.layers_tree;
        for (let i = 0; i < length; i++) {
            if (currentConfiguration[layers[i]]) {
                if (layers[i] === LAYERS_KEY) {
                    if (!currentConfiguration[layers[i]]) {
                        return false;
                    }
                    const layersList = currentConfiguration[layers[i]];
                    return layersList.includes(layers[i + 1]);
                }
                else {
                    currentConfiguration = currentConfiguration[layers[i]];
                }
            }
            else {
                return false;
            }
        }
        return true;
    };
    const mapDisplay = userSettings?.map || (currentSite?.legacyId === '130' ? SATELLITE : ROADMAP);
    const mapDisplaySatellite = mapDisplay !== ROADMAP ? mapDisplay : SATELLITE;
    const streetViewMode = userSettings?.streetView;
    return {
        hasFeature,
        hasMapControl,
        hasDashboardItem,
        hasAnyDashboardItem,
        hasSearchField,
        hasMenuItem,
        hasPlotTab,
        hasPlotInfo,
        hasProjectTab,
        hasPersonalization,
        hasMainLayersTree,
        hasLayersTree,
        mapDisplay,
        mapDisplaySatellite,
        streetViewMode,
    };
};
export default useSiteConfiguration;
