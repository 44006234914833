import pick from 'lodash/pick';
import moment from 'moment';
import { formatDate } from 'utils/formatDate';
const translations = {
    companyActivity: 'company_activity',
    decreeDate: 'decree_date',
    companyName: 'company_name',
};
const fields = [
    'companyName',
    'siren',
    'companyActivity',
    'staff',
    'form',
    'category',
    'decreeDate',
    'tefen',
    'address',
    'ape_code',
];
export const formatOccupantData = (occupants) => {
    return occupants.map((occupant) => {
        return pick(occupant, fields);
    });
};
export const getOccupantFieldName = ({ fieldName, t }) => {
    return t(`tabs.plots.section.occupant.columns.` +
        (translations[fieldName] ?? fieldName));
};
export const formatOccupantCellValue = ({ cellValue, fieldName }) => {
    if (fieldName === 'decreeDate') {
        return { value: moment(cellValue).format('DD/MM/YYYY'), halign: 'center' };
    }
    if (fieldName === 'staff') {
        const manager = [];
        cellValue.forEach((staff) => {
            manager.push(staff.firstname +
                ' ' +
                staff.name +
                ' - ' +
                formatDate(staff.dateBirth, 'YYYY-MM', 'fr'));
        });
        return { value: manager.join(', '), halign: 'left' };
    }
    return { value: cellValue };
};
