import { memo } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Heading, Stack, useDisclosure, } from '@chakra-ui/react';
import ImportPlotsForm from './ImportPlotsForm';
const PlotImportDrawer = ({ handleClose, triggerItem, }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const closeDrawer = () => {
        if (handleClose)
            handleClose();
        onClose();
    };
    return (<>
      {triggerItem && triggerItem(onOpen)}
      <Drawer isOpen={isOpen} onClose={() => closeDrawer()} size="xl">
        <DrawerOverlay />
        <DrawerContent backgroundColor="colorMode.background900" overflowY="auto">
          <DrawerCloseButton />
          <DrawerHeader>
            <Heading as="h4" variant="h4">
              {t(`plot.import.title`)}
            </Heading>
          </DrawerHeader>
          <DrawerBody paddingY={1}>
            <Stack width="full" spacing={2}>
              <ImportPlotsForm closeDrawer={closeDrawer}/>
            </Stack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>);
};
export default memo(PlotImportDrawer);
