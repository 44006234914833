import * as React from 'react';
import { useLayoutEffect } from 'react';
import { Box, usePrevious } from '@chakra-ui/react';
import { useTabs } from 'hooks/contexts/useTabsContext';
import { useOverflow } from 'hooks/useOverflow';
import { TabItem } from '../TabsItem/TabItemWrapper';
import TabsScrollButton from './TabsScrollButton';
const VERTICAL_SCROLL_OFFSET = 200;
const MultipleTabScroll = () => {
    const tabs = useTabs();
    const previousTabsCount = usePrevious(tabs.length);
    const horizontalRef = React.useRef(null);
    useLayoutEffect(() => {
        if (previousTabsCount !== tabs.length) {
            horizontalRef.current.scrollLeft = horizontalRef.current.scrollWidth;
        }
    }, [tabs.length]);
    const { refXOverflowing, refXScrollBegin, refXScrollEnd } = useOverflow(horizontalRef);
    const hasLeftOverflow = refXOverflowing && !refXScrollBegin;
    const hasRightOverflow = refXOverflowing && !refXScrollEnd;
    const hasOverflow = hasLeftOverflow || hasRightOverflow;
    const handleScroll = React.useCallback((direction) => {
        if (horizontalRef.current) {
            if (direction === 'right') {
                horizontalRef.current.scrollLeft += VERTICAL_SCROLL_OFFSET;
            }
            else {
                horizontalRef.current.scrollLeft -= VERTICAL_SCROLL_OFFSET;
            }
        }
    }, [horizontalRef]);
    return (<>
      {hasOverflow && (<TabsScrollButton type="left" disabled={!hasLeftOverflow} handleClick={() => handleScroll('left')}/>)}

      <Box scrollBehavior="smooth" ref={horizontalRef} borderTopRadius="md" display="flex" overflowX="hidden" overflowY="hidden">
        {tabs.map((tab) => (<Box key={tab.id + '_' + tab.type}>
            <TabItem tab={tab}/>
          </Box>))}
      </Box>
      {hasOverflow && (<TabsScrollButton type="right" handleClick={() => handleScroll('right')} disabled={!hasRightOverflow}/>)}
    </>);
};
export default MultipleTabScroll;
