import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@chakra-ui/react';
import SelectInput from 'components/inputs/SelectInput';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { COMPANY_TYPE_OPTIONS } from 'utils/constants';
import { FOOT_BUILDING_CONSTRUCTION_KEY } from 'utils/plotsSearch';
import { getPlotSelectOptions } from './ActualBuildingSearch';
export const companySearchFields = ['typeCompany', 'footBuilding'];
export const OwnerSearch = ({ isLoading, hasSearchField, }) => {
    const { t } = useTranslation();
    if (!companySearchFields.some(hasSearchField)) {
        return null;
    }
    return (<Stack alignItems="start" padding={4} boxShadow={accordionBoxShadow} bgColor="colorMode.sidebar500" spacing={2} data-cy="company_search">
      {hasSearchField('typeCompany') && (<SelectInput size="sm" isLoading={isLoading} name="typeCompany" placeholder={t('global.select.none.placeholder')} label={t('sidebar.search_plots.type_company')} options={COMPANY_TYPE_OPTIONS}/>)}

      {hasSearchField('footBuilding') && (<SelectInput size="sm" isLoading={isLoading} name="footBuilding" label={t('sidebar.search_plots.foot_building')} options={getPlotSelectOptions(FOOT_BUILDING_CONSTRUCTION_KEY, 'foot_building', t)}/>)}
    </Stack>);
};
export default OwnerSearch;
