import * as React from 'react';
import { useMemo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Button, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CRM } from 'analytics/useAnalytics';
import ProjectButton from 'components/buttons/ProjectButton';
import ProjectStatusSelect from 'components/inputs/ProjectStatusSelect';
import Table from 'components/table/Table';
import { PLOT_SELECTION_PROJECT } from 'context/LocationContext';
import { useSetLayoutHalf } from 'hooks/contexts/useLayoutContext';
import { useSelectedTown, useSetPlotSelectionMode, } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import { useSetFeasibilityParentId } from 'hooks/contexts/useSecondaryPlotsContext';
import { useShowProject } from 'hooks/contexts/useTabsContext';
import usePlotProjectsList from 'hooks/crm/usePlotProjectsList';
import useProject from 'hooks/crm/useProject';
import useNavigateToTown from 'hooks/navigation/useNavigateToTown';
import { centerAndZoomOnCoord } from 'utils/map';
import { getProjectStatusLabel } from 'utils/personalization';
const getColumnHeader = (column) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.plots.section.my_crm.project.columns.${column}`)}
    </Translation>
  </Text>);
const ProjectList = ({ plot }) => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const { plotProjects, isLoading } = usePlotProjectsList(plot.id);
    const { addProject, isAddingProject } = useProject();
    const showProject = useShowProject();
    const selectedTown = useSelectedTown();
    const navigateToTown = useNavigateToTown();
    const map = useMap();
    const setPlotSelectionMode = useSetPlotSelectionMode();
    const setFeasibilityParentId = useSetFeasibilityParentId();
    const createProject = () => {
        if (isAddingProject) {
            return;
        }
        addProject({
            plotIds: [plot.id],
            townshipId: plot.townId,
        }, {
            onSuccess: (createdProject) => {
                const projectCenter = new google.maps.LatLng(parseFloat(plot.lat), parseFloat(plot.lng));
                if (plot.townId !== selectedTown?.id) {
                    navigateToTown({
                        lat: plot.lat,
                        lng: plot.lng,
                        centerMapOnLat: plot.lat,
                        centerMapOnLng: plot.lng,
                    });
                }
                else {
                    centerAndZoomOnCoord(map, projectCenter, 2);
                }
                setPlotSelectionMode(PLOT_SELECTION_PROJECT);
                setFeasibilityParentId(createdProject.id);
                showProject({
                    resource: {
                        id: createdProject.id,
                        label: createdProject.label,
                        townId: plot.townId,
                        lat: plot.lat,
                        lng: plot.lng,
                    },
                });
            },
        });
    };
    const columns = useMemo(() => [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'label',
                    header: () => getColumnHeader('label'),
                    accessorFn: (row) => {
                        if (!row.label)
                            return t('table.empty_column');
                        return row.label;
                    },
                    cell: (info) => (<ProjectButton projectId={info.row.original.id} projectLabel={info.row.original.label} projectTownIds={[plot.townId]} lat={plot.lat} lng={plot.lng} analyticDetail="Ouvrir fiche projet" analyticOrigin={ANALYTICS_CRM} data-cy="project_button">
                {info.row.original.label}
              </ProjectButton>),
                    filterFn: 'arrIncludesSome',
                },
                {
                    id: 'status',
                    accessorFn: (row) => {
                        if (!row.status)
                            return t('table.empty_column');
                        return getProjectStatusLabel(row.status);
                    },
                    header: () => getColumnHeader('status'),
                    cell: (info) => {
                        return (<Box maxWidth="300px">
                  <ProjectStatusSelect projectId={info.row.original.id} status={info.row.original.status} plotIds={[plot.id]} townshipId={plot.townId} onChange={() => {
                                trackEvent({
                                    category: ANALYTICS_CRM,
                                    action: 'Statut du projet onglet mon crm',
                                });
                            }}/>
                </Box>);
                    },
                    filterFn: 'arrIncludesSome',
                },
            ],
        },
    ], [plot.id]);
    const setLayoutHalf = useSetLayoutHalf();
    return (<Stack width="full" spacing={3} data-cy="project_list">
      <HStack>
        <Heading as="h4" variant="h4">
          {t('tabs.plots.section.my_crm.project.title')}
        </Heading>
        <Button variant="outline" size="sm" onClick={() => {
            createProject();
            setLayoutHalf();
            trackEvent({
                category: ANALYTICS_CRM,
                action: 'Ajouter un projet',
            });
        }} data-cy="plot_my_crm_create_project">
          {t('global.create_project')}
        </Button>
      </HStack>

      <Table id={`table_plot_${plot.id}_projects`} columns={columns} data={plotProjects || []} isLoading={isLoading} withExport={false}/>
    </Stack>);
};
export default ProjectList;
