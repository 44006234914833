import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
const fetchBuildingPermitList = (department) => () => get(null, `legacy/${department}/building-permit-list`);
const useBuildingPermitList = (department) => {
    return useQuery({
        queryKey: ['building-permit-list', { department }],
        queryFn: fetchBuildingPermitList(department),
        enabled: !!department,
    });
};
export default useBuildingPermitList;
