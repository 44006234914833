import * as React from 'react';
import { memo, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, HStack, Stack } from '@chakra-ui/react';
import moment from 'moment';
import DateTimePickerInput from 'components/inputs/DateTimePickerInput';
import StatisticList from 'components/tabs/crmTabs/teamStatistics/StatisticList';
import { CRM_TABS } from 'context/TabsContext';
import { useToggleCRMTab } from 'hooks/contexts/useTabsContext';
import useTeamStatistics from 'hooks/crm/useTeamStatistics';
import StatisticUtilisationList from './StatisticUtilisationList';
const TeamStatisticsPanel = () => {
    const { t } = useTranslation();
    const toggleCRMTab = useToggleCRMTab();
    const [dateStart, setDateStart] = useState(moment().format('YYYY') + '-01-01');
    const [dateEnd, setDateEnd] = useState(moment().format('YYYY-MM-DD'));
    const methods = useForm({
        defaultValues: {
            dateStart: new Date(moment().format('YYYY-MM') + '-01'),
            dateEnd: new Date(moment().format('YYYY-MM-DD')),
        },
    });
    const { handleSubmit, watch } = methods;
    const watchDateStart = watch('dateStart');
    const watchDateEnd = watch('dateEnd');
    useEffect(() => {
        setDateStart(watchDateStart ? moment(watchDateStart).format('YYYY-MM-DD') : null);
        setDateEnd(watchDateEnd ? moment(watchDateEnd).format('YYYY-MM-DD') : null);
    }, [watchDateStart, watchDateEnd]);
    const { teamStatistics, isLoadingTeamStatistics, errorTeamStatistics, teamStatisticsGlobal, isLoadingTeamStatisticsGlobal, teamStatisticsUtilisation, isLoadingTeamStatisticsUtilisation, } = useTeamStatistics(dateStart, dateEnd);
    useEffect(() => {
        if (errorTeamStatistics) {
            toggleCRMTab({ tabId: CRM_TABS.TEAM_STATISTICS, closeTab: true });
        }
    }, [errorTeamStatistics]);
    // This page has restricted access so we don't want to show anything
    // if the query returns an error
    if (errorTeamStatistics) {
        return null;
    }
    const onSubmit = () => { };
    return (<Stack paddingX={4} height="100%" spacing={4}>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <HStack alignItems="center">
            <DateTimePickerInput name="dateStart" label={t('tabs.team_statistics.date_start')} placeholder={t('global.beginning')} required withTime={false} direction="row" minDate={new Date('2015-01-01')} size="sm" width="auto"/>
            <DateTimePickerInput name="dateEnd" label={t('tabs.team_statistics.date_end')} placeholder={t('global.today')} required withTime={false} isClearable={true} direction="row" minDate={null} size="sm" width="auto"/>
          </HStack>
        </form>
      </FormProvider>

      <StatisticUtilisationList data={teamStatisticsUtilisation || []} title="tabs.statistic_team.utilisation_title" analyticsAction="Equipe statistiques utilisation" id="table-utilisation-team" isLoading={isLoadingTeamStatisticsUtilisation}/>
      <StatisticList data={teamStatistics || []} title="tabs.statistic_team.statistic_title" analyticsAction="Equipe statistiques nouvelles" id="table-statistic-team" isLoading={isLoadingTeamStatistics}/>
      <Box marginTop={4}>
        <StatisticList data={teamStatisticsGlobal || []} title="tabs.statistic_team.opportunities_title" analyticsAction="Equipe statistiques total" id="table-opportunities-team" isLoading={isLoadingTeamStatisticsGlobal}/>
      </Box>
    </Stack>);
};
export default memo(TeamStatisticsPanel);
