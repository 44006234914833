import * as React from 'react';
import { Box, TabList, useDimensions } from '@chakra-ui/react';
import { TABS_VIEW_CLOSED_VERTICAL, TABS_VIEW_HALF_VERTICAL, } from 'context/LayoutContext';
import { useLayout, useTabsView } from 'hooks/contexts/useLayoutContext';
import { useMenuOpened } from 'hooks/contexts/useMenuContext';
import CloseAllTab from './CloseAllTab';
import { TabsScroll } from './TabsScroll';
import TabsViewButton from './TabsViewButton';
const TabsNavigation = () => {
    const layout = useLayout();
    const tabsView = useTabsView();
    const menuOpened = useMenuOpened();
    const elementRef = React.useRef();
    const dimensions = useDimensions(elementRef, true);
    const isNarrowNavigation = (tabsView === TABS_VIEW_HALF_VERTICAL &&
        (!!menuOpened || dimensions?.borderBox?.width < 350)) ||
        tabsView === TABS_VIEW_CLOSED_VERTICAL;
    return (<TabList id="TabsList" border="none" backgroundColor={layout === 'vertical' ? 'colorMode.background600' : 'transparent'} width={layout === 'vertical' ? '100%' : 'auto'} maxWidth="100%" justifyContent={layout === 'vertical' ? 'flex-start' : 'flex-end'} ref={elementRef} data-cy={`tabs_list_` + tabsView}>
      <Box display="flex" flex="0 1">
        <TabsViewButton type="deploy"/>
      </Box>
      <TabsScroll />
      <Box display="flex" flex="0 1">
        <TabsViewButton type="reduce"/>
      </Box>
      <CloseAllTab isNarrowNavigation={isNarrowNavigation}/>
    </TabList>);
};
export default TabsNavigation;
