import snakeCase from 'lodash/snakeCase';
import { NEIGHBORHOOD_CATEGORIES } from 'components/tabs/plots/PlotSections/neighborhood/PlotNeighborhoodWrapper';
import { getPlotExportTitle } from 'utils/plots';
import { getCellValue, getFieldName } from './plotExportUtils';
const categories_fields = ['neighborhood', 'town', 'department'];
export const formatNeighborhoodData = ({ neighborhood, towns, plot, activeSection, t, }) => {
    if (!neighborhood) {
        return [];
    }
    const sheets = [];
    // Neighborhood Categories Table
    const neighborhoodSheets = Object.keys(NEIGHBORHOOD_CATEGORIES).map((categoryIndex) => {
        const categoryData = neighborhood.statistics[categoryIndex].map((stat) => {
            const statData = {};
            statData['stat'] = stat.title;
            categories_fields.forEach((field) => {
                if (!neighborhood.displayNeighborhood && field === 'neighborhood') {
                    return;
                }
                statData[`${t(`tabs.plots.section.neighborhood.table.${snakeCase(field)}`)}${field === 'town' ? ' ' + towns[plot.townId]?.name : ''}`] = stat[field];
            });
            return statData;
        });
        return {
            sheetTitle: t(`tabs.plots.export.title.neighborhood`) +
                ' - ' +
                t(`tabs.plots.section.neighborhood.sections.${NEIGHBORHOOD_CATEGORIES[categoryIndex]}`) +
                ' - ' +
                getPlotExportTitle(plot, towns[plot.townId]?.name ?? ''),
            getData: () => categoryData,
            formatHeader: getFieldName({ activeSection, t }),
            formatValue: getCellValue({ activeSection, t }),
            mode: 'horizontal',
        };
    });
    sheets.push(...neighborhoodSheets);
    return sheets;
};
