import { useCallback, useMemo } from 'react';
import { useOpenPlotModal } from 'hooks/contexts/useModalContext';
import { useSelectedProject } from 'hooks/contexts/useTabsContext';
import useProject from 'hooks/crm/useProject';
import createLayersHook from 'hooks/layers/createLayersHook';
import useOpenPlotFromMap from 'hooks/plot/useOpenPlotFromMap';
import { filtersKey } from 'utils/filters';
const LEGENDS = {
    plotProject: {
        label: filtersKey.PROJECT_PLOTS,
        layerKey: filtersKey.PROJECT_PLOTS,
        needsTranslate: true,
        color: '#FFFFFE',
        borderColor: '#000000',
        borderWidth: 1,
        borderOpacity: 1,
    },
};
const getInfoWindowContent = (layerInfo) => {
    return layerInfo.contacts
        .map((contact) => {
        return contact.firstname + ' ' + contact.lastname;
    })
        .join('<br>');
};
const useProjectPlotsLayer = ({ preSelectedProject = null, }) => {
    const baseSelectedProject = useSelectedProject();
    const selectedProject = preSelectedProject ?? baseSelectedProject;
    const { projectPlots } = useProject(selectedProject?.townId, selectedProject?.id);
    const openPlotFromMap = useOpenPlotFromMap();
    const openPlotModal = useOpenPlotModal();
    const providedData = useMemo(() => {
        return projectPlots ? Object.values(projectPlots) : null;
    }, [projectPlots]);
    const handleClick = useCallback((item) => {
        if (!preSelectedProject) {
            openPlotFromMap(item);
        }
        else {
            openPlotModal({
                townshipId: item.townId,
                plotId: item.id,
            });
        }
    }, [preSelectedProject]);
    // La gestion de l'affichage en fonction du zoom est directement gérer en css
    // (voir #googleMap .zoom-0 .marker-label-plot)
    createLayersHook({
        providedData,
        onClick: handleClick,
        getInfoWindowContent,
        eventInfoWindow: 'hover',
        legends: LEGENDS,
    })(filtersKey.PROJECT_PLOTS);
};
const ProjectPlotsLayer = ({ selectedProject = null, }) => {
    useProjectPlotsLayer({ preSelectedProject: selectedProject });
    return null;
};
export default ProjectPlotsLayer;
