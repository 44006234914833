import { useEffect, useState } from 'react';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, Heading, HStack, Stack, Text } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PERSONALIZATION } from 'analytics/useAnalytics';
import PersonalisationConfirmModal from 'components/tabs/personalization/PersonalisationConfirmModal';
import usePersonalizationPlotStatus from 'hooks/personalization/usePersonalizationPlotStatus';
import { PLOT_STATUS_DEFAULT, PLOT_STATUS_OPTIONS } from 'utils/constants';
import { formatDate } from 'utils/formatDate';
import 'react-color-palette/css';
import PersonalisationStatus from './PersonalisationStatus';
const PersonalizationPlotStatus = () => {
    const { trackEvent } = useAnalytics();
    const { t } = useTranslation();
    const methods = useForm();
    const { personalizationPlotStatus, isLoading, updatePersonalizationPlotStatus, isUpdateStatus, } = usePersonalizationPlotStatus();
    const [personalization, setPersonalization] = useState([]);
    const [personalizationState, setPersonalizationState] = useState(null);
    useEffect(() => {
        if (!personalizationPlotStatus) {
            return;
        }
        const perso = [];
        let persoState = null;
        if (personalizationPlotStatus.status.length > 0) {
            personalizationPlotStatus.status.map((item) => {
                perso.push({
                    id: item.statusId,
                    statusId: item.statusId,
                    label: item.label,
                    color: item.color,
                    order: item.order,
                });
            });
            persoState = {
                modificationUser: personalizationPlotStatus.modificationUser,
                modificationDate: formatDate(personalizationPlotStatus.modificationDate, 'extended'),
            };
        }
        else {
            Object.values(PLOT_STATUS_OPTIONS).map((item) => {
                perso.push({
                    id: item.value,
                    statusId: item.value,
                    label: item.label,
                    color: item.color,
                    order: item.legendOrder,
                });
            });
        }
        setPersonalization(perso);
        setPersonalizationState(persoState);
    }, [personalizationPlotStatus]);
    const resetStatus = () => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Reset de la personnalisation parcelle',
        });
        const perso = [];
        Object.values(PLOT_STATUS_OPTIONS).map((item) => {
            perso.push({
                id: item.value,
                statusId: item.value,
                label: item.label,
                color: item.color,
                order: item.legendOrder,
            });
        });
        setPersonalization(perso);
    };
    const onSubmit = () => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: 'Mise à jour des statuts des parcelles',
        });
        updatePersonalizationPlotStatus(personalization);
    };
    return (<Stack spacing={1} padding={4} alignItems="start">
      <Heading as="h4" variant="h4">
        {personalizationPlotStatus?.countCustomerAccount > 1
            ? t('tabs.personalization.section.plot_status.info')
            : t('tabs.personalization.section.plot_status.info_alone')}
      </Heading>
      <FormProvider {...methods}>
        <PersonalisationStatus personalization={personalization} analyticsActionType="projet" isLoading={isLoading} statusDefault={PLOT_STATUS_DEFAULT} setPersonalization={setPersonalization}/>
      </FormProvider>
      <HStack>
        <PersonalisationConfirmModal onSubmit={onSubmit} isUpdated={isUpdateStatus} content={t('tabs.personalization.section.plot_status.modal_content')}/>
        <Button isDisabled={isUpdateStatus} variant="ghostBorder" onClick={resetStatus}>
          {t('tabs.personalization.section.plot_status.reset')}
        </Button>
      </HStack>
      {personalizationState && (<Text marginTop={1} fontSize="12px">
          {t('tabs.personalization.section.plot_status.state', personalizationState)}{' '}
          {personalizationState.modificationDate}
        </Text>)}
    </Stack>);
};
export default PersonalizationPlotStatus;
