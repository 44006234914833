import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IODPEInfo } from 'components/infoWindow/DPEInfoWindow';
export const fetchDPE = (projectId) => () => get(iots.array(IODPEInfo), `${projectId}/project-dpe`);
export const QUERY_KEY = 'project-dpe';
export const getQueryKey = (projectId) => [QUERY_KEY, projectId];
const useProjectDPE = (projectId, enabled) => {
    const { data: dpe, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(projectId),
        queryFn: fetchDPE(projectId),
        enabled: !!projectId && enabled,
    });
    return { dpe, isLoading };
};
export default useProjectDPE;
