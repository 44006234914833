import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AddIcon } from '@chakra-ui/icons';
import { Box, Button, HStack } from '@chakra-ui/react';
import MultiSelectInput, { multiSelectStyles, } from 'components/inputs/MultiSelectInput';
import { CRM_TABS } from 'context/TabsContext';
import { useSetSelectedSection, useToggleCRMTab, } from 'hooks/contexts/useTabsContext';
import usePersonalizationPlotTag from 'hooks/personalization/usePersonalizationPlotTag';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PERSONALIZATION_PLOT_TAG } from 'types/api';
import { getContrastColor } from 'utils/color';
const PlotTag = ({ plot }) => {
    const { t } = useTranslation();
    const toggleCRMTab = useToggleCRMTab();
    const setSelectedSection = useSetSelectedSection();
    const { hasPersonalization } = useSiteConfiguration();
    const { personalizationPlotTags, isLoadingPlotTags, personalizationPlotTagsByPlot, isLoadingPlotTagsByPlot, updatePersonalizationPlotTagByPlot, isUpdateTagByPlot, } = usePersonalizationPlotTag(hasPersonalization(PERSONALIZATION_PLOT_TAG), plot?.id);
    const methods = useForm();
    const { setValue, getValues, watch } = methods;
    const plotTags = watch('plotTags');
    useEffect(() => {
        if (personalizationPlotTagsByPlot) {
            const personalizationPlotTagByPlotValues = personalizationPlotTagsByPlot.map((personalizationPlotTagByPlot, index) => {
                return {
                    value: parseInt(personalizationPlotTagByPlot.tagId),
                    label: personalizationPlotTagByPlot.label,
                    color: personalizationPlotTagByPlot.color,
                    legendOrder: index,
                };
            });
            setValue('plotTags', personalizationPlotTagByPlotValues);
        }
    }, [personalizationPlotTagsByPlot]);
    const handleManagePlotTag = (selectValues) => {
        setValue('plotTags', selectValues);
        const tagIds = getValues('plotTags');
        updatePersonalizationPlotTagByPlot({
            plotId: plot.id,
            tagIds: tagIds.map((tag) => tag.value),
        });
    };
    const personalizationPlotTagsOptions = useMemo(() => {
        if (!personalizationPlotTags || !plotTags) {
            return [];
        }
        else {
            const selectedLabelIds = plotTags.map((item) => item.value.toString());
            return personalizationPlotTags
                .filter((label) => !selectedLabelIds.includes(label.id))
                .map((personalizationPlotTag, index) => {
                return {
                    value: personalizationPlotTag.id,
                    label: personalizationPlotTag.label,
                    color: personalizationPlotTag.color,
                    legendOrder: index,
                };
            });
        }
    }, [personalizationPlotTags, plotTags]);
    return (<Box width="full" maxWidth="625px">
      <FormProvider {...methods}>
        <HStack width="100%" alignItems="flex-end" marginTop={2}>
          <MultiSelectInput name="plotTags" isDisabled={!plot} label={t('tabs.plots.section.plot.tag.title')} options={personalizationPlotTagsOptions ?? []} isLoading={isLoadingPlotTags || isLoadingPlotTagsByPlot || isUpdateTagByPlot} placeholder={t('tabs.plots.section.plot.tag.placeholder')} onChange={handleManagePlotTag} noOptionsMessage={({ inputValue }) => inputValue?.length
            ? t('global.no_result')
            : t('tabs.plots.section.plot.tag.placeholder')} styles={{
            ...multiSelectStyles,
            multiValue: (base, { data }) => ({
                ...base,
                backgroundColor: data.color,
            }),
            multiValueLabel: (base, { data }) => ({
                ...base,
                color: getContrastColor(data.color),
            }),
        }}/>
        </HStack>
      </FormProvider>
      <Button padding={0} leftIcon={<AddIcon fill="currentColor"/>} variant="link-primary" onClick={() => {
            setSelectedSection(PERSONALIZATION_PLOT_TAG);
            toggleCRMTab({ tabId: CRM_TABS.PERSONALIZATION });
        }} data-cy="plot_manager_plot_tag">
        {t('tabs.plots.section.plot.manage_plot_tab')}
      </Button>
    </Box>);
};
export default PlotTag;
