import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Badge, Heading, Stack, Text } from '@chakra-ui/react';
import { useActiveFiltersCount } from 'hooks/contexts/useFiltersContext';
const FiltersHeader = () => {
    const { t } = useTranslation();
    const activeFiltersCount = useActiveFiltersCount();
    return (<Stack alignItems="start" id="FiltersTreeHeader">
      <Heading width="full" as="h4" variant="h3" marginBottom="3" display="flex">
        <Text color="colorMode.font900" as="span" marginRight="1">
          {t('sidebar.header.title')}
        </Text>
        <Badge textTransform="none" backgroundColor="gray.900" color="white" width="20px" height="20px" borderRadius="35" textAlign="center">
          {activeFiltersCount}
        </Badge>
      </Heading>
    </Stack>);
};
export default FiltersHeader;
