import * as React from 'react';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Translation } from 'react-i18next';
import { Stack, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import * as iots from 'io-ts';
import snakeCase from 'lodash/snakeCase';
import { get } from 'api/client';
import PlotButton from 'components/buttons/PlotButton';
import Table from 'components/table/Table';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { IOCoordinate } from 'types/types';
export const IOCoOwnerTableData = iots.type({
    id: iots.string,
    plotNumber: iots.string,
    department: iots.string,
    town: iots.string,
    coOwner: iots.string,
    nbLotsTotal: iots.number,
    nbLots: iots.number,
    nbLotsHabitat: iots.number,
    nbLotsParking: iots.number,
    townId: iots.string,
    lat: IOCoordinate,
    lng: IOCoordinate,
    plotId: iots.string,
});
const getColumnHeader = (column, suffix = null) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.co_owner.columns.${column}`)}
    </Translation>
    {suffix}
  </Text>);
const columns = [
    {
        id: 'columns',
        columns: [
            {
                accessorKey: 'plotNumber',
                header: () => getColumnHeader('plot_number'),
                accessorFn: (row) => {
                    if (!row.plotNumber)
                        return t('table.empty_column');
                    return row.plotNumber;
                },
                cell: (info) => {
                    return (<PlotButton lat={info.row.original.lat} lng={info.row.original.lng} townId={info.row.original.townId} variant="link-primary" plotId={info.row.original.plotId}>
              {info.row.original.plotNumber}
            </PlotButton>);
                },
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'department',
                header: () => getColumnHeader('department'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'town',
                header: () => getColumnHeader('town'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'coOwner',
                header: () => getColumnHeader('co_owner'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'nbLotsTotal',
                header: () => getColumnHeader('nb_lots_total'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'nbLots',
                header: () => getColumnHeader('nb_lots'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'nbLotsHabitat',
                header: () => getColumnHeader('nb_lots_habitat'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
            {
                accessorKey: 'nbLotsParking',
                header: () => getColumnHeader('nb_lots_parking'),
                cell: (info) => info.getValue(),
                enableGlobalFilter: false,
                filterFn: 'arrIncludesSome',
            },
        ],
    },
];
const EXPORTED_FIELDS = [
    'plotNumber',
    'department',
    'town',
    'coOwner',
    'nbLotsTotal',
    'nbLots',
    'nbLotsHabitat',
    'nbLotsParking',
];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.co_owner.columns.${snakeCase(header)}`);
const fetchCoOwner = (department, townId) => () => get(iots.array(IOCoOwnerTableData), `${department}/${townId}/co-owner`);
const CoOwnerPanel = () => {
    const { t } = useTranslation();
    const selectedTown = useSelectedTown();
    const department = selectedTown?.department;
    const townId = selectedTown?.id;
    const { data, isInitialLoading: isLoading } = useQuery({
        queryKey: ['co-owner', department, townId],
        queryFn: fetchCoOwner(department, townId),
        enabled: !!department && !!townId,
    });
    return (<Stack paddingX={4} height="100%">
      <Table variant="simple" id="co-owner" columns={columns} data={data || []} isLoading={isLoading} exportTitle={t('tabs.extra_tabs.co_owner.tab_title')} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)}/>
    </Stack>);
};
export default memo(CoOwnerPanel);
