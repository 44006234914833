import { useTranslation } from 'react-i18next';
import * as iots from 'io-ts';
import createLayersHook from 'hooks/layers/createLayersHook';
import { IOPolygonLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { get } from '../../api/client';
import { formatDate } from '../../utils/formatDate';
const IOSecurityOrderLayerItem = iots.intersection([
    IOPolygonLayerItem,
    iots.type({
        date: iots.string,
        url: iots.string,
    }),
], 'IODataCenterLayerItem');
const getLegends = () => {
    const legends = {};
    legends.security_order = {
        layerKey: filtersKey.SECURITY_ORDER,
        label: 'security_order',
        needsTranslate: true,
        color: '#FF0000',
    };
    return legends;
};
const fetchSecurityOrder = (townId) => () => get(iots.array(IOPolygonLayerItem), `${townId}/security-order/layer`);
const SecurityOrderLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = (layerInfo) => {
        let content = '';
        if (layerInfo.date) {
            content = '<div>' + formatDate(layerInfo.date, 'short') + '</div>';
        }
        if (layerInfo.url) {
            content +=
                '<a href="' +
                    layerInfo.url +
                    '" target="_blank">' +
                    t('info_window.security_order.url') +
                    '</a>';
        }
        return content;
    };
    createLayersHook({
        fetch: fetchSecurityOrder,
        getInfoWindowContent: getInfoWindowContent,
        legends: getLegends(),
    })(filtersKey.SECURITY_ORDER);
    return null;
};
export default SecurityOrderLayer;
