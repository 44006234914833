import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Select } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useExtraFilters, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { filtersKey, getTimePeriodOptions, LAST_SIX_MONTHS, OLDER_YEAR, } from 'utils/filters';
export const HOUSING_PURPOSE_OPTIONS = {
    indifferent: 'none',
    0: 'senior',
    1: 'student',
    2: 'tourism',
    3: 'social_hotel',
    4: 'social_residence',
    5: 'disabled_persons',
    6: 'other',
};
export const OFFICES_PURPOSE_OPTIONS = {
    indifferent: 'none',
    3: 'offices',
    4: 'store',
    5: 'craftmanship',
    6: 'industry',
    2: 'hotels',
    7: 'agriculture',
    8: 'warehouse',
    9: 'public_service',
    1: 'housing',
};
const getPurposeOptions = (item) => {
    if (item === filtersKey.BUILDING_PERMIT_HOUSING) {
        return HOUSING_PURPOSE_OPTIONS;
    }
    if (item === filtersKey.BUILDING_PERMIT_OFFICES) {
        return OFFICES_PURPOSE_OPTIONS;
    }
    return null;
};
const BuildingPermitNewMarketSidebarContent = ({ item, filterUpdateDate }) => {
    const { trackEvent } = useAnalytics();
    const extraFilters = useExtraFilters();
    const updateExtraFilter = useUpdateExtraFilter();
    const { t } = useTranslation();
    const timePeriodSelectedOption = extraFilters?.[item]?.timePeriod || '';
    const updateDate = new Date(filterUpdateDate?.updateDate);
    const timePeriodDisplayOptions = getTimePeriodOptions(2019, updateDate.getFullYear()).map((option) => {
        return {
            value: option,
            label: option === LAST_SIX_MONTHS
                ? t(`global.select.time_period.last_six_months`)
                : option === OLDER_YEAR
                    ? `${option} ${t('global.select.time_period.and_before')}`
                    : option,
        };
    });
    const purposeSelectedOption = item === filtersKey.BUILDING_PERMIT_PLANNING_PERMIT
        ? ''
        : extraFilters?.[item]?.purpose || '';
    const purposeOptions = getPurposeOptions(item);
    const purposeDisplayOptions = purposeOptions
        ? Object.keys(purposeOptions).map((option) => {
            return {
                value: option === 'indifferent' ? '' : option,
                label: t(`sidebar_content.building_permit_new_market.${item}.purpose.${purposeOptions[option]}`),
            };
        })
        : null;
    const handleTimePeriodChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${item}.timePeriod`, value === LAST_SIX_MONTHS ? value : parseInt(value));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${item}`)} ${t('global.select.time_period.label')}`,
        });
    };
    const handlePurposeChange = (e) => {
        const value = e.target.value;
        updateExtraFilter(`${item}.purpose`, value);
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${item}`)} ${t(`sidebar_content.building_permit_new_market.${item}.purpose.label`).replace(':', '')}`,
        });
    };
    return (<Box width="full">
      <FormControl display="flex" alignItems="center" marginBottom={purposeDisplayOptions ? 2 : 0}>
        <FormLabel textStyle="small" marginBottom={0}>
          {t('global.select.time_period.label')}&nbsp;:
        </FormLabel>
        <Select size="sm" placeholder={t('global.select.time_period.placeholder')} onChange={handleTimePeriodChange} value={timePeriodSelectedOption}>
          {timePeriodDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
              {option.label}
            </option>))}
        </Select>
      </FormControl>
      {!!purposeDisplayOptions && (<FormControl display="flex" alignItems="center">
          <FormLabel textStyle="small" marginBottom={0}>
            {t(`sidebar_content.building_permit_new_market.${item}.purpose.label`)}
          </FormLabel>
          <Select size="sm" onChange={handlePurposeChange} value={purposeSelectedOption}>
            {purposeDisplayOptions.map((option) => (<option key={option.value} value={option.value}>
                {option.label}
              </option>))}
          </Select>
        </FormControl>)}
    </Box>);
};
export default BuildingPermitNewMarketSidebarContent;
