import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IODPEInfo } from '../../components/infoWindow/DPEInfoWindow';
export const fetchDPE = (plotId) => () => get(iots.array(IODPEInfo), `${plotId}/DPE`);
export const QUERY_KEY = 'plot-dpe';
export const getQueryKey = (plotId) => [QUERY_KEY, plotId];
const usePlotDPE = (plotId, enabled) => {
    const { data: dpe, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(plotId),
        queryFn: fetchDPE(plotId),
        enabled: !!plotId && enabled,
    });
    return { dpe, isLoading };
};
export default usePlotDPE;
