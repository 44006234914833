import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import * as iots from 'io-ts';
import DPE_A from 'images/dpe/A.png';
import DPE_B from 'images/dpe/B.png';
import DPE_C from 'images/dpe/C.png';
import DPE_D from 'images/dpe/D.png';
import DPE_E from 'images/dpe/E.png';
import DPE_F from 'images/dpe/F.png';
import DPE_G from 'images/dpe/G.png';
export const IODPEInfo = iots.type({
    numberDPE: iots.string,
    dateEstablishment: iots.string,
    GES: iots.string,
    DPE: iots.string,
    yearConstruction: iots.union([iots.number, iots.string]),
    typeBuilding: iots.string,
    typeHeating: iots.string,
    typeECS: iots.string,
    periodConstruction: iots.string,
    ceilingHeight: iots.number,
    nbApartment: iots.number,
    livingSurfaceBuilding: iots.number,
    livingSurfaceHousing: iots.number,
    tertiarySurfaceBuilding: iots.number,
    inertiaClass: iots.string,
    typologyHousing: iots.string,
    positionHousingInBuilding: iots.string,
    address: iots.string,
    postalCode: iots.string,
    stage: iots.number,
    residenceName: iots.string,
    addressComplementBuilding: iots.string,
    heatingEnergy: iots.string,
    configInstallationHeating: iots.string,
    installationHeating: iots.string,
    energyTypeECS: iots.string,
    descriptionECS: iots.string,
});
const DPEInfoWindow = ({ info, width = '400px', }) => {
    const { t } = useTranslation();
    if (!info) {
        return null;
    }
    const getImg = (type) => {
        switch (type) {
            case 'A':
                return DPE_A;
            case 'B':
                return DPE_B;
            case 'C':
                return DPE_C;
            case 'D':
                return DPE_D;
            case 'E':
                return DPE_E;
            case 'F':
                return DPE_F;
            case 'G':
                return DPE_G;
            default:
                return null;
        }
    };
    const unity = (key) => {
        switch (key) {
            case 'ceilingHeight':
            case 'livingSurfaceBuilding':
            case 'livingSurfaceHousing':
            case 'tertiarySurfaceBuilding':
                return 'm²';
            default:
                return '';
        }
    };
    return (<Box style={{ maxHeight: '400px', maxWidth: width }}>
      {info.map((data, index) => {
            const infoFiltered = Object.keys(data).filter((key) => !!data[key]);
            return (<Box key={index} style={{ marginBottom: '0.5rem' }}>
            {infoFiltered.map((key) => {
                    if (key === 'dateEstablishment') {
                        return (<Box key={key}>
                    <Box style={{
                                backgroundColor: '#DDDDDD',
                                marginBottom: '0.1rem',
                                fontSize: '14px',
                                padding: '0.2rem',
                                textAlign: 'center',
                            }}>
                      <strong>{data[key]}</strong>
                    </Box>
                    <Box>
                      {t(`dpe.infos.${key}`)} :{' '}
                      <strong>
                        {data[key]} {unity(key)}
                      </strong>
                    </Box>
                  </Box>);
                    }
                    else {
                        return (<Box key={key} style={key === 'DPE' || key === 'GES'
                                ? { display: 'flex', alignItems: 'center' }
                                : {}}>
                    {t(`dpe.infos.${key}`)} :{' '}
                    {key === 'DPE' || key === 'GES' ? (<img src={getImg(data[key])} style={{
                                    width: '18px',
                                    marginLeft: '4px',
                                    marginTop: '1px',
                                    marginBottom: '1px',
                                }}/>) : (<strong>
                        {data[key]} {unity(key)}
                      </strong>)}
                  </Box>);
                    }
                })}
          </Box>);
        })}
    </Box>);
};
export default DPEInfoWindow;
