import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { getBaseTileUrlHost3 } from 'utils/multiplexage';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    const layer = 'bruit';
    return (getBaseTileUrlHost3() +
        'cgi-bin/mm/mapserv.cgi?map=/var/data/public_maps/project_suisseBruit.map&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetMap&FORMAT=image%2Fpng&TRANSPARENT=true&LAYERS=' +
        layer +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:3857&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = () => {
    const legends = {};
    legends.noise_sensibility_relaxation = {
        layerKey: filtersKey.NOISE_SENSIBILITY,
        label: 'noise_sensibility_relaxation',
        needsTranslate: true,
        color: '#89B377',
    };
    legends.noise_sensibility_habitat = {
        layerKey: filtersKey.NOISE_SENSIBILITY,
        label: 'noise_sensibility_habitat',
        needsTranslate: true,
        color: '#FFE785',
    };
    legends.noise_sensibility_habitat_craftsmanship = {
        layerKey: filtersKey.NOISE_SENSIBILITY,
        label: 'noise_sensibility_habitat_craftsmanship',
        needsTranslate: true,
        color: '#FF8585',
    };
    legends.noise_sensibility_industry = {
        layerKey: filtersKey.NOISE_SENSIBILITY,
        label: 'noise_sensibility_industry',
        needsTranslate: true,
        color: '#B185FF',
    };
    return legends;
};
const useNoiseSensibilityLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.NOISE_SENSIBILITY],
        getLegends,
    })(filtersKey.NOISE_SENSIBILITY);
};
const NoiseSensibilityLayers = () => {
    useNoiseSensibilityLayers();
    return null;
};
export default NoiseSensibilityLayers;
