import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button, HStack, Input } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PERSONALIZATION } from 'analytics/useAnalytics';
import DeleteButton from 'components/buttons/DeleteButton';
import ColorPalette from 'components/tools/ColorPalette';
import usePersonalizationPlotTag from 'hooks/personalization/usePersonalizationPlotTag';
import useCustomToast from 'hooks/useCustomToast';
import 'react-color-palette/css';
const PersonalizationPlotTag = ({ plotTag, setAddLabel }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const { getErrorToast } = useCustomToast();
    const { updatePersonalizationPlotTag, isUpdateLabel, deletePersonalizationPlotTag, isDeleteLabel, } = usePersonalizationPlotTag(true);
    const methods = useForm({
        defaultValues: plotTag,
        mode: 'onChange',
    });
    const { register, watch, setValue, getValues, formState: { isDirty }, reset, } = methods;
    const formValues = watch();
    const handleColorChange = (id, color) => {
        setValue('color', color, { shouldDirty: true });
    };
    const onSubmit = () => {
        if (formValues.label === '') {
            getErrorToast({
                title: t('toast.error_title'),
                description: t('tabs.personalization.section.plot_tag.no_label'),
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                id: 'personalization_plot_tag',
            });
        }
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: "Mise à jour d'une balise pour les parcelles",
        });
        setAddLabel && setAddLabel(false);
        const data = {
            id: formValues.id,
            label: formValues.label,
            color: formValues.color,
        };
        updatePersonalizationPlotTag(data);
        reset(data);
    };
    const handleDelete = (id) => {
        trackEvent({
            category: ANALYTICS_PERSONALIZATION,
            action: "Suppression d'une balise pour les parcelles",
        });
        setAddLabel && setAddLabel(false);
        deletePersonalizationPlotTag({ id });
    };
    return (<FormProvider {...methods}>
      <HStack spacing={3} width="410px">
        <Input {...register('id')} type="hidden"/>
        <Input {...register('color')} type="hidden"/>
        <Input {...register('label')} type="text" placeholder={t('tabs.personalization.section.plot_tag.placeholder')} maxLength={32} width="220px"/>
        <ColorPalette id={0} analyticsAction="des balises des parcelles" colorDefault={getValues('color') ?? ''} chosenColor={handleColorChange}/>
        <Button size="sm" type="button" variant="primary" paddingX={2} minWidth="auto" onClick={onSubmit} isLoading={isUpdateLabel} isDisabled={!isDirty}>
          {plotTag.id === '0'
            ? t('global.actions.add')
            : t('global.actions.save')}
        </Button>
        {plotTag.id !== '0' ? (<DeleteButton id="plot_tag_delete_button" size="sm" mode="icon" variant="outlineDelete" handleDelete={() => handleDelete(plotTag.id)} disabled={isUpdateLabel || isDeleteLabel} showIcon confirmText={t('tabs.personalization.section.plot_tag.delete_confirm')}/>) : (<DeleteButton id="plot_tag_delete_add_button" size="sm" mode="icon" variant="outlineDelete" confirm={false} onClick={() => setAddLabel(false)} handleDelete={() => { }} disabled={isUpdateLabel || isDeleteLabel} showIcon/>)}
      </HStack>
    </FormProvider>);
};
export default PersonalizationPlotTag;
