import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier, getNormalizedCoordinates, } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const normCoord = getNormalizedCoordinates(coord, zoom);
    if (!normCoord) {
        return null;
    }
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_environnement', 'unesco', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.wind_unesco_natural_near = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_near',
        needsTranslate: true,
        color: '#7b00c9',
        opacity: 0.6,
    };
    legends.wind_unesco_natural_far = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_far',
        needsTranslate: true,
        color: '#b644ff',
        opacity: 0.6,
    };
    legends.wind_unesco_natural_project_near = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_project_near',
        needsTranslate: true,
        color: '#0047ff',
        opacity: 0.6,
    };
    legends.wind_unesco_natural_project_far = {
        layerKey: filtersKey.WIND_UNESCO_NATURAL,
        label: 'wind_unesco_natural_project_far',
        needsTranslate: true,
        color: '#0374be',
        opacity: 0.6,
    };
    return legends;
};
const useUNESCONaturalLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_UNESCO_NATURAL],
        getLegends,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.WIND_UNESCO_NATURAL);
};
const UNESCONaturalLayers = () => {
    useUNESCONaturalLayers();
    return null;
};
export default UNESCONaturalLayers;
