import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_environnement', 'rpg_ilots_de_reference_2023', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.rpg_islet = {
        layerKey: filtersKey.RPG_ISLET,
        label: 'rpg_islet',
        needsTranslate: true,
        color: '#FFFFFF',
        opacity: 1,
        borderWidth: 3,
        borderColor: '#979797',
    };
    return legends;
};
const useRPGIsletLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.RPG_ISLET],
        getLegends,
        opacity: 1,
        tileWidth: 1024,
        tileHeight: 1024,
    };
    createTilesLayerHook(options)(filtersKey.RPG_ISLET);
};
const RPGIsletLayer = () => {
    useRPGIsletLayer();
    return null;
};
export default RPGIsletLayer;
