import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, FormLabel, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Tooltip, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_URBANISM_FILTERS, } from 'analytics/useAnalytics';
import { useIsFilterActive, useToggleFilter, useUpdateExtraFilter, } from 'hooks/contexts/useFiltersContext';
import { filtersKey } from 'utils/filters';
import { formatNumberToLocale } from 'utils/formatNumber';
// On multiplie par 10 car le slider arrondit
export const DEFAULT_CAPARESEAU_ISODISTANCE = 100;
const ElectricNetworkCapareseauIsodistanceCarSidebarContent = () => {
    const { t, i18n } = useTranslation();
    const { trackEvent } = useAnalytics();
    const updateExtraFilter = useUpdateExtraFilter();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const [sliderValue, setSliderValue] = useState(formatNumberToLocale(DEFAULT_CAPARESEAU_ISODISTANCE / 10, i18n.language));
    const [showTooltip, setShowTooltip] = useState(false);
    const handleChange = (value) => {
        updateExtraFilter(`${filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR}.isoDistance`, parseInt(value));
        if (!isFilterActive(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR)) {
            toggleFilter(filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR, true);
        }
        setSliderValue(formatNumberToLocale(value / 10, i18n.language));
        trackEvent({
            category: ANALYTICS_URBANISM_FILTERS,
            action: `${t(`sidebar.categories.${filtersKey.ELECTRICAL_NETWORKS_CAPARESEAU_CAR}`)} ${t(`sidebar_content.capareseau.distance`)} ` +
                value / 10 +
                ' km',
        });
    };
    return (<Box width="full" maxWidth="500px">
        <FormControl display="flex" alignItems="center">
          <FormLabel textStyle="small" marginBottom={0} minWidth="45px" fontSize="12px">
            {`${sliderValue} km`}
          </FormLabel>
          <Box width="75%">
            {/*On multiplie par 10 car le slider arrondie*/}
            <Slider defaultValue={DEFAULT_CAPARESEAU_ISODISTANCE} min={25} max={150} step={25} onChange={(val) => handleChange(val)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}>
              <SliderTrack bg="blue.100">
                <SliderFilledTrack bg="blue.500"/>
              </SliderTrack>
              <Tooltip hasArrow bg="blue.300" color="white" placement="top" isOpen={showTooltip} label={`${sliderValue} km`}>
                <SliderThumb boxSize={4}/>
              </Tooltip>
            </Slider>
          </Box>
        </FormControl>
      </Box>);
};
export default ElectricNetworkCapareseauIsodistanceCarSidebarContent;
