import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_electricite', 'elec_htb_pylones', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_pylon = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON,
        label: 'electrical_networks_pylon',
        needsTranslate: true,
        color: '#636463',
        borderWidth: 10,
        width: 10,
        borderRadius: 0,
        polygonType: VECTOR_TYPES.polyline,
    };
    return legends;
};
const useElectricalNetworksLayers = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON],
        getLegends,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.ELECTRICAL_NETWORKS_ELECTRICITY_PYLON);
};
const ElectricalNetworksPylonHTBLayers = () => {
    useElectricalNetworksLayers();
    return null;
};
export default ElectricalNetworksPylonHTBLayers;
