import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOBuildingPermitNewMarketInfo, } from 'components/infoWindow/BuildingPermitNewMarketInfoWindow';
export const fetchBuildingPermit = (plotId) => () => get(iots.array(IOBuildingPermitNewMarketInfo), `${plotId}/building-permit`);
export const QUERY_KEY = 'plot-building-permit';
export const getQueryKey = (plotId) => [QUERY_KEY, plotId];
const usePlotBuildingPermit = (plotId, enabled) => {
    const { data: buildingsPermits, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(plotId),
        queryFn: fetchBuildingPermit(plotId),
        enabled: !!plotId && enabled,
    });
    return { buildingsPermits, isLoading };
};
export default usePlotBuildingPermit;
