import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Checkbox, FormLabel, HStack, Image, Input, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_IMPORT_PUBLIPOSTAGE, } from 'analytics/useAnalytics';
import { t } from 'i18next';
import SelectInput from 'components/inputs/SelectInput';
import CourierOptions from 'components/tabs/couriers/courierForm/CourierOptions';
import SenderFields from 'components/tabs/couriers/courierForm/SenderFields';
import { CRM_TABS } from 'context/TabsContext';
import { useToggleCRMTab } from 'hooks/contexts/useTabsContext';
import useImportPublipostage from 'hooks/import/useImportPublipostage';
import useCustomToast from 'hooks/useCustomToast';
import LogoWord from 'images/logo/word.svg';
const ValidatePublipostage = ({ children, fileContent, contactIds, closeDrawer, }) => {
    const { trackEvent } = useAnalytics();
    const toggleCRMTab = useToggleCRMTab();
    const methods = useForm({
        defaultValues: {
            file: null,
            mailevaOptionColor: 1,
            saveFields: true,
            templateId: fileContent.templateId ?? '',
        },
        mode: 'onSubmit',
    });
    const { register, handleSubmit } = methods;
    const { getSuccessToast } = useCustomToast();
    const { finishImportPublipostage, isLoading, generateCourierPublipostage, isLoadingGenerateCourier, } = useImportPublipostage();
    const [selectedFields, setSelectedFields] = useState({});
    const handleOnChange = (index) => (e) => {
        setSelectedFields((prev) => ({ ...prev, [index]: e.target.value }));
    };
    useEffect(() => {
        const selectedFieldsDefault = {};
        fileContent?.fields?.forEach((field, index) => {
            if (field.fieldDb !== '') {
                selectedFieldsDefault[index] = field.fieldDb;
            }
        });
        setSelectedFields(selectedFieldsDefault);
    }, [fileContent]);
    const generateImportPublipostageData = (data, regrouped) => {
        const fields = fileContent.fields.map((field, index) => {
            return {
                fieldFile: field.fieldFile,
                fieldDb: data?.fieldsDb[index],
            };
        });
        return {
            fields,
            importId: fileContent.importId,
            contactIds: contactIds,
            saveFields: data?.saveFields ?? 0,
            templateId: data?.templateId ?? null,
            regrouped,
        };
    };
    const downloadFilesSeparated = (data) => {
        const importPublipostageData = generateImportPublipostageData(data, false);
        finishImportPublipostage(importPublipostageData);
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Télécharger les fichiers',
        });
    };
    const downloadFilesRegrouped = (data) => {
        const importPublipostageData = generateImportPublipostageData(data, true);
        finishImportPublipostage(importPublipostageData);
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Télécharger le fichier complet',
        });
    };
    const generateCourier = (data) => {
        const fields = fileContent.fields.map((field, index) => {
            return {
                fieldFile: field.fieldFile,
                fieldDb: data?.fieldsDb[index],
            };
        });
        const importPublipostageData = {
            fields,
            saveFields: data.saveFields ?? 0,
            templateId: data?.templateId ?? null,
            importId: fileContent.importId,
            senderId: data.senderId,
            contactIds: contactIds,
            mailevaOptionColor: data.mailevaOptionColor ? 1 : 0,
            mailevaOptionTreatUndeliveredMail: data.mailevaOptionTreatUndeliveredMail
                ? 1
                : 0,
        };
        generateCourierPublipostage(importPublipostageData, {
            onSuccess: () => {
                getSuccessToast({
                    title: t('publipostage.generate_courier'),
                    id: 'publipostage_courier',
                });
                closeDrawer();
                toggleCRMTab({ tabId: CRM_TABS.COURIER });
            },
        });
        trackEvent({
            category: ANALYTICS_IMPORT_PUBLIPOSTAGE,
            action: 'Générer les courriers du publipostage',
        });
    };
    const propsFileLabel = {
        width: '50%',
        marginRight: 0,
        marginBottom: 0,
    };
    const propsHeader = {
        width: '50%',
        fontSize: '14px',
        fontWeight: 'bold',
    };
    const getFieldOptions = ({ fieldDb }) => {
        const options = [{ value: '', label: 'Aucun', selected: false }];
        const fields = fileContent.availableFields;
        fields.forEach((field) => {
            options.push({
                value: field,
                label: t(`publipostage.fields.import.db.${field}`),
                selected: fieldDb === field,
            });
        });
        return options;
    };
    return (<>
      <Text fontWeight="bold">
        2. {t('publipostage.second_step_title')}
        <Image src={LogoWord} alt="word" display="inline-block" paddingLeft="5px" width="20px" verticalAlign="middle"/>
      </Text>
      <HStack>
        <Text {...propsHeader}>
          {t('publipostage.fields.import.fileValue')}
          <Image src={LogoWord} alt="word" display="inline-block" paddingLeft="5px" width="20px" verticalAlign="middle"/>
        </Text>
        <Text {...propsHeader}>{t('global.import.DBValue')}</Text>
      </HStack>
      <FormProvider {...methods}>
        <form>
          {fileContent?.fields?.map((field, index) => (<HStack key={index} marginTop={2}>
              <FormLabel {...propsFileLabel} data-cy="file_label">
                {field.fieldFile}
              </FormLabel>
              <SelectInput name={`fieldsDb[${index}]`} widthFormControl={'50%'} defaultValue={field.fieldDb} options={getFieldOptions(field)} onChange={handleOnChange(index)} style={{
                fontWeight: `${selectedFields[index] ? '' : 'bold'}`,
                backgroundColor: `${selectedFields[index] ? '' : '#ffe5a9'}`,
            }}/>
            </HStack>))}
          {fileContent.templateId && (<HStack justifyContent="flex-end" marginTop={2}>
              <Input type="hidden" {...register('templateId')}/>
              <Checkbox {...register('saveFields')}/>
              <Text textStyle="paragraph">
                {t('publipostage.fields.import.save_fields')}
              </Text>
            </HStack>)}
          <VStack marginTop={5} alignItems="flex-end">
            {children}
            <HStack justifyContent="flex-end">
              <Button size="sm" type="submit" variant="primary" isLoading={isLoading} loadingText={t('global.status.importing')} onClick={handleSubmit((data) => {
            downloadFilesSeparated(data);
        })} data-cy="generate-files">
                {t('contact.actions.download_files_separated')}
              </Button>
              <Button size="sm" type="submit" variant="primary" isLoading={isLoading} loadingText={t('global.status.importing')} onClick={handleSubmit((data) => {
            downloadFilesRegrouped(data);
        })} data-cy="generate-files">
                {t('contact.actions.download_files_regrouped')}
              </Button>
            </HStack>
          </VStack>
          <VStack spacing={5} marginTop={2}>
            <SenderFields title={t('publipostage.fill_data_sender')}/>
            <CourierOptions title={t('courier.option')}/>
            <HStack width="full" justifyContent="flex-end">
              <Button size="sm" type="button" variant="primary" isLoading={isLoadingGenerateCourier} loadingText={t('global.status.generation')} data-cy="generate-publipostage" onClick={handleSubmit((data) => {
            generateCourier(data);
        })}>
                {t('contact.actions.generate_publipostage')}
              </Button>
            </HStack>
          </VStack>
        </form>
      </FormProvider>
    </>);
};
export default ValidatePublipostage;
