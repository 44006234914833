import * as React from 'react';
import { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, Box, Heading, Link, Stack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_PLOT_TAB } from 'analytics/useAnalytics';
import ContactDrawer from 'components/modals/contactModal/ContactDrawer';
import { getPlotSkeleton } from 'components/tabs/plots/PlotPannelSkeletons';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useTowns } from 'hooks/contexts/useLocationContext';
import { BUTTON_CONTACT_OWNER } from 'hooks/crm/useContact';
import usePlotOwnersInfo from 'hooks/plot/usePlotOwnersInfo';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { OWNER_HISTORY_PLOT_TAB } from 'types/api';
import { ACTION_CREATE } from 'utils/constants';
import { filtersKey } from 'utils/filters';
import { PlotOwnerDetail } from './PlotOwnerDetail';
import PlotOwnerPhysicalDetail from './PlotOwnerPhysicalDetail';
const DgfipEmails = ({ emails }) => {
    const { t } = useTranslation();
    if (!emails || !emails.length)
        return <span>{t('tabs.plots.section.owner.no_contact_owner')}</span>;
    return (<>
      {emails.map((email, index) => (<span key={email}>
          <>
            {index === 0
                ? ''
                : index === emails.length - 1
                    ? t('tabs.plots.section.owner.contact_owner_or')
                    : ', '}
          </>
          <Link href={`mailto:${email}?subject=${t('tabs.plots.section.owner.contact_owner_subject')}`} variant="primary">
            {email}
          </Link>
        </span>))}
    </>);
};
const PlotOwner = ({ plot, isLoading: isLoadingPlot, }) => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const towns = useTowns();
    const { currentSite } = useCurrentSite();
    const { hasPlotTab } = useSiteConfiguration();
    const { ownersByYear, isInitialLoading: isLoadingOwners } = usePlotOwnersInfo(currentSite?.ownerSubscription, currentSite?.legacyId, plot, hasPlotTab('owner'));
    const isLoading = isLoadingPlot || isLoadingOwners;
    const [contactToCreate, setContactToCreate] = useState(null);
    const toggleFilter = useToggleFilter();
    React.useEffect(() => {
        toggleFilter(filtersKey.PLOTS_OWNERS, true);
    }, []);
    let initialOpenedAccordions = [];
    if (ownersByYear) {
        // Open most recent year
        initialOpenedAccordions = [0, 1];
    }
    const handleClickCERFA = () => {
        trackEvent({
            category: ANALYTICS_PLOT_TAB,
            action: 'Propriétaire CERFA',
        });
    };
    const handleClickCERFARecipient = () => {
        trackEvent({
            category: ANALYTICS_PLOT_TAB,
            action: 'Propriétaire CERFA organisme',
        });
    };
    const skeleton = getPlotSkeleton();
    if (isLoading)
        return skeleton;
    return (<Box margin={4} data-cy="plot_owner_tab">
      <Stack alignItems="start" spacing={4}>
        <PlotOwnerPhysicalDetail plot={plot}/>
        {(currentSite.ownerPublicity && (<Box width="full" backgroundColor="colorMode.base" borderRadius="md" boxShadow="md" padding={4} marginBottom={4} alignItems="start">
            <Text textStyle="paragraph">
              {t('tabs.plots.section.owner.publicity')}
            </Text>
          </Box>)) ||
            (currentSite.ownerSubscription && !ownersByYear && (<Box width="full" backgroundColor="colorMode.base" borderRadius="md" boxShadow="md" padding={4} marginBottom={4} alignItems="start">
              <Text textStyle="paragraph">{t('global.none')}</Text>
            </Box>)) ||
            (currentSite.ownerSubscription && (<>
              <Accordion allowMultiple border="none" width="100%" defaultIndex={initialOpenedAccordions}>
                {Object.keys(ownersByYear)
                    .reverse()
                    .map((year, index) => {
                    if (year === 'layers') {
                        return;
                    }
                    if (index !== 1 && !hasPlotTab(OWNER_HISTORY_PLOT_TAB)) {
                        return;
                    }
                    return (<React.Fragment key={index}>
                        {ownersByYear[year].building && (<PlotOwnerDetail owners={ownersByYear[year].building} year={year} type="building" setContactToCreate={setContactToCreate}/>)}
                        {ownersByYear[year].ground && (<PlotOwnerDetail owners={ownersByYear[year].ground} year={year} type="ground" setContactToCreate={setContactToCreate}/>)}
                      </React.Fragment>);
                })}
              </Accordion>
            </>))}
      </Stack>
      <VStack width="full" backgroundColor="colorMode.base" borderRadius="md" boxShadow="md" padding={4} alignItems="start">
        <Heading as="h4" variant="h4" fontWeight={700} color="red.500">
          {t('tabs.plots.section.owner.find_owner')}
        </Heading>
        {towns[plot?.townId]?.dgfipEmails && (<Text textStyle="paragraph">
            <Trans i18nKey="tabs.plots.section.owner.contact_owner" t={t}>
              contact
              <DgfipEmails emails={towns[plot?.townId]?.dgfipEmails}/>
              or print
              <Link isExternal href="https://impots.gouv.fr/sites/default/files/formulaires/6815-em-sd/2012/6815-em-sd_31.pdf" variant="primary" onClick={handleClickCERFA}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                {{ cerfa: t('tabs.plots.section.owner.cerfa') }}
              </Link>{' '}
              and send it to
              <Link isExternal href="https://www.service-public.fr/particuliers/vosdroits/R18819" variant="primary" onClick={handleClickCERFARecipient}>
                {{
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                cerfa_recipient: t('tabs.plots.section.owner.cerfa_recipient'),
            }}
              </Link>
              .
            </Trans>
          </Text>)}
      </VStack>
      <ContactDrawer action={ACTION_CREATE} contact={contactToCreate} button={BUTTON_CONTACT_OWNER} handleClose={() => setContactToCreate(null)} triggerItem={() => <></>}/>
    </Box>);
};
export default PlotOwner;
