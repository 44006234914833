import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { VECTOR_TYPES } from 'types/types';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_electricite', 'elec_hta_bt_poteaux', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.electrical_networks_bt_aerial = {
        layerKey: filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT,
        label: 'electrical_networks_pylon_hta_bt',
        needsTranslate: true,
        polygonType: VECTOR_TYPES.point,
        color: '#0BC573',
        height: 8,
        opacity: 1,
    };
    return legends;
};
const useElectricalNetworksPylonHTABTLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT],
        getLegends,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.ELECTRICAL_NETWORKS_PYLON_HTA_BT);
};
const ElectricalNetworksPylonHTABTLayer = () => {
    useElectricalNetworksPylonHTABTLayer();
    return null;
};
export default ElectricalNetworksPylonHTABTLayer;
