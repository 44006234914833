import * as React from 'react';
import { Box } from '@chakra-ui/react';
import BuildingPermit from 'components/tabs/plot-project/Sections/BuildingPermit';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useBuildingPermitLayers from 'hooks/filter/useBuildingPermitLayers';
import usePlotBuildingPermit from 'hooks/plot/usePlotBuildingPermit';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { BUILDING_PERMIT_PLOT_TAB } from 'types/api';
const PlotBuildingPermit = ({ plot }) => {
    const selectedTown = useSelectedTown();
    const { hasPlotTab } = useSiteConfiguration();
    const { currentSite } = useCurrentSite();
    const { buildingsPermits, isLoading } = usePlotBuildingPermit(plot?.id, hasPlotTab(BUILDING_PERMIT_PLOT_TAB));
    const { buildingPermitLayers, isLoading: isLoadingLayers } = useBuildingPermitLayers(selectedTown?.id, currentSite.legacyId);
    return (<Box padding="4">
      <BuildingPermit buildingsPermits={buildingsPermits} isLoading={isLoading} buildingPermitLayers={buildingPermitLayers} isLoadingLayers={isLoadingLayers}/>
    </Box>);
};
export default PlotBuildingPermit;
