import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { BASE_TILE_SIZE, formatNatura2000Info, getLayerCoordInfo, } from 'utils/ign';
import { getLayerCoordTileUrl } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = () => (coord, zoom) => {
    return getLayerCoordTileUrl('PROTECTEDAREAS.ZPS', 'PROTECTEDAREAS.ZPS', zoom, coord);
};
const getDetails = (t) => () => async (coords, map) => {
    return await getLayerCoordInfo('PROTECTEDAREAS.ZPS', formatNatura2000Info, coords, map, t);
};
const getLegends = () => {
    const legends = {};
    legends.natura_2000_zps = {
        layerKey: filtersKey.NATURA_2000_ZPS,
        label: 'natura_2000_zps',
        needsTranslate: true,
        color: '#A8D613',
        opacity: 0.6,
    };
    return legends;
};
const useNatural2000ZPSLayer = () => {
    const { t } = useTranslation();
    const getInfoWindowContent = useMemo(() => getDetails(t), [t]);
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.NATURA_2000_ZPS],
        getLegends,
        getInfoWindowContent,
        tileWidth: BASE_TILE_SIZE,
        tileHeight: BASE_TILE_SIZE,
        opacity: 0.5,
    })(filtersKey.NATURA_2000_ZPS);
};
const Natura2000ZPSLayer = () => {
    useNatural2000ZPSLayer();
    return null;
};
export default Natura2000ZPSLayer;
