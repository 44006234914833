import * as iots from 'io-ts';
import { get } from 'api/client';
import createLayersHook from 'hooks/layers/createLayersHook';
import PostIcon from 'images/post_icon.png';
import PostProjectIcon from 'images/post_project_icon.png';
import { IOMarkerLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
export const IOElectricalNetworksSourcePostLayerItem = iots.intersection([
    IOMarkerLayerItem,
    iots.type({
        project: iots.boolean,
    }),
], 'IODPEMarkerItem');
const LEGENDS = {
    source_post: {
        label: 'source_post',
        layerKey: filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS,
        needsTranslate: true,
        image: PostIcon,
    },
    source_post_project: {
        label: 'source_post_project',
        layerKey: filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS,
        needsTranslate: true,
        image: PostProjectIcon,
    },
};
const fetchElectricalNetworksSourcePost = (townId) => () => get(iots.array(IOElectricalNetworksSourcePostLayerItem), `${townId}/source-post`);
const getMarkerImg = (marker) => {
    if (marker.project) {
        return PostProjectIcon;
    }
    return PostIcon;
};
const useElectricalNetworksSourcePostLayer = () => {
    createLayersHook({
        fetch: fetchElectricalNetworksSourcePost,
        markerImg: getMarkerImg,
        legends: LEGENDS,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.ELECTRICAL_NETWORKS_SOURCE_POSTS);
};
const ElectricalNetworksSourcePostLayer = () => {
    useElectricalNetworksSourcePostLayer();
    return null;
};
export default ElectricalNetworksSourcePostLayer;
