import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_environnement', 'perimetres_protection_captages_eau_potable_01', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.drinking_water_immediate = {
        layerKey: filtersKey.DRINKING_WATER_CAPTURE,
        label: 'drinking_water_immediate',
        needsTranslate: true,
        color: '#ff0000',
        opacity: 0.8,
    };
    legends.drinking_water_near = {
        layerKey: filtersKey.DRINKING_WATER_CAPTURE,
        label: 'drinking_water_near',
        needsTranslate: true,
        color: '#ffff00',
        opacity: 0.8,
    };
    legends.drinking_water_far = {
        layerKey: filtersKey.DRINKING_WATER_CAPTURE,
        label: 'drinking_water_far',
        needsTranslate: true,
        color: '#00512D',
        opacity: 0.8,
    };
    return legends;
};
const useDrinkingWaterCaptureLayer = () => {
    const options = {
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.DRINKING_WATER_CAPTURE],
        getLegends,
        opacity: 0.6,
        tileWidth: 1024,
        tileHeight: 1024,
    };
    createTilesLayerHook(options)(filtersKey.DRINKING_WATER_CAPTURE);
};
const DrinkingWaterCaptureLayer = () => {
    useDrinkingWaterCaptureLayer();
    return null;
};
export default DrinkingWaterCaptureLayer;
