import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IODeathTableData } from 'types/api';
export const QUERY_KEY = 'death';
const QUERY_SEARCH_KEY = 'death-search';
const fetchDeath = (department) => () => get(iots.array(IODeathTableData), `${department}/death`);
const fetchSearchDeath = (name, townId) => () => get(iots.array(IODeathTableData), `death/search`, { name, townId });
const useDeath = (department, contact) => {
    const { data: deathDepartment, isInitialLoading: isLoadingDeathDepartment } = useQuery({
        queryKey: [QUERY_KEY, { department }],
        queryFn: fetchDeath(department),
        enabled: !!department,
    });
    const { data: deathContact, isInitialLoading: isLoadingDeathContact } = useQuery({
        queryKey: [
            QUERY_SEARCH_KEY,
            { name: contact?.lastname, townId: contact?.townId },
        ],
        queryFn: fetchSearchDeath(contact?.lastname, contact?.townId),
        enabled: !!contact,
    });
    return {
        deathDepartment,
        isLoadingDeathDepartment,
        deathContact,
        isLoadingDeathContact,
    };
};
export default useDeath;
