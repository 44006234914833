import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Heading, Spinner, Text, VStack } from '@chakra-ui/react';
const RPGSection = ({ rpg, type, isLoading, }) => {
    const { t } = useTranslation();
    if (!isLoading && !rpg) {
        return (<Text textStyle="paragraph">
        {t(`tabs.${type}.section.rpg.not_available`)}
      </Text>);
    }
    if (isLoading) {
        return (<Center height="100%" width="100%">
        <Spinner size="lg"/>
      </Center>);
    }
    return (<Box width="full">
      <Heading as="h4" variant="h4" marginBottom={1}>
        {t(`tabs.${type}.section.rpg.header`)} :
      </Heading>
      <VStack alignItems="flex-start">
        {rpg?.map((rpg) => {
            return (<Text key={rpg.id} as="div" textStyle="paragraph">
              {rpg.year} :{' '}
              {rpg.labels === null
                    ? t(`tabs.${type}.section.rpg.nothing`)
                    : rpg.labels}
            </Text>);
        })}
      </VStack>
    </Box>);
};
export default RPGSection;
