import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { del, get, post } from 'api/client';
import { getPlotStatusQueryKey } from 'hooks/crm/usePlotStatus';
import { QUERY_KEY as USER_PLOTS_QUERY_KEY } from 'hooks/crm/useUserPlotsList';
import useCustomToast from 'hooks/useCustomToast';
import useOnError from 'hooks/useOnError';
import { IOEmpty, IOPersonalizationPlotTag, } from 'types/api';
const QUERY_KEY = 'perso_plot_tag';
const QUERY_KEY_TAB_BY_PLOT = 'perso_plot_tag_by_plot';
export const getQueryKeyByPlot = (plotId) => [
    QUERY_KEY_TAB_BY_PLOT,
    plotId,
];
export const fetchPersonalizationPlotTags = () => {
    return get(iots.array(IOPersonalizationPlotTag), `personalization/plot/tags`);
};
export const fetchPersonalizationPlotTagsByPlot = (plotId) => {
    return get(iots.array(IOPersonalizationPlotTag), `personalization/plot/${plotId}/tags`);
};
const updatePersonalizationPlotTagRequest = (plotTags) => post(IOEmpty, `personalization/plot/tag`, plotTags);
const updatePersonalizationPlotTagsByPlotRequest = ({ plotId, tagIds, }) => post(IOEmpty, `personalization/plot/${plotId}/tags`, { tagIds });
const deletePersonalizationPlotTagRequest = ({ id }) => del(IOEmpty, `personalization/plot/tag/${id}`);
const usePersonalizationPlotTag = (enabled, plotId) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const onErrorCallback = useOnError();
    const { getSuccessToast } = useCustomToast();
    const { data: personalizationPlotTags, isInitialLoading: isLoadingPlotTags } = useQuery({
        queryKey: [QUERY_KEY],
        queryFn: () => fetchPersonalizationPlotTags(),
        enabled: enabled,
    });
    const { data: personalizationPlotTagsByPlot, isInitialLoading: isLoadingPlotTagsByPlot, } = useQuery({
        queryKey: getQueryKeyByPlot(plotId),
        queryFn: () => fetchPersonalizationPlotTagsByPlot(plotId),
        enabled: !!plotId && enabled,
    });
    const { mutate: updatePersonalizationPlotTag, isPending: isUpdateLabel } = useMutation({
        mutationFn: updatePersonalizationPlotTagRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: (data, variables) => {
            getSuccessToast({
                title: variables.id === '0'
                    ? t('personalization.plot.label_create_success')
                    : t('personalization.plot.label_update_success'),
                id: 'personalization_plot_tag',
            });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TAB_BY_PLOT] });
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
        },
    });
    const { mutate: updatePersonalizationPlotTagByPlot, isPending: isUpdateTagByPlot, } = useMutation({
        mutationFn: updatePersonalizationPlotTagsByPlotRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: getQueryKeyByPlot(plotId) });
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
            queryClient.invalidateQueries({
                queryKey: getPlotStatusQueryKey(plotId),
            });
        },
    });
    const { mutate: deletePersonalizationPlotTag, isPending: isDeleteLabel } = useMutation({
        mutationFn: deletePersonalizationPlotTagRequest,
        onError: (err) => onErrorCallback(err),
        onSuccess: () => {
            getSuccessToast({
                title: t('personalization.plot.label_delete_success'),
                id: 'personalization_plot_tag_delete',
            });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
            queryClient.invalidateQueries({ queryKey: [QUERY_KEY_TAB_BY_PLOT] });
            queryClient.invalidateQueries({ queryKey: USER_PLOTS_QUERY_KEY });
        },
    });
    return {
        personalizationPlotTags,
        isLoadingPlotTags,
        personalizationPlotTagsByPlot,
        isLoadingPlotTagsByPlot,
        updatePersonalizationPlotTag,
        isUpdateLabel,
        updatePersonalizationPlotTagByPlot,
        isUpdateTagByPlot,
        deletePersonalizationPlotTag,
        isDeleteLabel,
    };
};
export default usePersonalizationPlotTag;
