import * as React from 'react';
import { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { useContextFeasibilityPlots } from 'context/useContextFeasibilityPlots';
import { useContextPlotHighlights } from 'context/useContextPlotHighlights';
import { useToggleFilter } from 'hooks/contexts/useFiltersContext';
import { useClearPlotSummary } from 'hooks/contexts/useLegendContext';
import { useClickedCoord, useIsSecondarySelectionMode, usePlotSelectionMode, useSelectedTown, } from 'hooks/contexts/useLocationContext';
import { filtersKey } from 'utils/filters';
import { PLOT_SELECTION_FEASIBILITY, } from './LocationContext';
import { useContextPlotPostsHighlights, } from './useContextPlotPostsHighlights';
export const SecondaryPlotsContext = createContext(null);
export const SecondaryPlotsProvider = ({ children, }) => {
    const selectedTown = useSelectedTown();
    const clickedCoord = useClickedCoord();
    const toggleFilter = useToggleFilter();
    const plotSelectionMode = usePlotSelectionMode();
    const isSecondarySelectionMode = useIsSecondarySelectionMode();
    const clearPlotSummary = useClearPlotSummary();
    const { highlights: plotsHighlights, arePlotsHighlighted, toggleHighlights: togglePlotsHighlights, } = useContextPlotHighlights(filtersKey.HIGHLIGHTED_PLOTS);
    const { highlights: contactPlotsHighlights, addHighlights: addContactPlotsHighlights, toggleHighlights: toggleContactPlotsHighlights, clearHighlights: clearContactPlotsHighlights, arePlotsHighlighted: areContactPlotsHighlighted, } = useContextPlotHighlights(filtersKey.CONTACT_PLOTS);
    const { highlights: sourcePostsPlotHighlights, arePlotsHighlighted: arePlotSourcePostsHighlighted, toggleHighlights: togglePlotSourcePostsHighlights, clearHighlights: clearPlotSourcePostsHighlights, } = useContextPlotPostsHighlights(filtersKey.PLOT_SOURCE_POST);
    const { highlights: distributionPostsPlotHighlights, arePlotsHighlighted: arePlotDistributionPostsHighlighted, toggleHighlights: togglePlotDistributionPostsHighlights, clearHighlights: clearPlotDistributionPostsHighlights, } = useContextPlotPostsHighlights(filtersKey.PLOT_DISTRIBUTION_POST);
    const [feasibilityParentId, setFeasibilityParentId] = useState();
    const [secondaryPlotCoord, setSecondaryPlotCoord] = useState(null);
    const { getFeasibilityPlots, isLoadingFeasibilityPlot, clearAllFeasibilityForParent, removeOneFeasibilityPlot, resetFeasibilityPlots, } = useContextFeasibilityPlots(feasibilityParentId, secondaryPlotCoord, setSecondaryPlotCoord);
    const { getFeasibilityPlots: getFeasibilityAdditionalPlots, isLoadingFeasibilityPlot: isLoadingFeasibilityAdditionalPlot, removeOneFeasibilityPlot: removeOneFeasibilityAdditionalPlot, clearAllFeasibilityForParent: clearAllAdditionalFeasibilityForParent, resetFeasibilityPlots: resetAdditionalFeasibilityPlots, } = useContextFeasibilityPlots(feasibilityParentId, secondaryPlotCoord, setSecondaryPlotCoord, true);
    // Selecting feasibility plots by clicking on the map
    useEffect(() => {
        if (!isSecondarySelectionMode || !clickedCoord) {
            return;
        }
        if (!selectedTown) {
            // We don't have a selected town, so we don't want to select a plot
            setSecondaryPlotCoord(null);
            return;
        }
        setSecondaryPlotCoord({
            mode: plotSelectionMode,
            coord: clickedCoord,
            town: selectedTown,
        });
    }, [clickedCoord]);
    useEffect(() => {
        if (isSecondarySelectionMode) {
            clearPlotSummary();
            toggleFilter(filtersKey.PLOTS, true);
        }
    }, [isSecondarySelectionMode]);
    useEffect(() => {
        if (!isLoadingFeasibilityPlot && !isLoadingFeasibilityAdditionalPlot) {
            setSecondaryPlotCoord(null);
        }
    }, [isLoadingFeasibilityPlot, isLoadingFeasibilityAdditionalPlot]);
    const initPlotFeasibility = (plot) => {
        setSecondaryPlotCoord({
            mode: PLOT_SELECTION_FEASIBILITY,
            id: plot.id,
            coord: new google.maps.LatLng(parseFloat(plot.lat), parseFloat(plot.lng)),
            town: selectedTown,
        });
        setFeasibilityParentId(plot?.id);
    };
    const closeOneFeasibilityPlot = (parentId, plotId) => {
        removeOneFeasibilityPlot(parentId, plotId);
        removeOneFeasibilityAdditionalPlot(parentId, plotId);
    };
    const clearAllPlotFeasibility = (parentId) => {
        clearAllFeasibilityForParent(parentId);
        clearAllAdditionalFeasibilityForParent(parentId);
    };
    const resetFeasibility = () => {
        setFeasibilityParentId(null);
        resetFeasibilityPlots();
        resetAdditionalFeasibilityPlots();
    };
    return (<SecondaryPlotsContext.Provider value={{
            // plots highlights
            plotsHighlights,
            togglePlotsHighlights,
            arePlotsHighlighted,
            contactPlotsHighlights,
            addContactPlotsHighlights,
            toggleContactPlotsHighlights,
            clearContactPlotsHighlights,
            areContactPlotsHighlighted,
            sourcePostsPlotHighlights,
            togglePlotSourcePostsHighlights,
            arePlotSourcePostsHighlighted,
            clearPlotSourcePostsHighlights,
            distributionPostsPlotHighlights,
            togglePlotDistributionPostsHighlights,
            arePlotDistributionPostsHighlighted,
            clearPlotDistributionPostsHighlights,
            // feasibility plots
            secondaryPlotCoord,
            setSecondaryPlotCoord,
            setFeasibilityParentId,
            getFeasibilityPlots,
            isLoadingFeasibilityPlot,
            getFeasibilityAdditionalPlots,
            isLoadingFeasibilityAdditionalPlot,
            initPlotFeasibility,
            closeOneFeasibilityPlot,
            clearAllPlotFeasibility,
            resetFeasibility,
        }}>
      {children}
    </SecondaryPlotsContext.Provider>);
};
export default SecondaryPlotsContext;
