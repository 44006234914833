import { useTranslation } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { get, post } from 'api/client';
import { QUERY_KEY as TEAM_PLOTS_QUERY_KEY } from 'hooks/crm/useTeamPlots';
import useCustomToast from 'hooks/useCustomToast';
import { IOTeamShareable } from 'types/api';
const fetchPlotShareableTeam = (plotId) => () => get(IOTeamShareable, `${plotId}/plot-shareable-team`);
const sharePlotRequest = ({ plotId, townshipId, users, copyStatus = false, copyContact = false, copyComment = false, copyDocument = false, copyPhoto = false, }) => post(null, `${townshipId}/plot/${plotId}/share`, {
    users,
    copyStatus,
    copyContact,
    copyComment,
    copyDocument,
    copyPhoto,
});
const useSharePlot = (plotId) => {
    const { getSuccessToast, getErrorToast } = useCustomToast();
    const queryClient = useQueryClient();
    const { t } = useTranslation();
    const queryKey = ['plot-shareable-team', plotId];
    const { data: shareableTeam, isInitialLoading: isLoading } = useQuery({
        queryKey,
        queryFn: fetchPlotShareableTeam(plotId),
        enabled: !!plotId,
    });
    const { mutate: sharePlot } = useMutation({
        mutationFn: sharePlotRequest,
        onError: (err) => {
            getErrorToast({
                title: t('modals.share_plot.error_title'),
                description: err.message,
                id: err.response?.status,
            });
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: queryKey });
            queryClient.invalidateQueries({ queryKey: TEAM_PLOTS_QUERY_KEY });
            getSuccessToast({
                title: t('modals.share_plot.success_title'),
                description: t('modals.share_plot.success_description'),
                id: 'plot_share_success',
            });
        },
    });
    return { shareableTeam, isLoading, sharePlot };
};
export default useSharePlot;
