import { memo, useEffect, useRef } from 'react';
import * as React from 'react';
import { Box } from '@chakra-ui/react';
import ProjectPlotsLayer from 'components/layers/ProjectPlotsLayer';
import Toolbar from 'components/mapModal/ToolBar/ToolbarWrapper';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { addSatelliteIgnMapType, createMap } from 'utils/map';
import MapPlotsLayer from '../layers/MapPlotsLayer';
import MapBlock from './MapBlock';
import { usePlotSelectionMode } from 'hooks/contexts/useLocationContext';
import { useMapLandPoint, useSetMapLandPoint, } from 'hooks/contexts/useMapContext';
import { useSetSecondaryPlotCoord } from 'hooks/contexts/useSecondaryPlotsContext';
const MapContainer = ({ selectedProject, }) => {
    const { mapDisplay } = useSiteConfiguration();
    const plotSelectionMode = usePlotSelectionMode();
    const setSecondaryPlotCoord = useSetSecondaryPlotCoord();
    const refMap = useRef(null);
    const clickListener = useRef(null);
    const mapLandPoint = useMapLandPoint();
    const setMapLandPoint = useSetMapLandPoint();
    useEffect(() => {
        const newMap = createMap(refMap, false, true, mapDisplay);
        addSatelliteIgnMapType(newMap);
        setMapLandPoint(newMap);
    }, [refMap]);
    useEffect(() => {
        if (!mapLandPoint) {
            return;
        }
        if (!clickListener.current) {
            clickListener.current = mapLandPoint.addListener('click', (event) => {
                setSecondaryPlotCoord({
                    mode: plotSelectionMode,
                    coord: event.latLng,
                    town: null,
                });
            });
        }
    }, [mapLandPoint]);
    return (<Box data-cy="map_container_modal" position="relative" flex={1} width="full" height="100%">
      <MapBlock refMap={refMap}/>
      <Box height="auto">
        <Toolbar map={mapLandPoint}/>
      </Box>
      {selectedProject && (<>
          <ProjectPlotsLayer selectedProject={selectedProject}/>
          <MapPlotsLayer selectedProject={selectedProject}/>
        </>)}
    </Box>);
};
export default memo(MapContainer);
