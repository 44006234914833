import { useEffect } from 'react';
import { useSelectedPlot } from 'hooks/contexts/useTabsContext';
import { useSectionHash, useToggleLayerTab, } from 'hooks/contexts/useTabsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import usePlotOwnersInfo from 'hooks/plot/usePlotOwnersInfo';
import useCurrentSite from 'hooks/useCurrentSite';
import { IOLayerItem } from 'types/api';
import { filtersKey } from 'utils/filters';
import { defaultGetInfoWindowContent } from 'utils/map';
const IOPlotOwnerLayerItem = IOLayerItem;
const usePlotOwnerLayer = () => {
    const { currentSite } = useCurrentSite();
    const toggleLayerTab = useToggleLayerTab();
    const selectedPlot = useSelectedPlot();
    const { layers } = usePlotOwnersInfo(currentSite?.ownerSubscription, currentSite?.legacyId, selectedPlot);
    const sectionHash = useSectionHash();
    useEffect(() => {
        if (sectionHash && sectionHash !== 'owner') {
            toggleLayerTab({ tabId: filtersKey.PLOTS_OWNERS, closeTab: true });
        }
    }, [sectionHash]);
    createLayersHook({
        providedData: layers,
        getInfoWindowContent: defaultGetInfoWindowContent,
    })(filtersKey.PLOTS_OWNERS);
};
const PlotOwnerLayer = () => {
    usePlotOwnerLayer();
    return null;
};
export default PlotOwnerLayer;
