import * as React from 'react';
import useTabTitle from 'hooks/navigation/useTabTitle';
import { filtersKey } from 'utils/filters';
import TabItemWrapper, { TabItemTitle, } from './TabItemWrapper';
export const FilterResultsTabKeys = [
    filtersKey.BUILDING_PERMIT_HOUSING,
    filtersKey.BUILDING_PERMIT_OFFICES,
    filtersKey.BUILDING_PERMIT_PLANNING_PERMIT,
    filtersKey.BANKRUPT_COMPANIES_PLOTS,
    filtersKey.BANKRUPT_COMPANIES,
    filtersKey.BANKRUPT_COMPANIES,
    filtersKey.COMPANIES,
    filtersKey.COMPANY_OWNED_MANAGER,
    filtersKey.CO_OWNER,
    filtersKey.DEATH,
];
const FilterResultTabItem = (props) => {
    const { tab } = props;
    const { getTitle, getDetail } = useTabTitle();
    const title = getTitle(tab);
    const detail = getDetail(tab);
    return (<TabItemWrapper {...props} tooltipContent={`${title} - ${detail}`}>
      <TabItemTitle title={title} detail={detail}/>
    </TabItemWrapper>);
};
export default FilterResultTabItem;
