import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Center, Checkbox, Spinner, Text, VStack } from '@chakra-ui/react';
import BusinessInfoWindow from 'components/infoWindow/BusinessInfoWindow';
import { useIsFilterActive, useToggleFilter, } from 'hooks/contexts/useFiltersContext';
import usePlotBusiness from 'hooks/plot/usePlotBusiness';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { BUSINESS_PLOT_TAB } from 'types/api';
import { filtersKey } from 'utils/filters';
const PlotBusiness = ({ plot }) => {
    const { t } = useTranslation();
    const toggleFilter = useToggleFilter();
    const isFilterActive = useIsFilterActive();
    const { hasPlotTab } = useSiteConfiguration();
    const { business, isLoading } = usePlotBusiness(plot?.id, hasPlotTab(BUSINESS_PLOT_TAB));
    const handleChange = (filterKey) => {
        toggleFilter(filterKey);
    };
    return (<VStack alignItems="flex-start" padding={4} color="var(--colorModeFont-800)">
      <Text fontWeight="bold">
        {t('tabs.plots.section.business.title_content')}
      </Text>
      <Checkbox key={filtersKey.BODACC_BUSINESS} data-id={filtersKey.BODACC_BUSINESS} isChecked={isFilterActive(filtersKey.BODACC_BUSINESS)} onChange={() => handleChange(filtersKey.BODACC_BUSINESS)} lineHeight="1" display="flex" alignItems="center" cursor="pointer">
        <Text fontSize="14px">{t('sidebar.categories.bodacc_business')}</Text>
      </Checkbox>
      <Box width="full" marginTop={2} fontSize="13px">
        {isLoading ? (<Center>
            <Spinner />
          </Center>) : business ? (<BusinessInfoWindow info={business}/>) : (t('tabs.plots.section.business.no_result'))}
      </Box>
    </VStack>);
};
export default PlotBusiness;
