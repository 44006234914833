import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import LegendImage from 'images/legends/wind_potential_50.png';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_environnement', 'cartes_des_vents_50m', zoom, coord, tileWidth, tileHeight);
};
const getLegends = () => {
    const legends = {};
    legends.wind_potential_50 = {
        layerKey: filtersKey.WIND_POTENTIAL_50,
        label: '',
        needsTranslate: false,
        image: LegendImage,
        height: '90px',
    };
    return legends;
};
const useWindPotential50Layer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.WIND_POTENTIAL_50],
        getLegends,
        opacity: 0.6,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filtersKey.WIND_POTENTIAL_50);
};
const WindPotential50Layer = () => {
    useWindPotential50Layer();
    return null;
};
export default WindPotential50Layer;
