import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';
import { Box, ChakraProvider, Heading, HStack, Image, Link, Text, VStack, } from '@chakra-ui/react';
import LogoKelFoncier from 'images/logo_kelFoncier.png';
import extendedTheme from 'utils/theme';
const ErrorPage = () => {
    const error = useRouteError();
    const { t } = useTranslation();
    const notFound = error.status === 404;
    return (<ChakraProvider theme={extendedTheme}>
      <Box as="main" width="100%">
        <HStack justifyContent="center" spacing={0}>
          <Box width="50%">
            <VStack height="100vh" bgColor="colorMode.background900">
              <VStack flex={1} justifyContent="center" alignItems="center">
                <Box width="150px">
                  <Image src={LogoKelFoncier} objectFit="contain" w="150px" marginBottom="50px"/>
                </Box>
                <Heading as="h3" variant="h3" marginBottom={3}>
                  {notFound ? t('page_not_found.title') : error.statusText}
                </Heading>

                <Text>
                  {notFound ? (t('page_not_found.text')) : process.env.NODE_ENV !== 'development' ? (<Text textStyle="paragraph" textAlign="center">
                      {t('page_not_found.error_message')}{' '}
                      <Link href={`mailto:${t('page_not_found.error_email')}`} variant="primary">
                        {t('page_not_found.error_email')}
                      </Link>{' '}
                      {t('page_not_found.error_tel')}
                    </Text>) : (error.message)}
                </Text>

                <Box width="full" paddingTop={20}>
                  <Text textStyle="paragraph" textAlign="center">
                    {t('login.come_back_home')}
                    <Link href="/" variant="primary" paddingLeft={1}>
                      {t('login.home')}
                    </Link>
                  </Text>
                </Box>
              </VStack>
            </VStack>
          </Box>
        </HStack>
      </Box>
    </ChakraProvider>);
};
export default ErrorPage;
