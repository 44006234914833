export const getDomain = () => {
    const domain = window.location.hostname;
    if (domain === 'local.kelfoncier.com' ||
        domain === 'jolicode.kelfoncier.com') {
        return 'immo.kelfoncier.com';
    }
    if (domain === 'local-suisse.kelfoncier.com' ||
        domain === 'test.kelfoncier.com') {
        return 'ch.landdetector.com';
    }
    return domain;
};
export const isSwissSite = () => {
    return getDomain() === 'ch.landdetector.com';
};
export const isGermanySite = () => {
    return getDomain() === 'de.landdetector.com';
};
export const getDefaultCountry = () => {
    if (isSwissSite()) {
        return 'CH';
    }
    if (isGermanySite()) {
        return 'DE';
    }
    return 'FR';
};
