import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOSourcePostInfo } from 'types/api';
export const fetchSourcePosts = (plotId) => () => get(iots.array(IOSourcePostInfo), `${plotId}/sources-posts`);
export const QUERY_KEY = 'plot-source-post';
export const getQueryKey = (plotId) => [QUERY_KEY, plotId];
const usePlotSourcePost = (plotId, enabled) => {
    const { data: sourcePosts, isInitialLoading: isLoading } = useQuery({
        queryKey: getQueryKey(plotId),
        queryFn: fetchSourcePosts(plotId),
        enabled: !!plotId && enabled,
    });
    return { sourcePosts, isLoading };
};
export default usePlotSourcePost;
