import { useCallback, useMemo } from 'react';
import { useFilters } from 'hooks/contexts/useFiltersContext';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
import { filtersKey } from 'utils/filters';
import { getLayerBoundingTileUrlKelfoncier } from 'utils/map';
import { CST_POSITIONS } from 'utils/tilesPositions';
const getLayerTileUrl = (map, tileWidth, tileHeight, filterKey) => (coord, zoom) => {
    let layer = '';
    switch (filterKey) {
        case filtersKey.PARKING_SPACE_500_750:
            layer = 'parking500750';
            break;
        case filtersKey.PARKING_SPACE_750_1000:
            layer = 'parking7501000';
            break;
        case filtersKey.PARKING_SPACE_1000_1500:
            layer = 'parking10001500';
            break;
        case filtersKey.PARKING_SPACE_1500_5000:
            layer = 'parking15005000';
            break;
        case filtersKey.PARKING_SPACE_5000_sup:
            layer = 'parking5000';
            break;
    }
    return getLayerBoundingTileUrlKelfoncier(map, 'carte_environnement', layer, zoom, coord, tileWidth, tileHeight);
};
const getLegends = (enabledFilters) => {
    const legends = {};
    if (enabledFilters.includes(filtersKey.PARKING_SPACE_500_750)) {
        legends.parking_space_500_750 = {
            layerKey: filtersKey.PARKING_SPACE_500_750,
            label: 'parking_space_500_750',
            needsTranslate: true,
            color: '#5BDD45',
            opacity: 0.8,
        };
    }
    if (enabledFilters.includes(filtersKey.PARKING_SPACE_750_1000)) {
        legends.parking_space_750_1000 = {
            layerKey: filtersKey.PARKING_SPACE_750_1000,
            label: 'parking_space_750_1000',
            needsTranslate: true,
            color: '#00C590',
            opacity: 0.8,
        };
    }
    if (enabledFilters.includes(filtersKey.PARKING_SPACE_1000_1500)) {
        legends.parking_space_1000_1500 = {
            layerKey: filtersKey.PARKING_SPACE_1000_1500,
            label: 'parking_space_1000_1500',
            needsTranslate: true,
            color: '#12AD2B',
            opacity: 0.8,
        };
    }
    if (enabledFilters.includes(filtersKey.PARKING_SPACE_1500_5000)) {
        legends.parking_space_1500_5000 = {
            layerKey: filtersKey.PARKING_SPACE_1500_5000,
            label: 'parking_space_1500_5000',
            needsTranslate: true,
            color: '#317023',
            opacity: 0.8,
        };
    }
    if (enabledFilters.includes(filtersKey.PARKING_SPACE_5000_sup)) {
        legends.parking_space_5000_sup = {
            layerKey: filtersKey.PARKING_SPACE_5000_sup,
            label: 'parking_space_5000_sup',
            needsTranslate: true,
            color: '#18472C',
            opacity: 0.8,
        };
    }
    return legends;
};
const useParkingSpaceLayerLayer = (filterKey) => {
    const filters = useFilters();
    const enabledMap = useMemo(() => filters[filterKey] || null, [filters]);
    const getTileUrl = useCallback((map, tileWidth, tileHeight) => getLayerTileUrl(map, tileWidth, tileHeight, filterKey), [enabledMap]);
    createTilesLayerHook({
        getLayerTileUrl: getTileUrl,
        position: CST_POSITIONS[filterKey],
        getLegends,
        opacity: 0.8,
        tileWidth: 1024,
        tileHeight: 1024,
    })(filterKey);
};
const ParkingSpaceLayer = ({ filterKey, }) => {
    useParkingSpaceLayerLayer(filterKey);
    return null;
};
export default ParkingSpaceLayer;
