import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Image, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, } from '@chakra-ui/react';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
const PreviewButton = ({ photos, photosFiles, initialSlide = 0, show = false, setShow, }) => {
    const { t } = useTranslation();
    const onClose = () => {
        setShow(false);
    };
    return (<>
      <Modal isOpen={show} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('tabs.photo.modal_title')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Swiper slidesPerView={1} spaceBetween={0} loop={false} initialSlide={initialSlide} pagination={{
            clickable: true,
        }} modules={[Navigation]} navigation={true} centeredSlides={true} autoplay={false} allowTouchMove={false} style={{ height: '100%' }} className="swiper-photo-modal">
              {photos.map((photo) => {
            return (<SwiperSlide key={photo.id} className="slide">
                    <Image src={photosFiles?.[photo.id]} alt={photo.name}/>
                  </SwiperSlide>);
        })}
            </Swiper>
          </ModalBody>

          <ModalFooter>
            <Button variant="primary" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>);
};
export default PreviewButton;
