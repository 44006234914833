import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControl, Radio, RadioGroup, Stack, Text, VStack, } from '@chakra-ui/react';
import { hasOnlyFeasibilityAdditional, hasOnlyFeasibilityMain, } from 'utils/town';
const PlotsSearchFormAdditional = ({ selectedTown }) => {
    const { t } = useTranslation();
    const { register, setValue } = useFormContext();
    const handleChange = (value) => {
        setValue('searchAdditional', value);
    };
    const searchAdditional = () => {
        if (!selectedTown.hasFeasibility && selectedTown.hasAdditionalFeasibility) {
            return '1';
        }
        else if (selectedTown.hasFeasibility) {
            return '0';
        }
    };
    const isHidden = () => {
        return (hasOnlyFeasibilityAdditional(selectedTown) ||
            hasOnlyFeasibilityMain(selectedTown));
    };
    return ((selectedTown?.hasFeasibility || selectedTown.hasAdditionalFeasibility) && (<FormControl display={isHidden() ? 'none' : 'flex'} paddingX={4} paddingY={2} backgroundColor="#F0F0F0">
        <RadioGroup onChange={handleChange} value={searchAdditional()}>
          <VStack spacing={1} alignItems="flex-start">
            <Stack>
              <Radio value="1" {...register('searchAdditional')} isDisabled={!selectedTown.hasAdditionalFeasibility} opacity={!selectedTown.hasAdditionalFeasibility ? 0.6 : 1}>
                <Text fontSize="13px" color="#333333">
                  {t(`sidebar.search_plots.plu_additional`)}
                </Text>
              </Radio>
            </Stack>
            <Stack>
              <Radio id="no_additionnal" value="0" {...register('searchAdditional')} isDisabled={!selectedTown.hasFeasibility} opacity={!selectedTown.hasFeasibility ? 0.6 : 1}>
                <Text fontSize="13px" color="#333333">
                  {t(`sidebar.search_plots.plu_no_additional`)}
                </Text>
              </Radio>
            </Stack>
          </VStack>
        </RadioGroup>
      </FormControl>));
};
export default PlotsSearchFormAdditional;
