import pick from 'lodash/pick';
const fieldsSource = ['name', 'distance'];
const fieldsDistribution = ['id', 'distance'];
export const formatSourcePostData = ({ sourcePosts }) => {
    if (!sourcePosts)
        return null;
    return Object.values(sourcePosts).map((sourcePost) => {
        return pick(sourcePost, fieldsSource);
    });
};
export const formatDistributionPostsData = ({ distributionPosts }) => {
    if (!distributionPosts)
        return null;
    return distributionPosts.map((distributionPost) => {
        return pick(distributionPost, fieldsDistribution);
    });
};
