import { useQuery } from '@tanstack/react-query';
import { get } from 'api/client';
import { IOPLUGPUInfo } from 'types/api';
const QUERY_KEY = 'plu-gpu-infos';
const fetchPLUGPUInfos = (coords, signal) => {
    return get(IOPLUGPUInfo, `plu-gpu-infos/${coords.x}/${coords.y}`, {}, false, { signal }, (error) => {
        return error.response.status !== 404;
    });
};
export const getPLUGPUInfosQueryKey = (coords) => [QUERY_KEY, coords];
const usePLUGPUInfo = (coords, enabled) => {
    const { data: PLUGPUInfo, isInitialLoading, error, } = useQuery({
        queryKey: getPLUGPUInfosQueryKey(coords),
        queryFn: ({ signal }) => fetchPLUGPUInfos(coords, signal),
        enabled: !!coords && enabled,
    });
    return {
        PLUGPUInfo,
        isInitialLoading,
        error,
    };
};
export default usePLUGPUInfo;
