import { pick, snakeCase } from 'lodash';
import { TABLE_HEADERS as fields } from 'components/tabs/plots/PlotSections/PlotBuilding';
export const formatBuildingCellValue = ({ cellValue, fieldName }) => {
    if (fieldName === 'constructionYear') {
        return { value: cellValue, halign: 'center' };
    }
    return { value: cellValue };
};
export const getBuildingsFieldName = ({ fieldName, t }) => {
    return t(`tabs.plots.section.building.columns.${snakeCase(fieldName)}`);
};
export const formatBuildingData = ({ buildings }) => {
    return buildings
        ? Object.values(buildings).map((building) => ({
            ...pick(building, fields),
        }))
        : null;
};
