import { useQuery } from '@tanstack/react-query';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { useTowns } from 'hooks/contexts/useLocationContext';
const IOPluRegulationUrl = iots.type({
    label: iots.string,
    url: iots.string,
}, 'IOPluRegulationUrl');
const IOPluRegulationItem = iots.type({
    type: iots.string,
    value: iots.string,
    urls: iots.union([iots.array(IOPluRegulationUrl), iots.undefined]),
}, 'IOPluRegulationInfoRegulation');
const IOPluRegulationInfo = iots.type({
    regulations: iots.record(iots.string, iots.array(IOPluRegulationItem)),
    zone: iots.union([iots.string, iots.null]),
    subZone: iots.union([iots.string, iots.null]),
    linear: iots.string,
});
const fetchPlotPluRegulation = (townshipId, plotId, lat, lng) => () => get(IOPluRegulationInfo, `legacy/${townshipId}/plu-regulation/${plotId}/${lat}/${lng}`);
const fetchPlotPluAdditionalRegulation = (townshipId, plotId, lat, lng) => () => get(IOPluRegulationInfo, `legacy/${townshipId}/plu-additional-regulation/${plotId}/${lat}/${lng}`);
const getQueryKey = (townshipId, plotId, lat, lng) => ['plu-regulation', townshipId, plotId, lat, lng];
const getAdditionalQueryKey = (townshipId, plotId, lat, lng) => ['plu-additional-regulation', townshipId, plotId, lat, lng];
const usePlotPluRegulation = (townshipId, plotId, lat, lng) => {
    const towns = useTowns();
    const town = towns[townshipId];
    const { data: pluRegulation, isInitialLoading: isLoadingPluRegulation } = useQuery({
        queryKey: getQueryKey(townshipId, plotId, lat, lng),
        queryFn: fetchPlotPluRegulation(townshipId, plotId, lat, lng),
        enabled: !!townshipId && !!plotId && !!lat && !!lng,
    });
    const { data: pluAdditionalRegulation, isInitialLoading: isLoadingPluAdditionalRegulation, } = useQuery({
        queryKey: getAdditionalQueryKey(townshipId, plotId, lat, lng),
        queryFn: fetchPlotPluAdditionalRegulation(townshipId, plotId, lat, lng),
        enabled: !!townshipId && !!plotId && !!lat && !!lng && !!town?.additionalPLU,
    });
    return {
        pluRegulation,
        isLoadingPluRegulation,
        pluAdditionalRegulation,
        isLoadingPluAdditionalRegulation,
    };
};
export default usePlotPluRegulation;
