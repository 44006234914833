import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormLabel, HStack, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Text, Tooltip, } from '@chakra-ui/react';
import useBuildingConsumption from 'hooks/useBuildingConsumption';
const DEFAULT_RADIUS = 10;
const BuildingConsumptionSliderExport = ({ lat, lng, plotNumber = '', }) => {
    const [radius, setRadius] = useState(DEFAULT_RADIUS);
    const [showTooltip, setShowTooltip] = useState(false);
    const { generateBuildingConsumption, isLoading } = useBuildingConsumption();
    const { t } = useTranslation();
    const handleDistanceChange = (value) => {
        setRadius(value);
    };
    const generateFile = () => {
        generateBuildingConsumption({ lat, lng, radius, plotNumber });
    };
    return (<Box width="full">
      <Text textStyle="paragraph">
        {t('sidebar_content.building_consumption.radius')}
      </Text>
      <HStack spacing={1} marginY="4px">
        <FormLabel textStyle="small" marginBottom={0} minWidth="45px" fontSize="12px">
          {`${radius} km`}
        </FormLabel>
        <Slider defaultValue={DEFAULT_RADIUS} min={5} max={20} step={1} onChange={(val) => handleDistanceChange(val)} onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)} maxWidth="250px">
          <SliderTrack bg="blue.100">
            <SliderFilledTrack bg="blue.500"/>
          </SliderTrack>
          <Tooltip hasArrow bg="blue.300" color="white" placement="top" isOpen={showTooltip} label={`${radius} km`}>
            <SliderThumb boxSize={4}/>
          </Tooltip>
        </Slider>
      </HStack>
      <Button size="sm" variant="outline" isLoading={isLoading} loadingText={t('global.status.download_long')} onClick={() => generateFile()} isDisabled={isLoading} whiteSpace="wrap">
        {t('sidebar_content.building_consumption.download', { radius })}
      </Button>
    </Box>);
};
export default BuildingConsumptionSliderExport;
