import * as React from 'react';
import DistributionPostSection from '../../../plot-project/Sections/DistributionPostSection';
import SourcePostSection from '../../../plot-project/Sections/SourcePostSection';
const ProjectEnergySection = ({ projectPlots, }) => {
    const plotId = projectPlots ? projectPlots[0].id : null;
    if (plotId === null) {
        return;
    }
    return (<>
      <SourcePostSection plotId={plotId}/>
      <DistributionPostSection plotId={plotId}/>
    </>);
};
export default ProjectEnergySection;
