import * as React from 'react';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, } from 'analytics/useAnalytics';
import pick from 'lodash/pick';
import ExportButton, { ExportItemButton } from 'components/buttons/ExportButton';
import { exportDataToXLSX } from 'components/export/excelUtils';
import { exportDataToPdf } from 'components/export/pdfUtils';
import { getAllSectionsSheets, getSingleSectionSheets, } from 'components/export/plot/plotExportUtils';
import { useLayers, useSelectedTown, useTowns, } from 'hooks/contexts/useLocationContext';
import { useMap } from 'hooks/contexts/useMapContext';
import { useGetFeasibilityAdditionalPlots, useGetFeasibilityPlots, } from 'hooks/contexts/useSecondaryPlotsContext';
import useNeighborhood from 'hooks/plot/useNeighborhood';
import usePlotBuildingPermit from 'hooks/plot/usePlotBuildingPermit';
import usePlotBuildings from 'hooks/plot/usePlotBuildings';
import usePlotBusiness from 'hooks/plot/usePlotBusiness';
import usePlotDistributionPost from 'hooks/plot/usePlotDistributionPost';
import usePlotDPE from 'hooks/plot/usePlotDPE';
import usePlotFeasibility from 'hooks/plot/usePlotFeasibility';
import usePlotOccupants from 'hooks/plot/usePlotOccupants';
import usePlotOwnersInfo from 'hooks/plot/usePlotOwnersInfo';
import usePlotPluRegulation from 'hooks/plot/usePlotPluRegulation';
import usePlotRPG from 'hooks/plot/usePlotRPG';
import usePlotSales from 'hooks/plot/usePlotSales';
import usePlotSourcePost from 'hooks/plot/usePlotSourcePost';
import useCurrentSite from 'hooks/useCurrentSite';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { BUILDING_PERMIT_PLOT_TAB, BUSINESS_PLOT_TAB, DPE_PLOT_TAB, ENERGY_DISTRIBUTION_POST_PLOT_TAB, ENERGY_PLOT_TAB, ENERGY_SOURCE_POST_PLOT_TAB, FEASIBILITY_ADDITIONAL_PLOT_TAB, FEASIBILITY_PLOT_TAB, OCCUPANT_PLOT_TAB, PLOT_PLOT_TAB, PLOT_RULES_PLOT_TAB, PLU_ADDITIONAL_REGULATION_PLOT_TAB, PLU_REGULATION_PLOT_TAB, RPG_PLOT_TAB, SALES_PLOT_TAB, } from 'types/api';
import { getImageMap } from 'utils/image';
import { isCompletelyEmpty } from 'utils/isEmptyExtend';
import { getPlotExportName, SECTIONS_EXPORT } from 'utils/plots';
import { hasOnlyFeasibilityAdditional, hasOnlyFeasibilityMain, } from 'utils/town';
import { DEFAULT_VALUES, getFeasibilityDefaultValues, } from './PlotSections/feasibility/calculator';
import { getFeasibilityWithCadastre } from './PlotSections/feasibility/PlotFeasibility';
const PlotExport = ({ plot, activeSection, disabled, }) => {
    const { t, i18n } = useTranslation();
    const towns = useTowns();
    const { trackGetNameExport, trackEvent } = useAnalytics();
    const { currentSite } = useCurrentSite();
    const { hasPlotTab } = useSiteConfiguration();
    const map = useMap();
    const { plotFeasibility, plotFeasibilityAdditional, isLoadingPlotFeasibility, } = usePlotFeasibility(plot.townId, plot.id, false);
    const { sourcePosts, isLoading: isLoadingSourcePosts } = usePlotSourcePost(plot?.id, hasPlotTab(ENERGY_PLOT_TAB));
    const { distributionPosts, isLoading: isLoadingDistribution } = usePlotDistributionPost(plot?.id, hasPlotTab(ENERGY_PLOT_TAB));
    const { plotRPG, isInitialLoading: isLoadingRPG } = usePlotRPG(plot, currentSite.legacyId, hasPlotTab(RPG_PLOT_TAB));
    const { occupants: occupant, isInitialLoading: isLoadingOccupants } = usePlotOccupants(plot, hasPlotTab(OCCUPANT_PLOT_TAB));
    const { pluRegulation, pluAdditionalRegulation, isLoadingPluRegulation } = usePlotPluRegulation(plot.townId, plot.id, plot.lat, plot.lng);
    const { ownersByYear: owners, isInitialLoading: isLoadingOwners } = usePlotOwnersInfo(currentSite?.ownerSubscription, currentSite?.legacyId, plot, hasPlotTab('owner'));
    const { data: buildings, isInitialLoading: isLoadingBuildings } = usePlotBuildings(plot?.id, plot?.townId, hasPlotTab('building'));
    const { data: sales, isInitialLoading: isLoadingSales } = usePlotSales(plot.townId, plot.id, hasPlotTab(SALES_PLOT_TAB));
    const { buildingsPermits, isLoading: isLoadingPermit } = usePlotBuildingPermit(plot?.id, hasPlotTab(BUILDING_PERMIT_PLOT_TAB));
    const { data: neighborhood, isInitialLoading: isLoadingNeighborhood } = useNeighborhood(plot.lat, plot.lng, hasPlotTab('neighborhood'));
    const { dpe, isLoading: isLoadingDPE } = usePlotDPE(plot?.id, hasPlotTab(DPE_PLOT_TAB));
    const { business, isLoading: isLoadingBusiness } = usePlotBusiness(plot?.id, hasPlotTab(BUSINESS_PLOT_TAB));
    const [formFeasibility, setFormFeasibility] = useState(null);
    const [formFeasibilityAdditional, setFormFeasibilityAdditional] = useState(null);
    const loadingMap = {
        feasibility: isLoadingPlotFeasibility,
        plu_regulation: isLoadingPluRegulation,
        owner: isLoadingOwners,
        building: isLoadingBuildings,
        sales: isLoadingSales,
        neighborhood: isLoadingNeighborhood,
        occupant: isLoadingOccupants,
        energy_source_post: isLoadingSourcePosts,
        energy_distribution_post: isLoadingDistribution,
        rpg: isLoadingRPG,
        building_permit: isLoadingPermit,
        dpe: isLoadingDPE,
        business: isLoadingBusiness,
    };
    const mapData = {
        feasibility: plotFeasibility,
        feasibility_additional: plotFeasibilityAdditional,
        plu_regulation: pluRegulation,
        plu_additional_regulation: pluAdditionalRegulation,
        owner: owners,
        building: buildings,
        sales,
        neighborhood,
        plot,
        plot_rules: plot,
        occupant,
        building_permit: buildingsPermits,
        rpg: plotRPG,
        dpe,
        business,
    };
    const isLoadingActiveSection = loadingMap[activeSection];
    // Pour le CRM pas d'export actuellement
    const isDisabledSection = isCompletelyEmpty(mapData[activeSection]);
    const isLoadingSome = Object.values(loadingMap).some((loading) => loading);
    const getFeasibilityPlots = useGetFeasibilityPlots();
    const feasibilityPlots = getFeasibilityPlots(plot.id);
    const getFeasibilityAdditionalPlots = useGetFeasibilityAdditionalPlots();
    const feasibilityPlotsAdditional = getFeasibilityAdditionalPlots(plot.id);
    const fileName = getPlotExportName(plot, towns[plot.townId]?.name) +
        '_' +
        t('tabs.plots.export.filename.' + activeSection);
    const fileNameAll = getPlotExportName(plot, towns[plot.townId]?.name);
    const { getValues, reset } = useFormContext();
    const layers = useLayers();
    const selectedTown = useSelectedTown();
    // Initialize feasibility calculator context here to enable export even when tab has not been opened
    React.useEffect(() => {
        // Set PlotFeasibilityFormData
        if (plotFeasibility && !!feasibilityPlots) {
            const feasibilityWithCadastre = getFeasibilityWithCadastre(plotFeasibility, plot);
            reset(getFeasibilityDefaultValues({
                feasibility: feasibilityWithCadastre,
                feasibilityPlots,
            }));
        }
        else if (!plotFeasibility) {
            const noFeasibility = DEFAULT_VALUES;
            noFeasibility.constructibleSurface = plot.floorArea
                ? plot.floorArea
                : plot.surface;
            reset(noFeasibility);
        }
        setFormFeasibility(getValues());
    }, [feasibilityPlots, plotFeasibility]);
    React.useEffect(() => {
        // Set PlotFeasibilityFormData
        if (feasibilityPlotsAdditional && !!plotFeasibilityAdditional) {
            const feasibilityWithCadastre = getFeasibilityWithCadastre(plotFeasibilityAdditional, plot);
            reset(getFeasibilityDefaultValues({
                feasibility: feasibilityWithCadastre,
                feasibilityPlots,
            }));
        }
        else if (!plotFeasibilityAdditional) {
            const noFeasibility = DEFAULT_VALUES;
            noFeasibility.constructibleSurface = plot.floorArea
                ? plot.floorArea
                : plot.surface;
            reset(noFeasibility);
        }
        setFormFeasibilityAdditional(getValues());
    }, [feasibilityPlotsAdditional, plotFeasibilityAdditional]);
    const plotData = {
        activeSection,
        plot,
        plot_rules: plot,
        sourcePosts,
        distributionPosts,
        plotRPG,
        feasibility: {
            ...plotFeasibility,
            //We need to get last version of plot data when we export (ex feasibility secondary plots can be modified)
            formData: formFeasibility,
        },
        feasibilityAdditional: {
            ...plotFeasibilityAdditional,
            //We need to get last version of plot data when we export (ex feasibility secondary plots can be modified)
            formData: formFeasibilityAdditional,
        },
        feasibilityPlots,
        feasibilityPlotsAdditional,
        pluRegulation: hasPlotTab(PLU_REGULATION_PLOT_TAB)
            ? pluRegulation
            : {
                ...pluRegulation,
                regulations: pick(pluRegulation?.regulations, ['plu_zone']),
            },
        pluAdditionalRegulation: hasPlotTab(PLU_REGULATION_PLOT_TAB)
            ? pluAdditionalRegulation
            : {
                ...pluAdditionalRegulation,
                regulations: pick(pluAdditionalRegulation?.regulations, ['plu_zone']),
            },
        owners,
        buildings,
        sales,
        neighborhood,
        occupant,
        buildingsPermits,
        dpe,
        business,
        towns,
        plotLayers: layers[plot?.id],
        t,
        selectedTown,
        i18n,
    };
    const exportAnalyticsEvent = {
        category: ANALYTICS_CATEGORY_DOWNLOAD,
        action: trackGetNameExport(activeSection),
    };
    const hasPlotTabExtend = (section) => {
        switch (section) {
            case ENERGY_DISTRIBUTION_POST_PLOT_TAB:
                return hasPlotTab(ENERGY_PLOT_TAB);
            case ENERGY_SOURCE_POST_PLOT_TAB:
                return hasPlotTab(ENERGY_PLOT_TAB);
            default:
                return hasPlotTab(section);
        }
    };
    const exportAllData = async (type) => {
        const sectionFiltered = SECTIONS_EXPORT.filter((section) => {
            // Pour gérer l'affichage de la faisabilité arrété et en vigueur
            if (section === FEASIBILITY_PLOT_TAB) {
                if (hasOnlyFeasibilityAdditional(selectedTown) ||
                    !selectedTown.hasFeasibility) {
                    return false;
                }
            }
            else if (section === FEASIBILITY_ADDITIONAL_PLOT_TAB) {
                if (hasOnlyFeasibilityMain(selectedTown)) {
                    return false;
                }
                else if (selectedTown?.hasAdditionalFeasibility) {
                    return hasPlotTab(FEASIBILITY_PLOT_TAB);
                }
            }
            else if (section === PLU_ADDITIONAL_REGULATION_PLOT_TAB) {
                return hasPlotTab(PLU_REGULATION_PLOT_TAB);
            }
            else if (section === PLOT_PLOT_TAB) {
                return !hasPlotTab(PLOT_RULES_PLOT_TAB);
            }
            return hasPlotTabExtend(section);
        });
        let action = 'Onglet Tout exporter';
        if (type === 'XLSX') {
            action = 'Onglet Tout exporter Excel';
            exportDataToXLSX(fileNameAll, getAllSectionsSheets(sectionFiltered, plotData));
        }
        else {
            const image = await getImageMap(map);
            if (image) {
                image.title = selectedTown?.name + ' - ' + plot.plotNumber;
            }
            exportDataToPdf(fileNameAll, getAllSectionsSheets(sectionFiltered, plotData), image, 2);
        }
        trackEvent({ category: ANALYTICS_CATEGORY_DOWNLOAD, action });
    };
    return (<Box width="125px">
      <ExportButton isDisabled={disabled || isLoadingActiveSection} isDisabledCurrent={isDisabledSection} fileName={fileName} analyticsEvent={exportAnalyticsEvent} getSheets={() => getSingleSectionSheets(plotData)}>
        <ExportItemButton isDisabled={isLoadingSome} onExport={() => exportAllData('PDF')} id="export_all_pdf_button">
          {t('tabs.plots.export.export_all_as_pdf')}
        </ExportItemButton>
        <ExportItemButton isDisabled={isLoadingSome} onExport={() => exportAllData('XLSX')} id="export_all_xlsx_button">
          {t('tabs.plots.export.export_all_as_xls')}
        </ExportItemButton>
      </ExportButton>
    </Box>);
};
export default PlotExport;
