import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { FormControl, FormLabel, HStack, Input, Stack } from '@chakra-ui/react';
import { debounce } from 'lodash';
import AreaInputWrapper from 'components/inputs/AreaInputWrapper';
import MultiSelectCreateOption from 'components/inputs/MultiSelectCreateOption';
import MultiSelectInput, { multiSelectStyles, } from 'components/inputs/MultiSelectInput';
import SelectInput from 'components/inputs/SelectInput';
import TextInput from 'components/inputs/TextInput';
import APECodesModal from 'components/sidebar/PlotSearchSideBar/form/APECodesModal';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import useOwner from 'hooks/useOwner';
import { accordionBoxShadow } from 'utils/accordionArrowStyle';
import { STAFF_TYPE_OPTIONS } from 'utils/constants';
import { NUMBER_OF_OWNERS_KEY, TITLE_DEEDS_KEY, TYPE_OF_OWNERS_VALUES, } from 'utils/plotsSearch';
import { getPlotSelectOptions } from './ActualBuildingSearch';
export const ownerSearchFields = [
    'oneOrMoreOwners',
    'ownerType',
    'titleDeeds',
    'ownerMainActivity',
    'ownerName',
    'ownerSiren',
    'ageManager',
    'typeManager',
    'nbManager',
];
export const OwnerSearch = ({ isLoading, hasSearchField, form, }) => {
    const { t } = useTranslation();
    const { control } = form;
    const [searchText, setSearchText] = React.useState('');
    const selectedTown = useSelectedTown();
    const { register } = useFormContext();
    const { owners, isLoadingOwners } = useOwner({
        townshipId: selectedTown?.id,
        query: searchText,
    });
    const debouncedHandleInputChange = debounce((inputText, event) => {
        // prevent outside click from resetting inputText to ""
        if (event.action !== 'input-blur' && event.action !== 'menu-close') {
            setSearchText(inputText);
        }
        else {
            setSearchText('');
        }
    }, 500);
    const numberOfOwnersOptions = Object.keys(NUMBER_OF_OWNERS_KEY).map((key) => {
        return {
            label: t('sidebar.search_plots.number_of_owners_options.' +
                NUMBER_OF_OWNERS_KEY[key]),
            value: key,
        };
    });
    const typeOfOwnersOptions = TYPE_OF_OWNERS_VALUES.map((option) => {
        return {
            label: t('sidebar.search_plots.type_of_owners_options.' + option.label),
            value: option.value,
        };
    });
    if (!ownerSearchFields.some(hasSearchField)) {
        return null;
    }
    return (<Stack alignItems="start" padding={4} boxShadow={accordionBoxShadow} bgColor="colorMode.sidebar500" spacing={2} data-cy="owner_search">
      {hasSearchField('ownerName') && (<MultiSelectInput name="ownerName" isDisabled={isLoading} label={t('sidebar.search_plots.owner_name')} options={owners ?? []} isLoading={isLoadingOwners} placeholder={t('sidebar.search_plots.owner_name_placeholder')} onInputChange={(inputText, event) => {
                debouncedHandleInputChange(inputText, event);
            }} noOptionsMessage={({ inputValue }) => inputValue?.length
                ? t('global.no_result')
                : t('sidebar.search_plots.owner_name_placeholder')}/>)}

      {hasSearchField('ownerSiren') && (<TextInput isLoading={isLoading} name="ownerSiren" label={t('sidebar.search_plots.owner_siren')} placeholder={t('sidebar.search_plots.owner_siren_placeholder')} size="sm" type="text"/>)}

      {hasSearchField('oneOrMoreOwners') && (<Stack width="100%" spacing={1}>
          <FormControl isDisabled={isLoading}>
            <FormLabel>
              {t('sidebar.search_plots.one_or_more_owners')}
            </FormLabel>
            <Controller name="numberOfOwners" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_number_of_owners')} noOptionsMessage={() => t('sidebar.search_plots.no_number_of_owners')} closeMenuOnSelect={false} isMulti options={numberOfOwnersOptions} styles={multiSelectStyles} loadingMessage={() => t('global.loading')}/>)}/>
          </FormControl>
        </Stack>)}

      {hasSearchField('ownerMainActivity') && (<>
          <MultiSelectCreateOption label={t('sidebar.search_plots.owner_main_activity')} name="ownerMainActivity" placeholder={t('sidebar.search_plots.owner_main_activity_placeholder')} isDisabled={isLoading} noOptionsMessage={() => t('sidebar.search_plots.owner_main_activity_helper')} formatCreateLabel={(inputValue) => `${t('global.action.add')} ${inputValue}`}/>

          <APECodesModal />
        </>)}

      {hasSearchField('ownerType') && (<Stack width="100%" spacing={1}>
          <FormControl isDisabled={isLoading}>
            <FormLabel>{t('sidebar.search_plots.type_of_owners')}</FormLabel>
            <Controller name="typeOfOwners" control={control} render={({ field }) => (<Select {...field} placeholder={t('sidebar.search_plots.placeholder_type_of_owners')} noOptionsMessage={() => t('sidebar.search_plots.no_type_of_owners')} closeMenuOnSelect={false} isMulti options={typeOfOwnersOptions} styles={multiSelectStyles} loadingMessage={() => t('global.loading')} menuPlacement="top"/>)}/>
          </FormControl>
        </Stack>)}

      {hasSearchField('titleDeeds') && (<SelectInput size="sm" isLoading={isLoading} name="titleDeeds" label={t('sidebar.search_plots.title_deeds')} options={getPlotSelectOptions(TITLE_DEEDS_KEY, 'title_deeds', t)}/>)}

      {hasSearchField('ageManager') && (<FormControl>
          <FormLabel htmlFor="ageManager" marginBottom={1}>
            {t('sidebar.search_plots.age_manager')}
          </FormLabel>
          <HStack>
            <AreaInputWrapper>
              <Input size="sm" placeholder="Min" maxLength={3} {...register('ageManagerMin')} type="number" data-cy="min_age_manager_input"/>
            </AreaInputWrapper>
            <AreaInputWrapper>
              <Input size="sm" placeholder="Max" maxLength={3} {...register('ageManagerMax')} type="number" data-cy="max_age_manager_input"/>
            </AreaInputWrapper>
          </HStack>
        </FormControl>)}

      {hasSearchField('typeManager') && (<SelectInput size="sm" isLoading={isLoading} name="typeManager" placeholder={t('global.select.none.placeholder2')} label={t('sidebar.search_plots.type_manager')} options={STAFF_TYPE_OPTIONS}/>)}

      {hasSearchField('nbManager') && (<FormControl>
          <FormLabel htmlFor="nbManager" marginBottom={1}>
            {t('sidebar.search_plots.nb_manager')}
          </FormLabel>
          <AreaInputWrapper>
            <Input size="sm" maxLength={3} placeholder={t('global.input.enter_number')} {...register('nbManager', { valueAsNumber: true })} type="number" data-cy="nb_manager_input"/>
          </AreaInputWrapper>
        </FormControl>)}
    </Stack>);
};
export default OwnerSearch;
