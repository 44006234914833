import * as React from 'react';
import { useMutation } from '@tanstack/react-query';
import { queryClient } from 'app';
import { t } from 'i18next';
import * as iots from 'io-ts';
import { post } from 'api/client';
import { QUERY_KEY as PLOT_QUERY_KEY } from 'hooks/crm/useUserPlotsList';
import useCustomToast from 'hooks/useCustomToast';
const importPlotRequest = (importFile) => {
    const formData = new FormData();
    formData.append('file', importFile.file[0], importFile.file[0]?.name);
    return post(null, `plot/import-client/file`, formData, null, false, {
        headers: { 'Content-Type': 'multipart/form-data;' },
    });
};
const finishImportPlotRequest = (importPlots) => {
    return post(null, `plot/import-client/treatment`, importPlots, null, false);
};
export const IOPlotReport = iots.type({
    lineImported: iots.number,
    lineIgnored: iots.number,
    plotCreated: iots.number,
    plotAlreadyExist: iots.number,
    contactCreated: iots.number,
    contactUpdated: iots.number,
    plotIds: iots.array(iots.string),
    errors: iots.array(iots.string),
});
const useImportPlots = () => {
    const { getSuccessToast } = useCustomToast();
    const { mutate: importPlots, isPending: isLoadingImport } = useMutation({ mutationFn: importPlotRequest });
    const { mutate: finishImportPlotsMutate, isPending: isLoading } = useMutation({
        mutationFn: finishImportPlotRequest,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: PLOT_QUERY_KEY });
        },
    });
    const finishImportPlots = (importContacts, onSuccess) => {
        finishImportPlotsMutate(importContacts, {
            onSuccess: (response) => {
                getSuccessToast({
                    title: (<>
              <p>
                {t('plot.import.imported_plots_lines', {
                            count: response.lineImported,
                        })}
              </p>
              <p>
                {t('plot.import.ignored_plots_lines', {
                            count: response.lineIgnored,
                        })}
              </p>
              <p>
                {t('plot.import.created_plots_lines', {
                            count: response.plotCreated,
                        })}
              </p>
              <p>
                {t('plot.import.already_exist_lines', {
                            count: response.plotAlreadyExist,
                        })}
              </p>
              {response.contactCreated > 0 && (<p>
                  {t('plot.import.created_contacts_lines', {
                                count: response.contactCreated,
                            })}
                </p>)}
              {response.contactUpdated > 0 && (<p>
                  {t('plot.import.updated_contacts_lines', {
                                count: response.contactUpdated,
                            })}
                </p>)}
              {response.errors.map((error, index) => {
                            return <p key={index}>{error}</p>;
                        })}
            </>),
                    duration: response.errors.length > 0 ? 30000 : 10000,
                    id: 'imported_plots',
                });
                onSuccess(response);
            },
        });
    };
    return {
        importPlots,
        isLoadingImport,
        finishImportPlots,
        isLoading,
    };
};
export default useImportPlots;
