import * as React from 'react';
import DPESection from 'components/tabs/plot-project/Sections/DPESection';
import useProjectDPE from 'hooks/project/useProjectDPE';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PROJECT_DPE } from 'types/api';
const ProjectDPESection = ({ project, }) => {
    const { hasProjectTab } = useSiteConfiguration();
    const { dpe, isLoading } = useProjectDPE(project?.id, hasProjectTab(PROJECT_DPE));
    return <DPESection dpe={dpe} type="project" isLoading={isLoading}/>;
};
export default ProjectDPESection;
