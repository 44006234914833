import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Heading, HStack, Link, Stack, Text, VStack, } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_CATEGORY_DOWNLOAD, } from 'analytics/useAnalytics';
import i18next from 'i18next';
import DownloadLinkWrapper from 'components/links/DownloadLinkWrapper';
import { useTowns } from 'hooks/contexts/useLocationContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { PLU_REGULATION_PLOT_TAB } from 'types/api';
import { isGermanySite, isSwissSite } from 'utils/site';
import { getPlotSkeleton } from '../PlotPannelSkeletons';
const PlotPlu = ({ plot, pluInfo, type = 'PLU', isLoading, }) => {
    const { t } = useTranslation();
    const towns = useTowns();
    const { trackEvent } = useAnalytics();
    const { hasPlotTab } = useSiteConfiguration();
    const skeleton = getPlotSkeleton();
    if (isLoading)
        return skeleton;
    if (!towns[plot?.townId] || !pluInfo || pluInfo?.zone === null) {
        return (<Box margin={4}>
        <Text textStyle="paragraph">
          {t('tabs.plots.section.plu_regulation.not_available')}
        </Text>
      </Box>);
    }
    const hideCategory = (category) => {
        if (pluInfo.regulations[category].length === 0) {
            return true;
        }
        if (category === 'plu_zone') {
            return false;
        }
        return ((type === 'PLU_ADDITIONAL' && !hasPlotTab(PLU_REGULATION_PLOT_TAB)) ||
            (type === 'PLU' && !hasPlotTab(PLU_REGULATION_PLOT_TAB)));
    };
    return (<VStack padding={4}>
      <Box bgColor="colorMode.background900" boxShadow="md" width="100%">
        {Object.keys(pluInfo.regulations).map((category) => {
            if (hideCategory(category)) {
                return <React.Fragment key={category}/>;
            }
            return (<Stack width="100%" key={category} spacing={0}>
              <HStack backgroundColor="colorMode.background700">
                <Box paddingLeft={3} paddingY={2}>
                  <Heading as="h5" variant="h5" fontWeight={700} color="colorMode.font800">
                    {t(`tabs.plots.section.plu_regulation.category_title.${category}`)}
                    {i18next.exists(`tabs.plots.section.plu_regulation.category_subtitle.${category}`) && (<Text as="span" paddingLeft={2} fontWeight={400}>
                        {t(`tabs.plots.section.plu_regulation.category_subtitle.${category}`)}
                      </Text>)}
                  </Heading>
                </Box>
              </HStack>
              <Box>
                {pluInfo.regulations[category].map((regulation, index) => {
                    return (<HStack alignItems="flex-start" key={index} paddingY={1} backgroundColor="colorMode.background900" data-cy="plot_regulation_category">
                      <Box paddingLeft={3} width="30%" minWidth="200px">
                        <Text textStyle="paragraph" fontWeight={400}>
                          {regulation.type ?? t('global.not_defined')}
                        </Text>
                      </Box>
                      <HStack width="70%">
                        <Text pr={5} textStyle="paragraph" fontWeight={400}>
                          {regulation.value}
                        </Text>
                        {regulation.urls?.map((link, index) => {
                            return (link.url && (<DownloadLinkWrapper key={index}>
                                <Link target="_blank" href={link.url} onClick={() => trackEvent({
                                    category: ANALYTICS_CATEGORY_DOWNLOAD,
                                    action: 'Télécharger règlement onglet PLU',
                                })}>
                                  {link.label
                                    ? link.label
                                    : t('tabs.plots.section.plu_regulation.download_document')}
                                </Link>
                              </DownloadLinkWrapper>));
                        })}
                      </HStack>
                    </HStack>);
                })}
              </Box>
            </Stack>);
        })}
      </Box>

      {Object.keys(pluInfo.regulations).length > 0 &&
            type === 'PLU' &&
            hasPlotTab(PLU_REGULATION_PLOT_TAB) &&
            !isSwissSite() &&
            !isGermanySite() && (<HStack justifyContent="flex-start" width="100%" paddingTop={2}>
            <Text textStyle="small" textAlign="start" alignSelf="flex-start">
              {t('tabs.plots.section.plu_regulation.more_info')}
            </Text>
          </HStack>)}
    </VStack>);
};
export default PlotPlu;
