import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import * as iots from 'io-ts';
import { get } from 'api/client';
import { IOPlotLayer } from 'types/api';
export const QUERY_KEY = 'project-layers';
export const getProjectLayersQueryKey = (projectId, landPointId) => [QUERY_KEY, projectId, landPointId];
const fetchProjectLayers = (projectId, landPointId) => () => get(iots.array(IOPlotLayer), `project/${projectId}/layers`, { landPointId });
const useProjectLayers = (townshipId, projectId, landPointId) => {
    const [progress, setProgress] = useState(0);
    const { data: projectLayers, isInitialLoading: isLoading, isFetching, } = useQuery({
        queryKey: getProjectLayersQueryKey(projectId, landPointId),
        queryFn: fetchProjectLayers(projectId, landPointId),
        enabled: !!projectId,
    });
    useEffect(() => {
        if (!isFetching)
            return;
        const interval = setInterval(async () => {
            const res = await axios.get(`/api/project-layers/${projectId}/progress`);
            setProgress(res.data);
            if (res.data >= 100) {
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    }, [isFetching, projectId]);
    return {
        projectLayers,
        isLoading,
        isFetching,
        progress,
    };
};
export default useProjectLayers;
