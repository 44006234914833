import { useMemo } from 'react';
import { usePlotDistributionPostsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
const usePlotDistributionPostLayer = () => {
    const plotDistributionPostsHighlights = usePlotDistributionPostsHighlights();
    const distributionPostsItems = useMemo(() => {
        return Object.values(plotDistributionPostsHighlights).map((distributionPostHighlights) => ({
            ...distributionPostHighlights,
            borderWidth: 5,
            layerKey: filtersKey.PLOT_DISTRIBUTION_POST,
        }));
    }, [plotDistributionPostsHighlights]);
    createLayersHook({
        providedData: distributionPostsItems,
    })(filtersKey.PLOT_DISTRIBUTION_POST);
};
const PlotDistributionPostLayer = () => {
    usePlotDistributionPostLayer();
    return null;
};
export default PlotDistributionPostLayer;
