import * as React from 'react';
import { useEffect, useState } from 'react';
import { createContext } from 'use-context-selector';
import { CADASTRE_ZOOM_LIMIT } from 'components/layers/CadastreLayer';
import { useFilters, useSetFilters, useToggleFilter, useUpdateFilters, } from 'hooks/contexts/useFiltersContext';
import { useCloseLayout, useSetLayoutHalf, useTabsView, } from 'hooks/contexts/useLayoutContext';
import { useLastTownAddedToHistory, useSelectedTown, useSetDisplayedPlot, } from 'hooks/contexts/useLocationContext';
import { useClearParentFeasibility } from 'hooks/contexts/useSecondaryPlotsContext';
import useUserHistory from 'hooks/crm/useUserHistory';
import useHash from 'hooks/useHash';
import { usePrevious } from 'hooks/usePrevious';
import { filtersKey } from 'utils/filters';
import { TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL, TABS_VIEW_HALF, } from './LayoutContext';
export const PLOT_TAB_TYPE = 'plot';
export const PROJECT_TAB_TYPE = 'project';
export const CONTACT_TAB_TYPE = 'contact';
export const DASHBOARD_TAB_TYPE = 'dashboard';
export const CRM_TAB_TYPE = 'crmTab';
export const FILTER_TAB_TYPE = 'filterTab';
export const CRM_TABS = {
    USER_PLOTS: 'user_plots',
    USER_PROJECTS: 'user_projects',
    CONTACT_LIST: 'contact_list',
    TEAM_ACCESS_RIGHTS: 'team_access_rights',
    TEAM_PLOTS: 'team_plots',
    TEAM_PROJECTS: 'team_projects',
    TEAM_STATISTICS: 'team_statistics',
    TEAM_STATISTICS_CRM: 'team_statistics_crm',
    DASHBOARD: 'dashboard',
    MY_STATISTICS: 'my_statistics',
    MY_STATISTICS_CRM: 'my_statistics_crm',
    ACTIVITY: 'activity',
    COURIER: 'courier',
    USER_PLOTS_NEWS: 'user_plots_news',
    LAND_POINT: 'land_point',
    PERSONALIZATION: 'personalization',
};
// Tabs linked to a filter checked / search filled in urbanism or plot finder menu
const FILTER_LAYER_TABS = [
    filtersKey.COMPANIES,
    filtersKey.BANKRUPT_COMPANIES,
    filtersKey.BANKRUPT_COMPANIES_PLOTS,
    filtersKey.COMPANY_OWNED_PLOTS,
    filtersKey.PLOTS_SEARCH,
    filtersKey.CNAC,
    filtersKey.DEATH,
    filtersKey.AGRICULTURE_REARING_COMPANY,
    filtersKey.CO_OWNER,
];
// Building filter has a special format with child filters
export const FILTERS_BUILDING_PERMIT = {
    [filtersKey.BUILDING_PERMIT_HOUSING]: [
        filtersKey.BUILDING_PERMIT_HOUSING_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_HOUSING_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_HOUSING_WORK_DONE,
        filtersKey.BUILDING_PERMIT_HOUSING_CANCELED_PERMITS,
    ],
    [filtersKey.BUILDING_PERMIT_OFFICES]: [
        filtersKey.BUILDING_PERMIT_OFFICES_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_OFFICES_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_OFFICES_WORK_DONE,
        filtersKey.BUILDING_PERMIT_OFFICES_CANCELED_PERMITS,
    ],
    [filtersKey.BUILDING_PERMIT_PLANNING_PERMIT]: [
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_ALLOWED_PERMITS,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_STARTED,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_WORK_DONE,
        filtersKey.BUILDING_PERMIT_PLANNING_PERMITS_CANCELED_PERMITS,
    ],
};
// Layer tabs related to user info
export const CRM_LAYER_TABS = [
    CRM_TABS.USER_PLOTS,
    CRM_TABS.CONTACT_LIST,
    CRM_TABS.USER_PROJECTS,
    CRM_TABS.TEAM_PLOTS,
    CRM_TABS.TEAM_PROJECTS,
];
// Layer tabs related to selected town (plot search or filters)
export const ALL_FILTER_TABS_KEYS = [
    ...FILTER_LAYER_TABS,
    ...Object.values(FILTERS_BUILDING_PERMIT).flat(),
];
// All tabs linked to any layer displayed on the map
export const ALL_LAYER_TABS_KEYS = [...ALL_FILTER_TABS_KEYS, ...CRM_LAYER_TABS];
export const TabsContext = createContext(null);
export const TabsProvider = ({ children }) => {
    const filters = useFilters();
    const setFilters = useSetFilters();
    const toggleFilter = useToggleFilter();
    const updateFilters = useUpdateFilters();
    const setLayoutHalf = useSetLayoutHalf();
    const closeLayout = useCloseLayout();
    const tabsView = useTabsView();
    const setDisplayedPlot = useSetDisplayedPlot();
    const [lastTownAddedToHistory, setLastTownAddedToHistory] = useLastTownAddedToHistory();
    const { addAddressToHistory, addPlotToHistory } = useUserHistory(false);
    const clearAllPlotFeasibility = useClearParentFeasibility();
    const selectedTown = useSelectedTown();
    // Keep plots/projects/contacts/tabs order
    const [tabs, setTabs] = useState([]);
    const previousTabs = usePrevious(tabs);
    // one project = one tab
    const [projects, setProjects] = useState({});
    // one contact = one tab
    const [contacts, setContacts] = useState({});
    // on plot = one tab
    const [plots, setPlots] = useState({});
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedTab, setSelectedTab] = React.useState(null);
    const currentTab = tabs.find((tab) => tab.id === selectedTab);
    const selectedPlot = plots[currentTab?.id];
    const selectedProject = projects[currentTab?.id];
    useEffect(() => {
        // Before the plot is loaded, we will still get a plot with only a few fields.
        // We need to wait for the full plot to be loaded before saving it to history
        if (!selectedPlot || !selectedPlot.address) {
            return;
        }
        // When the selected plot changes, we save the plot address only if the town was not yet saved
        if (selectedPlot.townId === lastTownAddedToHistory) {
            return;
        }
        const address = selectedPlot.address.length
            ? selectedPlot.address[0]
            : selectedPlot.street && selectedPlot.street !== ''
                ? selectedPlot.street + ' ' + selectedPlot.town
                : selectedPlot.town;
        addAddressToHistory({
            townId: selectedPlot.townId,
            address: address,
            lat: selectedPlot.lat,
            lng: selectedPlot.lng,
            acuteness: CADASTRE_ZOOM_LIMIT,
        });
        setLastTownAddedToHistory(selectedPlot.townId);
    }, [selectedPlot]);
    const resetTabs = ({ resetLayerTabs }) => {
        setSelectedTab(null);
        setProjects({});
        setContacts({});
        setPlots({});
        setLayoutHalf();
        if (resetLayerTabs) {
            resetLayerTabsFilters();
        }
        setTabs([]);
    };
    const { sectionHash, setSectionHashToUrl, clearSectionHash } = useHash();
    useEffect(() => {
        if (Object.keys(plots)?.length === 0) {
            clearSectionHash();
        }
    }, [plots]);
    const removeTab = (tabId) => {
        setTabs((previousTabs) => {
            const newTabs = [...previousTabs];
            const deletedTabIndex = newTabs.findIndex((tab) => tab.id === tabId);
            if (deletedTabIndex > -1) {
                newTabs.splice(deletedTabIndex, 1);
            }
            return newTabs;
        });
    };
    // When selected tab is removed, we select a new one
    useEffect(() => {
        const tabsIds = tabs.map((tab) => tab.id);
        if (!!selectedTab && tabsIds.includes(selectedTab)) {
            return;
        }
        selectNextTab();
    }, [tabs]);
    const deleteTab = (tab) => {
        if (tab.type === PLOT_TAB_TYPE) {
            removeTab(tab.id);
            setPlots((prevPlots) => {
                const { [tab.id]: _, ...cleanedPlots } = prevPlots;
                return cleanedPlots;
            });
            // Suppression des faisabilités (vu que l'on ferme la parcelle)
            clearAllPlotFeasibility(tab.id);
            return;
        }
        if (tab.type === CONTACT_TAB_TYPE) {
            removeTab(tab.id);
            setContacts((prevContacts) => {
                const { [tab.id]: _, ...cleanedContacts } = prevContacts;
                return cleanedContacts;
            });
            return;
        }
        if (tab.type === PROJECT_TAB_TYPE) {
            removeTab(tab.id);
            toggleFilter(filtersKey.MAP_PLOTS, false);
            setProjects((prevProjects) => {
                const { [tab.id]: _, ...cleanedProjects } = prevProjects;
                return cleanedProjects;
            });
            return;
        }
        if (tab.type === FILTER_TAB_TYPE) {
            toggleLayerTab({ tabId: tab.id, closeTab: true });
        }
        if (tab.type === CRM_TAB_TYPE) {
            toggleCRMTab({ tabId: tab.id, closeTab: true });
        }
    };
    const showResourceTab = (setter, type) => ({ resource }) => {
        setter((prevResources) => {
            const newResources = { ...prevResources };
            newResources[resource.id] = resource;
            return newResources;
        });
        setTabs((previousTabs) => {
            const newTabs = [...previousTabs];
            if (!newTabs.find((tab) => tab.id === resource.id)) {
                newTabs.push({ id: resource.id, type });
            }
            return newTabs;
        });
        changeSelectedTab(resource.id, TABS_VIEW_HALF);
    };
    const showPlot = ({ resource, ...rest }) => {
        setDisplayedPlot(resource);
        setLayoutHalf();
        return showResourceTab(setPlots, PLOT_TAB_TYPE)({
            ...rest,
            resource,
        });
    };
    const updatePlot = (newPlotData) => {
        setPlots((prevPlots) => {
            const newPlots = { ...prevPlots };
            // If plot was not already fully loaded, we add it to the history
            if (!prevPlots[newPlotData?.id] || !prevPlots[newPlotData?.id]?.address) {
                addPlotToHistory({
                    plotId: newPlotData.id,
                    cadastreId: newPlotData.cadastreId,
                    townshipId: newPlotData.townId,
                    townshipName: newPlotData.town,
                    lat: newPlotData.lat,
                    lng: newPlotData.lng,
                });
            }
            newPlots[newPlotData?.id] = newPlotData;
            return newPlots;
        });
    };
    const showProject = ({ resource, ...rest }) => {
        return showResourceTab(setProjects, PROJECT_TAB_TYPE)({ ...rest, resource });
    };
    const updateProjectName = ({ projectId, newName, }) => {
        setProjects((prevProjects) => {
            return {
                ...prevProjects,
                [projectId]: { ...prevProjects[projectId], label: newName },
            };
        });
    };
    const showContact = ({ resource, ...rest }) => {
        return showResourceTab(setContacts, CONTACT_TAB_TYPE)({ ...rest, resource });
    };
    const updateContactInfo = ({ contactId, newInfo }) => {
        setContacts((prevContacts) => {
            return {
                ...prevContacts,
                [contactId]: { ...prevContacts[contactId], ...newInfo },
            };
        });
    };
    const toggleCRMTab = ({ tabId, closeTab }) => {
        if (closeTab) {
            removeTab(tabId);
            return;
        }
        setTabs((previousTabs) => {
            const newTabs = [...previousTabs];
            if (!newTabs.find((tab) => tab.id === tabId)) {
                newTabs.push({ id: tabId, type: CRM_TAB_TYPE });
            }
            return newTabs;
        });
        changeSelectedTab(tabId, TABS_VIEW_HALF);
    };
    const resetLayerTabsFilters = () => {
        const filtersToRemove = {};
        ALL_LAYER_TABS_KEYS.map((key) => (filtersToRemove[key] = false));
        setFilters((filters) => ({
            ...filters,
            ...filtersToRemove,
        }));
    };
    const toggleLayerTab = ({ tabId, closeTab, skipFilter, layout = TABS_VIEW_HALF, }) => {
        if (closeTab) {
            if (!skipFilter) {
                const updatedFilters = { [tabId]: false };
                if (Object.keys(FILTERS_BUILDING_PERMIT).includes(tabId)) {
                    FILTERS_BUILDING_PERMIT[tabId].forEach((filter) => (updatedFilters[filter] = false));
                }
                updateFilters(updatedFilters);
            }
            removeTab(tabId);
            return;
        }
        if (!skipFilter) {
            toggleFilter(tabId, true);
        }
        setTabs((previousTabs) => {
            const newTabs = [...previousTabs];
            if (!newTabs.find((tab) => tab.id === tabId)) {
                newTabs.push({ id: tabId, type: FILTER_TAB_TYPE });
            }
            return newTabs;
        });
        changeSelectedTab(tabId, layout);
    };
    const adaptLayerTabs = (filters) => {
        // Open or close tabs depending on associated filter state
        ;
        [...FILTER_LAYER_TABS, ...CRM_LAYER_TABS]?.map((tabId) => {
            if (isTabOpened(tabId) && !filters[tabId]) {
                toggleLayerTab({ tabId, closeTab: true, skipFilter: true });
            }
            else if (!!filters[tabId] && !isTabOpened(tabId)) {
                toggleLayerTab({ tabId, skipFilter: true });
            }
        });
        Object.keys(FILTERS_BUILDING_PERMIT).map((tabId) => {
            const hasChildFiltersChecked = FILTERS_BUILDING_PERMIT[tabId].some((el) => !!filters[el]);
            if (isTabOpened(tabId) && !hasChildFiltersChecked) {
                toggleLayerTab({ tabId, closeTab: true, skipFilter: true });
            }
            else if (!isTabOpened(tabId) && hasChildFiltersChecked) {
                toggleLayerTab({ tabId, skipFilter: true });
            }
        });
    };
    useEffect(() => {
        adaptLayerTabs(filters);
    }, [filters]);
    const selectNextTab = () => {
        if (!tabs.length) {
            setSelectedTab(null);
            return;
        }
        const selectedTabIndex = previousTabs.findIndex((tab) => tab.id === selectedTab);
        const selectedTabData = previousTabs[selectedTabIndex];
        // Tabs sorted by closest index, first the ones that are before our selected tab, then the ones that are after
        const tabsSortedByClosestIndex = [...tabs].sort((a, b) => {
            const previousIndexA = previousTabs.findIndex((tab) => tab.id === a.id);
            const previousIndexB = previousTabs.findIndex((tab) => tab.id === b.id);
            const aIsBefore = previousIndexA < selectedTabIndex;
            const bIsBefore = previousIndexB < selectedTabIndex;
            // if tab was not here before, it comes last
            if (previousIndexA === -1) {
                return 1;
            }
            if (previousIndexB === -1) {
                return -1;
            }
            if (aIsBefore === bIsBefore) {
                // Closest comes first
                return (Math.abs(selectedTabIndex - previousIndexA) -
                    Math.abs(selectedTabIndex - previousIndexB));
            }
            // Before comes first
            return aIsBefore ? -1 : 1;
        });
        // First, we try to stay in the same town
        const townId = selectedTown?.id;
        if (townId) {
            const favoriteType = selectedTabData &&
                (selectedTabData.type === PLOT_TAB_TYPE ||
                    selectedTabData.type === PROJECT_TAB_TYPE)
                ? selectedTabData.type
                : PLOT_TAB_TYPE;
            const tabsInSameTown = tabsSortedByClosestIndex.filter((tab) => {
                if (tab.type === PLOT_TAB_TYPE) {
                    const plot = plots[tab.id];
                    return plot?.townId === townId;
                }
                if (tab.type === PROJECT_TAB_TYPE) {
                    const project = projects[tab.id];
                    return project?.townId === townId;
                }
                return false;
            });
            const favoriteTypeInSameTown = tabsInSameTown.filter((tab) => tab.type === favoriteType);
            if (favoriteTypeInSameTown.length) {
                setSelectedTab(favoriteTypeInSameTown[0].id);
                return;
            }
            if (tabsInSameTown.length) {
                setSelectedTab(tabsInSameTown[0].id);
                return;
            }
        }
        // If we have no plot or project in the same town, we select the first tab that is not town related
        const tabsWithoutTown = tabsSortedByClosestIndex.filter((tab) => tab.type !== PLOT_TAB_TYPE && tab.type !== PROJECT_TAB_TYPE);
        if (tabsWithoutTown.length) {
            setSelectedTab(tabsWithoutTown[0].id);
            return;
        }
        // Else we simply select the first tab
        setSelectedTab(tabsSortedByClosestIndex[0].id);
    };
    const changeSelectedTab = (tabId, layout = TABS_VIEW_HALF) => {
        setSelectedTab(tabId);
        if ([TABS_VIEW_CLOSED, TABS_VIEW_CLOSED_VERTICAL]?.includes(tabsView) &&
            layout === TABS_VIEW_HALF) {
            setLayoutHalf();
        }
        else if (layout === TABS_VIEW_CLOSED ||
            layout === TABS_VIEW_CLOSED_VERTICAL) {
            closeLayout();
        }
    };
    const isTabOpened = (tabId) => {
        return !!tabId && tabs.map((tab) => tab?.id)?.includes(tabId);
    };
    return (<TabsContext.Provider value={{
            tabs,
            selectedTab,
            changeSelectedTab,
            showPlot,
            selectedBuilding,
            setSelectedBuilding,
            selectedSection,
            setSelectedSection,
            projects,
            selectedProject,
            showProject,
            updateProjectName,
            contacts,
            showContact,
            updateContactInfo,
            plots,
            selectedPlot,
            updatePlot,
            toggleCRMTab,
            toggleLayerTab,
            isTabOpened,
            sectionHash,
            setSectionHashToUrl,
            resetTabs,
            deleteTab,
        }}>
      {children}
    </TabsContext.Provider>);
};
export default TabsContext;
