import { useMemo } from 'react';
import { usePlotSourcePostsHighlights } from 'hooks/contexts/useSecondaryPlotsContext';
import createLayersHook from 'hooks/layers/createLayersHook';
import { filtersKey } from 'utils/filters';
const usePlotSourcePostLayer = () => {
    const plotSourcePostsHighlights = usePlotSourcePostsHighlights();
    const sourcePostsItems = useMemo(() => {
        return Object.values(plotSourcePostsHighlights).map((sourcePostHighlights) => ({
            ...sourcePostHighlights,
            borderWidth: 5,
            layerKey: filtersKey.PLOT_SOURCE_POST,
        }));
    }, [plotSourcePostsHighlights]);
    createLayersHook({
        providedData: sourcePostsItems,
    })(filtersKey.PLOT_SOURCE_POST);
};
const PlotSourcePostLayer = () => {
    usePlotSourcePostLayer();
    return null;
};
export default PlotSourcePostLayer;
