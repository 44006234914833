import * as React from 'react';
const EyeIcon = (props) => (<svg width="18px" height="18px" viewBox="0 0 9.946 9.946" {...props} xmlns="http://www.w3.org/2000/svg">
    <g>
      <g>
        <path d="M4.973 7.679c-1.118 0 -2.322 -0.437 -3.481 -1.265 -0.866 -0.619 -1.401 -1.231 -1.423 -1.257a0.281 0.281 0 0 1 0 -0.368c0.022 -0.026 0.557 -0.639 1.423 -1.257 1.159 -0.827 2.363 -1.265 3.481 -1.265s2.322 0.437 3.481 1.265c0.866 0.619 1.401 1.232 1.423 1.257a0.281 0.281 0 0 1 0 0.368c-0.022 0.026 -0.557 0.639 -1.423 1.257 -1.159 0.828 -2.363 1.265 -3.481 1.265M0.667 4.973c0.216 0.22 0.624 0.608 1.163 0.992 0.739 0.526 1.884 1.152 3.143 1.152s2.404 -0.627 3.143 -1.152c0.539 -0.383 0.947 -0.771 1.163 -0.992 -0.216 -0.22 -0.624 -0.608 -1.163 -0.992 -0.739 -0.526 -1.884 -1.152 -3.143 -1.152s-2.404 0.627 -3.143 1.152c-0.539 0.383 -0.947 0.771 -1.163 0.992"/>
      </g>
      <g>
        <path d="M4.973 6.717c-0.962 0 -1.744 -0.782 -1.744 -1.744s0.782 -1.744 1.744 -1.744a1.742 1.742 0 0 1 1.149 0.432c0.117 0.102 0.129 0.28 0.026 0.397s-0.28 0.129 -0.397 0.026a1.18 1.18 0 0 0 -0.778 -0.292c-0.651 0 -1.181 0.53 -1.181 1.181s0.53 1.181 1.181 1.181 1.181 -0.53 1.181 -1.181c0 -0.155 0.126 -0.281 0.281 -0.281s0.281 0.126 0.281 0.281c0 0.961 -0.782 1.744 -1.744 1.744"/>
      </g>
      <g>
        <path d="M4.973 5.535c-0.31 0 -0.563 -0.252 -0.563 -0.563s0.252 -0.563 0.563 -0.563 0.563 0.252 0.563 0.563 -0.252 0.563 -0.563 0.563"/>
      </g>
    </g>
  </svg>);
export default EyeIcon;
