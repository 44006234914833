import * as React from 'react';
import { Box } from '@chakra-ui/react';
import DPESection from 'components/tabs/plot-project/Sections/DPESection';
import usePlotDPE from 'hooks/plot/usePlotDPE';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import { DPE_PLOT_TAB } from 'types/api';
const PlotDPE = ({ plot }) => {
    const { hasPlotTab } = useSiteConfiguration();
    const { dpe, isLoading } = usePlotDPE(plot?.id, hasPlotTab(DPE_PLOT_TAB));
    return (<Box width="full" padding="4">
      <DPESection dpe={dpe} type="plots" isLoading={isLoading}/>
    </Box>);
};
export default PlotDPE;
