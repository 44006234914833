import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';
const fields = ['year', 'labels'];
export const formatRPGCellValue = ({ cellValue, fieldName, t }) => {
    if (!cellValue) {
        return { value: t(`tabs.plots.section.rpg.nothing`), halign: 'left' };
    }
    if (fieldName === 'year') {
        return { value: cellValue, halign: 'center' };
    }
    return { value: cellValue, halign: 'left' };
};
export const getRPGFieldName = ({ fieldName, t }) => {
    if (fieldName === 'labels') {
        fieldName = 'farming';
    }
    return t(`tabs.plots.export.header.${snakeCase(fieldName)}`);
};
export const formatRPGData = ({ plotRPG }) => {
    if (!plotRPG)
        return null;
    return plotRPG.map((farming) => {
        return pick(farming, fields);
    });
};
