import { filtersKey } from 'utils/filters';
import { getTileBoundingBox } from 'utils/geo';
import { CST_POSITIONS } from 'utils/tilesPositions';
import createTilesLayerHook from 'hooks/layers/createTilesLayerHook';
const getLayerTileUrl = (map, tileWidth, tileHeight) => (coord, zoom) => {
    const boundingBox = getTileBoundingBox(coord, zoom, map, tileWidth, tileHeight);
    return ('https://mapsref.brgm.fr/wxs/georisques/rapport_risques?' +
        'LAYERS=SUP_INDUS' +
        '&SERVICE=WMS' +
        '&TRANSPARENT=true' +
        '&VERSION=1.3.0&REQUEST=GetMap' +
        '&FORMAT=image/png' +
        '&TILED=true&WIDTH=' +
        tileWidth +
        '&HEIGHT=' +
        tileHeight +
        '&CRS=EPSG:3857&STYLES=&BBOX=' +
        boundingBox);
};
const getLegends = () => {
    const legends = {};
    legends.ppr_icpe_industrial_risk = {
        layerKey: filtersKey.PPR_ICPE_INDUSTRIAL_RISK,
        label: 'ppr_icpe_industrial_risk',
        needsTranslate: true,
        color: '#F5A5F7',
        opacity: 1,
    };
    return legends;
};
const useICPEIndustrialRiskLayer = () => {
    createTilesLayerHook({
        getLayerTileUrl,
        position: CST_POSITIONS[filtersKey.PPR_ICPE_INDUSTRIAL_RISK],
        getLegends,
        opacity: 0.8,
    })(filtersKey.PPR_ICPE_INDUSTRIAL_RISK);
};
const ICPEIndustrialRiskLayer = () => {
    useICPEIndustrialRiskLayer();
    return null;
};
export default ICPEIndustrialRiskLayer;
