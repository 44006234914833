import * as React from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { CONTACT_TAB_TYPE, CRM_TABS } from 'context/TabsContext';
import useTabTitle from 'hooks/navigation/useTabTitle';
import CalendarIcon from 'images/icons/Calendar';
import ContactsIcon from 'images/icons/Contacts';
import EnvelopeIcon from 'images/icons/Envelope';
import PlotIcon from 'images/icons/Plot';
import ProjectIcon from 'images/icons/Project';
import ProtectionIcon from 'images/icons/Protection';
import StatisticIcon from 'images/icons/Statistic';
import TabItemWrapper, { TabItemTitle, } from './TabItemWrapper';
const TAB_ICON = {
    [CRM_TABS.CONTACT_LIST]: <ContactsIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.USER_PLOTS]: <PlotIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.USER_PROJECTS]: (<ProjectIcon fill="currentColor" width="15px" height="16px"/>),
    [CRM_TABS.TEAM_PLOTS]: <PlotIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.TEAM_PROJECTS]: (<ProjectIcon fill="currentColor" width="15px" height="16px"/>),
    [CRM_TABS.TEAM_STATISTICS]: (<StatisticIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.TEAM_STATISTICS_CRM]: (<StatisticIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.MY_STATISTICS]: <StatisticIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.MY_STATISTICS_CRM]: (<StatisticIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.TEAM_ACCESS_RIGHTS]: (<ProtectionIcon fill="currentColor" width="15px"/>),
    [CRM_TABS.ACTIVITY]: <CalendarIcon fill="currentColor" width="15px"/>,
    [CRM_TABS.COURIER]: <EnvelopeIcon fill="currentColor" width="17px"/>,
};
const getTabIcon = (tab) => {
    if (tab.type === CONTACT_TAB_TYPE) {
        return (<Box color="blue.500" marginRight={1}>
        {TAB_ICON[CRM_TABS.CONTACT_LIST]}
      </Box>);
    }
    if (TAB_ICON[tab.id]) {
        return (<Box color="blue.500" marginRight={1}>
        {TAB_ICON[tab.id]}
      </Box>);
    }
    return null;
};
const CRMTabItem = (props) => {
    const { tab } = props;
    const { getTitle } = useTabTitle();
    const title = getTitle(tab);
    return (<TabItemWrapper {...props} tooltipContent={title}>
      <HStack marginRight={1}>
        <Box>{getTabIcon(tab)}</Box>
        <TabItemTitle title={title}/>
      </HStack>
    </TabItemWrapper>);
};
export default CRMTabItem;
