import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Icon, IconButton, Text, VStack } from '@chakra-ui/react';
import useAnalytics, { ANALYTICS_TELECOMMANDE } from 'analytics/useAnalytics';
import { useSelectedTown } from 'hooks/contexts/useLocationContext';
import { useMenuOpened, useToggleMenu } from 'hooks/contexts/useMenuContext';
import useSiteConfiguration from 'hooks/useSiteConfiguration';
import DashboardIcon from 'images/icons/Dashboard';
import ExpandIcon from 'images/icons/Expand';
import FoncierIcon from 'images/icons/Foncier';
import NewsIcon from 'images/icons/News';
import UrbanismeIcon from 'images/icons/Urbanisme';
import { menuItems } from '../utils';
import MenuExtra from './MenuExtra';
import VerticalMenuButton from './VerticalMenuButton';
const VERTICAL_MENU_ITEMS = [
    {
        key: menuItems.DASHBOARD,
        modularisation_key: 'dashboard',
        icon: DashboardIcon,
    },
    { key: menuItems.LAYERS_TREE, icon: UrbanismeIcon, needsTown: true },
    {
        key: menuItems.SEARCH_PLOTS,
        modularisation_key: 'search',
        icon: FoncierIcon,
        needsTown: true,
    },
    { key: menuItems.NEWS, modularisation_key: 'news', icon: NewsIcon },
];
const ToggleMenuButton = () => {
    const { t } = useTranslation();
    const { trackEvent } = useAnalytics();
    const menuOpened = useMenuOpened();
    const toggleMenu = useToggleMenu();
    return (<Box marginTop={3} marginBottom={3} textAlign="center" onClick={() => {
            trackEvent({
                category: ANALYTICS_TELECOMMANDE,
                action: menuOpened ? 'Réduire' : 'Agrandir',
            });
            toggleMenu();
        }}>
      <IconButton backgroundColor="colorMode.background900" _hover={{
            backgroundColor: 'colorMode.background900',
        }} aria-label={'expand_remote_control'} height={6} icon={<Icon as={ExpandIcon} fill={'red.500'} transform={!menuOpened ? 'rotateY(0deg)' : 'rotateY(180deg)'} transition="transform 0.5s" width={25} height={25}/>} data-cy="vertical_menu_toggle_btn"/>
      <Text textStyle="paragraph" color="red.500">
        {menuOpened ? t('tabs.panel.reduce') : t('tabs.panel.deploy')}
      </Text>
    </Box>);
};
const VerticalMenu = () => {
    const { hasMenuItem } = useSiteConfiguration();
    const toggleMenu = useToggleMenu();
    const selectedTown = useSelectedTown();
    return (<VStack cursor="pointer" overflowY="auto" className="no-print" zIndex={21} boxShadow="lg" width="75px" backgroundColor="colorMode.background900">
      <VStack spacing={0}>
        {VERTICAL_MENU_ITEMS.map((menuItem) => {
            if (menuItem?.modularisation_key &&
                !hasMenuItem(menuItem?.modularisation_key)) {
                return null;
            }
            const isDisabled = menuItem?.needsTown && !selectedTown;
            return (<VerticalMenuButton key={menuItem?.key} Icon={menuItem?.icon} disabled={isDisabled} menuItemKey={menuItem.key}/>);
        })}
      </VStack>
      <ToggleMenuButton />
      <Box data-cy="low_vertical_menu" flex={1} width="100%" onClick={() => {
            toggleMenu();
        }}/>

      <MenuExtra />
    </VStack>);
};
export default VerticalMenu;
