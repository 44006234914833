import * as React from 'react';
import { memo } from 'react';
import { Translation, useTranslation } from 'react-i18next';
import { Box, Stack, Text } from '@chakra-ui/react';
import { ANALYTICS_CRM } from 'analytics/useAnalytics';
import snakeCase from 'lodash/snakeCase';
import PlotButton from 'components/buttons/PlotButton';
import SirenLink from 'components/links/SirenLink';
import Table from 'components/table/Table';
import { formatNumberToLocale } from 'utils/formatNumber';
import { getPlotStatusLabel } from 'utils/personalization';
export const getColumnHeader = (column, suffix = null) => (<Text align="center">
    <Translation>
      {(t) => t(`tabs.extra_tabs.agriculture_rearing_company.columns.${column}`)}
    </Translation>
    {suffix}
  </Text>);
const initialSort = [
    {
        id: 'town',
        desc: false,
    },
];
const EXPORTED_FIELDS = [
    'plotNumber',
    'town',
    'surface',
    'plotStatus',
    'company',
    'siren',
    'surfaceTotal',
    'codeAPE',
];
const formatExportHeader = (t) => (header) => t(`tabs.extra_tabs.agriculture_rearing_company.columns.${snakeCase(header)}`);
const formatExportValue = (value, field) => {
    if (field === 'plotStatus') {
        return getPlotStatusLabel(value);
    }
    return value;
};
const AgricultureRearingCompanyTable = ({ id, data, isLoading }) => {
    const { t, i18n } = useTranslation();
    const columns = [
        {
            id: 'columns',
            columns: [
                {
                    accessorKey: 'plotNumber',
                    header: () => getColumnHeader('plot_number'),
                    cell: (info) => (<Box textAlign="left">
              <PlotButton lat={info.row.original.lat} lng={info.row.original.lng} townId={info.row.original.townId} plotId={info.row.original.plotId} variant="link-primary" analyticDetail="Ouvrir fiche parcelle fiche sociétés agricoles et élevage" analyticOrigin={ANALYTICS_CRM}>
                {info.getValue()}
              </PlotButton>
            </Box>),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'town',
                    header: () => getColumnHeader('town'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'subZone',
                    header: () => getColumnHeader('sub_zone'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'surface',
                    header: () => getColumnHeader('surface'),
                    cell: (info) => info.getValue(),
                    filterFn: 'inNumberRange',
                },
                {
                    accessorKey: 'plotStatus',
                    header: () => getColumnHeader('plot_status'),
                    accessorFn: (row) => getPlotStatusLabel(row.plotStatus),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'company',
                    header: () => getColumnHeader('company'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'siren',
                    header: () => getColumnHeader('siren'),
                    cell: (info) => {
                        return (<SirenLink siren={info.getValue()} analyticDetail="Pappers sociétés agricoles et élevage" showExternalLink/>);
                    },
                    filterFn: 'arrIncludesSome',
                },
                {
                    accessorKey: 'surfaceTotal',
                    header: () => getColumnHeader('surface_total'),
                    cell: (info) => formatNumberToLocale(info.getValue(), i18n.language),
                    filterFn: 'inNumberRange',
                },
                {
                    accessorKey: 'codeAPE',
                    header: () => getColumnHeader('code_ape'),
                    cell: (info) => info.getValue(),
                    filterFn: 'arrIncludesSome',
                },
            ],
        },
    ];
    return (<Stack paddingX={4} height="calc(100% - 20px)">
      <Table variant="simple" id={id} columns={columns} data={data} initialSort={initialSort} isLoading={isLoading} exportTitle={t(`tabs.extra_tabs.agriculture_rearing_company.title_exp`)} exportedFields={EXPORTED_FIELDS} formatExportHeader={formatExportHeader(t)} formatExportValue={formatExportValue}/>
    </Stack>);
};
export default memo(AgricultureRearingCompanyTable);
